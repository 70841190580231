import React from "react";
import Authcheck from "../../components/Authcheck/Authcheck";

import moment from "moment";

import "./ChatText.scss";

const ChatText = ({ message, userId }) => {
  const { current_date, text, userid, username,documents } = message;
  console.log("disputemsg",message);
  if(documents){
    var splitted = documents.split('/'); //this will output ["1234", "56789"]
    var filename = splitted[4]; 
      }

  return (
    <> 
    <div className="container">
                <Authcheck />
        {
            userid == localStorage.getItem('username') ? <div>
            <div className={"chat-text" + (+userId === userid ? " lightblue self" : " lightred" )} style={{ maxWidth: "90%", marginLeft: "10%" }}>
            <p className="time">{moment(current_date).format("DD-MM-YYYY")}</p>
            {documents?
        <><a href={documents} download target="_blank" style={{ lineBreak: "anywhere", fontSize:"13px", color:"blue"}}>{filename} &#8595;</a></>
        :
        <></>
          }
              <p style={{ lineBreak: "anywhere"}}><b>{text}</b></p>
             <p className="time" style={{ textAlign: "end"}}>{username}&nbsp;&nbsp;{moment(current_date).format("h:mm a")}</p>
            </div>
          </div> : <div >
            <div className={"chat-text" + (+userId === userid ? " lightblue self" : " lightred")} style={{ maxWidth: "90%", background: "none"}}>
            <p className="time"  >{moment(current_date).format("DD-MM-YYYY")}</p>
            {documents?
        <><a href={documents} download target="_blank" style={{ lineBreak: "anywhere", fontSize:"13px", color:"blue"}}>{filename} &#8595;</a></>
        :
        <></>
          }
              <p style={{ lineBreak: "anywhere"}}><b>{text}</b></p>
              <p className="time" style={{ textAlign: "end"}}>{username}&nbsp;&nbsp; {moment(current_date).format("h:mm a")}</p>
            </div>
          </div>
          }
        </div>
        </>   
     
  );
  };


export default ChatText;
