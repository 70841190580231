import React, { useState, useEffect } from 'react'
import Authcheck from "../../../components/Authcheck/Authcheck";
import axios from 'axios'
import ReactPaginate from 'react-paginate';
// import "./Blockuser.scss";
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation, withTranslation } from "react-i18next";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem('userId');
    const  token = localStorage.getItem("token");

function Shortreleasecontract() {
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState(null);
  const [pageCount, setPageCount] = useState(0)
  const [t, i18n] = useTranslation('common');

  const getPostData = (data) => {


    return (
      
      <section className=" hero--full hero--v10 d-flex align-items-center hidden" style={{ minHeight: '300px', paddingBottom: '0px',paddingTop: "10px" }}>
         <div class="container">
        <Authcheck />
          <div className="conuter-header">
            <h4 class="sendWOWb">Release Contract</h4>
          </div>
          <div className="counterdown-content">
                      {/* Countdown  */}
                      <div class="row">
                        <div class="col-lg-12">
                          <table class="table table-bordered">
                            <thead>
                            <tr>
                           <th >ID</th>
                           <th className="tableCancel">Released Date</th>
                           <th className="tableCancel">Created date</th>
                           {/* <th className="tableAnd">Title</th> */}
                           <th className="tableAnd">Trade Partner</th>
                           <th>Amount</th>
                           <th>Trade WOWCoin Amount</th>
                       </tr>
                            </thead>
                            
                  {data ?
                    <tbody>
                      {data.map((item,i) => (
                          <tr key={item.id}>
                                                       <td key={item.id}>{item?.id}</td>
                                                       <td key={item.id}>{item?.updated_at}</td>
                                                       <td key={item.id}>{item?.created_at}</td>
                                                       {/* <td key={item.id}>{item?.headline}</td> */}
                                                     <td key={item.id}>{item?.usermaker}</td>
                                                     <td key={item.id}>{item?.fiat_amount}</td>
                          <td key={item.id}>{item?.bch_amount}</td>
                                                   </tr>
                      ))}
                    </tbody>
                    :
                    <tbody style={{ textAlign:'center' }} className="borderTab" >
                      <tr>
                        <td>{t("Transactions.No data Found")}</td>
                      </tr>
                    </tbody>
                  }
                          </table>
                        </div>
                      </div>
                    </div>
         
        </div>
        <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
      </section>

    )

  }

  const getAllPosts = async () => {
    const res = await axios.get(`${baseURL}/api/trades/get_two_released_contract/${UserId}`);
    // console.log("release data", res);
    if (res.data.data) {
      const data = res.data.data;
      const slice = data.slice(offset - 1, offset - 1 + postsPerPage)
  
      // For displaying Data
  
      // Using Hooks to set value
      setAllPosts(slice)
      setPageCount(Math.ceil(data.length / postsPerPage)) 
    } else {
      setAllPosts(null)
    }
  }

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const unBlockuser = (id) =>{
    Axios.post(`${baseURL}/api/auth/unblock_user/${id}`, {
      headers: {
         "Authorization" : `Bearer ${token}`
      }
       })
    .then(response => {
      toast.success(t("userprofile.User Unblocked Successfully"));
      // console.log("response", response);
      getAllPosts();
      // history.push('/offers/trade')
    }).catch(error => {
      toast.success("Error in Unblocking");
    })
  }

  useEffect(() => {
    getAllPosts()
  }, [offset])

  return (
    <div className="main-app">

      {/* Display all the posts */}
      {getPostData(posts)}

      {/* Using React Paginate */}
      {/* <ReactPaginate
        previousLabel={t('Withdraw.Previous')}
        nextLabel={t('Withdraw.Next')}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"} /> */}
    </div>
  );
}

export default Shortreleasecontract;


// // reactstrap components
// import React, { useState, useEffect } from 'react'
// import axios from 'axios'
// import ReactPaginate from 'react-paginate';
// import { Link } from "react-router-dom";
// import "./Shortcontract.scss";
// const baseURL = process.env.REACT_APP_BASE_URL;
// // import { useHistory } from "react-router-dom";

// // import { ToastContainer, toast } from 'react-toastify';
// // import 'react-toastify/dist/ReactToastify.css';

// function Shortreleasecontract() {
//   const [postsPerPage] = useState(10);
//   const [offset, setOffset] = useState(1);
//   const [posts, setAllPosts] = useState([]);
//   const [pageCount, setPageCount] = useState(0);
//   const [data, setData] = useState();
//   // console.log("data", data);

//   const getPostData = (data) => {
//     return (
//       <>
//            <section className=" hero--v10 d-flex align-items-center hidden closedabc">
//         <div className="container">
//             <div className="row flex-column-reverse flex-lg-row reveal">


//                 <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox ">
//                     <h5><b>Released Contract</b></h5><br />
//                      <div class="container">
//                   <div class="table">
//                     <table class="table-responsive">
//                       <thead>
//                         <tr>
//                           <th >ID</th>
//                           <th className="tableCancel">Released Date</th>
//                           <th className="tableCancel">Created date</th>
//                           <th className="tableAnd">Title</th>
//                           <th className="tableAnd">Trade Partner</th>
//                         </tr>


//                       </thead>
//                       {/* <tbody>
//                         {data.map((item) => (
//                           <tr key={item.id}>
//                             <td key={item.id}>{item?.id}</td>
//                             <td key={item.id}>{item?.updated_at}</td>
//                             <td key={item.id}>{item?.created_at}</td>
//                             <td key={item.id}>{item?.headline}</td>
//                             <td key={item.id}>{item?.username}</td>
//                           </tr>
//                         ))}
//                         <tr >

//                         </tr>

//                       </tbody> */}
//                     </table>
//                   </div>
//                 </div>
              

//                 </div>
//             </div>



//         </div>
//     </section>
//       </>
//     );
//   };


//   const getAllTrade = async () => {
//     const userId = localStorage.getItem("userId");
//     const res = await axios.get(`${baseURL}/api/trades/gettwoclosedcontract/${userId}`)
//     console.log("release", res.data );
//     const data = res.data;
//     setData(data);
//     const slice = data.slice(offset - 1, offset - 1 + postsPerPage)
//     // For displaying Data
//     const postData = getPostData(slice)
//     // Using Hooks to set value
//     setAllPosts(postData)
//     setPageCount(Math.ceil(data.length / postsPerPage))
//   }

//   const handlePageClick = (event) => {
//     const selectedPage = event.selected;
//     setOffset(selectedPage + 1);
//     let start = selectedPage * postsPerPage;
//     let end = start + postsPerPage;
//     const slice = data.slice(start,end);
//     const postData = getPostData(slice);
//     setAllPosts(postData)
//     setPageCount(Math.ceil(data.length / postsPerPage))
//   };

//   useEffect(() => {
//     getAllTrade()
//   }, [offset])

//   return (
//     <div className="main-app">

//       {/* Display all the posts */}
//       {posts}

//       {/* Using React Paginate */}
//       {/* <ReactPaginate
//         previousLabel={t('Withdraw.Previous')}
//         nextLabel={t('Withdraw.Next')}
//         breakLabel={"..."}
//         breakClassName={"break-me"}
//         pageCount={pageCount}
//         onPageChange={handlePageClick}
//         containerClassName={"pagination"}
//         subContainerClassName={"pages pagination"}
//         activeClassName={"active"} /> */}
//     </div>
//   );
// }


// export default Shortreleasecontract;
