import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link } from "react-router-dom";
import { Form, Input, Button, Select } from "antd";
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { fetchAccount } from "../../store/actions/myAccountActions";
import {useTranslation, withTranslation} from "react-i18next";
import "./Tier1AddressValidation.scss";
const baseURL = process.env.REACT_APP_BASE_URL;
// const url = `${baseURL}/api/verification/tier1_address_validation`;

const Account = (props) => {
  const [t, i18n] = useTranslation('common');
  const { Option } = Select;
  const [data, setData] = useState({});
  const [getCountry, setGetCountry] = useState([]);
  const formRef = React.useRef(null);

  useEffect(  () => {
    if(sessionStorage.getItem("verificationmsg")!=''){
      toast.success(sessionStorage.getItem("verificationmsg"));
      sessionStorage.removeItem("verificationmsg");
     }

     try {
      Axios.get(`${baseURL}/api/auth/getcountry`).then((response) => {
        // console.log("getcountry response.....", response.data);
        setGetCountry(response.data);
      });
    } catch (error) {
      // console.log(error);
    }
  
  }, []);


  const submit = (e) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    // console.log('data:', data);
    const sendData = {
      userid: userId,
      addressline1: data.addressline1,
      addressline2: data.addressline2,
      city: data.city,
      state: data.state,
      country: data.country,
      postalcode: data.postalcode
    };
    // console.log("sendData", sendData)
    Axios.post(`${baseURL}/api/verification/tier1_address_validation`, sendData, {
      headers: {
         "Authorization" : `Bearer ${token}`
      }
       })
      .then(res => {
        //toast.success("Profile Updated Successfully");
        // console.log("res.data.......", res.data);
        sessionStorage.setItem("addressmsg", "Address Details Submmited Successfully");
        props.history.push("/verification");
      }).catch(error => {
        // console.log("error:", error);
        toast.error("Address Details Submmited Failed");
         //window.location.reload();
      })
  }

  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };


  const [userInfo, setUserInfo] = useState({});

  const useraccount = async () => {
    let myData = await fetchAccount();
    // console.log('myData:', myData);
    setUserInfo(myData);
  };

  useEffect( () => {
    useraccount();
  }, []);


function handle(e) {
  // console.log('e.target.id:', e.target.id);
  let newdata = data;
  newdata[e.target.id] = e.target.value
  setData({ ...data, [e.target.id]: e.target.value })
  // console.log("newdata......", newdata);
}


  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">

            <div className="profile-container">
              <div className="secondary-nav">
                <div className="tab-link">
                  <Link to="/my-account" activeClassName="is-active">
                  {t('Profile.Profile')}
                  </Link>
                </div>
                <div className="tab-link">
                  <Link to="/settings" activeClassName="is-active">
                  {t("Profile.Security")}
                  </Link>
                </div>

                <div className="tab-link">
                  <Link to="/verification" activeClassName="is-active">
                  {t("Profile.Verification")}
                  </Link>
                </div>
{/* 
                <div className="tab-link">
                  <Link to="/notifications" activeClassName="is-active">
                    Notifications
                  </Link>
                </div> */}

                {/* <div className="tab-link">
                  <Link to="/advanced" activeClassName="is-active">
                  {t("Profile.Advanced")}
                  </Link>
                </div> */}
              </div>

              <br></br>
              <div class="row">Address validation
                <div class="col-12 col-lg-6 pr-lg-0">

                  <div className="container">
                <Authcheck />
                    <div className="row  mb-4">
                      <div className="col kyc-form-wrapper">
                        <h4 className="mb-3">Tier 1 Address validation</h4>
                        <Form
                          initialValues={{
                            remember: true,
                          }}
                         ref={formRef}
                            onFinish={(e) => {
                              submit(e);
                            }}
                        >
                          <input type="hidden" name="csrfmiddlewaretoken" defaultValue="ZjGWaPOXbRaUcs9jHDcsR8haW0HBqnG8lcM9gDmuF02hTmV21Nnpd22eQx3vYrek" />
                          <div id="div_id_address1" className="form-group">
                             <Form.Item
                                  name="addressline1"
                                  id="addressline1"
                                  onChange={(e) => handle(e)}
                                  label="Address Line 1*"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your postal code!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                             <div id="hint_id_address1" className="help-block">Street address, P.O. box</div>
                            </div> 
                              
                            <div id="div_id_address2" className="form-group"> 
                            <Form.Item
                                  name="addressline2"
                                  id="addressline2"
                                  onChange={(e) => handle(e)}
                                  label="Address Line 2*"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your address line 2!",
                                    },
                                  ]}
                                >
                                  <Input />
                            </Form.Item>
                                 <div id="hint_id_address2" className="help-block">Apartment, suite, unit, building, floor</div>
                            </div>
                              <div id="div_id_city" className="form-group">
                                <Form.Item
                                  name="city"
                                  id="city"
                                  onChange={(e) => handle(e)}
                                  label="City*"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your city!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </div>
                               <div id="div_id_state" className="form-group"> 
                               <Form.Item
                                  name="state"
                                  id="state"
                                  onChange={(e) => handle(e)}
                                  label="State*"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your state!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                               </div>

                                <div id="div_id_country" className="form-group">
                                <Form.Item
                                    label={t("Profile.Choose a country code")}
                                    name="country"
                                    value={data.countrycode}
                                    id="country"
                                    rules={[
                                      {
                                        required: true,
                                        message: t("Profile.Please select your nationality"),
                                      },
                                    ]}
                                  >
                                 <Select
                                  onChange={(e) => {
                                    // console.log('country e:', e);
                                    setData({ ...data, country: e })
                                  }} >
                                  {getCountry.map((value, index) => {
                                    return <Option value={value.label}>{value.label}</Option>
                                  })}

                                </Select>
                                </Form.Item>
                                

                               </div>
                                 <div id="div_id_postal_code" className="form-group"> 
                                 <Form.Item
                                  name="postalcode"
                                  id="postalcode"
                                  onChange={(e) => handle(e)}
                                  label="postal code*"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your postal code!",
                                    },
                                  ]}
                                >
                                  <Input />
                              </Form.Item>
                                 </div>

                                 <Button type="primary" htmlType="submit">
                                  {t("Withdraw.Submit")}
                                </Button>
                          </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
    </section>
  );
};

export default Account;
