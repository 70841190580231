import React, { useState } from "react";
import Shortcancelcontract from "./Shortcontract/Shortcancelcontract";
import Shortclosecontract from "./Shortcontract/Shortclosecontract";
import Shortreleasecontract from "./Shortcontract/Shortreleasecontract";
import { Route, NavLink } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import "./Contract.css";
import Closecontract from "./Contractall/Closecontract";
import Cancelcontract from "./Contractall/Cancelcontract";

const Contract = () => {
    const [t, i18n] = useTranslation("common");
    const [option, setOption] = useState("1");

    return (
        <div className="mytrade-page">
            <div className="options-wrap">
                <p
                    className={
                        option === "1" ? "contract-options" : "contract-non-options"
                    }
                    onClick={() => {
                        setOption("1");
                    }}
                >
                    Closed/Completed Contract list
                </p>
                <p
                    className={
                        option === "2" ? "contract-options" : "contract-non-options"
                    }
                    onClick={() => {
                        setOption("2");
                    }}
                >
                    Cancelled Contract list
                </p>
            </div>
            {option === "1" ? (
                <>
                    {" "}
                    <Closecontract />
                </>
            ) : (
                <>
                    <Cancelcontract />
                </>
            )}
        </div>
    );
};

export default Contract;