import React, { useState, useEffect } from "react";
import { Breadcrumb, Form, Select, Radio, Input, Button } from "antd";
import { Link, useParams } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import Axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { confirm } from "react-confirm-box";
import "./OfferFormContainer.css";
import { ReactComponent as Percentage } from "../../assets/icons/percentage.svg";
import {
  payMethodData,
  currencyTypesData,
  exchangeTypesData,
  selectTimesData,
} from "../../helpers/dummyData";
import {
  setBuyBCH,
  setCurrency,
  setInput,
  setTime,
  setPaymentMethod,
  setExchange,
  setIsDynamicPrice,
  setDefaultLimits,
  setVerifiedOnly,
  createOffer,
  setTradeTerms,
  setDefaultTime,
  fetchOffer,
  updateOffer,
  deleteOffer,
} from "../../store/actions/myOffersActions";
import SectionSix from "../../components/Home/sections/sectionsix";

const initialUIState = {
  firstSelect: false,
  geoSelect: false,
  paySelect: false,
  currencySelect: false,
  dynamicSelect: false,
  reviewPriceSelect: false,
  confirmPriceSelect: false,
  limitSelect: false,
  headlineSelect: false,
  termsSelect: false,
  hoursSelect: false,
  verifiedSelect: false,
};

const baseURL = process.env.REACT_APP_BASE_URL;

const OfferFormContainer = (props) => {
  const { offerId } = useParams();
  const [getType, setType] = useState(initialUIState);
  const [getLiveprice, setLiveprice] = useState("");
  const [getLiveprice1, setLiveprice1] = useState("");
  const coinStatsAPI = "https://openapiv1.coinstats.app/coins/usd?currency=";
  const offerForm = useSelector((state) => state.myOffers.offerForm);
  const dispatch = useDispatch();

  const [formUI, setFormUI] = useState(initialUIState);

  const { Option } = Select;

  useEffect(() => {
    dispatch(
      setCurrency({
        currencyType: "KRW",
        currencySymbol: "₩",
      })
    );
    console.log("offerForm", offerForm);
    console.log("formUI", formUI);
    if (sessionStorage.getItem("offermsg")) {
      toast.success(t("newoffers." + sessionStorage.getItem("offermsg")));
      sessionStorage.removeItem("offermsg");
    }

    if (sessionStorage.getItem("offererror")) {
      toast.error(t("newoffers." + sessionStorage.getItem("offererror")));
      sessionStorage.removeItem("offererror");
    }

    if (sessionStorage.getItem("tradeerror")) {
      toast.error(t("newoffers." + sessionStorage.getItem("tradeerror")));
      sessionStorage.removeItem("tradeerror");
    }

    if (offerId !== undefined) {
      let editUI = {};
      for (let prop in formUI) {
        editUI[prop] = true;
      }
      setFormUI(editUI);
      dispatch(fetchOffer(offerId));
    }
  }, []);

  const onSelectHandle = (value) => {
    setType(value);
    setBuyorsell(value);
    let buyBCH = false;
    if (value === "buyBCH") {
      buyBCH = true;
    }
    dispatch(setBuyBCH(buyBCH));
    setFormUI({
      ...formUI,
      firstSelect: !offerForm.firstSelect,
    });
  };
  const onCheckHandle = (e) => {
    setType(e.target.value);
    setBuyorsell(e.target.value);
    let buyBCH = false;
    if (e.target.value === "buyBCH") {
      buyBCH = true;
    }
    dispatch(setBuyBCH(buyBCH));
  };
  const onSelectCurrency = (value) => {
    const currency = currencyTypesData.filter((cur) => value === cur.name);
    const { symbol } = currency[0];
    dispatch(
      setCurrency({
        currencyType: value,
        currencySymbol: symbol,
      })
    );
  };

  const onInputHandle = (e) => {
    if (e.target.name == "limitMin") {
      LivePrice(e.target.value);
    }

    if (e.target.name == "limitMax") {
      LivePrice1(e.target.value);
    }
    dispatch(setInput(e));
  };

  const LivePrice = async (min) => {
    try {
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "X-API-KEY": "6UEisKAzxdGYrzCYCGJTFIBPQMY8XGGtFDHkEPU4Fgc=",
        },
      };
      const result = await Axios.get(
        `${coinStatsAPI}${offerForm.currencyType}`, options
      );
      let price = result.data.price * 100;
      if (offerForm.currencyType === "USD") {
        const resp = await Axios.get(`${baseURL}/api/wowprice/`);
        price = Number(resp.data.price);
      }
      let adjPrice =
        price +
        (offerForm.marginAbove
          ? price * (offerForm.margin / 100)
          : -price * (offerForm.margin / 100));
      let onewow = 1 / adjPrice;
      let finalwow = onewow * min;
      setLiveprice(finalwow.toFixed(8));
    } catch {
      // console.log("error in tier 0");
    }
  };
  const LivePrice1 = async (max) => {
    try {
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "X-API-KEY": "6UEisKAzxdGYrzCYCGJTFIBPQMY8XGGtFDHkEPU4Fgc=",
        },
      };
      const result = await Axios.get(
        `${coinStatsAPI}${offerForm.currencyType}`, options
      );
      let price = result.data.price * 100;
      if (offerForm.currencyType === "USD") {
        const resp = await Axios.get(`${baseURL}/api/wowprice/`);
        price = Number(resp.data.price);
      }
      let adjPrice =
        price +
        (offerForm.marginAbove
          ? price * (offerForm.margin / 100)
          : -price * (offerForm.margin / 100));
      let onewow1 = 1 / adjPrice;
      let finalwow1 = onewow1 * max;
      setLiveprice1(finalwow1.toFixed(8));
    } catch {
      // console.log("error in tier 0");
    }
  };

  const onSelectTime = (value, when) => {
    if (when === "openHours") {
      dispatch(setTime({ when, value }));
    }
    if (when === "closeHours") {
      dispatch(setTime({ when, value }));
    }
  };

  const onSelectPayment = (value) => {
    var x = document.getElementById("bankdetails");
    if (value == "Bank transfer") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
    dispatch(setPaymentMethod(value));
    setFormUI({ ...formUI, paySelect: true });
  };
  const onSelectExchange = (value) => {
    dispatch(setExchange(value));
  };
  const [aboveOrBelow, setAboveOrBelow] = useState("above");
  const [buyorsell, setBuyorsell] = useState("");
  const onDynamicHandle = (e) => {
    let isDynamic = true;
    console.log(e.target.value);
    if (e.target.value === "custom") {
      isDynamic = false;
    }
    dispatch(setIsDynamicPrice(isDynamic));
  };
  const DynamicWrap = {
    display: "flex",
    cursor: "pointer",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    maxWidth: "430px",
    minHeight: "147px",
    border: "2px solid #16c784",
    borderRadius: "25px",
  };

  const onSelectLimit = (value) => {
    if (value === "skip") {
      dispatch(setDefaultLimits());
    }
    //unsure why below code is included, requires review
    // else {
    //  dispatch(setOfferForm({ ...offerForm });
    // }
    setFormUI({
      ...formUI,
      limitSelect: true,
    });
  };

  const onSelectVerified = (value) => {
    console.log(value);
    let verifiedOnly;
    if (value === "verified") {
      verifiedOnly = true;
    } else {
      verifiedOnly = false;
    }

    dispatch(setVerifiedOnly(verifiedOnly));
    setFormUI({
      ...formUI,
      verifiedSelect: true,
    });
  };
  const onSubmitForm = (e) => {
    e.preventDefault();
    console.log("offerForm", offerForm);
    if (offerId === undefined) {
      if (
        offerForm.paymentMethod == "Bank transfer" &&
        offerForm.buyBCH == false
      ) {
        if (
          offerForm.bankname &&
          offerForm.bank_name_account &&
          offerForm.bank_account_number
        ) {
          setFormUI("");
          dispatch(createOffer(offerForm, props.history));
        } else {
          console.log("Bank details is required");
          toast.error(t("newoffers.Bank details is required"));
        }
      } else {
        setFormUI("");
        dispatch(createOffer(offerForm, props.history));
      }
    }
  };

  const onEditForm = (e) => {
    e.preventDefault();
    console.log("offerForm", offerForm);
    if (
      offerForm.bankname &&
      offerForm.bank_name_account &&
      offerForm.bank_account_number
    ) {
      setFormUI("");
      dispatch(updateOffer(offerForm, props.history));
      props.history.push("/my-offers");
    }
  };

  const onDeleteForm = async (e) => {
    e.preventDefault();
    if (window.confirm(t("newoffers.Are you sure, you want to delete this"))) {
      dispatch(deleteOffer(offerForm.id));
      sessionStorage.setItem("Offermsg", "Offer Deleted Successfully");
      props.history.push("/my-offers");
    }
  };

  useEffect(() => {
    if (offerForm.buyBCH == 0) {
      setType("sellBCH");
    }
  });

  const nonSelected = {
    width: "118px",
    height: "50px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    textAlign: "center",
    color: " #000000",
    border: "1px solid #000000",
    borderRadius: "45px",
  };
  const selectedOption = {
    width: "118px",
    height: "50px",
    fontFamily: "Poppins",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    border: "none",
    paddingTop: "10px",
    color: "#ffffff",
    background: "#16c784",
    borderRadius: "45px",
  };
  const [t, i18n] = useTranslation("common");
  return (
    <>
      <div className="main-container">
        <div className="upper-part"></div>
        <div className="new-offer-explore-container">
          <p className="profile-heading">{t("newoffers.Create a new offer")}</p>
          <div className="new-offer-form">
            <Form
              name="order-form"
              onSubmit={() => {
                return;
              }}
            >
              <div
                className={
                  "first-action" + (!formUI.firstSelect ? " active-form" : "")
                }
              >
                <p className="buyorsellcoin">
                  {t("newoffers.Do you want to buy or sell WOW")}
                </p>
                {!formUI.firstSelect ? (
                  <Form.Item name="buyBCH">
                    <Select
                      bordered={false}
                      className="selectbuyorsell"
                      placeholder={t("newoffers.Select")}
                      onChange={(value) => onSelectHandle(value)}
                    >
                      <Option value="buyBCH">{t("newoffers.Buy WOW")}</Option>
                      <Option value="sellBCH">{t("newoffers.Sell WOW")}</Option>
                    </Select>
                  </Form.Item>
                ) : (
                  <Radio.Group
                    defaultValue={offerForm.buyBCH ? "buyBCH" : "sellBCH"}
                    onChange={(e) => onCheckHandle(e)}
                  >
                    <Radio.Button
                      style={{
                        borderRadius: "20px",
                        minWidth: "118px",
                        height: "50px",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                        paddingTop: "10px",
                        border:
                          `${buyorsell}` === "buyBCH"
                            ? "none"
                            : "1px solid #d8d8d8",
                        background:
                          `${buyorsell}` === "buyBCH" ? "#16C784" : "#ffffff",
                        color:
                          `${buyorsell}` === "buyBCH" ? "#ffffff" : "#000000",
                      }}
                      className={"ant-radio-button-wrapper"}
                      value="buyBCH"
                    >
                      {t("newoffers.Buy WOW")}
                    </Radio.Button>
                    <Radio.Button
                      style={{
                        marginLeft: "10px",
                        borderRadius: "20px",
                        minWidth: "118px",
                        height: "50px",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "16px",
                        paddingTop: "10px",
                        border:
                          `${buyorsell}` === "sellBCH"
                            ? "none"
                            : "1px solid #d8d8d8",
                        background:
                          `${buyorsell}` === "sellBCH" ? "#16C784" : "#ffffff",
                        color:
                          `${buyorsell}` === "sellBCH" ? "#ffffff" : "#000000",
                      }}
                      className={"ant-radio-button-wrapper"}
                      value="sellBCH"
                    >
                      {t("newoffers.Sell WOW")}
                    </Radio.Button>
                  </Radio.Group>
                )}
              </div>
              {formUI.firstSelect && (
                <div className={!formUI.geoSelect ? " active-form" : ""}>
                  <p style={{ marginTop: "40px" }} className="buyorsellcoin">
                    {t("newoffers.What location do you want to display")}
                  </p>
                  {!formUI.geoSelect ? (
                    <>
                      <div className="geo-input">
                        <input
                          autoComplete="off"
                          style={{ paddingLeft: "20px" }}
                          className="selectbuyorsell"
                          name="city"
                          type="text"
                          placeholder={t("newoffers.Enter your city")}
                          value={offerForm.city}
                          onChange={(e) => onInputHandle(e)}
                        />
                        <input
                          autoComplete="off"
                          style={{ paddingLeft: "20px" }}
                          className="selectbuyorsell"
                          name="country"
                          type="text"
                          placeholder={t("newoffers.Enter your country")}
                          value={offerForm.country}
                          onChange={(e) => onInputHandle(e)}
                        />
                      </div>
                      {!formUI.geoSelect && (
                        <div className="limit-button-container">
                          {offerForm.country !== "" ? (
                            <button
                              className="next-btn"
                              id="citybutton"
                              bankdetails
                              onClick={() =>
                                setFormUI({ ...formUI, geoSelect: true })
                              }
                            >
                              {t("newoffers.Next")}
                            </button>
                          ) : (
                            offerForm.country !== "" && (
                              <button
                                className="next-btn"
                                id="citybutton"
                                onClick={() =>
                                  setFormUI({ ...formUI, geoSelect: true })
                                }
                              >
                                {t("newoffers.Next")}
                              </button>
                            )
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="geo-container">
                      <input
                        autoComplete="off"
                        style={{
                          paddingLeft: "20px",
                          backgroundColor: "white",
                        }}
                        className="selectbuyorsell"
                        name="country"
                        type="text"
                        value={`${offerForm.city}`}
                        disabled={true}
                      />
                      <input
                        autoComplete="off"
                        style={{
                          paddingLeft: "20px",
                          backgroundColor: "white",
                        }}
                        className="selectbuyorsell"
                        name="country"
                        type="text"
                        value={`${offerForm.country}`}
                        disabled={true}
                      />
                      {/* <button
                        className="change-btn"
                        onClick={() =>
                          setFormUI({ ...formUI, geoSelect: false })
                        }
                      >
                        {t("newoffers.Change")}
                      </button> */}
                    </div>
                  )}
                </div>
              )}
              {formUI.geoSelect && (
                <div className="pay-container-edit">
                  <p style={{ marginTop: "40px" }} className="buyorsellcoin">
                    {t("newoffers.Which payment method do you want to accept")}
                  </p>

                  <Radio.Group
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      maxWidth: "765px",
                      flexWrap: "wrap",
                    }}
                    className="custom-button-group"
                    defaultValue={offerForm.paymentMethod}
                    buttonStyle="solid"
                  >
                    {payMethodData.map((item) => (
                      <div
                        onClick={() => onSelectPayment(item.name)}
                        className="radioWrapInput"
                      >
                        {" "}
                        <input
                          autoComplete="off"
                          type="radio"
                          id="html"
                          name="fav_language"
                          value={item.name}
                          key={item.name}
                          checked={
                            item.name == offerForm.paymentMethod ? true : false
                          }
                        />
                        <p className="radioTitles">
                          {" "}
                          {t("newoffers." + item.name)}
                        </p>
                      </div>
                    ))}
                  </Radio.Group>
                  {(offerForm.bankname &&
                    offerForm.bank_name_account &&
                    offerForm.bank_account_number) ||
                  offerForm.paymentMethod == "Bank transfer" ? (
                    <div
                      className="bankdetails"
                      id="bankdetails"
                      style={{ display: "block" }}
                    >
                      {getType == "sellBCH" ? (
                        <>
                          <div className="input-wrapper">
                            <p
                              style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                              className="buyorsellcoin"
                            >
                              {t("newoffers.Bank Name")}{" "}
                            </p>
                            <input
                              autoComplete="off"
                              style={{ paddingLeft: "20px" }}
                              name="bankname"
                              className="selectbuyorsell"
                              rows={4}
                              value={offerForm.bankname}
                              onChange={(e) => onInputHandle(e)}
                              placeholder={t("newoffers.Bank Name")}
                            />
                            <br />
                          </div>
                          <div className="input-wrapper">
                            <p
                              style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                              className="buyorsellcoin"
                            >
                              {t("newoffers.Name of account holder")}{" "}
                            </p>
                            <input
                              autoComplete="off"
                              style={{ paddingLeft: "20px" }}
                              className="selectbuyorsell"
                              name="bank_name_account"
                              rows={4}
                              value={offerForm.bank_name_account}
                              onChange={(e) => onInputHandle(e)}
                              placeholder={t(
                                "newoffers.Name of account holder"
                              )}
                            />
                            <br />
                          </div>
                          <div className="input-wrapper">
                            <p
                              style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                              className="buyorsellcoin"
                            >
                              {" "}
                              {t("newoffers.Account Number")}{" "}
                            </p>
                            <input
                              autoComplete="off"
                              style={{ paddingLeft: "20px" }}
                              className="selectbuyorsell"
                              name="bank_account_number"
                              rows={4}
                              value={offerForm.bank_account_number}
                              onChange={(e) => onInputHandle(e)}
                              placeholder={t("newoffers.Account Number")}
                            />
                            <br />
                          </div>
                          <div className="input-wrapper">
                            <p
                              style={{
                                marginTop: "20px",
                                marginBottom: "20px",
                              }}
                              className="buyorsellcoin"
                            >
                              {t("newoffers.Other Bank Details")}{" "}
                            </p>
                            <textarea
                              style={{ padding: "20px" }}
                              className="selectbuyorsell-textarea"
                              name="bankDetails"
                              rows={4}
                              value={offerForm.bankDetails}
                              onChange={(e) => onInputHandle(e)}
                              placeholder={t("newoffers.Other Bank Details")}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <div
                      className="bankdetails"
                      id="bankdetails"
                      style={{ display: "none" }}
                    >
                      {getType == "sellBCH" ? (
                        <>
                          <div>
                            <h2>{t("newoffers.Bank Name")}*</h2>
                            <Input
                              name="bankname"
                              rows={4}
                              value={offerForm.bankname}
                              onChange={(e) => onInputHandle(e)}
                              placeholder={t("newoffers.Bank Name")}
                            />
                            <br />
                          </div>
                          <div>
                            <h2>{t("newoffers.Name of account holder")}*</h2>
                            <Input
                              name="bank_name_account"
                              rows={4}
                              value={offerForm.bank_name_account}
                              onChange={(e) => onInputHandle(e)}
                              placeholder={t(
                                "newoffers.Name of account holder"
                              )}
                            />
                            <br />
                          </div>
                          <div>
                            <h2>{t("newoffers.Account Number")}*</h2>
                            <Input
                              name="bank_account_number"
                              rows={4}
                              value={offerForm.bank_account_number}
                              onChange={(e) => onInputHandle(e)}
                              placeholder={t("newoffers.Account Number")}
                            />
                            <br />
                          </div>
                          <div>
                            <h2>{t("newoffers.Other Bank Details")}</h2>
                            <Input.TextArea
                              name="bankDetails"
                              rows={4}
                              value={offerForm.bankDetails}
                              onChange={(e) => onInputHandle(e)}
                              placeholder={t("newoffers.Other Bank Details")}
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                    </div>
                  )}
                </div>
              )}
              {formUI.paySelect && (
                <div
                  className={
                    "currency-select" + (formUI.paySelect ? " active-form" : "")
                  }
                >
                  <p style={{ marginTop: "20px" }} className="buyorsellcoin">
                    {t(
                      "newoffers.Which local currency do you want to trade with"
                    )}
                  </p>
                  {!formUI.currencySelect ? (
                    <>
                      <p className="sub-sellorbuytag">
                        {t("newoffers.You're probably after this one")}:
                      </p>
                      <button className="radioselected">
                        {offerForm.currencyType
                          ? offerForm.currencyType
                          : "KRW"}
                      </button>
                      <p className="sub-sellorbuytag">
                        {t("newoffers.Choose another local currency")}:
                      </p>
                      <Select
                        style={{ paddingTop: "10px" }}
                        className="selectbuyorsell"
                        bordered={false}
                        defaultValue={
                          offerForm.currencyType
                            ? offerForm.currencyType
                            : "KRW"
                        }
                        placeholder={t("newoffers.Select")}
                        onChange={(value) => onSelectCurrency(value)}
                      >
                        {currencyTypesData.map((cur) => (
                          <Option key={cur.name} value={cur.name}>
                            {cur.name}
                          </Option>
                        ))}
                      </Select>
                      <br />
                      <button
                        className="next-btn"
                        //disabled={!offerForm.currencyType ? true : false}
                        onClick={() =>
                          setFormUI({ ...formUI, currencySelect: true })
                        }
                      >
                        {t("newoffers.Next")}
                      </button>
                    </>
                  ) : (
                    <div className="currency-edit">
                      <button
                        className="radioselected"
                        size="large"
                        style={{ marginRight: "20px" }}
                      >
                        {offerForm.currencyType}
                      </button>
                      <button
                        className="change-btn"
                        size="large"
                        onClick={() =>
                          setFormUI({ ...formUI, currencySelect: false })
                        }
                      >
                        {t("newoffers.Other")}
                      </button>
                    </div>
                  )}
                </div>
              )}
              {formUI.currencySelect ? (
                <>
                  {!formUI.dynamicSelect ? (
                    <>
                      <p
                        style={{ marginTop: "20px" }}
                        className="buyorsellcoin"
                      >
                        {t("newoffers.How would you like to set your rate")}
                      </p>
                      <Radio.Group
                        className="rate-select"
                        defaultValue="dynamic"
                        buttonStyle={DynamicWrap}
                        onChange={(e) => onDynamicHandle(e)}
                      >
                        <Radio.Button
                          style={{
                            border: "2px solid #16c784",
                            borderRadius: "25px",
                          }}
                          value="dynamic"
                          className="dynamic-wrap"
                        >
                          {" "}
                          <p className="dynamic-tag">
                            {t("newoffers.Dynamic market price")}(
                            {t("newoffers.easy")})
                          </p>
                          <p className="dynamic-sub-tag">
                            {t(
                              "newoffers.Choose a percentage margin above or below the current market price on a chosen exchange"
                            )}
                            {t("newoffers.below Kraken WOW/USD")}.
                          </p>
                        </Radio.Button>
                      </Radio.Group>
                      <br />
                      <button
                        className="next-btn"
                        onClick={() =>
                          setFormUI({ ...formUI, dynamicSelect: true })
                        }
                      >
                        {t("newoffers.Next")}
                      </button>
                    </>
                  ) : !formUI.reviewPriceSelect ? (
                    <>
                      <p
                        style={{ marginTop: "20px" }}
                        className="buyorsellcoin"
                      >
                        {t(
                          "newoffers.What margin and exchange do you want to use for your rate"
                        )}
                      </p>
                      <p className="sub-sellorbuyta">
                        {t("newoffers.Choose a percentage margin")}:
                      </p>
                      <div className="set-margin">
                        <div className="input-margin-wrap">
                          <div
                            style={{ marginBottom: "20px" }}
                            className="margin-selectbuyorsell"
                          >
                            {" "}
                            <input
                              autoComplete="off"
                              className="selectbuyorsell-margin-input"
                              style={{ border: "none" }}
                              name="margin"
                              placeholder="e.g. 1.5%"
                              value={offerForm.margin}
                              onChange={(e) => onInputHandle(e)}
                            />
                            <Percentage style={{ marginRight: "15px" }} />
                          </div>

                          <p className="input-margin-tag">
                            {offerForm.buyBCH
                              ? t("newoffers.Buyers")
                              : t("newoffers.Sellers")}{" "}
                            {t("newoffers.Below")} 2%{" "}
                            {offerForm.buyBCH
                              ? t("newoffers.below")
                              : t("newoffers.above")}{" "}
                            {t("newoffers.market price")}
                          </p>
                        </div>
                        <div>
                          <Radio.Group
                            defaultValue={offerForm.buyBCH ? "below" : "above"}
                          >
                            <Radio.Button
                              onClick={() => {
                                setAboveOrBelow("above");
                              }}
                              style={{
                                borderRadius: "20px",
                                width: "118px",
                                height: "50px",
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                fontSize: "16px",
                                paddingTop: "10px",
                                border:
                                  `${aboveOrBelow}` === "above"
                                    ? "none"
                                    : "1px solid #d8d8d8",
                                background:
                                  `${aboveOrBelow}` === "above"
                                    ? "#16C784"
                                    : "#ffffff",
                                color:
                                  `${aboveOrBelow}` === "above"
                                    ? "#ffffff"
                                    : "#000000",
                              }}
                              value="above"
                            >
                              {t("newoffers.Above")}
                            </Radio.Button>
                            <Radio.Button
                              onClick={() => {
                                setAboveOrBelow("below");
                              }}
                              style={{
                                marginLeft: "10px",
                                borderRadius: "20px",
                                width: "118px",
                                height: "50px",
                                fontFamily: "Poppins",
                                fontWeight: "400",
                                fontSize: "16px",
                                paddingTop: "10px",
                                border:
                                  `${aboveOrBelow}` === "below"
                                    ? "none"
                                    : "1px solid #d8d8d8",
                                background:
                                  `${aboveOrBelow}` === "below"
                                    ? "#16C784"
                                    : "#ffffff",
                                color:
                                  `${aboveOrBelow}` === "below"
                                    ? "#ffffff"
                                    : "#000000",
                              }}
                              value="below"
                            >
                              {t("newoffers.Below")}
                            </Radio.Button>
                          </Radio.Group>
                        </div>
                      </div>

                      <div
                        style={{ marginTop: "20px" }}
                        className="rate-button-container"
                      >
                        <button
                          className="white-btn"
                          onClick={() =>
                            setFormUI({ ...formUI, dynamicSelect: false })
                          }
                        >
                          {t("newoffers.BACK")}
                        </button>
                        <button
                          style={{ marginLeft: "5px", width: "215px" }}
                          className="radioselected"
                          onClick={() =>
                            setFormUI({ ...formUI, reviewPriceSelect: true })
                          }
                        >
                          {t("newoffers.REVIEW PRICE")}
                        </button>
                      </div>
                    </>
                  ) : !formUI.confirmPriceSelect ? (
                    <div className="review-price">
                      <p className="buyorsellcoin">
                        {t("newoffers.Review your")}{" "}
                        {offerForm.dynamicPricing ? "dynamic" : "custom"}{" "}
                        {t("newoffers.price selection")}
                        <br />
                        {t("newoffers.You selected")}
                      </p>
                      <div>
                        <p>
                          {offerForm.margin}%{" "}
                          {offerForm.marginAbove ? "above" : "below"}{" "}
                          {offerForm.marketExchange}
                        </p>
                      </div>
                      <div className="rate-button-container">
                        <button
                          className="white-btn"
                          onClick={() =>
                            setFormUI({ ...formUI, dynamicSelect: false })
                          }
                        >
                          {t("newoffers.BACK")}
                        </button>
                        <button
                          style={{ marginLeft: "5px" }}
                          className="radioselected"
                          onClick={() =>
                            setFormUI({ ...formUI, confirmPriceSelect: true })
                          }
                        >
                          {t("newoffers.CONFIRM")}
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="rate-selected">
                      <p className="buyorsellcoin">
                        {t("newoffers.How would you like to set your rate")}
                      </p>
                      <div>
                        <button className="radioselected">
                          {offerForm.margin}%{" "}
                          {offerForm.marginAbove
                            ? t("newoffers.above")
                            : t("newoffers.below")}{" "}
                          {offerForm.marketExchange}
                        </button>
                        <button
                          className="change-btn"
                          style={{ marginLeft: "5px" }}
                          onClick={() =>
                            setFormUI({
                              ...formUI,
                              confirmPriceSelect: false,
                            })
                          }
                        >
                          Other
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : null}
              {formUI.confirmPriceSelect ? (
                <div
                  className={
                    "offer-limits" +
                    (formUI.confirmPriceSelect ? " active-form" : "")
                  }
                >
                  <p style={{ marginTop: "20px" }} className="buyorsellcoin">
                    {t("newoffers.Set Limit")}
                  </p>
                  <div
                    className={
                      "limits" + (formUI.limitSelect ? " limits-selected" : "")
                    }
                  >
                    <div>
                      <p className="sub-sellorbuytag">
                        {t("newoffers.Minimum trade size")} (in{" "}
                        {offerForm.currencyType})
                      </p>
                      <div
                        style={{ marginBottom: "10px" }}
                        className="margin-selectbuyorsell"
                      >
                        <p
                          style={{ paddingTop: "10px", marginRight: "15px" }}
                          className="sub-sellorbuytag"
                        >
                          {" "}
                          {offerForm.currencySymbol}
                        </p>

                        <input
                          autoComplete="off"
                          className="selectbuyorsell-margin-input"
                          style={{ border: "none" }}
                          name="limitMin"
                          placeholder="e.g. $50.00"
                          value={offerForm.limitMin}
                          onChange={(e) => onInputHandle(e)}
                        />
                        <p
                          style={{ paddingTop: "10px", marginRight: "15px" }}
                          className="sub-sellorbuytag"
                        >
                          {" "}
                          {offerForm.currencyType}
                        </p>
                      </div>

                      <div
                        style={{ marginBottom: "10px" }}
                        className="margin-selectbuyorsell"
                      >
                        <input
                          autoComplete="off"
                          className="selectbuyorsell-margin-input"
                          style={{ border: "none" }}
                          placeholder="e.g. 0.00000000 WOW"
                          value={getLiveprice}
                        />
                        <p
                          style={{ paddingTop: "10px", marginRight: "15px" }}
                          className="sub-sellorbuytag"
                        >
                          {"WOW"}
                        </p>
                      </div>
                    </div>

                    <div>
                      <p className="sub-sellorbuyta">
                        {t("newoffers.Maximum trade size")}
                      </p>
                      <div
                        style={{ marginBottom: "10px" }}
                        className="margin-selectbuyorsell"
                      >
                        <p
                          style={{ paddingTop: "10px", marginRight: "15px" }}
                          className="sub-sellorbuytag"
                        >
                          {" "}
                          {offerForm.currencySymbol}
                        </p>

                        <input
                          autoComplete="off"
                          className="selectbuyorsell-margin-input"
                          style={{ border: "none" }}
                          name="limitMax"
                          placeholder="e.g. $100,000.00"
                          value={offerForm.limitMax}
                          onChange={(e) => onInputHandle(e)}
                        />
                        <p
                          style={{ paddingTop: "10px", marginRight: "15px" }}
                          className="sub-sellorbuytag"
                        >
                          {" "}
                          {offerForm.currencyType}
                        </p>
                      </div>
                      <div
                        style={{ marginBottom: "10px" }}
                        className="margin-selectbuyorsell"
                      >
                        <input
                          autoComplete="off"
                          className="selectbuyorsell-margin-input"
                          style={{ border: "none" }}
                          name="limitMax"
                          placeholder="e.g. 0.00000000"
                          value={getLiveprice1}
                        />
                        <p
                          style={{ paddingTop: "10px", marginRight: "15px" }}
                          className="sub-sellorbuytag"
                        >
                          {" "}
                          {"WOW"}
                        </p>
                      </div>
                    </div>
                  </div>
                  {!formUI.limitSelect && (
                    <div className="limit-button-container">
                      {offerForm.limitMin !== "" ? (
                        <button
                          className="next-btn"
                          onClick={() => onSelectLimit("next")}
                        >
                          {t("newoffers.Next")}
                        </button>
                      ) : (
                        offerForm.limitMax !== "" && (
                          <button
                            className="next-btn"
                            onClick={() => onSelectLimit("next")}
                          >
                            {t("newoffers.Next")}
                          </button>
                        )
                      )}
                    </div>
                  )}
                </div>
              ) : null}
              {formUI.limitSelect && (
                <div
                  className={
                    "headline" + (formUI.limitSelect ? " active-form" : "")
                  }
                >
                  <p className="buyorsellcoin">
                    {t("newoffers.Do you want to choose a headline")}
                  </p>
                  <input
                    autoComplete="off"
                    style={{ paddingLeft: "20px" }}
                    className="selectbuyorsell"
                    name="headline"
                    placeholder={t("newoffers.Type a headline to stand out")}
                    value={offerForm.headline}
                    onChange={(e) => onInputHandle(e)}
                  />
                  <br />
                  {!formUI.headlineSelect && (
                    <button
                      className="next-btn"
                      disabled={!offerForm.headline.length >= 1}
                      onClick={() =>
                        setFormUI({ ...formUI, headlineSelect: true })
                      }
                    >
                      {t("newoffers.Next")}
                    </button>
                  )}
                </div>
              )}
              {formUI.headlineSelect && (
                <div className="trade-terms">
                  <p
                    style={{
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    className="buyorsellcoin"
                  >
                    {t(
                      "newoffers.Do you want to outline the terms of the trade"
                    )}
                  </p>
                  <textarea
                    style={{ padding: "20px" }}
                    className="selectbuyorsell-textarea"
                    name="tradeTerms"
                    rows={7}
                    value={offerForm.tradeTerms}
                    onChange={(e) => onInputHandle(e)}
                    placeholder={t(
                      "newoffers.Meet up at a local cafe any time from"
                    )}
                  />
                  {!formUI.termsSelect && (
                    <p className="sub-sellorbuytag">
                      {t(
                        "newoffers.Use the terms of trade field to outline trade terms"
                      )}
                    </p>
                  )}
                  <div className="terms-button-group">
                    {offerForm.tradeTerms.length >= 1 &&
                      !formUI.termsSelect && (
                        <button
                          className="next-btn"
                          onClick={() =>
                            setFormUI({ ...formUI, termsSelect: true })
                          }
                        >
                          {t("newoffers.Next")}
                        </button>
                      )}
                  </div>
                </div>
              )}
              {formUI.termsSelect ? (
                !formUI.hoursSelect ? (
                  <div className="hours">
                    <p
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      className="buyorsellcoin"
                    >
                      {t("newoffers.Do you want to set your")}
                    </p>
                    <p
                      style={{ maxWidth: "430px" }}
                      className="sub-sellorbuytag"
                    >
                      {t(
                        "newoffers.These are the hours that you are normally available for contact"
                      )}
                    </p>
                    <div className="time-picker">
                      <p className="select-hours">
                        {t("newoffers.Select your standard hours")}:
                      </p>
                      <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                        <Select
                          bordered={false}
                          style={{
                            width: "205px",
                            height: "50px",
                            border: "1px solid #E9E9E9",
                            borderRadius: "45px",
                          }}
                          value={offerForm.openHours}
                          placeholder='Wake (e.g. "9:00 AM")'
                          onChange={(value) => onSelectTime(value, "openHours")}
                        >
                          {selectTimesData.map((el, index) => (
                            <Option key={index} value={el.time}>
                              {el.time}
                            </Option>
                          ))}
                        </Select>
                        <Select
                          bordered={false}
                          style={{
                            marginLeft: "10px",
                            width: "205px",
                            height: "50px",
                            border: "1px solid #E9E9E9",
                            borderRadius: "45px",
                          }}
                          value={offerForm.closeHours}
                          placeholder="Sleep (e.g. '5:00 PM')"
                          onChange={(value) =>
                            onSelectTime(value, "closeHours")
                          }
                        >
                          {selectTimesData.map((el, index) => (
                            <Option key={index} value={el.time}>
                              {el.time}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    <div className="hours-button-group">
                      {offerForm.openHours !== undefined ||
                      offerForm.closeHours !== undefined ? (
                        <button
                          className="next-btn"
                          disabled={
                            offerForm.openHours === undefined ||
                            offerForm.closeHours === undefined
                          }
                          onClick={() =>
                            setFormUI({ ...formUI, hoursSelect: true })
                          }
                        >
                          {t("newoffers.Next")}
                        </button>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="hours-selected">
                    <p
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      className="buyorsellcoin"
                    >
                      {t("newoffers.Do you want to set your")}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        flexWrap: "wrap",
                        marginBottom: "20px",
                      }}
                    >
                      <button
                        style={{ minWidth: "193px" }}
                        className="radioselected"
                      >
                        {offerForm.openHours === undefined
                          ? "All hours of the day"
                          : `${t("newoffers." + offerForm.openHours)} ${t(
                              "newoffers.to"
                            )} ${t("newoffers." + offerForm.closeHours)}`}
                      </button>
                      <button
                        style={{ marginLeft: "5px" }}
                        className="change-btn"
                        onClick={() =>
                          setFormUI({ ...formUI, hoursSelect: false })
                        }
                      >
                        {t("newoffers.Other")}
                      </button>
                    </div>
                  </div>
                )
              ) : null}

              {formUI.hoursSelect ? (
                <div>
                  <div className="confirm-detail-message">
                    {offerId === undefined ? (
                      <p className="confirm-message ">
                        {t(
                          "newoffers.You may want to double-check all of the details above"
                        )}
                      </p>
                    ) : (
                      <p children="confirm-message ">
                        {t(
                          "newoffers.You can pause, modify or delete your offer at any time"
                        )}
                      </p>
                    )}
                  </div>
                  {offerId === undefined ? (
                    <button
                      className="next-btn"
                      size="large"
                      type="primary"
                      onClick={(e) => onSubmitForm(e)}
                    >
                      {t("newoffers.CONFIRM DETAILS")}
                    </button>
                  ) : (
                    <div>
                      <button
                        className="next-btn"
                        size="large"
                        type="primary"
                        onClick={(e) => onEditForm(e)}
                      >
                        {t("newoffers.UPDATE OFFER")}
                      </button>
                      <Button
                        size="large"
                        type="danger"
                        onClick={(e) => onDeleteForm(e)}
                      >
                        {t("newoffers.DELETE OFFER")}
                      </Button>
                    </div>
                  )}
                </div>
              ) : null}
            </Form>
          </div>
        </div>
      </div>

      <ToastContainer autoClose={10000} style={{ marginTop: "60px" }} />
      <SectionSix />
    </>
  );
};

export default OfferFormContainer;
