import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Form, Input, Button, Select } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Axios from "axios";
import { Link, NavLink, useParams } from "react-router-dom";
import "./BuywowCoin.css";
import {
  getTier0,
  getTier1,
  getTier2,
  kycData,
} from "../../views/Verification/Helper_veri";
import { OfferListingsBuy } from "../OfferListings/OfferLisitngsBuy";
import { useTranslation, withTranslation } from "react-i18next";
import OfferWoWCard from "../OfferListings/OfferCard/OfferWoWCard";
import { payMethodData } from "../../helpers/dummyData";
const baseURL = process.env.REACT_APP_BASE_URL;
const { Option } = Select;

//dynamically needs to change based on url params
const title = {
  trade: "Buy and Sell WOWCoin Everywhere",
  items: "Buy and sell goods using wow coin (wow)",
};
const UserId = localStorage.getItem("userId");
const token = localStorage.getItem("token");
// const url = "${baseURL}/api/orders/search-order";
// const userget = `${baseURL}/api/auth/useraccount/${UserId}`;

const BuywowCoin = (props) => {
  const [getCountry, setGetCountry] = useState([]);
  const [data, setData] = useState({});
  const [filterRes, setfilterRes] = useState([]);
  // console.log("data", data);
  const [tierzero, setTierzero] = useState("");
  const [tierone, setTierone] = useState("");
  const [getfilterpage, setfilterpage] = useState("");
  const [tiertwo, setTiertwo] = useState("");
  const [pager, setpages] = useState([]);

  const queryParams = new URLSearchParams(window.location.search);
  // const [userName, setuserName] = useState();
  const page = parseInt(queryParams.get("page")) || 1;

  useEffect(() => {
    try {
      Axios.get(`${baseURL}/api/auth/getcountry`).then((response) => {
        setGetCountry(response.data);
      });
    } catch (error) {
      // console.log(error);
    }
    getData();
  }, []);

  useEffect(() => {
    const page = parseInt(queryParams.get("page")) || 1;
    const Send = {
      amount: "",
      country: "",
      currency_type: "",
      payment_method: "",
      buyBCH: "0",
      userid: UserId ? UserId : null,
    };

    Axios.post(
      `${baseURL}/api/orders/search-order/?pageNo=${page}&perpage=10`,
      Send
    ).then((resss) => {
      console.log("resss data:", resss.data.data.length);
      // console.log("Chetna",resss.data);
      setfilterRes(resss.data.data);
      setfilterpage(resss.data.pages);
    });
  }, []);

  const getData = async () => {
    try {
      kycData.subscribe((res) => {
        if (!res || !res.tier_z) {
          getTier0();
        } else {
          // console.log('kycData res:',res);
          setTierzero(res.tier_z);
        }
      });
    } catch {
      // console.log("error in tier 0");
    }

    try {
      kycData.subscribe((res) => {
        if (!res || !res.dataa) {
          getTier1();
        } else {
          // console.log("kycData tier1.....", res)
          setTierone(res.dataa);
        }
      });
    } catch {
      // console.log("error in tier 1");
    }

    try {
      kycData.subscribe((res) => {
        if (!res || !res.data) {
          getTier2();
        } else {
          // console.log("kycData tier2.....", res)
          setTiertwo(res.data);
        }
      });
    } catch {
      // console.log("error in tier 2");
    }
  };

  const [expand, setExpand] = useState(false);
  function onChange(value) {
    // to add actionCreator needs to change slice of state
    // console.log(`selected ${value}`);
  }

  function onBlur() {
    // console.log("blur");
  }

  function onFocus() {
    // console.log("focus");
  }

  function onSearch(val) {
    // console.log("search:", val);
  }

  // const coinPurchase = (value) =>{
  //     this.props.history(value).push("/CoinPurchase");
  // }

  const submit = (e) => {
    try {
      const page = parseInt(queryParams.get("page")) || 1;
      const sendData = {
        amount: data.amount && data.amount.trim() ? data.amount : "",
        country: data.countrycode
          ? data.countrycode.substr(
              0,
              data.countrycode.length - data.countrycode.indexOf("(")
            )
          : "",
        currency_type:
          data.currency_type && data.currency_type.trim()
            ? data.currency_type
            : "",
        payment_method:
          data.payment_method && data.payment_method.trim()
            ? data.payment_method
            : "",
        buyBCH: "0",
        userid: UserId ? UserId : null,
      };
      // console.log("sendData", sendData)
      Axios.post(
        `${baseURL}/api/orders/search-order/?pageNo=${page}&perpage=10`,
        sendData
      )
        .then((res) => {
          // console.log("res.data.......", res.data);
          setfilterRes(res.data.data);
        })
        .catch((error) => {
          // console.log("error:", error);
        });
    } catch (err) {
      // console.log(err);
    }
  };

  function handle(e) {
    // console.log('e.target.id:', e.target.id);
    let newdata = data;
    newdata[e.target.id] = e.target.value;
    setData({ ...data, [e.target.id]: e.target.value });
    // console.log("newdata......", newdata);
  }

  const [t, i18n] = useTranslation("common");

  return (
    <div style={{ marginTop: "30px" }}>
      <Form
        initialValues={{
          remember: true,
        }}
        onFinish={(e) => {
          submit(e);
        }}
      >
        <div className="filters-web">
          <div className="input-wraps">
            <Form.Item
              style={{ margin: 0 }}
              label={t("Trades.Amount")}
              name="amount"
              id="amount"
              className="input-header"
              onChange={(e) => handle(e)}
            />
            <input className="input-fields" placeholder={t("Trades.Amount")} />
          </div>
          <div className="input-wraps">
            <Form.Item
              style={{ margin: 0 }}
              label={t("Trades.Currency Type")}
              name="currency_type"
              id="currency_type"
              className="input-header"
            />
            <Select
              size="large"
              className="input-fields"
              showSearch
              bordered={false}
              defaultValue={t("Trades.Currency Type")}
              optionFilterProp="children"
              onChange={(e) => {
                // console.log('currency_type e:', e);
                setData({ ...data, currency_type: e });
              }}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="USD">USD</Option>
              <Option value="HKD">HKD</Option>
              <Option value="KRW">KRW</Option>
              <Option value="EUR">EUR</Option>
            </Select>
          </div>
          <div className="input-wraps">
            <Form.Item
              style={{ margin: 0 }}
              label={t("Trades.Payment method")}
              name="payment_method"
              id="payment_method"
              className="input-header"
            />
            <Select
              size="large"
              showSearch
              className="input-fields"
              bordered={false}
              defaultValue={t("Explore.Any payment method")}
              optionFilterProp="children"
              onChange={(e) => {
                // console.log('payment_method e:', e);
                setData({ ...data, payment_method: e });
              }}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="">{t("Explore.Any payment method")}</Option>
              {payMethodData.map((item) => (
                <Option value={item.name}>{t("newoffers." + item.name)}</Option>
              ))}
            </Select>
          </div>
          <div className="input-wraps">
            <Form.Item
              style={{ margin: 0 }}
              label={t("Trades.Location")}
              name="country"
              className="input-header"
              id="country"
            />
            <Select
              size="large"
              showSearch
              className="input-fields"
              bordered={false}
              defaultValue={t("Trades.Location")}
              optionFilterProp="children"
              onChange={(e) => {
                // console.log('countrycode e:', e);
                setData({ ...data, countrycode: e });
              }}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {getCountry.map((value, index) => {
                return <Option value={value.label}>{value.label}</Option>;
              })}
            </Select>
          </div>
          <div className="input-wraps">
            <Form.Item style={{ float: "right" }}>
              <br />
              <br />
              <br />
              <button className="submitBtn" htmlType="submit" id="continue">
                {t("Trades.Search")}
              </button>
            </Form.Item>
          </div>
        </div>
        <div className="filters-mob">
          <div className="filter-mob-row">
            <div className="input-wraps">
              <Form.Item
                style={{ margin: 0 }}
                label={t("Trades.Amount")}
                name="amount"
                id="amount"
                className="input-header"
                onChange={(e) => handle(e)}
              />
              <input
                className="input-fields-mob"
                placeholder={t("Trades.Amount")}
              />
            </div>
            <div className="input-wraps">
              <Form.Item
                style={{ margin: 0 }}
                label={t("Trades.Currency Type")}
                name="currency_type"
                id="currency_type"
                className="input-header"
              />
              <Select
                size="large"
                className="input-fields-mob"
                showSearch
                bordered={false}
                defaultValue={t("Trades.Currency Type")}
                optionFilterProp="children"
                onChange={(e) => {
                  // console.log('currency_type e:', e);
                  setData({ ...data, currency_type: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="USD">USD</Option>
                <Option value="HKD">HKD</Option>
                <Option value="KRW">KRW</Option>
                <Option value="EUR">EUR</Option>
              </Select>
            </div>
          </div>
          <div className="filter-mob-row">
            <div className="input-wraps">
              <Form.Item
                style={{ margin: 0 }}
                label={t("Trades.Payment method")}
                name="payment_method"
                id="payment_method"
                className="input-header"
              />
              <Select
                size="large"
                showSearch
                className="input-fields-mob"
                bordered={false}
                defaultValue={t("Explore.Any payment method")}
                optionFilterProp="children"
                onChange={(e) => {
                  // console.log('payment_method e:', e);
                  setData({ ...data, payment_method: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="">{t("Explore.Any payment method")}</Option>
                {payMethodData.map((item) => (
                  <Option value={item.name}>
                    {t("newoffers." + item.name)}
                  </Option>
                ))}
              </Select>
            </div>
            <div className="input-wraps">
              <Form.Item
                style={{ margin: 0 }}
                label={t("Trades.Location")}
                name="country"
                className="input-header"
                id="country"
              />
              <Select
                size="large"
                showSearch
                className="input-fields-mob"
                bordered={false}
                defaultValue={t("Trades.Location")}
                optionFilterProp="children"
                onChange={(e) => {
                  // console.log('countrycode e:', e);
                  setData({ ...data, countrycode: e });
                }}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {getCountry.map((value, index) => {
                  return <Option value={value.label}>{value.label}</Option>;
                })}
              </Select>
            </div>
          </div>
          <div className="input-wraps">
            <Form.Item>
              <br />
              <br />
              <br />
              <button className="submitBtn" htmlType="submit" id="continue">
                {t("Trades.Search")}
              </button>
            </Form.Item>
          </div>
        </div>
      </Form>
      <h2>{filterRes?.maker_id}</h2>
      <div className="buy-wow-coin-sell-web-header">
        <p style={{ width: "90px" }} className="buy-coin-sell-header-tag">
          <b>{t("offerTrade.Username")}</b>
        </p>
        <p style={{ width: "44px" }} className="buy-coin-sell-header-tag">
          <b>{t("offerTrade.Type")}</b>
        </p>
        <p style={{ width: "120px" }} className="buy-coin-sell-header-tag">
          <b>{t("offerTrade.Payment Method")}</b>
        </p>
        <p style={{ width: "59px" }} className="buy-coin-sell-header-tag">
          <b>{t("offerTrade.Location")}</b>
        </p>
        <p style={{ width: "64px" }} className="buy-coin-sell-header-tag">
          <b>{t("offerTrade.Currency")}</b>
        </p>
        <p style={{ width: "104px" }} className="buy-coin-sell-header-tag">
          <b>{t("offerTrade.Price/Wowcoin")}</b>
        </p>
        <p style={{ width: "150px" }} className="buy-coin-sell-header-tag">
          <b>{t("offerTrade.Limit")}</b>
        </p>
        <p style={{ width: "90px" }} className="buy-coin-sell-header-tag">
          <b>{t("offerTrade.Action")}</b>
        </p>
      </div>

      {
        <div className="buy-coin-sell-rows">
          {filterRes.length != 0 ? (
            <>
              {filterRes?.map((offer, index) => (
                <OfferWoWCard key={index} offer={offer} isBuy={props?.isBuy} />
              ))}
            </>
          ) : (
            <></>
          )}
        </div>
      }
      {page && (
        <ul style={{ paddingLeft: "0" }} className="pagination-buy-coin">
          <li
            className={`page-item first-item ${page === 1 ? "disabled" : ""}`}
          >
            <Link
              to={`/offers/buywowCoin?page=1`}
              onClick={() => {
                window.location.href = `/offers/buywowCoin?page=1`;
              }}
              className="page-link-first"
            >
              {t("Withdraw.First")}
            </Link>
          </li>
          <li
            className={`page-item previous-item ${
              page === 1 ? "disabled" : ""
            }`}
          >
            <Link
              to={`/offers/buywowCoin?page=${page - 1}`}
              onClick={() => {
                window.location.href = `/offers/buywowCoin?page=${page - 1}`;
              }}
              className="page-link-tags"
            >
              {t("Withdraw.Previous")}
            </Link>
          </li>

          <li
            key={page}
            className={`${page === page ? "pages" : "non-active-pages"}`}
          >
            <Link
              to={`/offers/buywowCoin?page=${page}`}
              onClick={() => {
                window.location.href = `/offers/buywowCoin?page=${page}`;
              }}
              style={{ color: "#ffffff" }}
            >
              {page}
            </Link>
          </li>

          <li>
            <Link
              to={`/offers/buywowCoin?page=${page + 1}`}
              onClick={() => {
                window.location.href = `/offers/buywowCoin?page=${page + 1}`;
              }}
              className="page-link-tags"
            >
              {t("Withdraw.Next")}
            </Link>
          </li>
          <li
            className={`page-item last-item ${
              page === getfilterpage ? "disabled" : ""
            }`}
          >
            <Link
              to={`/offers/buywowCoin?page=${getfilterpage}`}
              onClick={() => {
                window.location.href = `/offers/buywowCoin?page=${getfilterpage}`;
              }}
              className="page-link-last"
            >
              {t("Withdraw.Last")}
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
};

export default BuywowCoin;
