import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import TradeCard from "../../components/Trades/TradeCard/TradeCard";

import "./MyActiveTrades.scss";
import SectionSix from "../Home/sections/sectionsix";
import OfferListings from "../OfferListings/OfferListings";
import ActiveTradeList from "./ActiveTradeList";

const MyActiveTrades = () => {
  const [t, i18n] = useTranslation("common");
  return (
    <>
      <div className="mytrade-page">
        <div className="mytrade-upper"></div>
        <div className="mytrade-container ">
          <p className="profile-heading">{t("Dashboard.Dashboard")}</p>
          <div className="boxSections-sub-wrap">
            <div className="wallet-tags">
              <NavLink className={"active-section"} to="/my-trades">
                {t("Trades.Active Trades")}
              </NavLink>
            </div>
            <div className="wallet-tags">
              <NavLink
                className={"non-active-section"}
                to="/dashboard/contract"
              >
                {t("Trades.Past Trades")}
              </NavLink>
            </div>
          </div>

          {/* <OfferListings myOrders={myOrders} /> */}
          <ActiveTradeList />
        </div>
      </div>
      <SectionSix />
    </>
  );
};

export default MyActiveTrades;