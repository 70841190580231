import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import Axios from "axios";
import "./Notibar.scss";
import { useTranslation, withTranslation } from "react-i18next";

const baseURL = process.env.REACT_APP_BASE_URL;

const Notibar = () => {
  const [data, setData] = useState();
  const [t, i18n] = useTranslation("common");
  // console.log("data",data);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (userId && token) {
      try {
        Axios.get(
          `${baseURL}/api/notifications/getall_commannotification_five/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        ).then((response) => {
          setData(response.data.data);
          // console.log("Notibaraaaaa........", response.data.data);
        });
      } catch (error) {
        // console.log(error);
      }
    }
  }, []);
  return (
    <div>
      {data ? (
        <>
          {data?.map((item) => {
            if (item?.type == "Complate_order") {
              const Complateordermsg = item?.msg;
              var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
              if (splitted.length == 4) {
                var msg = "Paid successfully by";
                var name = splitted[3];
              }
            }

            if (item?.type == "Dispute") {
              const Complateordermsg = item?.msg;
              var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
              var msg = "A dispute request has been sent against you from";
              var name = splitted[10];
            }

            if (item?.type == "Conversation") {
              const Complateordermsg = item?.msg;
              var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
              var msg = "Trade# new message from";
              var id = splitted[1];
              var name = splitted[5];
            }

            if (item?.type == "Dispute_Conversation") {
              const Complateordermsg = item?.msg;
              var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
              var msg = "Trade# new message from Dispute";
              var id = splitted[1];
              var name = splitted[6];
            }

            if (item?.type == "Dispute Solve Admin") {
              const Complateordermsg = item?.msg;
              var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
              var msg = "Admin resolved the dispute in favor of";
              if (splitted[7]) {
                var name = splitted[7];
              } else {
                var name = splitted[8];
              }
            }

            if (item?.type == "Approve") {
              const Complateordermsg = item?.msg;
              var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
              var msg = "Trade# released escrow from";
              var id = splitted[1];
              var name = splitted[5];
            }

            if (item?.type == "MaticTransaction") {
              const Complateordermsg = item?.msg;
              var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
              var msg = "Matic received from";
              var name = splitted[3];
            }

            if (item?.type == "Trade") {
              const Complateordermsg = item?.msg;
              var splitted = Complateordermsg.split(" ");
              let result1 = Complateordermsg.substring(6, 0);
              if (splitted[8] == "Sell") {
                var result = Complateordermsg.substring(59, 11);
              } else {
                var result = Complateordermsg.substring(58, 11);
              }
              //this will output ["1234", "56789"]
              var msg = result1 + " " + result;
              var id = splitted[1];
              var name = splitted[12];
            }

            if (item?.type == "Cancel") {
              const Complateordermsg = item?.msg;
              var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
              var msg = splitted[0] + " " + splitted[2] + " " + splitted[3];
              var id = splitted[1];
              if (splitted[4]) {
                var name = splitted[4];
              } else {
                var name = splitted[5];
              }
            }

            if (item?.type == "Transaction") {
              const Complateordermsg = item?.msg;
              var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
              var msg = "Wowcoin received from";
              var name = splitted[3];
            }

            if (item?.type == "Trade Cancelled In 90 Minutes") {
              const Complateordermsg = item?.msg;
              var msg = Complateordermsg.substring(62, 0);
            }

            if (item?.type == "Kyc_approve_byadmin") {
              const Complateordermsg = item?.msg;
              if (Complateordermsg.length) {
                var msg = item?.msg;
              } else {
                var msg = Complateordermsg.substring(38, 0);
              }
            }

            return (
              <div key={item.id}>
                <Link
                  to={`/noti_detail/${item?.main_type}/${item?.type}/${item?.id}/${item?.offer_id}`}
                  onClick={() => {
                    window.location.href = `/noti_detail/${item?.main_type}/${item?.type}/${item?.id}/${item?.offer_id}`;
                  }}
                >
                  {item?.type == "Complate_order" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + msg, { name: name })}
                    </p>
                  ) : (
                    <></>
                  )}

                  {item?.type == "Conversation" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + msg, { name: name, id: id })}
                    </p>
                  ) : (
                    <></>
                  )}

                  {item?.type == "Dispute_Conversation" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + msg, { name: name, id: id })}
                    </p>
                  ) : (
                    <></>
                  )}

                  {item?.type == "Dispute Solve Admin" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + msg, { name: name })}
                    </p>
                  ) : (
                    <></>
                  )}

                  {item?.type == "Approve" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + msg, { name: name, id: id })}
                    </p>
                  ) : (
                    <></>
                  )}

                  {item?.type == "Trade" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + msg, { name: name, id: id })}
                    </p>
                  ) : (
                    <></>
                  )}
                  {item?.type == "Cancel" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + msg, { name: name, id: id })}
                    </p>
                  ) : (
                    <></>
                  )}

                  {item?.type == "Dispute" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + msg, { name: name })}
                    </p>
                  ) : (
                    <></>
                  )}
                  {item?.type == "Trade Cancelled In 90 Minutes" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + msg)}
                    </p>
                  ) : (
                    <></>
                  )}
                  {item?.type == "Kyc_approve_byadmin" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + msg)}
                    </p>
                  ) : (
                    <></>
                  )}
                  {item?.type == "MaticTransaction" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + msg, { name: name })}
                    </p>
                  ) : (
                    <></>
                  )}
                  {item?.type == "Transaction" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + msg, { name: name })}
                    </p>
                  ) : (
                    <></>
                  )}

                  {item?.type == "Kyc_Declined_byadmin" ||
                    item?.type == "withdrawal" ? (
                    <p
                      style={{ maxWidth: "230px", overflowWrap: "break-word", fontWeight: item?.status === "0" ? '700' : '400' }}
                      key={item.id}
                      className="notibar notibaraa"
                    >
                      *{t("notification." + item.msg)}
                    </p>
                  ) : (
                    <></>
                  )}
                </Link>
              </div>
            );
          })}
        </>
      ) : (
        <>
          <div style={{ color: "grey", fontsize: "8px" }}>
            {t("Profile.No notification yet")}
          </div>
        </>
      )}
    </div>
  );
};

export default Notibar;