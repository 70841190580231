import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import Select from 'react-select';
import Axios from 'axios';
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { fetchAccount } from "../../store/actions/myAccountActions";
import "./AuthorizedBrowsers.scss";
const baseURL = process.env.REACT_APP_BASE_URL;

const Account = (props) => {
  const [getAuthorizedBrowsers, setAuthorizedBrowsers] = useState([]);
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };
  const UserId = localStorage.getItem('userId');
    const  token = localStorage.getItem("token");

  useEffect( () => {
    try {
      Axios.get(`${baseURL}/api/auth/getauthorizedbrowsers/${UserId}`)
      .then((response) => {
        setAuthorizedBrowsers(response.data.user[0]);
      });
    } catch (error) {
      // console.log(error);
    }
  });

  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="login-guard-container">
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div class="authorized_browsers-container">
          <div class="col-md-12">
            <h4>Authorized browsers</h4>
            <br/>
            <p>
              <a href="/settings/"><i class="fa fa-arrow-left"></i> Back to Account security</a>
            </p>
          </div>
          <div class="col-md-12">
               <table class="table table-striped table-condensed table-hover">
              <tbody>
                <tr>
                <th class="date-column">Date</th>
                <th>User agent</th>
                <th>Location</th>
                <th>IP address</th>
                <th></th>
                <th></th>
              </tr>
                <tr>
                   <td class="date-column">
                   {getAuthorizedBrowsers?.created_date}
                  </td>
                   <td>
                   {getAuthorizedBrowsers?.user_agent}
                  </td>
                  <td>
                  {getAuthorizedBrowsers?.location}
                   </td>
                   <td>
                   {getAuthorizedBrowsers?.ipaddress}
                  </td>
                  <td>
                     <span class="label label-success">Current browser</span>
                   </td>
                  <td>
                   </td>
                  </tr>
                </tbody></table>
              </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Account;
