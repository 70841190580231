import React from "react";
import "./sections.css";
import Section5Left from "./img/section-5-right.svg";
import { Collapse } from "antd";
import "antd/dist/antd.css";
import { useTranslation, withTranslation } from "react-i18next";

function SectionFive() {
  const { Panel } = Collapse;

  const [t, i18n] = useTranslation("common");
  return (
    <div className="section5">
      <img className="section5-right" src={Section5Left} alt="section5" />

      <div className="section5-right-side">
        <p className="section5-heading">
          {t("Home.Frequently asked questions")}
        </p>
        <p className="section5-sub-heading">
          {t(
            "Home.Browse through these FAQs to find answers to commonly raised questions"
          )}
        </p>
        <Collapse
          style={{ border: "none", maxWidth: "702px" }}
          expandIconPosition="end"
          ghost
        >
          <Panel
            className="section5panel"
            header={t("Home.What is Cryptocurrency")}
            key="1"
          >
            <p>{t("Home.Cryptocurrency is a digital or virtual currency that is created by Cryptography")}</p>
          </Panel>
          <Panel
            className="section5panel"
            header={t("Home.Who can buy, sell, and hold cryptocurrencies")}
            key="2"
          >
            <p>{t("Home.Customers in the US (except Hawaii) who have verified their identity with WalletDays can buy, sell, and hold cryptocurrency with their personal WalletDays profiles")}</p>
          </Panel>
          <Panel
            header={t(
              "Home.I made crypto purchase in error - can I cancel or reverse it"
            )}
            className="section5panel"
            key="3"
          >
            <p>{t("Home.There is not a way for you to cancel or reverse a crypto transaction")}</p>
          </Panel>
          <Panel
            header={t("Home.Will I pay tax on cryptocurrency profits")}
            className="section5panel"
            key="4"
          >
            <p>{t("Home.For any tax advice, you would need to speak with a tax expert")}</p>
          </Panel>
          <Panel
            header={t(
              "Home.Can I transfer cryptocurrency into and out of WalletDays"
            )}
            className="section5panel"
            key="5"
          >
            <p>{t("Home.Currently, you can only hold the cryptocurrency that you buy on WalletDays in your account")}</p>
          </Panel>
          <Panel
            header={t("Home.What are the limits")}
            className="section5panel"
            key="6"
          >
            <p>{t("Home.You are limited to $30,000 in cryptocurrency purchases per week")}</p>
          </Panel>
        </Collapse>
      </div>
    </div>
  );
}

export default SectionFive;
