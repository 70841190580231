import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BuywowCoinTable from "../../BuywowCoin/BuywowCoin-table";
import SellwowCoinTable from "../../SellwowCoin/SellwowCoin-table";
import SectionOne1 from "./img/section-one-1.svg";
import SectionOne2 from "./img/section-one-2.svg";
import SectionOne3 from "./img/section-one-3.svg";
import SectionOne4 from "./img/section-one-4.svg";
import "./sections.css";
import { Auth, authData } from "../../../auth/Auth";
import { useTranslation } from "react-i18next";
import { Breadcrumb } from "antd";
import Kyccheck from "../../Kyccheck/Kyccheck";

function SectionOne() {
  const [section, setSection] = useState("1");
  const [isDisplayNav, setIsDisplayNav] = useState(true);

  const checkAuth = () => {
    authData.subscribe((res) => {
      if (res == null) {
        Auth.isAuthenticated();
      } else {
        setIsDisplayNav(res);
      }
    });
  };
  useEffect(() => {
    let lng = localStorage.getItem("lng");
    if (lng) {
      i18n.changeLanguage(lng);
    }
    checkAuth();
  }, []);
  const [t, i18n] = useTranslation("common");
  return (
    <div className="section1">
      <div className="section1-upper-part">
        <p className="section1-heading">
          {t("Home.Trade Crypto with")}
          <span className="section1-heading-span">{t("Home.Wowcoin")}</span>
        </p>
        <p className="section1-sub-heading">
          {t(
            "Home.Buy and sell digital currencies using over 10 different payment methods"
          )}
        </p>
      </div>
      <div className="section1-lower-part">
        <div className="box">
          <div className="extraMargin">
            <Kyccheck />
            <div className="boxSections m-0 mt-3">
              
              <div className="boxSections-sub-wrap">
                <p
                  onClick={() => {
                    setSection("1");
                  }}
                  className={
                    section === "1" ? "active-section" : "non-active-section"
                  }
                >
                  {t("Home.Buy WOW Coin")}
                </p>
                <p
                  style={{ marginLeft: "39px" }}
                  onClick={() => {
                    setSection("2");
                  }}
                  className={
                    section === "2" ? "active-section" : "non-active-section"
                  }
                >
                  {t("Home.Sell WOW Coin")}
                </p>
              </div>
              <div>
                {" "}
                <Link to="/offers/buywowCoin?page=1">
                  {" "}
                  <button className="view-more">{t("Myoffers.View more")}</button>
                </Link>
              </div>
            </div>
          </div>
        
          {section === "1" ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <BuywowCoinTable desktop={true} />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <SellwowCoinTable desktop={true} />
              </div>
            </>
          )}
          <div className="view-more-mobile-section-1">
            {" "}
            <Link
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
              to="/offers/buywowCoin?page=1"
            >
              {" "}
              <button className="view-more-section-one">
                {t("Myoffers.View more")}
              </button>
            </Link>
          </div>
        </div>
        <div className="section1-sub-lp">
          <div className="section1-sub-wrapper">
            <img className="section1-sub-lp-img" alt="img" src={SectionOne1} />
            <p className="section1-sub-wrap-text">
              {t("Home.Buy wow coin in under five minutes")}
            </p>
          </div>
          <div className="section1-sub-wrapper">
            <img className="section1-sub-lp-img" alt="img" src={SectionOne2} />
            <p className="section1-sub-wrap-text">
              {t("Home.Trade with full secure envoirement")}
            </p>
          </div>
          <div className="section1-sub-wrapper">
            <img className="section1-sub-lp-img" alt="img" src={SectionOne3} />
            <p className="section1-sub-wrap-text">
              {t("Home.Provides 24X7 live support")}
            </p>
          </div>
          <div className="section1-sub-wrapper">
            <img className="section1-sub-lp-img" alt="img" src={SectionOne4} />
            <p className="section1-sub-wrap-text">
              {t("Home.Automatic matching by country")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionOne;
