import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Layout } from "antd";
import { useDispatch } from "react-redux";
import "./App.scss";
import Navigation from "./components/Navigation/Navigation";
import Login from "./components/Auth/Login";
import Forgetpass from "./components/Auth/Forgetpass";
import Resetpassword from "./components/Auth/Resetpassword";
import PasswordReset from "./components/Auth/PasswordReset";
import Signup from "./components/Auth/Signup";
import Marketing from "./views/Marketing/Marketing";
import Offers from "./views/Offers/Offers";
import { Auth } from "./auth/Auth";
import Referrals from "./views/Referrals/Referrals";
import PrivateRoute from "./components/Auth/PrivateRoute";
import MyTrades from "./views/MyTrades/MyTrades";
import Account from "./views/Account/Account";
import Withdraw from "./views/Withdraw/Withdraw";
import Setting from "./views/Setting/Setting";
import TwoFactor from "./views/TwoFactor/TwoFactor";
import LoginGuard from "./views/LoginGuard/LoginGuard";
import LoginHistory from "./views/LoginHistory/LoginHistory";
import Verification from "./views/Verification/Verification";
import Notifications from "./views/Notifications/Notifications";
import Advanced from "./views/Advanced/Advanced";
import Tier1identification from "./views/Tier1identification/Tier1identification";
import Guides from "./views/Guides/Guides";
import Onfido from "./views/Onfido/Onfido";
import Passport from "./views/Passport/Passport";
import OnfidoDocument from "./views/OnfidoDocument/OnfidoDocument";
import Tier1AddressValidation from "./views/Tier1AddressValidation/Tier1AddressValidation";
import Receipts from "./views/Receipts/Receipts";
import ChangePincode from "./views/ChangePincode/ChangePincode";
import AuthorizedBrowsers from "./views/AuthorizedBrowsers/AuthorizedBrowsers";
import PasswordChange from "./views/PasswordChange/PasswordChange";
import MyOffers from "./views/MyOffers/MyOffers";
import Blockuser from "./views/Blockuser/Blockuser";
import Authcheck from "./components/Authcheck/Authcheck";
import Noti from "./views/Noti/Noti";
import Allbuyer from "./views/Allbuyer/Allbuyer";
import Notiall from "./views/Notifications/Notiall/Notiall";
import Notidetail from "./views/Notidetail/Notidetail";
import Wallet from "./views/Wallet/Wallet";
import BnbWallet from "./views/BnbWallet/BnbWallet";
import Dashboard from "./views/Dashboard/Dashboard";
// import Feedback from "./views/Feedback/Feedback";
import OfferFormContainer from "./views/OfferFormContainer/OfferFormContainer";
import OfferDetails from "./views/OfferDetails/OfferDetails";
import OrderDetails from "./views/OrderDetails/OrderDetails";
import UserProfile from "./views/UserProfile/UserProfile";
import { fetchMyOffers } from "./store/actions/myOffersActions";
import QrCode from "./views/QrCode/QrCode";
import CoinPurchase from "./components/CoinPurchase/CoinPurchase";
import KycImage from "./views/KycImage/KycImage";
import Kycid from "./views/KycImage/Kycid";
import Contract from "./views/Contract/Contract";
import Trustuser from "./views/Trustuser/Trustuser";
import Cancelcontract from "./views/Contract/Contractall/Cancelcontract";
import Closecontract from "./views/Contract/Contractall/Closecontract";
import Releasecontract from "./views/Contract/Contractall/Releasecontract";
import Contact from "./views/Contact/Contact";
import Escrowview from "./components/Escrow/Escrowview";
import Disputeall from "./components/Dispute/Disputeall";
import Privacy from "./components/Privacy/Privacy";
import Faq from "./components/Faq/Faq";
import Disputesingle from "./components/Dispute/Disputesingle";
import Accountdelete from "./components/Accountdelete/Accountdelete";
import { TimeProvider } from "./helpers/TimeContext";
import Footer from "./components/Footer/Footer.jsx";
import Home from "./components/Home/home";
import HeaderComp from "./components/header/header";

const { Header, Content } = Layout;

function App(props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchMyOffers());
  });
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App">
      <Layout>
        <HeaderComp />
        {/* <Header> */}
        {/* <Navigation /> */}
        {/* </Header> */}
        <TimeProvider>
          <Content>
            <Authcheck />

            <Switch>
              <Route exact path="/">
                <Home />
                {/* <Marketing /> */}
              </Route>
              <Route path="/offers" render={(props) => <Offers {...props} />} />
              <Route path="/login" render={(props) => <Login {...props} />} />
              <Route
                path="/Forgetpass"
                render={(props) => <Forgetpass {...props} />}
              />
              <Route
                path="/Resetpassword"
                render={(props) => <Resetpassword {...props} />}
              />
              <Route path="/signup" render={(props) => <Signup {...props} />} />
              <Route
                path="/PasswordReset"
                render={(props) => <PasswordReset {...props} />}
              />
              <Route
                path="/contact"
                render={(props) => <Contact {...props} />}
              />
              <Route
                path="/privacy"
                render={(props) => <Privacy {...props} />}
              />
              <Route path="/faq" render={(props) => <Faq {...props} />} />
              <Route
                path="/referral-program"
                render={(props) => <Referrals {...props} />}
              />
              <Route
                path="/offer-details/:offerId"
                render={(props) => <OfferDetails {...props} />}
              />
              <Route
                path="/:fromuser/:fromid/:main_type/offer-details/:offerId/:uniqueid"
                render={(props) => <OfferDetails {...props} />}
              />
              <PrivateRoute path="/my-trades">
                <MyTrades />
              </PrivateRoute>
              <PrivateRoute
                path="/escrow"
                render={(props) => <Escrowview {...props} />}
              />
              <PrivateRoute
                path="/my-offers"
                render={(props) => <MyOffers {...props} />}
              />
              <PrivateRoute
                path="/blockuser"
                render={(props) => <Blockuser {...props} />}
              />
              <PrivateRoute
                path="/noti"
                render={(props) => <Noti {...props} />}
              />
              <PrivateRoute
                path="/notiall"
                render={(props) => <Notiall {...props} />}
              />
              <PrivateRoute
                path="/noti_detail/:main_type/:type/:offerId/:offer_id"
                render={(props) => <Notidetail {...props} />}
              />
              <PrivateRoute
                path="/withdraw/withdrawcoin"
                render={(props) => <Withdraw {...props} />}
              />
              <PrivateRoute
                path="/withdraw/Transcations"
                render={(props) => <Withdraw {...props} />}
              />
              <PrivateRoute
                path="/allbuyer/:offer_id"
                render={(props) => <Allbuyer {...props} />}
              />
              <PrivateRoute
                path="/withdraw/withdrawupdate"
                render={(props) => <Withdraw {...props} />}
              />
              <PrivateRoute
                path="/wallet/SendWowCoin"
                render={(props) => <Wallet {...props} />}
              />
              <PrivateRoute
                path="/wallet/ReceiveWowCoin"
                render={(props) => <Wallet {...props} />}
              />
              <PrivateRoute
                path="/wallet/AdminTransactions"
                render={(props) => <Wallet {...props} />}
              />
              <PrivateRoute
                path="/wallet/Transactions"
                render={(props) => <Wallet {...props} />}
              />

              <PrivateRoute
                path="/wallet/SendBnbCoin"
                render={(props) => <BnbWallet {...props} />}
              />
              <PrivateRoute
                path="/wallet/ReceiveBnbCoin"
                render={(props) => <BnbWallet {...props} />}
              />
              <PrivateRoute
                path="/wallet/BnbTransactions"
                render={(props) => <BnbWallet {...props} />}
              />
              <PrivateRoute
                path="/wallet/MaticTransactions"
                render={(props) => <BnbWallet {...props} />}
              />

              <PrivateRoute
                path="/disputeall"
                render={(props) => <Disputeall {...props} />}
              />

              <PrivateRoute
                path="/disputesingle/:id"
                render={(props) => <Disputesingle {...props} />}
              />
              <PrivateRoute
                path="/accountdelete"
                render={(props) => <Accountdelete {...props} />}
              />
              {/* Dashboard Routes start */}

              <PrivateRoute
                path="/dashboard/my-account"
                render={(props) => <Dashboard {...props} />}
              />
              <PrivateRoute
                path="/dashboard/my-trades"
                render={(props) => <Dashboard {...props} />}
              />
              <PrivateRoute
                path="/dashboard/my-offers"
                render={(props) => <Dashboard {...props} />}
              />
              <PrivateRoute
                path="/dashboard/contract"
                render={(props) => <Dashboard {...props} />}
              />
              <PrivateRoute
                path="/dashboard/trustuser"
                render={(props) => <Dashboard {...props} />}
              />

              {/* Dashboard Routes end */}
              <PrivateRoute
                path="/cancelcontract"
                render={(props) => <Cancelcontract {...props} />}
              />
              <PrivateRoute
                path="/closecontract"
                render={(props) => <Closecontract {...props} />}
              />
              <PrivateRoute
                path="/releasecontract"
                render={(props) => <Releasecontract {...props} />}
              />

              <PrivateRoute
                path="/my-account"
                render={(props) => <Account {...props} />}
              />
              <PrivateRoute
                path="/withdraw"
                render={(props) => <Withdraw {...props} />}
              />
              <PrivateRoute
                path="/qrcode"
                render={(props) => <QrCode {...props} />}
              />

              <PrivateRoute
                path="/settings"
                render={(props) => <Setting {...props} />}
              />

              <PrivateRoute
                path="/tier1_identification"
                render={(props) => <Tier1identification {...props} />}
              />

              <PrivateRoute
                path="/kyc"
                render={(props) => <KycImage {...props} />}
              />
              <PrivateRoute
                path="/Kycid"
                render={(props) => <Kycid {...props} />}
              />
              <PrivateRoute
                path="/Contract"
                render={(props) => <Contract {...props} />}
              />
              <PrivateRoute
                path="/tier1_address_validation"
                render={(props) => <Tier1AddressValidation {...props} />}
              />

              <PrivateRoute
                path="/onfido"
                render={(props) => <Onfido {...props} />}
              />

              <PrivateRoute
                path="/passport"
                render={(props) => <Passport {...props} />}
              />
              <PrivateRoute
                path="/onfido_document"
                render={(props) => <OnfidoDocument {...props} />}
              />

              <PrivateRoute
                path="/two_factor"
                render={(props) => <TwoFactor {...props} />}
              />
              <PrivateRoute
                path="/login-guard"
                render={(props) => <LoginGuard {...props} />}
              />

              <PrivateRoute
                path="/authorized_browsers"
                render={(props) => <AuthorizedBrowsers {...props} />}
              />
              <PrivateRoute
                path="/login_history"
                render={(props) => <LoginHistory {...props} />}
              />

              <PrivateRoute
                path="/verification"
                render={(props) => <Verification {...props} />}
              />

              <PrivateRoute
                path="/advanced"
                render={(props) => <Advanced {...props} />}
              />

              <PrivateRoute
                path="/receipts"
                render={(props) => <Receipts {...props} />}
              />

              <PrivateRoute
                path="/notifications"
                render={(props) => <Notifications {...props} />}
              />

              <PrivateRoute
                path="/change-pincode/"
                render={(props) => <ChangePincode {...props} />}
              />

              <PrivateRoute
                path="/guides/"
                render={(props) => <Guides {...props} />}
              />

              <PrivateRoute
                path="/password_change"
                render={(props) => <PasswordChange {...props} />}
              />

              <PrivateRoute
                path="/CoinPurchase"
                render={(props) => <CoinPurchase {...props} />}
              />

              <PrivateRoute
                path="/new-offer"
                render={(props) => <OfferFormContainer {...props} />}
              />
              <PrivateRoute
                path="/edit-offer/:offerId"
                render={(props) => <OfferFormContainer {...props} />}
              />
              <PrivateRoute
                path="/user-profile/:id/:username"
                render={(props) => <UserProfile {...props} />}
              />
              <PrivateRoute
                path="/:from/:main_type/:type/:orderId/:fromuser/:fromid/:uniqueId"
                render={(props) => <OrderDetails {...props} />}
              />
              <PrivateRoute
                path="/:fromuser/:type/:orderId/:makerId/:uniqueId"
                render={(props) => <OrderDetails {...props} />}
              />
              <PrivateRoute
                path="/:fromuser/:type/:orderId/:makerId/:uniqueId"
                render={(props) => <OrderDetails {...props} />}
              />
              {/* <PrivateRoute
              path="/:fromuser/:type/:orderId/:makerId"
              render={(props) => <OrderDetails {...props} />}
            /> */}
            </Switch>
          </Content>
        </TimeProvider>
        <Footer />
      </Layout>
    </div>
  );
}

export default App;
