import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { useTranslation } from "react-i18next";
import { fetchAccount } from "../../store/actions/myAccountActions";
import "./ChangePincode.scss";

const Account = (props) => {
  const [t, i18n] = useTranslation('common');
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };

  const [userInfo, setUserInfo] = useState({});


  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">

            <div className="password-change-container">

            <div className="col-md-12">
        <h4>Change App PIN code</h4>
        <br/>
        <p>Apps cannot send any Wowcoin from Wowcoins wallet until you set an <i>App PIN code</i> on your account. The PIN code should be four numbers.</p>
        <br/>
        <form id="theform" action method="POST">
          <input type="hidden" name="csrfmiddlewaretoken" defaultValue="wGM42OnooOAQCnwdg8qAfZ2Ixsl3eJQ1RIl2m9Eb29MDnUcwEt8N3NKaZq0T8yZe" />
          <div className="row">
            <div className="col-md-12" style={{paddingBottom: '20px'}}>
              <div className="row">
                <div className="col-md-4">
                  <div id="div_id_pincode_new" className="form-group">
                    <label htmlFor="id_pincode_new" className="control-label  requiredField">
                      App PIN code<span className="asteriskField">*</span>
                    </label>
                    <div className="controls ">
                      <input type="password" name="pincode_new" maxLength={4} autoComplete="off" className="textinput textInput form-control" required id="id_pincode_new" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div id="div_id_pincode_new2" className="form-group">
                    <label htmlFor="id_pincode_new2" className="control-label  requiredField">
                      App PIN code, again<span className="asteriskField">*</span>
                    </label>
                    <div className="controls ">
                      <input type="password" name="pincode_new2" maxLength={4} autoComplete="off" className="textinput textInput form-control" required id="id_pincode_new2" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button type="submit" class="ant-btn ant-btn-primary">
          {t('Withdraw.Submit')}
          </button>
          <br />
        </form>
      </div>
          </div>
        </div>
              </div>
              </div>
    </section>
            );
};

            export default Account;
