import axios from "axios";
import { axiosWithAuth } from "../../helpers/axiosWithAuth";
import { updateAction } from "../../helpers/updateActions";
import { dataMapper } from "../../helpers/dataMapper";


export const FETCH_MY_ACCOUNT = "FETCH_MY_ACCOUNT";

const baseURL = process.env.REACT_APP_BASE_URL;


export const fetchAccount = async() => {
    const userId = localStorage.getItem("userId");
    const  token= localStorage.getItem("token");
    try {
      const result = await axios.get(`${baseURL}/api/auth/useraccount/${userId}`, {
        headers: {
           "Authorization" : `Bearer ${token}`
        }
         });
      // console.log('result:',result);
      // let updatedData = dataMapper(...result.data);
      // console.log(updatedData);
      // dispatch(updateAction(FETCH_MY_ACCOUNT, updatedData));
      return result.data;
    } catch (error) {
      // console.log(error);
    }
  };

  export const fetchWithdraw = async() => {
    const userId = localStorage.getItem("userId");
    const  token= localStorage.getItem("token");
   try {
      const response = await axios.get(`${baseURL}/api/auth/get_user_bank_details/${userId}`, {
        headers: {
           "Authorization" : `Bearer ${token}`
        }
         });
     //  console.log('response:',response);
      // let updatedData = dataMapper(...result.data);
      // console.log(updatedData);
      // dispatch(updateAction(FETCH_MY_ACCOUNT, updatedData));
      return response.data.data;
    } catch (error) {
      console.log('response:',error);
    }
  };

 
//  fetchAccount;
