import Axios from "axios";
import { BehaviorSubject } from "rxjs";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem('userId');
   

export const balanceSub = new BehaviorSubject(null);

export const adminApproveKycSub = new BehaviorSubject(null);

export const getWowBalance = async (UserId) => {
  const  token = localStorage.getItem("token");
  await Axios.get(`${baseURL}/api/auth/getuserbalance/${UserId}`, {
        headers: {
           "Authorization" : `Bearer ${token}`
        }
         })
    .then((res) => {
      balanceSub.next(res.data.balance.toFixed(8));
       }).catch((error)=>{
      });
}
export const getAdminkycapprove = () => {
  const UserId = localStorage.getItem('userId');
    const  token = localStorage.getItem("token");
  Axios.get(`${baseURL}/api/verification/user_approved_check/${UserId}`, {
    headers: {
       "Authorization" : `Bearer ${token}`
    }
     })
  .then((res) => {
    adminApproveKycSub.next(res.data);
    // console.log("adminChetna", res.data);
  }).catch((error)=>{
    // console.log("getadmin kyc approve error", error);
  })
}

