import React from "react";
import Authcheck from "../../components/Authcheck/Authcheck";

const Referrals = props => {
  return (
    <div>
      <p>Referrals</p>
    </div>
  );
};

export default Referrals;
