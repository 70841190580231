import axios from 'axios';
import { BehaviorSubject } from 'rxjs';

const baseURL = process.env.REACT_APP_BASE_URL;

let userId = localStorage.getItem("userId");

const kycData = new BehaviorSubject(null);

const getTier0 = async() => {
    try {
        userId = localStorage.getItem("userId");
        const token = localStorage.getItem("token");
        if (userId) {
            const response = await axios.get(`${baseURL}/api/identi/tier0_fetch/${userId}`, {
                headers: {
                   "Authorization" : `Bearer ${token}`
                }
                 });
            const tier_z = response.data;
            kycData.next({ ...kycData.getValue(), tier_z : tier_z });   
        }
    } catch (error) {
        // console.log('getTier0 error:',error);
    }
}

const getTier1 = async() => {
    try {
        userId = localStorage.getItem("userId");
        if (userId) {
            const response = await axios.get(`${baseURL}/api/identi/checktire_one_complate/${userId}`);
            const dataa = response.data;
            kycData.next({ ...kycData.getValue(), dataa : dataa });  
        }
    } catch (error) {
        // console.log('getTier1 error:',error);
    }
}

const getTier2 = async() => {
    try{ 
      userId = localStorage.getItem("userId");
      if (userId) {
        const res = await axios.get(`${baseURL}/api/identi/checktire_two_complate/${userId}`);
        const data = res.data;
        kycData.next({ ...kycData.getValue(), data : data });   
      }
    }catch (error) {
        // console.log('getTier2 error:',error);
    }
  
}

const gettwoFactorenable = async()=>{
    const reso = await axios.get(`${baseURL}/api/auth/two_factor_on_off/${userId}`);
    const data = reso.data;
    // console.log("Tiertwo_response.......", reso);
    return { data };
}

const getKycprocess = async()=>{
    const  token= localStorage.getItem("token");
    const kycreso = await axios.get(`${baseURL}/api/identi/get_kyc_proceed/${userId}`, {
        headers: {
           "Authorization" : `Bearer ${token}`
        }
         });
    const data = kycreso;
    // console.log("kycreso.......", kycreso);
    return { data };
}

const getKycstatus = async()=>{
    const  token= localStorage.getItem("token");
    const kycstatus = await axios.get(`${baseURL}/api/identi/get_form_filled/${userId}`, {
        headers: {
           "Authorization" : `Bearer ${token}`
        }
         });
    const reskyc = kycstatus;
    // console.log("kycstatus.......", kycstatus);
    return { reskyc };
}
const getEmailverify = async()=>{
    const  token= localStorage.getItem("token");
    const emailstatus = await axios.get(`${baseURL}/api/identi/get_form_filled/${userId}`, {
        headers: {
           "Authorization" : `Bearer ${token}`
        }
         });
    const emailstate = emailstatus;
    // console.log("emailstate.......", emailstate);
    return { emailstate };
}

export { getTier1, getTier2, getTier0, gettwoFactorenable, getKycprocess, kycData, getKycstatus, getEmailverify};
