import React from 'react';
import Authcheck from "../../components/Authcheck/Authcheck";
import SectionSix from '../Home/sections/sectionsix';
import { useTranslation } from "react-i18next";
import "./faq.css";

const Faq = () => {
    const [t, i18n] = useTranslation("common");
    return <div>

        <section className=" hero--full hero--v10 d-flex align-items-center hidden z-index2 " style={{ marginTop: "50px" }} >
            <div className="container ">
                <Authcheck />
                <div className="row flex-column-reverse flex-lg-row reveal">
                    <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 ">
                        <div className="col-12 col-md-10 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 " style={{ marginTop: '25px' }}>
                            <div className="ico-counter">
                                <div className="counter-down">
                                    <div className="content">
                                        <div className="conuter-header">
                                            <h4 style={{ aligned: 'left', paddingLeft: '25px' }} className="sendWOW pr-head">{t("Home.Faq")}</h4>
                                            <ul>
                                                <li>{t("Home.What is Cryptocurrency")}</li>
                                                <p>{t("Home.Cryptocurrency is a digital or virtual currency that is created by Cryptography")}</p>
                                                <li>{t("Home.Who can buy, sell, and hold cryptocurrencies")}</li>
                                                <p>{t("Home.Customers in the US (except Hawaii) who have verified their identity with WalletDays can buy, sell, and hold cryptocurrency with their personal WalletDays profiles")}</p>
                                                <li>{t("Home.I made crypto purchase in error - can I cancel or reverse it")}</li>
                                                <p>{t("Home.There is not a way for you to cancel or reverse a crypto transaction")}</p>
                                                <li>{t("Home.Will I pay tax on cryptocurrency profits")}</li>
                                                <p>{t("Home.For any tax advice, you would need to speak with a tax expert")}</p>
                                                <li>{t("Home.Can I transfer cryptocurrency into and out of WalletDays")}</li>
                                                <p>{t("Home.Currently, you can only hold the cryptocurrency that you buy on WalletDays in your account")}</p>
                                                <li>{t("Home.What are the limits")}</li>
                                                <p>{t("Home.You are limited to $30,000")}</p>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>
            </div>
        </section>
        <SectionSix />
    </div>;
};

export default Faq;
