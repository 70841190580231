import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Form, Input, Button } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Axios from 'axios';
import {useTranslation, withTranslation} from "react-i18next";
const baseURL = process.env.REACT_APP_BASE_URL;
export const LOGIN = "LOGIN";
export const LOADING_USER = "LOADING_USER";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const GET_USER = "GET_USER";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const USER_ERROR = "USER_ERROR";

// const url = "${baseURL}/api/auth/resetpassword";
const PasswordReset = (props) => {
 
  const query = new URLSearchParams(props.location.search);
  const token = query.get('token');
  const id = query.get('id');
 // console.log('PasswordReset props:',token, 'id:',id);
  const [data, setData] = useState({});
  const formRef = React.useRef(null);

  const userToken = async () => {
  formRef.current.setFieldsValue({
    token: token,
    id: id
  });
};

const submit = (e) => {
   const sendData = {
    token: token,
    id: id,
    password: data.normal_login_password,
    cpassword: data.normal_login_confirm
    };
//  console.log("sendData", sendData)
  Axios.post(`${baseURL}/api/auth/resetpassword`, sendData)
    .then(res => {
       sessionStorage.setItem("ResetPassmsg", t('ResetPassword.Reset password successfully'));
      //toast.success("Reset password successfully");
       // console.log("res.data.......", res.data);
       window.location.reload();
    }).catch(error => {
       // console.log("error:", error.errorMessage);
      //toast.error("Reset password failed");
       sessionStorage.setItem("ResetPasserror", t('ResetPassword.Reset password failed'));
       window.location.reload();
    })
}

useEffect( () => {
  userToken();

  if(sessionStorage.getItem("ResetPassmsg")){
    toast.success(sessionStorage.getItem("ResetPassmsg"));
    sessionStorage.removeItem("ResetPassmsg");
   }

   if(sessionStorage.getItem("ResetPasserror")){
    toast.error(sessionStorage.getItem("ResetPasserror"));
    sessionStorage.removeItem("ResetPasserror");
   }

}, []);

function handle(e) {
  // console.log('e.target.id:', e.target.id);
  let newdata = data;
  newdata[e.target.id] = e.target.value
  setData({ ...data, [e.target.id]: e.target.value })
  // console.log("newdata......", newdata);
}

const [t, i18n] = useTranslation('common');

    return (
        <div>
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
       <div className="container-fluid">
      <Authcheck />
         <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-11 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">
            <div className="col-lg-6">
            <div className="login-container">
              <div className="panel left">
                <h3>{t('ResetPassword.Reset Password')}</h3>
               <Form
                  name="normal_login"
                  className="login-form"
                  initialValues={{
                    remember: true,
                  }}
                  ref={formRef}
                  onFinish={(e) => {
                      submit(e);
                  }}
                 >
                  <Form.Item
                    label="UserId"
                    name="id"
                    onChange={(e) => handle(e)}
                    style={{ display: 'none' }}
                    rules={[
                      {
                        required: true,
                        message: "Please input your userid!",
                      },
                    ]}
                  >
                    <Input placeholder="UserId" value={ id } disabled />
                  </Form.Item>

                  <Form.Item
                    label="Token"
                    name="token"
                    style={{ display: 'none' }}
                    onChange={(e) => handle(e)}
                    rules={[
                      {
                        required: true,
                        message: "Please input your token!",
                      },
                    ]}
                  >
                    <Input placeholder="Token" value={ token } disabled />
                  </Form.Item>

                  <Form.Item
                  label={t('ResetPassword.Password')}
                  name="password"
                  onChange={(e) => handle(e)}
                  rules={[
                    {
                      required: true,
                      message: t('ResetPassword."Please input your Password'),
                    },
                  ]}
                >
                  <Input type="password" />
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label={t('ResetPassword.Confirm password')}
                  onChange={(e) => handle(e)}
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: t('ResetPassword."Please confirm your password'),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          t('ResetPassword."The two passwords that you entered do not match'),
                        );
                      },
                    }),
                  ]}
                >
                  <Input type="password" />
                </Form.Item>

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="login-form-button"
                    >
                    {t('ResetPassword.Reset Password')}
                    </Button>
                  </Form.Item>
                </Form>
              </div>
              
            </div>
            </div>
            <div className="col-lg-6">

            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
    </section>
        </div>
    )

  }

export default PasswordReset;