import React from "react";
import { ReactComponent as Facebook } from "../../assets//icons/facebook.svg";
import { ReactComponent as Instagram } from "../../assets//icons/Instagram.svg";
import { ReactComponent as Twitter } from "../../assets//icons/twitter.svg";
import { ReactComponent as Location } from "../../assets/icons/location.svg";
import AppleStore from "../../assets/icons/appleStore.svg";
import GoogleStore from "../../assets/icons/googleStore.svg";
import { ReactComponent as Email } from "../../assets/icons/email.svg";
import { ReactComponent as FooterLogo } from "../../assets/logos/footer.svg";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const [t, i18n] = useTranslation("common");

  return (
    <div className="main">
      <div className="footer container">
        <div className="logo-wrap">
          <FooterLogo />

          <div className="wrap">
            <Location />

            <p className="logo-detail">{t("Footer.Addressdetails")}</p>
          </div>
          <div className="wrap2">
            <Email />
            <p className="logo-detail"> walletdays@gmail.com</p>
          </div>
        </div>
        <div className="nav-links-wrap">
          <div className="nav-wrap">
            <p className="heading">{t("Footer.Company")}</p>
            <p className="link">
              {" "}
              <Link
                to="/about.us"
                style={{ color: "black" }}
                onClick={() => {
                  window.location.href = `/about.us`;
                }}
              >
                {t("Footer.About Us")}
              </Link>{" "}
            </p>

            <p as={Link} to="/contact" className="link">
              <Link
                to="/contact"
                style={{ color: "black" }}
                onClick={() => {
                  window.location.href = `/contact`;
                }}
              >
                {t("Footer.Contact Us")}
              </Link>
            </p>

            <p className="link">
              {" "}
              <Link
                to="/faq"
                style={{ color: "black" }}
                onClick={() => {
                  window.location.href = `/faq`;
                }}
              >
                {t("Footer.FAQs")}
              </Link>
            </p>
          </div>
          <div className="nav-wrap">
            <p as={Link} to="/offers/buywowCoin?page=1" className="heading">
              <Link
                to="/offers/buywowCoin?page=1"
                style={{ color: "black" }}
                onClick={() => {
                  window.location.href = `/offers/buywowCoin?page=1`;
                }}
              >
                {t("Footer.Quick Links")}
              </Link>
            </p>

            <p as={Link} to="/offers/buywowCoin?page=1" className="link">
              <Link
                to="/offers/buywowCoin?page=1"
                onClick={() => {
                  window.location.href = `/offers/buywowCoin?page=1`;
                }}
                style={{ color: "black" }}
              >
                {t("Footer.Buy WOW")}
              </Link>
            </p>

            <p as={Link} to="/offers/sellwowCoin?page=1" className="link">
              <Link
                to="/offers/sellwowCoin?page=1"
                onClick={() => {
                  window.location.href = `/offers/sellwowCoin?page=1`;
                }}
                style={{ color: "black" }}
              >
                {t("Footer.Sell WOW")}
              </Link>
            </p>

            <p as={Link} className="link">
              <Link
                to="/login"
                onClick={() => {
                  window.location.href = `/login`;
                }}
                style={{ color: "black" }}
              >
                {t("Footer.offers")}
              </Link>
            </p>
          </div>
          <div className="nav-wrap">
            <p className="heading">{t("Footer.Legal")}</p>
            <p as={Link} to="/privacy" className="link">
              <Link
                to="/privacy"
                onClick={() => {
                  window.location.href = `/privacy`;
                }}
                style={{ color: "black" }}
              >
                {t("Footer.Privacy Policy")}
              </Link>
            </p>

            <p className="link">
              <Link
                to="/terms&conditions"
                onClick={() => {
                  window.location.href = `/terms&conditions`;
                }}
                style={{ color: "black" }}
              >
                {t("Footer.Terms & Conditions")}
              </Link>
            </p>
          </div>
          <div className="nav-wrap">
            <p className="heading">{t("Footer.SOCIAL MEDIA")}</p>
            <div
              className="icon-wrap"
              style={{ marginTop: "13px", marginBottom: "17px" }}
            >
              <Facebook style={{ cursor: "pointer" }} />
              <Instagram style={{ marginLeft: "5px", cursor: "pointer" }} />
              <Twitter style={{ marginLeft: "5px", cursor: "pointer" }} />
            </div>
            <div className="icon-wrap2">
              <img className="icon" src={AppleStore} alt="apple-store" />
              <img className="icon" src={GoogleStore} alt="google-store" />
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "2px",
          marginBottom: "16px",
          opacity: "0.1",
          border: "1px solid #222222",
        }}
      />

      <p className="tagline">
        © 2017 - 2022 Walletdays.com All Rights Reserved
      </p>
    </div>
  );
}

export default Footer;
