import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { connect } from "react-redux";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { NavLink } from "react-router-dom";
import { doLogin } from "../../store/actions/authActions";
import "./Login.css";
import axios from "axios";
import { updateAction } from "../../helpers/updateActions";
import { useTranslation, withTranslation } from "react-i18next";
import { Auth } from "../../auth/Auth";
import { getWowBalance } from "../../helpers/CallAPi";
import footmailLogo from "../../views/Marketing/img/blackemail.png";
import SectionSix from "../Home/sections/sectionsix";

const baseURL = process.env.REACT_APP_BASE_URL;
export const LOGIN = "LOGIN";
export const LOADING_USER = "LOADING_USER";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const GET_USER = "GET_USER";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const USER_ERROR = "USER_ERROR";

const Login = (props) => {
  const onFinish = (values) => {
    if (captVal && captVal != 0) {
      // props.doLogin(values, props.history);
      doLogin(values);
    } else {
      setIsCap(false);
    }
  };

  const [ErrorMsg, setErrorMsg] = useState();
  const [captVal, setCaptVal] = useState("1");
  const [isCap, setIsCap] = useState(true);
  const [ip, setIP] = useState("");
  const [SuccMsg, setSuccMsg] = useState();
  const [loginMsg, setLoginMsg] = useState();
  const [TFAflag, setTFAflag] = useState("0");
  const [twoFactor, setTwofactor] = useState();
  // console.log("loginMsg......", loginMsg);
  // console.log("Captcha value:", captVal);
  const url = `${baseURL}/api/auth/createloginhistory`;

  const onChange = (value) => {
    if (value) {
      setCaptVal(value);
    }
  };

  useEffect(() => {
    axios.get("https://geolocation-db.com/json/").then((res) => {
      // console.log(res.data);
      setIP(res.data.IPv4);
    });
  });

  const publicIp = require("public-ip");
  const userId = localStorage.getItem("userId");
  (async () => {
    // console.log(await publicIp.v4());
    //setIp(await publicIp.v4());
  })();

  const Datetime = Date().toLocaleString();

  const doLogin = (logindata) => {
    const userId = localStorage.getItem("userId");
    const button = document.getElementById("login");
    button.disabled = true;
    console.log("doLogin", logindata);
    if (TFAflag == 0) {
      axios
        .post(`${baseURL}/api/auth/login`, logindata)
        .then((response) => {
          if (response.data.two_factor == 0) {
            const { token, userId, username, email, delete_status } = response.data;
            // console.log("delete_status",delete_status);
            if (delete_status == 1) {
              let errorMessage = t(
                "login.Your account is deleted, Please contact to administrator for further process"
              );
              toast.error(errorMessage);
              const button = document.getElementById("login");
              button.disabled = false;
            } else {
              localStorage.setItem("token", token);
              localStorage.setItem("userId", userId);
              localStorage.setItem("username", username);
              localStorage.setItem("email", email)
              sessionStorage.setItem("Loginmsg", t("login.Login successful"));
              Auth.isAuthenticated();
              getWowBalance(userId);
              // toast.success("Transaction Successfull");
              let sucessMsg = "Logged In Successfully";
              setSuccMsg(sucessMsg.sucessMsg);
              // console.log("Login donee ",sucessMsg);
              setLoginMsg(sucessMsg);
              loginHistory(sucessMsg, userId);
              props.history.push("/offers/buywowCoin?page=1");
            }
          } else {
            const { userId } = response.data;
            // console.log("Token", response.data);
            setTwofactor(response.data);
            if (response.data.message) {
              console.log("errorMessageab............", response);
              toast.error(t("login." + response.data.message));
              const button = document.getElementById("login");
              button.disabled = false;
            } else {
              setTFAflag("1");
              localStorage.setItem("userId", userId);
              const button = document.getElementById("login");
              button.disabled = false;
            }
          }
        })
        .catch((error) => {
          console.log("errorMessageaa............", error);
          let errorMessage = error.response.data
            ? error.response.data
            : "Logged In Failed";
          toast.error(t("login." + errorMessage.errorMessage));
          // console.log(errorMessage.errorMessage);
          setLoginMsg(t("login." + errorMessage.errorMessage));
          const button = document.getElementById("login");
          button.disabled = false;
          //loginHistory();
        })
        .finally(() => {});
    } else {
      const userId = localStorage.getItem("userId");
      // console.log("userId with auth", userId);
      const userToken = {
        user_id: userId,
        token: logindata.Authtoken,
      };
      console.log("userToken", userToken);
      axios
        .post(`${baseURL}/api/auth/logintfaverify`, userToken)
        .then((response) => {
          console.log("logintfaverify", response);
          const { status, token, userId, username } = response.data;
          if (status == 200) {
            // console.log("status", status);
            localStorage.setItem("token", token);
            localStorage.setItem("userId", userId);
            localStorage.setItem("username", username);
            props.history.push("/offers/trade");
            window.location.reload();
            toast.success("Login success !");
            let sucessMsg = "Logged In Successfully";
            //console.log("Login donee ",sucessMsg);
            setLoginMsg(sucessMsg);
            loginHistory(sucessMsg, userId);
          } else {
            let errorMessage = response.data.message
              ? response.data.message
              : "Logged In Failed";
            const button = document.getElementById("login");
            button.disabled = false;
            setLoginMsg(t("login." + errorMessage));
            toast.error(t("login." + errorMessage));
            //loginHistory();
          }
        })
        .catch((error) => {
          // console.log("errorMessage amrut...............", error);
          let errorMessage = error.response.data ? error.response.data : null;
          setErrorMsg(t("login." + errorMessage.errorMessage));
          // console.log(errorMessage.errorMessage);
          setLoginMsg(t("login." + errorMessage.errorMessage));
          //loginHistory();
          toast.error(t("login." + errorMessage.errorMessage));
          const button = document.getElementById("login");
          button.disabled = false;
        })
        .finally(() => {});
    }
  };
  useEffect(() => {
    if (localStorage.getItem("resetpassword")) {
      toast.success(localStorage.getItem("resetpassword"));
      localStorage.removeItem("resetpassword");
    }

    if (sessionStorage.getItem("GoLogin")) {
      toast.error(sessionStorage.getItem("GoLogin"));
      sessionStorage.removeItem("GoLogin");
    }

    if (sessionStorage.getItem("Registrationmsg") != "") {
      toast.success(sessionStorage.getItem("Registrationmsg"));
      sessionStorage.removeItem("Registrationmsg");
    }
  }, []);

  const loginHistory = (sucessMsg, userId) => {
    const sendData = {
      user_id: userId,
      type: sucessMsg,
      datetime: Datetime,
      ipaddress: ip,
    };
    // console.log("sendData", sendData);

    axios
      .post(`${baseURL}/api/auth/createloginhistory`, sendData)
      .then((res) => {
        //  console.log("loginHistory succ.......", res);
      })
      .catch((error) => {
        // console.log("loginHistory error:", error);
      });
  };

  // const onFinishFailed = errorInfo => {
  //   console.log("Failed:", errorInfo);
  // };

  //may need to refactor left and right panels as form could be reused in modal

  const [t, i18n] = useTranslation("common");
  return (
    <>
      {" "}
      <div className="login-page">
        <Authcheck />
        <div className="login-upper"></div>
        <div className="login-container">
          {/* <div class="col-12 col-lg-6"> */}
          <div className="left-panel">
            <h3 className="login-header">
              {t("login.Log in to your account")}
            </h3>
            <Form
              name="normal_login"
              className="login-form"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                label={t("login.Username")}
                className="input-headers username-ipt"
                name="username"
                rules={[
                  {
                    required: true,
                    message: t("login.Please input your Username"),
                  },
                ]}
              >
                <input
                  className="input-field"
                  placeholder={t("login.Username")}
                />
              </Form.Item>
              <Form.Item
                className="input-headers"
                label={t("login.Password")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("login.Please input your Password"),
                  },
                ]}
              >
                <input
                  className="input-field"
                  type="password"
                  placeholder={t("login.Password")}
                />
              </Form.Item>
              {TFAflag == 0 ? null : (
                <>
                  <Form.Item
                    label={t("login.Two Factor Auth Code")}
                    name="Authtoken"
                    rules={[
                      {
                        // required: true,
                        message: "Please input your Authtoken!",
                      },
                    ]}
                  >
                    <input
                      type="text"
                      placeholder={t("login.Two Factor Auth Code")}
                    />
                  </Form.Item>
                  <p>{t("login.Two Factor Note")}</p>
                </>
              )}
              <div className="forget-wrap">
                <div className="remember-wrap">
                  <Form.Item style={{ paddingTop: "25px" }} name="rememberMe">
                    <input
                      type="checkbox"
                      value="lsRememberMe"
                      id="rememberMe"
                    />
                  </Form.Item>
                  <p className="remember">{t("login.Remember me")}</p>
                </div>

                <NavLink to="/Forgetpass">
                  <p className="forget-password">
                    {t("login.Forgot your password")}
                  </p>
                </NavLink>
              </div>

              <ReCAPTCHA
                style={{ background: "none" }}
                sitekey="6LfqtKIgAAAAAADTuBpOEnLlqZpTf96bOR4R26ZI"
                onChange={onChange}
              />
              {!isCap ? (
                <span style={{ color: "red" }}>
                  {t("login.Please select recaptcha")}
                </span>
              ) : (
                <></>
              )}
              <Form.Item style={{ marginBottom: "0px" }}>
                <h6 style={{ color: "red" }}>{ErrorMsg}</h6>
              </Form.Item>

              <Form.Item>
                <button
                  type="primary"
                  htmlType="submit"
                  id="login"
                  className="login-form-btn"
                >
                  {t("login.LOG IN")}
                </button>
              </Form.Item>
            </Form>
          </div>
          <div className="login-form-right">
            <div className="right-panel">
              <p className="right-panel-heading">
                {t("login.Don't have an account yet")}
              </p>
              <br></br>
              <p className="right-panel-sub-heading">
                {t("login.WOW is the safest way to buy and sell WOW")}.
              </p>
              <br></br>
              <Link to="/signup">
                <button className="right-panel-btn">
                  {t("login.CREATE A NEW ACCOUNT")}
                </button>
              </Link>
            </div>

            <div className="right-panel">
              <p className="right-panel-heading">
                {" "}
                {t("signup.Register a New Account")}:
              </p>
              <br />
              <p className="right-panel-sub-heading">
                {t(
                  "signup.After completing the registration process, click the confirmation link sent to your registered email ID to activate your account"
                )}
              </p>
              <br />
              <p className="right-panel-sub-heading">
                {t(
                  "signup.If you do not receive a confirmation email, please contact us or send an email to"
                )}{" "}
                <span style={{ fontWeight: "600" }}> walletdays@gmail.com</span>
                <Link to="/contact">
                  <button
                    style={{ marginTop: "10px" }}
                    className="right-panel-btn"
                  >
                    {t("Footer.Contact")}
                  </button>
                </Link>
              </p>
            </div>
          </div>
        </div>

        <ToastContainer autoClose={2000} style={{ marginTop: "60px" }} />
      </div>
      <SectionSix />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingUser: state.auth.loadingUser,
    register: state.auth.registerError,
  };
};

export default connect(mapStateToProps, { doLogin })(Login);
