import React, { useState, useEffect } from "react";
import Authcheck from "../../../components/Authcheck/Authcheck";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import ReactPaginate from "react-paginate";
// import "./Blockuser.scss";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation, withTranslation } from "react-i18next";
import "./Cancelcontract.css";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem("userId");
const token = localStorage.getItem("token");

function Cancelcontract() {
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [posts, setAllPosts] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [t, i18n] = useTranslation("common");

  const getPostData = (data) => {
    console.log("DATA =====", data)
    return (
      <>
        <Authcheck />
        <div className="close-contract-table">
          <div className="close-contract-header">
            <p
              style={{ maxWidth: "60px", minWidth: "60px" }}
              className="close-contract-header-tag"
            >
              {t("closedcontract.Trade Id")}
            </p>
            <p
              style={{ maxWidth: "100px", minWidth: "100px" }}
              className="close-contract-header-tag"
            >
              {t("closedcontract.Created date")}
            </p>
            <p
              style={{ maxWidth: "94px", minWidth: "94px" }}
              className="close-contract-header-tag"
            >
              {t("closedcontract.Trade Type")}
            </p>
            <p
              style={{ maxWidth: "95px", minWidth: "95px" }}
              className="close-contract-header-tag"
            >
              {t("closedcontract.Trade Partner")}
            </p>
            <p
              style={{ maxWidth: "150px", minWidth: "150px" }}
              className="close-contract-header-tag"
            >
              {t("closedcontract.Transaction Status")}
            </p>
            <p
              style={{ maxWidth: "85px", minWidth: "85px" }}
              className="close-contract-header-tag"
            >
              {t("closedcontract.fiat Amount")}
            </p>
            <p
              style={{ maxWidth: "175px", minWidth: "175px" }}
              className="close-contract-header-tag"
            >
              {t("closedcontract.Trade WOWCoin Amount")}
            </p>
            <p
              style={{ maxWidth: "45px", minWidth: "45px" }}
              className="close-contract-header-tag"
            >
              Action
            </p>
          </div>

          {data ? (
            <>
              {data.map((item, i) => (
                <>
                  {" "}
                  <div key={item.id} className="close-contract-row">
                    <p
                      key={item.id}
                      style={{ maxWidth: "60px", minWidth: "60px" }}
                      className="close-contract-row-data-tag"
                    >
                      <Link
                        to={`/trade/closed/${item?.offer_id}/${item?.maker_id}/${item?.uniqueid}`}
                        style={{ color: "blue" }}
                      >
                        {item?.id}
                      </Link>
                    </p>
                    <p
                      key={item.id}
                      style={{ maxWidth: "100px", minWidth: "100px" }}
                      className="close-contract-row-data-tag"
                    >
                      {item?.created_at}
                    </p>
                    <p
                      key={item.id}
                      style={{ maxWidth: "94px", minWidth: "94px" }}
                      className="close-contract-row-data-tag"
                    >
                      {UserId == item.maker_id ? (
                        <>
                          {UserId == item.maker_id && item.is_maker_buying ? (
                            <>{t("newoffers.Buying")}</>
                          ) : (
                            <>{t("newoffers.Selling")}</>
                          )}
                        </>
                      ) : (
                        <>
                          {UserId == item.taker_id && item.is_maker_buying ? (
                            <>{t("newoffers.Selling")}</>
                          ) : (
                            <>{t("newoffers.Buying")}</>
                          )}
                        </>
                      )}
                    </p>
                    <p
                      key={item.id}
                      style={{ maxWidth: "95px", minWidth: "95px" }}
                      className="close-contract-row-data-tag"
                    >
                      {UserId == item.maker_id ? (
                        <>{item?.usertaker}</>
                      ) : (
                        <>{item?.usermaker}</>
                      )}
                    </p>
                    <p
                      key={item.id}
                      style={{ maxWidth: "150px", minWidth: "150px" }}
                      className="close-contract-row-data-tag"
                    >
                      {t("closedcontract.Wowcoin released")}
                    </p>
                    <p
                      key={item.id}
                      style={{ maxWidth: "85px", minWidth: "85px" }}
                      className="close-contract-row-data-tag"
                    >
                      {item?.currency_symbol} {item?.fiat_amount}
                    </p>
                    <p
                      key={item.id}
                      style={{ maxWidth: "175px", minWidth: "175px" }}
                      className="close-contract-row-data-tag"
                    >
                      {" "}
                      {item?.bch_amount}
                    </p>
                    <button className="close-contract-row-btn">
                      <Link
                        to={`/trade/closed/${item?.offer_id}/${item?.maker_id}/${item?.uniqueid}`}
                        style={{ color: "blue" }}
                      >
                        View
                      </Link>
                    </button>
                  </div>
                  <div key={item.id} className="close-contract-row-mobile">
                    <div className="mobile-sub-col">
                      {" "}
                      <div className="row-sub-wrapper">
                        <p
                          style={{ maxWidth: "60px", minWidth: "60px" }}
                          className="close-contract-header-tag"
                        >
                          {t("closedcontract.Trade Id")}
                        </p>
                        <p
                          key={item.id}
                          style={{ maxWidth: "60px", minWidth: "60px" }}
                          className="close-contract-row-data-tag"
                        >
                          <Link
                            to={`/trade/closed/${item?.offer_id}/${item?.maker_id}/${item?.uniqueid}`}
                            style={{ color: "blue" }}
                          >
                            {item?.id}
                          </Link>
                        </p>
                      </div>
                      <div className="row-sub-wrapper">
                        <p
                          style={{ maxWidth: "100px", minWidth: "100px" }}
                          className="close-contract-header-tag"
                        >
                          {t("closedcontract.Created date")}
                        </p>
                        <p
                          key={item.id}
                          style={{ maxWidth: "100px", minWidth: "100px" }}
                          className="close-contract-row-data-tag"
                        >
                          {item?.created_at}
                        </p>
                      </div>
                      <div className="row-sub-wrapper">
                        <p
                          style={{ maxWidth: "94px", minWidth: "94px" }}
                          className="close-contract-header-tag"
                        >
                          {t("closedcontract.Trade Type")}
                        </p>
                        <p
                          key={item.id}
                          style={{ maxWidth: "94px", minWidth: "94px" }}
                          className="close-contract-row-data-tag"
                        >
                          {UserId == item.maker_id ? (
                            <>
                              {UserId == item.maker_id &&
                                item.is_maker_buying ? (
                                <>{t("newoffers.Buying")}</>
                              ) : (
                                <>{t("newoffers.Selling")}</>
                              )}
                            </>
                          ) : (
                            <>
                              {UserId == item.taker_id &&
                                item.is_maker_buying ? (
                                <>{t("newoffers.Selling")}</>
                              ) : (
                                <>{t("newoffers.Buying")}</>
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="mobile-sub-col">
                      {" "}
                      <div className="row-sub-wrapper">
                        <p
                          style={{ maxWidth: "95px", minWidth: "95px" }}
                          className="close-contract-header-tag"
                        >
                          {t("closedcontract.Trade Partner")}
                        </p>

                        <p
                          key={item.id}
                          style={{ maxWidth: "95px", minWidth: "95px" }}
                          className="close-contract-row-data-tag"
                        >
                          {UserId == item.maker_id ? (
                            <>{item?.usertaker}</>
                          ) : (
                            <>{item?.usermaker}</>
                          )}
                        </p>
                      </div>
                      <div className="row-sub-wrapper">
                        <p
                          key={item.id}
                          style={{ maxWidth: "150px", minWidth: "150px" }}
                          className="close-contract-header-tag"
                        >
                          {t("closedcontract.Transaction Status")}
                        </p>

                        <p
                          style={{ maxWidth: "150px", minWidth: "150px" }}
                          className="close-contract-row-data-tag"
                        >
                          {t("closedcontract.Wowcoin released")}
                        </p>
                      </div>
                      <div className="row-sub-wrapper">
                        <p
                          style={{ maxWidth: "85px", minWidth: "85px" }}
                          className="close-contract-header-tag"
                        >
                          {t("closedcontract.fiat Amount")}
                        </p>
                        <p
                          key={item.id}
                          style={{ maxWidth: "85px", minWidth: "85px" }}
                          className="close-contract-row-data-tag"
                        >
                          {item?.currency_symbol} {item?.fiat_amount}
                        </p>
                      </div>
                      <div className="row-sub-wrapper">
                        <p
                          style={{ maxWidth: "175px", minWidth: "175px" }}
                          className="close-contract-header-tag"
                        >
                          {t("closedcontract.Trade WOWCoin Amount")}
                        </p>
                        <p
                          key={item.id}
                          style={{ maxWidth: "175px", minWidth: "175px" }}
                          className="close-contract-row-data-tag"
                        >
                          {item?.bch_amount}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="close-contract-row-mobile-btn">
                    {" "}
                    <button className="close-contract-row-btn">
                      {" "}
                      <Link
                        to={`/trade/closed/${item?.offer_id}/${item?.maker_id}/${item?.uniqueid}`}
                        style={{ color: "blue" }}
                      >
                        View
                      </Link>
                    </button>
                  </div>
                </>
              ))}
            </>
          ) : (
            <p>{t("Transactions.No data Found")}</p>
          )}
        </div>

        <ToastContainer autoClose={2000} style={{ marginTop: "60px" }} />
      </>
    );
  };

  const getAllPosts = async () => {
    const UserId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const res = await axios.get(
      `${baseURL}/api/trades/getallcancelcontract/${UserId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    // if data is present then
    if (res.data.data) {
      const data = res.data.data;
      const startIndex = offset * postsPerPage;
      const slice = data.slice(startIndex, startIndex + postsPerPage);
      setAllPosts(slice);
      setPageCount(Math.ceil(data.length / postsPerPage));
    } else {
      setAllPosts(null);
    }
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage);
  };

  const unBlockuser = (id) => {
    Axios.post(`${baseURL}/api/auth/unblock_user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        toast.success(t("userprofile.User Unblocked Successfully"));
        // console.log("response", response);
        getAllPosts();
        // history.push('/offers/trade')
      })
      .catch((error) => {
        toast.success("Error in Unblocking");
      });
  };

  useEffect(() => {
    getAllPosts();
  }, [offset]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {/* Display all the posts */}
      {getPostData(posts)}

      {/* Using React Paginate */}
      <ReactPaginate
        previousLabel={t("Withdraw.Previous")}
        nextLabel={t("Withdraw.Next")}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination-container"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
}

export default Cancelcontract;