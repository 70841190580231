import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Form, Input, Button, Select } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Axios from 'axios';
import "./Search.scss";
import { Link } from "react-router-dom";
import {useTranslation, withTranslation} from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
const { Option } = Select;
const baseURL = process.env.REACT_APP_BASE_URL;

//dynamically needs to change based on url params
const title = {
  trade: "Trade wow coin using any payment method",
  items: "Buy and sell goods using wow coin (wow)",
  buysell: "Buy and Sell WowCoin Everywhere",
};

// search option div has contains duplicate code - note to refactor
// needs to be connected to global state so that select could render OfferList component'
const Search = (props) => {
const [getCountry, setGetCountry] = useState([]);

  useEffect( () => {


    if(sessionStorage.getItem("trademsg")){
      toast.success(sessionStorage.getItem("trademsg"));
      sessionStorage.removeItem("trademsg");
     }
     
      if(sessionStorage.getItem("tradeerror")){
       toast.error(sessionStorage.getItem("tradeerror"));
       sessionStorage.removeItem("tradeerror");
      }
      if(sessionStorage.getItem("Canceltrade")){
        toast.success(sessionStorage.getItem("Canceltrade"));
        sessionStorage.removeItem("Canceltrade");
       }
  
    try {
      Axios.get(`${baseURL}/api/auth/getcountry`)
      .then((response) => {
        setGetCountry(response.data);
      });
    }catch (error) {
      // console.log(error);
    }
  
  }, []);

  const [expand, setExpand] = useState(false);
  function onChange(value) {
    // to add actionCreator needs to change slice of state
    // console.log(`selected ${value}`);
  }

  function onBlur() {
    // console.log("blur");
  }

  function onFocus() {
    // console.log("focus");
  }

  function onSearch(val) {
    // console.log("search:", val);
  }
  // if (location.pathname.match(/offers/trade)){
  //   return null;
  // }
const [t, i18n] = useTranslation('common');

  return (
    
    <div className="search-container " >
      <br/>
      <div className="head_all">
        <h6 style={{  fontWeight: '700', fontSize: "1.15rem" }}>{t('Explore.Trade wow coin using any payment method')}</h6>
        <br/>
      </div>
      <Form>
      <div className="search-options-container">
        <div className="search-option">
                      <Form.Item
                        label={t('Explore.I want to')}
                        name="buyBCH"
                        id="buyBCH"
                        >
                       <Select
                        className="select-buy-sell"
                        size="large"
                        showSearch
                        style={{marginLeft: "50px"}}
                        // style={{ width: 200 }}
                        defaultValue={t('Explore.Buy or Sell WOW')}
                        optionFilterProp="children"
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onSearch={onSearch}
                        filterOption={(input, option) =>
                          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        <Option value="buySell">
                          {t('Explore.Buy or Sell WOW')}
                        </Option>
                        <Option value="1">
                        <Link to={`/offers/buywowCoin?page=1`}>
                        {t('Explore.Buy WOW')}
                        </Link>
                        </Option>
                        <Option value="0">
                        <Link to={`/offers/sellwowCoin?page=1`}>
                        {t('Explore.Sell WOW')}
                        </Link>
                        </Option>
                      </Select>
                    </Form.Item>

        
        </div>
          {/* <div className="search-option">
            <Form.Item
              label="Payment method"
              name="payment_method"
              id="payment_method"
              >
            <Select
                size="large"
                showSearch
                style={{ width: 200 }}
                defaultValue="Any payment method"
                optionFilterProp="children"
                onChange={onChange}
                onFocus={onFocus}
                onBlur={onBlur}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="any">Any payment method</Option>
                <Option value="cash">Cash (in Person)</Option>
                <Option value="paypal">PayPal</Option>
                <Option value="skrill">Skrill</Option>
                <Option value="venmo">Venmo</Option>
              </Select>
          </Form.Item>

          </div> */}
        {/* <div className="search-option">
            <Form.Item
              label="Location"
              name="country"
              id="country"
              >
            <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            defaultValue="Anywhere"
            optionFilterProp="children"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
             {getCountry.map((value, index) => {
                  return <Option value={value.label}>{value.label}</Option>
                })}
          </Select>
          </Form.Item>

        </div> */}
        {/* <div className="search-option">
        <Form.Item
              label="Sort by"
              name="sort_by"
              id="sort_by"
              >
            <Select
            size="large"
            showSearch
            style={{ width: 200 }}
            defaultValue="Popularity"
            optionFilterProp="children"
            onChange={onChange}
            onFocus={onFocus}
            onBlur={onBlur}
            onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="popularity">Popularity</Option>
            <Option value="price">Price</Option>
          </Select>
          </Form.Item>

        </div> */}
      </div>
      {/* {expand && (
        <div className="search-options-container advanced">
          <div className="search-option">
            <label>I want to ...</label>
            <Select
              size="large"
              showSearch
              style={{ width: 250 }}
              defaultValue="Any currency"
              optionFilterProp="children"
              onChange={onChange}
              onFocus={onFocus}
              onBlur={onBlur}
              onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="any">Any currency</Option>
              <Option value="usd">USD</Option>
              <Option value="gbp">GBP</Option>
              <Option value="eur">EUR</Option>
              <Option value="cad">CAD</Option>
            </Select>
          </div>
          <div className="search-option">
            <label>Text search</label>
            <Input placeholder="Search usernames..." style={{ width: 500 }}/>
          </div>
         
          <div className="search-option">
          <Form.Item>
          <label><br/></label>
          <br/>
            <Button type="primary" htmlType="submit" id="continue">
              SEARCH
            </Button>
          </Form.Item>
          </div>

        </div>
      )} */}
      {/* <div className="advanced-search">
        <Button
          type="link"
          onClick={() => {
            setExpand(!expand);
          }}
        >
          {expand ? "Hide advanced search " : "Advanced search "}
          {expand ? <UpOutlined /> : <DownOutlined />}
        </Button>
      </div> */}
      </Form>

      {/* <div className="head_all">
        <h3 style={{  fontWeight: '700',fontSize: "1.75rem" }}>{title.buysell}</h3>
        <br/>
      </div> */}

       <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
    </div>
    
  );
};

export default Search;
