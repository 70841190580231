import React, { useState, useEffect } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, Spin } from "antd";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { useTranslation, withTranslation } from "react-i18next";
import "./Withdrawcoin.css";
import { getWowBalance } from "../../helpers/CallAPi";
import { LoadingOutlined } from "@ant-design/icons";

const Withdrawcoin = () => {
  const [t, i18n] = useTranslation("common");
  const formRef = React.useRef(null);
  const [data, setData] = useState({});
  const [userInfo, setUserInfo] = useState();
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [userData, setUserData] = useState({});
  const currentRoute = useHistory().location.pathname.toLowerCase();
  //   console.log("cuudfssdfdf", currentRoute)

  // console.log("chetna", userInfo?.Beneficiary_name)

  useEffect(() => {
    fetchAccount();
    if (sessionStorage.getItem("sendaccountAccount")) {
      toast.success(t("Withdraw.Withdraw amount sent to admin"));
      sessionStorage.removeItem("sendaccountAccount");
    }
    if (sessionStorage.getItem("sendaccounterror")) {
      toast.error(t("newoffers." + sessionStorage.getItem("sendaccounterror")));
      sessionStorage.removeItem("sendaccounterror");
    }

    if (sessionStorage.getItem("updatemsg") != "") {
      toast.success(sessionStorage.getItem("updatemsg"));
      sessionStorage.removeItem("updatemsg");
    }
  }, []);

  const fetchAccount = () => {
    try {
      axios
        .get(`${baseURL}/api/auth/get_user_bank_details/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          // console.log("getTran response.....", response.data.data);
          const accountres = response.data.data;
          // console.log("getAccount response.....", accountres);
          setUserInfo(accountres);
          formRef.current.setFieldsValue({
            benefiname: accountres?.Beneficiary_name,
            bankname: accountres?.Bank_name,
            biccode: accountres?.Bic_swift_code,
            natclearcode: accountres?.National_clearing_code,
            accountno: accountres?.Iban,
          });
        });
    } catch (error) {
      // console.log(error);
    }
  };

  const [PendingMsg, setPendingMsg] = useState();
  console.log("DATA ======", data, userInfo);
  const submit = (e) => {
    const userId = localStorage.getItem("userId");
    const username = localStorage.getItem("username");
    const email = localStorage.getItem("email");
    const button = document.getElementById("continue");
    button.disabled = true;
    setPendingMsg(false);

    // SEND WITHDRAW REQUEST SMS
    userId === 321 && sendSMS(username, email, data.wowwithdraw, "request");

    const sendAccount = {
      // benefiname: userInfo?.Beneficiary_name,
      // bankname: userInfo?.Bank_name,
      // bankname: userInfo?.Bank_name,
      // biccode: userInfo?.Bic_swift_code,
      // natclearcode: userInfo?.National_clearing_code,
      // accountno: userInfo?.Iban,
      userid: userId,
      amount: data.wowwithdraw,
    };
    // console.log("sendAccount", sendAccount)
    axios
      .post(
        `${baseURL}/api/wallet/user_send_coin_bankdetail_admin`,
        sendAccount,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("send_coin", res);
        if (res.data.status == false) {
          const button = document.getElementById("continue");
          button.disabled = false;
          setPendingMsg(false);
          sessionStorage.setItem("sendaccounterror", res.data.errorMessage);
          window.location.reload();
        } else {
          if (res.data.success == false) {
            const button = document.getElementById("continue");
            button.disabled = false;
            setPendingMsg(false);
            sessionStorage.setItem("sendaccounterror", res.data.error);
            window.location.reload();
          } else {
            // SEND WITHDRAW REQUEST SMS
            userId === 321 && sendSMS(username, email, data.wowwithdraw, "withdraw");
            getWowBalance(userId);
            //toast.success("Profile Updated Successfully");
            // console.log("withdrawamout to  admin.......", res);
            sessionStorage.setItem("sendaccountAccount", sendAccount);
            setPendingMsg(false);
            window.location.reload();
          }
        }
      })
      .catch((error) => {
        sessionStorage.setItem("sendaccounterror", error);
        setPendingMsg(false);
      });
  };

  const sendSMS = (username, email, amt, type) => {
    // SEND SMS
    const formData = new FormData();
    formData.append("fullName", username);
    formData.append("emailId", email);
    formData.append("amount", amt);
    formData.append("type", type);

    axios
      .post("https://www.fxbulls.com/crm/api/sendsms", formData)
      .then(({ data }) => console.log("DATA =======>", data));
  };

  function handle(e) {
    // console.log('e.target.id:', e.target.id);
    let newdata = data;
    newdata[e.target.id] = e.target.value;
    setData({ ...data, [e.target.id]: e.target.value });
    // console.log("newdata......", newdata);
  }

  if (PendingMsg) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: 60,
          flexDirection: "column",
        }}
      >
        <Spin indicator={antIcon} />
        <h6 style={{ fontSize: "15px", paddingTop: 8 }}>
        {t("notification.Please wait while your transaction is processing")}
        </h6>
      </div>
    );
  }

  return (
    <>
      <Authcheck />

      <div className="withdraw-container">
        <div class="row">
          <div class="col-12 col-lg-6 pr-lg-0">
            <Form
              initialValues={{
                remember: true,
              }}
              ref={formRef}
              onFinish={(e) => {
                submit(e);
              }}
            >
              <Form.Item
                name="benefiname"
                id="benefiname"
                onChange={(e) => handle(e)}
                rules={[
                  {
                    required: true,
                    message: "Please input Beneficiary Name",
                  },
                ]}
              >
                <div className="input-wrapper-withdraw-coin">
                  <p className="input-label-withdraw-coin">
                    {t("Withdraw.Beneficiary Name *")}
                  </p>
                  <input
                    className="withdraw-coin-input"
                    value={userInfo?.Beneficiary_name}
                    disabled={true}
                  />
                </div>
              </Form.Item>

              <Form.Item
                name="bankname"
                id="bankname"
                onChange={(e) => handle(e)}
                rules={[
                  {
                    required: true,
                    message: "Please input Bank Name",
                  },
                ]}
              >
                <div className="input-wrapper-withdraw-coin">
                  <p className="input-label-withdraw-coin">
                    {t("Withdraw.Bank Name *")}
                  </p>
                  <input
                    className="withdraw-coin-input"
                    value={userInfo?.Bank_name}
                    disabled={true}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="biccode"
                id="biccode"
                onChange={(e) => handle(e)}
                rules={[
                  {
                    required: true,
                    message: "Please input BIC/SWIFT Code",
                  },
                ]}
              >
                <div className="input-wrapper-withdraw-coin">
                  <p className="input-label-withdraw-coin">
                    {t("Withdraw.BIC/SWIFT Code *")}
                  </p>
                  <input
                    className="withdraw-coin-input"
                    value={userInfo?.Bic_swift_code}
                    disabled={true}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="natclearcode"
                id="natclearcode"
                onChange={(e) => handle(e)}
              >
                <div className="input-wrapper-withdraw-coin">
                  <p className="input-label-withdraw-coin">
                    {t("Withdraw.National Clearing Code")}
                  </p>
                  <input
                    className="withdraw-coin-input"
                    value={userInfo?.National_clearing_code}
                    disabled={true}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="accountno"
                id="accountno"
                onChange={(e) => handle(e)}
                rules={[
                  {
                    required: true,
                    message: t(
                      "Withdraw.Please input your Account Number or IBAN"
                    ),
                  },
                ]}
              >
                <div className="input-wrapper-withdraw-coin">
                  <p className="input-label-withdraw-coin">
                    {t("Withdraw.Account Number or IBAN *")}
                  </p>
                  <input
                    className="withdraw-coin-input"
                    value={userInfo?.Iban}
                    disabled={true}
                  />
                </div>
              </Form.Item>

              <Form.Item
                name="wowwithdraw"
                id="wowwithdraw"
                onChange={(e) => handle(e)}
                rules={[
                  {
                    required: true,
                    message: t("Withdraw.Please input Wow amount to withdraw"),
                  },
                ]}
              >
                <div className="input-wrapper-withdraw-coin">
                  <p className="input-label-withdraw-coin">
                    {t("Withdraw.Wow amount to withdraw *")}
                  </p>
                  <input
                    className="withdraw-coin-input-amount"
                    type="number"
                    name="wowwithdraw"
                    id="wowwithdraw"
                  />
                </div>
              </Form.Item>
              <Form.Item>
                <button
                  className="submit-coin-btn"
                  htmlType="submit"
                  id="continue"
                >
                  {t("Withdraw.Submit")}
                </button>
              </Form.Item>
              <h6 style={{ color: "red", fontSize: "15px" }}>{PendingMsg}</h6>
            </Form>
          </div>
        </div>
      </div>

      <ToastContainer autoClose={10000} style={{ marginTop: "60px" }} />
    </>
  );
};

export default Withdrawcoin;
