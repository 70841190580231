import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link } from "react-router-dom";
import { Form, Input, Button, Select } from "antd";
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import {useTranslation, withTranslation} from "react-i18next";
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import "./Onfido.scss";
const baseURL = process.env.REACT_APP_BASE_URL;
// const url = `${baseURL}/api/verification/uploadIdentity`;

const Account = (props) => {
  const [t, i18n] = useTranslation('common');
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };

  const [data, setData] = useState({});
  const dataArray = new FormData();
  const formRef = React.useRef(null);
  const  token= localStorage.getItem("token");
const [uploadFile, setUploadFile] = React.useState();

    const submitForm = (event) => {
    event.preventDefault();

    const dataArray = new FormData();
    dataArray.append("uploadFile", uploadFile);

    Axios
      .post(`${baseURL}/api/verification/uploadIdentity`, dataArray, {
        headers: {
          "Authorization" : `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
      })
      .then((response) => {
        // console.log("res.data.......", response);
        sessionStorage.setItem("addressmsg", "Address Details Submmited Successfully");
        props.history.push("/onfido");
      })
      .catch((error) => {
        // console.log("error:", error);
        toast.error("Address Details Submmited Failed");
      });
  };

  useEffect( () => {
    if(sessionStorage.getItem("addressmsg")!=''){
      toast.success(sessionStorage.getItem("addressmsg"));
      sessionStorage.removeItem("addressmsg");
     }
}, []);

  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">

            <div className="profile-container">
              <div className="secondary-nav">
                <div className="tab-link">
                  <Link to="/my-account" activeClassName="is-active">
                  {t('Profile.Profile')}
                  </Link>
                </div>
                <div className="tab-link">
                  <Link to="/settings" activeClassName="is-active">
                  {t("Profile.Security")}
                  </Link>
                </div>

                <div className="tab-link">
                  <Link to="/verification" activeClassName="is-active">
                  {t("Profile.Verification")}
                  </Link>
                </div>

                {/* <div className="tab-link">
                  <Link to="/notifications" activeClassName="is-active">
                    Notifications
                  </Link>
                </div> */}

                {/* <div className="tab-link">
                  <Link to="/advanced" activeClassName="is-active">
                  {t("Profile.Advanced")}
                  </Link>
                </div> */}
              </div>

              <br></br>
              <div class="row">
                <div className="col-md-12">
                  <div id="base-status-messages" className="alert alert-info ">
                    <a className="close" href="#" data-dismiss="alert">×</a>
                    Supported documents for India are: National ID Card, Passport, Tax ID document, Voter ID. See full list of supported documents <b><a href="/accounts/onfido/supported_documents/">Here</a></b>
                  </div>
                </div>

                <div class="col-12 col-lg-12 pr-lg-0">
                <div className="container">
                <Authcheck />
        <div className="row">
          <div className="offset-md-2 col-xs-8" id="onfido-mount" style={{minHeight: '35.5em'}}>
          <h3 align="center">Wowcoins Identity Verification</h3>
           <br/>
            <div className="onfido-sdk-ui-Modal-inner"><div className="onfido-sdk-ui-Theme-step onfido-sdk-ui-Theme-defaultLogo" tabIndex={-1}><div className="onfido-sdk-ui-Theme-navigationBar onfido-sdk-ui-NavigationBar-navigation">
              <button type="button" aria-label="back" className="onfido-sdk-ui-NavigationBar-back onfido-sdk-ui-NavigationBar-disabled onfido-sdk-ui-NavigationBar-backHoverDesktop" style={{float:'left'}}>
                <span className="onfido-sdk-ui-NavigationBar-iconBack" />
                <a href="/tier1_address_validation/">
                <span className="onfido-sdk-ui-NavigationBar-label" aria-hidden="true">back</span>
                </a>
                </button>
                </div>
                <div className="onfido-sdk-ui-Theme-content onfido-sdk-ui-Theme-scrollableContent">
                  <div className="onfido-sdk-ui-Theme-scrollableContent">
                    <div className="onfido-sdk-ui-PageTitle-titleWrapper">
                      <div className="onfido-sdk-ui-PageTitle-title" role="heading" aria-level={1} aria-live="assertive">
            <span className="onfido-sdk-ui-PageTitle-titleSpan" tabIndex={-1} style={{fontSize: '20px',lineHeight: '1.34',fontWeight: '900' }}>Verify your identity</span>
            <br/><br/>
            </div>
            <div className="onfido-sdk-ui-PageTitle-subTitle" role="heading" aria-level={2} style={{ fontSize: '16px',fontWeight: '900' }}>It should take a few minutes. Once your ID is verified with Wowcoins you will be able to buy and sell Wowcoins from many more traders.</div>
            <br/><br/>
            </div>
            <div className="onfido-sdk-ui-Welcome-text"  >
                       
                  <form onSubmit={submitForm}>
                 <input type="file" onChange={(e) => setUploadFile(e.target.files)} />
                  <br />
                  <input type="submit" className="btn btn-outline-primary btn-block mx-2" />
                </form> 
              </div>
              <br/><br/><br/><br/>
              </div>
              <div className="onfido-sdk-ui-Theme-actionsContainer">
                <div>
              
              </div>
              </div>
              </div>
              <div className="onfido-sdk-ui-Theme-footer">
                <div className="onfido-sdk-ui-Theme-logo" />
                </div>
                </div>
                </div>
                </div>
        </div>
        <div className="row">
          <div className="col-xs-12 onfido-error-wrapper" id="onfido-errors" style={{textAlign: 'center'}} />
        </div>
        <link href="/cached-static/thirdparty/jquery-3.3.1.min.0a0736858b57.map" />
        <link rel="stylesheet" href="/cached-static/onfido/6.10.0/style.9b803fe3962a.css" />
        <link rel="stylesheet" href="/cached-static/css/onfido_custom.c5a04251af04.css" />
        <div className="row" id="onfido-not-supported" style={{display: 'none', marginTop: '3em'}}>
          <div className="col-xs-12">
            <h4>Unfortunately your browser/device does not support the required features to complete liveness test.</h4>
            <br />
            <ul style={{paddingLeft: '2em'}}>
              <li><b>iOS users:</b> Unfortunately iOS does not support required features. Please try a different device.</li>
              <li><b>Others:</b> Please try a different browser. <i>Chrome</i> and <i>Firefox</i> are known to support the required features.</li>
            </ul>
            <p>If you are not using iOS and receive this message using Chrome or Firefox, please <a href="/support/request/">contact support</a>.</p>
            <a href="/settings/verification/">Back to verification</a>
          </div>
        </div>
      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
    </section>
  );
};

export default Account;
