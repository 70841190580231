import axios from "axios";
import { axiosWithAuth } from "../../helpers/axiosWithAuth";
import { orderMapper } from "../../helpers/orderMapper";
import { updateAction } from "../../helpers/updateActions";
import { getWowBalance } from '../../helpers/CallAPi';
const baseURL = process.env.REACT_APP_BASE_URL;
const coinStatsAPI = "https://openapiv1.coinstats.app/coins/usd?currency=";

//Example URL /w query params
//https://api.coinstats.app/public/v1/coins/bitcoin?currency=AMD
export const TRADE_INPUT = "TRADE_INPUT";
export const TRADE_INPUT_RESET = "TRADE_INPUT_RESET";
export const FETCH_MARKET_PRICE = "FETCH_MARKET_PRICE";
export const CREATE_TRADE_SUCCESS = "CREATE_TRADE_SUCCESS";
export const CREATE_BUY_TRADE_SUCCESS =  "CREATE_BUY_TRADE_SUCCESS";
export const CREATE_TRADE_FAILURE = "CREATE_TRADE_FAILURE";
export const FETCH_MY_ORDERS = "FETCH_MY_ORDERS";
export const FETCH_CURRENT_ORDER = "FETCH_CURRENT_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const COMPLETE_ORDER = "COMPLETE_ORDER";
export const PAID_ORDER = "PAID_ORDER";


export const getMyOrders = () => async (dispatch) => {
  const userId = localStorage.getItem("userId");
  try {
    const result = await axiosWithAuth().get(`${baseURL}/api/orders/${userId}`);
    // console.log("result.......::::::::::::::", result);
    let myActiveOrders = [];
    let myPastOrders = [];
    for (let order of result.data) {
      if (order.complete || order.cancelled || order.cancelled_contract === 1 || order.closed_contract === 1) {
        myPastOrders.push(orderMapper(order));
      } else {
        myActiveOrders.push(orderMapper(order));
      }
    }
    // console.log("myActiveOrders.......::::::::::::::", myActiveOrders);
    // console.log("myPastOrders.......::::::::::::::", myPastOrders);
    dispatch(updateAction(FETCH_MY_ORDERS, { myActiveOrders, myPastOrders }));
  } catch (error) {
    // console.log('getMyOrders error:',error);
  }
};

export const getCurrentOrder = (orderId, history) => async (dispatch) => {
  const userId = localStorage.getItem("userId");
  // console.log("UserId", userId);
  // console.log("orderId", orderId);
  try {
    const result = await axiosWithAuth().get(
      `${baseURL}/api/orders/${userId}/${orderId}`
    );
    // console.log("currentOrder", result);
    const order = orderMapper(result.data[0]);
    // console.log("orderCHETNA......", order);
    dispatch(updateAction(FETCH_CURRENT_ORDER, { currentOrder: order }));
  } catch (error) {
    // console.log("orderCHETNA......", error);
  }
};

export const inputChangeHandler = (e, bchPrice) => (dispatch) => {
  // console.log("e.target.value.........>>>>>>>", e.target.value, 'e.target.name.....>',e.target.name);
  let payload = { [e.target.name]: e.target.value };
  if (e.target.name === "cryptoAmount") {
    let fiatAmount = (bchPrice * e.target.value).toFixed(2);
    payload = { [e.target.name]: e.target.value, fiatAmount };
  } else if (e.target.name === "fiatAmount") {
    let cryptoAmount = (e.target.value / bchPrice).toFixed(8);
    payload = { [e.target.name]: e.target.value, cryptoAmount };
  }
  // console.log('updateAction(TRADE_INPUT, payload):',updateAction(TRADE_INPUT, payload));
  dispatch(updateAction(TRADE_INPUT, payload));
};
   
export const fetchMarketPrice = (currency, margin, marginAbove) => async (
  dispatch
) => {
  try {
    const options = {
      method: "GET",
      headers: {
        accept: "application/json",
        "X-API-KEY": "6UEisKAzxdGYrzCYCGJTFIBPQMY8XGGtFDHkEPU4Fgc=",
      },
    };
    const  token= localStorage.getItem("token");
    const result = await axios.get(`${coinStatsAPI}${currency}`, options);
    let price = (result.data.price)*100;

    let adjPrice =
      price + (marginAbove ? price * (margin / 100) : -price * (margin / 100));
    dispatch(updateAction(FETCH_MARKET_PRICE, adjPrice));
  } catch (error) {
    // console.log(error);
  }
};

export const createTrade = (
  orderDetails,
  limitMin,
  limitMax,
  makerId,
  id, 
  buyBCH,
  history,
  currencyType
) => async (dispatch) => {
  // console.log("Orderdetail..........", orderDetails, currencyType);
  const userId = localStorage.getItem("userId");
  const  token= localStorage.getItem("token");
  let errorMessages = [];

  if (userId === undefined) {
    //dispatch modal for logging in
  }

  // refactor to helper function
  if (makerId === userId) {
    errorMessages.push("You can't do that to yourself");
  }
  if (!orderDetails.fiatAmount || !orderDetails.cryptoAmount) {
    errorMessages.push("You must enter a trade amount");
  }
  // if (!orderDetails.initialMessage.trim()) {
  //   errorMessages.push("You must enter an initial message");
  // }

  if(limitMax < limitMin){
    const newMin = limitMax;
    limitMax = limitMin;
    limitMin = newMin;
  }

  if (
    orderDetails.fiatAmount > limitMax ||
    orderDetails.fiatAmount < limitMin
  ) {
    console.log("orderDetails.fiatAmount ====", orderDetails.fiatAmount, limitMin, limitMax)
    errorMessages.push(`Enter an amount between ${limitMin} and ${limitMax}`);
  }

  if (errorMessages.length > 0) {
    dispatch(updateAction(CREATE_TRADE_FAILURE, errorMessages));
  }
  // console.log("errorMessages.............................", errorMessages);
  if (errorMessages == "") {
    try {
      let orderBody = {
        maker_id: makerId,
        user_id: userId,
        offer_id: id,
        price_bch: orderDetails.livePriceBCH,
        amount: orderDetails.cryptoAmount,
        fiat_amount: orderDetails.fiatAmount,
        type: buyBCH,
        initial_message: orderDetails.initialMessage,
        currencyType: currencyType
      };
      // console.log('chetna........orderBody.............:',orderBody);
      const result = await axiosWithAuth().post(
        `${baseURL}/api/wallet/SendCoin_p1_to_p2`,
        orderBody, {
          headers: {
             "Authorization" : `Bearer ${token}`
          }
           }
      );

       console.log('resultresultresult............>>>>>>>>>>>>>>> :result:',result.data.errorMessage);
       if (result.data.errorMessage) {
        errorMessages.push(result.data.errorMessage);
        dispatch(updateAction(CREATE_TRADE_FAILURE, errorMessages));
      }
      const uniqueId = result.data.data.uniqueid; 
      dispatch(updateAction(CREATE_TRADE_SUCCESS, {...result.data.data, currencyType: currencyType} ));
      getWowBalance(userId);
      history.push(`/trade/${'actives'}/${id}/${makerId}/${uniqueId}`);
    } catch (error) {
       console.log("orderError",error); 
    }    

  } else {
    // console.log("Not  procced");
  } 
 
};

export const createTrade_ = (
  orderDetails,
  limitMin,
  limitMax,
  makerId, 
  id,
  buyBCH,
  history,
  fromuser,
  fromid,
  currencyType,
  livePrice
) => async (dispatch) => {
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  let errorMessages = [];

  if (userId === undefined) {
    //dispatch modal for logging in
  }
  // if(livePrice == orderDetails.fiatAmount){

  // }else{
  //   errorMessages.push("Error live price");
  // }
  // refactor to helper function
  if (makerId === userId) {
    errorMessages.push("You can't do that to yourself");
  }
  if (!orderDetails.fiatAmount || !orderDetails.cryptoAmount) {
    errorMessages.push("You must enter a trade amount");
  }
  // if (!orderDetails.initialMessage.trim()) {
  //   errorMessages.push("You must enter an initial message");
  // }
  if (
    orderDetails.fiatAmount > limitMax ||
    orderDetails.fiatAmount < limitMin
  ) {
    errorMessages.push(`Enter an amount between ${limitMin} and ${limitMax}`);
  }

  if (errorMessages.length > 0) {
    dispatch(updateAction(CREATE_TRADE_FAILURE, errorMessages));
  }
  if(errorMessages == ""){
    try {
      // console.log("makerId", makerId);
      // console.log("userId", userId);
      let orderBody = {
        maker_id: fromid,
        user_id: userId,
        offer_id: id,
        price_bch: orderDetails.livePriceBCH,
        amount: orderDetails.cryptoAmount,
        fiat_amount: orderDetails.fiatAmount,
        // address: "0x2b048F8D254a62C82Feba9e186cfd51c2cf3F40A",
        // type: buyBCH == 0 ? 1 : 0,
        type: buyBCH,
        initial_message: orderDetails.initialMessage,
        currencyType: currencyType
      };

      // console.log("Amrut........orderBody.........", orderBody);
      
      const result = await axiosWithAuth().post(
        `${baseURL}/api/wallet/SendCoin_p1_to_p2`,
        orderBody, {
          headers: {
             "Authorization" : `Bearer ${token}`
          }
           }
      );
       console.log("result..................createTrade_", result);
      const uniqueId = result.data.data.uniqueid; 
      dispatch(updateAction(CREATE_BUY_TRADE_SUCCESS, { ...result.data.data, bchAmount:result.data.data.bch_amount, priceBCH: result.data.data.price_bch, fiat_amount: orderDetails.fiatAmount, amount: orderDetails.cryptoAmount,
      currencyType : currencyType } ));
      getWowBalance(userId);
      history.push(`/trade/${'actives'}/${id}/${makerId}/${uniqueId}`);
    } catch (error) {
       console.log('createTrade_ error:',error);
    }
  }else{ 

  }
 
};

export const handleCancel = (order) => async (dispatch) => {
  try {
    const button = document.getElementById('cancel');
    button.disabled = true
    order.cancelled = true;
    await axiosWithAuth().put(`${baseURL}/api/orders/${order.id}`, order);
    dispatch(updateAction(CANCEL_ORDER));
    window.location.reload(false);
  } catch (error) {
    // console.log(error);
  }
};

export const handleComplete = (order) => async (dispatch) => {
  try {
    const button = document.getElementById('complete');
    button.disabled = true
    order.complete = true;
    // console.log("order", order);
    await axiosWithAuth().put(`${baseURL}/api/orders/${order.id}`, order);
    dispatch(updateAction(COMPLETE_ORDER));
    window.location.reload(false);
  } catch (error) {
    // console.log(error);
  }
};

export const handlePaid = (order) => async (dispatch) => {
  try {
    const button = document.getElementById('paid');
    button.disabled = true
    order.paid = true;
    // console.log("order", order);
    await axiosWithAuth().put(`${baseURL}/api/orders/${order.id}`, order);
    dispatch(updateAction(PAID_ORDER));
    window.location.reload(false);
  } catch (error) {
    // console.log(error);
  }
};

export const handleDispute = (order) => async (dispatch) => {
  try {
    order.paid = true;
    // console.log("order", order);
    await axiosWithAuth().put(`${baseURL}/api/orders/${order.id}`, order);
    dispatch(updateAction(PAID_ORDER));
    window.location.reload(false);
  } catch (error) {
    // console.log(error);
  }
};


