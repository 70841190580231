import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { connect } from "react-redux";
import { Form, Input, Button, Select } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { doRegister } from "../../store/actions/authActions";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

import { Route, NavLink } from "react-router-dom";
// import Search from "../../components/Search/Search";
import Withdrawcoin from "../../components/Withdrawcoin/Withdrawcoin";
import Withdrawupdate from "../../components/Withdrawupdate/Withdrawupdate";
import Transcations from "../../components/Withdrawtranscations/Withdrawtranscations";
import ItemListings from "../../components/ItemListings/ItemListings";
import { Tooltip } from "antd";
import "./Withdraw.scss";
import { useTranslation, withTranslation } from "react-i18next";
import SectionSix from "../../components/Home/sections/sectionsix";

// import "./Search.scss";
// import "./Signup.scss";
const { Option } = Select;

const Withdraw = (props) => {
  const [expand, setExpand] = useState(false);
  function onChange(value) {
    // to add actionCreator needs to change slice of state
    // console.log(`selected ${value}`);
  }

  function onBlur() {
    // console.log("blur");
  }

  function onFocus() {
    // console.log("focus");
  }

  function onSearch(val) {
    // console.log("search:", val);
  }

  const [t, i18n] = useTranslation("common");
  return (
    <>
      <div className="main-container">
        <div className="upper-part"></div>
        <div className="explore-container">
          <p className="profile-heading">{t("Withdraw.Withdraw")}</p>
          <div className="boxSections-sub-wrap">
            <div className="non-active-section">
              <NavLink
                to="/withdraw/SendWowCoin"
                activeClassName="active-section"
              >
                {t("Withdraw.Withdraw")}
              </NavLink>
            </div>
            <div style={{ marginLeft: "20px" }} className="non-active-section">
              <NavLink
                color="#000000"
                to="/withdraw/ReceiveWowCoin"
                activeClassName="active-section"
              >
                {t("Withdraw.Update withdraw detail")}
              </NavLink>
            </div>
            <div style={{ marginLeft: "20px" }} className="non-active-section">
              <NavLink
                to="/withdraw/Transcations"
                activeClassName="active-section"
              >
                {t("Withdraw.Withdraw Transactions")}
              </NavLink>
            </div>
          </div>
          {/* <Search /> */}

          <Route
            path="/withdraw/SendWowCoin"
            render={(props) => <Withdrawcoin {...props} />}
          />
          <Route
            path="/withdraw/ReceiveWowCoin"
            render={(props) => <Withdrawupdate {...props} />}
          />

          <Route
            path="/withdraw/Transcations"
            render={(props) => <Transcations {...props} />}
          />
        </div>
      </div>
      <SectionSix />
    </>
  );
};

export default Withdraw;