import axios from "axios";
import { axiosWithAuth } from "../../helpers/axiosWithAuth";
import { updateAction } from "../../helpers/updateActions";
import { dataMapper } from "../../helpers/dataMapper";
import { ToastContainer, toast } from 'react-toastify';


export const SET_MAKER_ID = "SET_MAKER_ID";
export const SET_BUYBCH = "SET_BUYBCH";
export const SET_CURRENCY = "SET_CURRENCY";
export const SET_INPUT = "SET_INPUT";
export const SET_TIME = "SET_TIME";
export const SET_DEFAULT_TIME = "SET_DEFAULT_TIME";
export const SET_PAYMENT = "SET_PAYMENT";
export const SET_DYNAMIC = "SET_DYNAMIC";
export const SET_EXCHANGE = "SET_EXCHANGE";
export const SET_LIMITS = "SET_LIMITS";
export const SET_VERIFIED = "SET_VERIFIED";
export const SET_TRADE_TERMS = "SET_TRADE_TERMS";

export const CREATE_OFFER = "CREATE_OFFER";
export const UPDATE_OFFER = "UPDATE_OFFER";
export const UPDATE_STATE = "UPDATE_STATE";
export const DELETE_OFFER = "DELETE_OFFER";

export const FETCH_MY_OFFERS = "FETCH_MY_OFFERS";
export const FETCH_OFFER = "FETCH_OFFER";

const baseURL = process.env.REACT_APP_BASE_URL;

export const setMakerId = (id) => (dispatch) => {
  dispatch(updateAction(SET_MAKER_ID, id));
};

export const setBuyBCH = (buyBCH) => (dispatch) => {
  dispatch(updateAction(SET_BUYBCH, buyBCH));
};

export const setCurrency = (currency) => (dispatch) => {
  dispatch(updateAction(SET_CURRENCY, currency));
};

export const setInput = (e) => (dispatch) => {
  dispatch(updateAction(SET_INPUT, e));
};

export const setTime = (time) => (dispatch) => {
  dispatch(updateAction(SET_TIME, time));
};
export const setDefaultTime = () => (dispatch) => {
  dispatch(updateAction(SET_DEFAULT_TIME));
};

export const setPaymentMethod = (value) => (dispatch) => {
  dispatch(updateAction(SET_PAYMENT, value));
};

export const setExchange = (value) => (dispatch) => {
  dispatch(updateAction(SET_EXCHANGE, value));
};

export const setIsDynamicPrice = (value) => (dispatch) => {
  dispatch(updateAction(SET_DYNAMIC, value));
};

export const setDefaultLimits = () => (dispatch) => {
  dispatch(updateAction(SET_LIMITS));
};

export const setVerifiedOnly = (value) => (dispatch) => {
  dispatch(updateAction(SET_VERIFIED, value));
};

export const setTradeTerms = () => (dispatch) => {
  dispatch(updateAction(SET_TRADE_TERMS));
};

export const createOffer = (offerForm, history) => async (dispatch) => {
  try {
    const userId = localStorage.getItem("userId");
    const  token= localStorage.getItem("token");
    offerForm.makerId = userId;
    const createoffer = await axiosWithAuth().post(`${baseURL}/api/offers`, offerForm, {
      headers: {
         "Authorization" : `Bearer ${token}`
      }
       });
       if(createoffer.data.status == false){
        console.log("createoffer",createoffer.data.status);
        console.log("tradeerror",createoffer.data.message);
        sessionStorage.setItem("tradeerror", createoffer.data.message);
        history.push("/new-offer");
        window.location.reload();
     }else{
     await sessionStorage.setItem("trademsg", "Offers Created Successfully");
     const result = await axiosWithAuth().get(`${baseURL}/api/offers/${userId}`);
     let updatedData = result.data.map((offer) => dataMapper(offer));
     dispatch(updateAction(FETCH_MY_OFFERS, updatedData));
     await history.push("/my-offers");
    dispatch(updateAction(CREATE_OFFER));
     }
  } catch (error) {
    console.log(error);
  }
};


export const createOffer1 = (offerForm, history) => async (dispatch) => {
  try {
    const userId = localStorage.getItem("userId");
    const  token= localStorage.getItem("token");
    offerForm.makerId = userId;
     console.log("OFFER FORM...", offerForm);
     const createoffer = await axiosWithAuth().post(`${baseURL}/api/offers`, offerForm, {
      headers: {
         "Authorization" : `Bearer ${token}`
      }
       });
      console.log("createoffer",createoffer.data.status);
      if(createoffer.data.status == false){
        console.log("createoffer",createoffer.data.status);
        console.log("tradeerror",createoffer.data.message);
        sessionStorage.setItem("tradeerror", createoffer.data.message);
        history.push("/new-offer");
        window.location.reload();
     }else{
     await sessionStorage.setItem("trademsg", "Offers Created Successfully");
     const result = await axiosWithAuth().get(`${baseURL}/api/offers/${userId}`);
     let updatedData = result.data.map((offer) => dataMapper(offer));
     dispatch(updateAction(FETCH_MY_OFFERS, updatedData));
     await history.push("/my-offers");
    setTimeout(() => {
      dispatch(updateAction(CREATE_OFFER));
    }, 100); 

      }
    
  } catch (error) {
    console.log("error_trade", error);
    let errorMessage = error.response.data? error.response.data: null;
    if(errorMessage.errorMessage.city){
      sessionStorage.setItem("tradeerror", errorMessage.errorMessage.city);
      // console.log("city");
    }else if(errorMessage.errorMessage.country){
      sessionStorage.setItem("tradeerror", errorMessage.errorMessage.country);
      // console.log("country");
    }else if(errorMessage.errorMessage.paymentMethod){
      sessionStorage.setItem("tradeerror", errorMessage.errorMessage.paymentMethod);
      // console.log("trade");
    }
    
    history.push("/my-offers");
  }
};

export const fetchOffer = (id) => async (dispatch) => {
  try {
    // console.log('mappedOffer id:',id);
    const result = await axios.get(`${baseURL}/api/offers/offer/${id}`);
    console.log('result:',result);
    let mappedOffer = dataMapper(...result.data);
     console.log('mappedOffer:',mappedOffer);
    dispatch(updateAction(FETCH_OFFER, mappedOffer));
  } catch (error) {
    // console.log('mappedOffer error:',error);
  }
};

export const fetchMyOffers = () => async (dispatch) => {
  const userId = localStorage.getItem("userId");
  try {
    const result = await axiosWithAuth().get(`${baseURL}/api/offers/${userId}`);
    let updatedData = result.data.map((offer) => dataMapper(offer));
    dispatch(updateAction(FETCH_MY_OFFERS, updatedData));
  } catch (error) {
    // console.log(error);
  }
};



export const updateOffer = (updatedOffer, history) => async (dispatch) => {
  const userId = localStorage.getItem("userId");
  const  token= localStorage.getItem("token");
  try {
    const result = await axiosWithAuth().put(
      `${baseURL}/api/offers/${userId}/${updatedOffer.id}`,
      updatedOffer, {
        headers: {
           "Authorization" : `Bearer ${token}`
        }
         }
    );
    if(result.data.status== false){
      sessionStorage.setItem("Offererror", result.data.message);
      history.push("/my-offers");
     }else{
      sessionStorage.setItem("Offermsg", "Offer Updated Successfull");
      history.push("/my-offers");
    }
    dispatch(updateAction(UPDATE_OFFER, result.data));
  } catch (error) {
    // console.log(error);
  }
};

export const updateState = (updateState) => async (dispatch) => {
  const userId = localStorage.getItem("userId");
  const  token= localStorage.getItem("token");
  try {
    const result = await axiosWithAuth().post(
      `${baseURL}/api/offers/single_offer_pause_resume/${userId}/${updateState.id}`,
      updateState, {
        headers: {
           "Authorization" : `Bearer ${token}`
        }
         }
    );
    if(result.data.status== false){
      sessionStorage.setItem("Offererror", result.data.message);
      window.location.reload();

    }else{
      sessionStorage.setItem("Offermsg", "Offer state changed successfully");
      window.location.reload();
    }
    dispatch(updateAction(UPDATE_STATE, result.data));
  } catch (error) {
    // console.log(error);
  }
};

export const deleteOffer = (id) => async (dispatch) => {
  const userId = localStorage.getItem("userId");
  const  token= localStorage.getItem("token");
  try {
    const result = await axiosWithAuth().delete(
      `${baseURL}/api/offers/${userId}/${id}`, {
        headers: {
           "Authorization" : `Bearer ${token}`
        }
         }
    );
    // console.log("deleted", result);
    dispatch(updateAction(DELETE_OFFER, id));
  } catch (error) {
    // console.log(error);
  }
};
