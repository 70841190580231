import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link } from "react-router-dom";
import { Form, Input, Button, Select } from "antd";
import "./Tier1identification.scss";
import Axios from 'axios';
import {useTranslation, withTranslation} from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';

const { Option } = Select;


const Identification = (props) => {
  const [t, i18n] = useTranslation('common');
const [getCountry, setGetCountry] = useState([]);
const [Specify, setSpecify] = useState('');
const [data, setData] = useState({});
const formRef = React.useRef(null);
const baseURL = process.env.REACT_APP_BASE_URL;
// const url = `${baseURL}/api/identi/saveidentification`;

useEffect( () => {
 document.getElementById("div_id_specify_reason").style.display = "none";
  
 try {
    Axios.get(`${baseURL}/api/auth/getcountry`).then((response) => {
      // console.log("getTran response.....", response.data);
      setGetCountry(response.data);
    });
  } catch (error) {
    // console.log(error);
  }

}, []);

const submit = (e) => {
  const userId = localStorage.getItem("userId");
   //console.log('data:', e);
  //console.log('reason_to_use_service:',  data.reason_to_use_service);
  const sendData = {
    userid: userId,
    occupation: data.occupation,
    nationality: data.nationality,
    service: data.reason_to_use_service,
    specify_reason: data.specify_reason,
    vasp_type: data.vasp_type,
    vasp_status: data.vasp_status,
    business: data.business,
  };
  // console.log("sendData", sendData)
  Axios.post(`${baseURL}/api/identi/saveidentification`, sendData)
    .then(res => {
      //toast.success("Profile Updated Successfully");
      console.log("res.data.......", res.data);
      sessionStorage.setItem("verificationmsg", "Identification Submmited Successfully");
      props.history.push("/verification");
    }).catch(error => {
      // console.log("error:", error);
      toast.error("Identification Submmited Failed");
       //window.location.reload();
    })
}

function handle(e) {
   console.log('e.target.id:', e.target.value);
  let newdata = data;
  newdata[e.target.id] = e.target.value
  setData({ ...data, [e.target.id]: e.target.value })
  // console.log("newdata......", newdata);
}

function specifydiv(e) {
  if(Specify){
    setSpecify('');
    document.getElementById("div_id_specify_reason").style.display = "none"  
  }else{
    setSpecify(e.target.value);
    document.getElementById("div_id_specify_reason").style.display = "block"
     }
  }

  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">

            <div className="profile-container">
              <div className="secondary-nav">
                <div className="tab-link">
                  <Link to="/my-account" activeClassName="is-active">
                  {t('Profile.Profile')}
                  </Link>
                </div>
                <div className="tab-link">
                  <Link to="/settings" activeClassName="is-active">
                  {t("Profile.Security")}
                  </Link>
                </div>

                <div className="tab-link">
                  <Link to="/verification" activeClassName="is-active">
                  {t("Profile.Verification")}
                  </Link>
                </div>

                {/* <div className="tab-link">
                  <Link to="/notifications" activeClassName="is-active">
                    Notifications
                  </Link>
                </div> */}

                {/* <div className="tab-link">
                  <Link to="/advanced" activeClassName="is-active">
                  {t("Profile.Advanced")}
                  </Link>
                </div> */}
              </div>

              <br></br>
              <div class="row">
                <div class="col-12 col-lg-12 pr-lg-0">
                  <div className="container">
                <Authcheck />
                    <div className="alert alert-warning">{t("Profile.You need to update")}
                      
                      <a href="/settings/verification/">{t("Profile.Proceed to verify")}</a>
                    </div>
                    <div className="row mt-5 mb-4">
                      <div className="col kyc-form-wrapper">
                        <h3 className="mb-3">{t("Profile.Tier 1 Questions")}</h3>
                                  <Form
                              initialValues={{
                                remember: true,
                              }}
                              ref={formRef}
                              onFinish={(e) => {
                                submit(e);
                              }}
                            >
                          <input type="hidden" name="csrfmiddlewaretoken" defaultValue="OnTkFTljooZ85lbyfSHFB3lh1UkETq84fGcRGWf4Wl67LfAQ8aWgelOA8MBYrqRX" />
                          <div id="div_id_occupation" className="form-group">
                            <Form.Item
                              label={t("Profile.What is your occupation")}
                              name="occupation"
                              id="occupation"
                              rules={[
                                {
                                  required: true,
                                  message: t("Profile.Please select your occupation"),
                                },
                              ]}
                            >
                              <Select  onChange={(e) => {
                          // console.log('occupation e:', e);
                          setData({ ...data, occupation: e })
                        }}>
                                <Option value={0}>{t("Profile.Self-employed / Freelancer")}</Option>
                                <Option value={1}>{t("Profile.Entrepreneur / Trader / Investor")}</Option>
                                <Option value={2}>{t("Profile.Temporary/ Part-time Employment")}</Option>
                                <Option value={3}>{t("Profile.Full-time Employment")}</Option>
                                <Option value={4}>{t("Profile.Student")}</Option>
                                <Option value={5}>{t("Profile.Unemployed")}</Option>
                                <Option value={6}>{t("Profile.Retired")}</Option>
                              </Select>
                            </Form.Item>
                          </div>
                   <div id="div_id_nationality" className="form-group">
                    <Form.Item
                      label={t("Profile.Choose a country code")}
                      name="nationality"
                      value={data.countrycode}
                      id="nationality"
                      rules={[
                        {
                          required: true,
                          message: t("Profile.Please select your nationality!")
                        },
                      ]}
                    >
                      <Select
                        onChange={(e) => {
                          // console.log('nationality e:', e);
                          setData({ ...data, nationality: e })
                        }} >
                        {getCountry.map((value, index) => {
                          return <Option value={value.label}>{value.label}</Option>
                        })}

                      </Select>
                    </Form.Item>
                    </div>

                          {/* <div id="hint_id_nationality" className="form-group">
                                <Form.Item
                                  name="social_security_number"
                                  id="social_security_number"
                                  onChange={(e) => handle(e)}
                                  label={t("Profile.Social security number*")}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your social security number!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>

                                <Form.Item
                                  name="tin_number"
                                  id="tin_number"
                                  onChange={(e) => handle(e)}
                                  label={t("Profile.Taxpayer Identification Number*")}
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your Taxpayer Identification Number!",
                                    },
                                  ]}
                                >
                                  <Input />
                                </Form.Item>
                              </div> */}
                          <div id="div_id_reason_to_use_service" className="form-group">
                            <Form.Item
                              label={t("Profile.Why are you using")}
                              id="reason_to_use_service"
                              name="reason_to_use_service"
                              onChange={(e) => handle(e)}
                              rules={[
                                {
                                  required: true,
                                  message: t("Profile.Please select your service"),
                                },
                              ]}
                            >

                             <div className="controls ">
                              <div className="checkbox">
                                <label className htmlFor="id_reason_to_use_service_0">
                                  <input type="checkbox" name="reason_to_use_service" id="reason_to_use_service" onChange={(e) => handle(e)} defaultValue={0} />  {t("Profile.Trading as main source of income")}
                                </label> </div> <div className="checkbox"> <label className htmlFor="id_reason_to_use_service_1"> <input type="checkbox" name="reason_to_use_service" id="reason_to_use_service" onChange={(e) => handle(e)} defaultValue={1} />  {t("Profile.Trading as extra source of income")}
                                </label> </div> <div className="checkbox"> <label className htmlFor="id_reason_to_use_service_2"> <input type="checkbox" name="reason_to_use_service" id="reason_to_use_service" onChange={(e) => handle(e)} defaultValue={2} />  {t("Profile.Purchasing WOW as investment")}
                                </label> </div> <div className="checkbox"> <label className htmlFor="id_reason_to_use_service_3"> <input type="checkbox" name="reason_to_use_service" id="reason_to_use_service" onChange={(e) => handle(e)} defaultValue={3} />  {t("Profile.Using WOW as an")}
                                </label> </div> <div className="checkbox"> <label className htmlFor="id_reason_to_use_service_4"> <input type="checkbox" name="reason_to_use_service" id="reason_to_use_service" onChange={(e) => handle(e)} defaultValue={4} />  {t("Profile.Remittance")}
                                </label> </div> <div className="checkbox"> <label className htmlFor="id_reason_to_use_service_5"> <input type="checkbox" name="reason_to_use_service" id="reason_to_use_service" onChange={(e) => handle(e)} defaultValue={5} />  {t("Profile.Using WOW for payments")}
                                </label> </div> <div className="checkbox"> <label className htmlFor="id_reason_to_use_service_6"> <input type="checkbox" name="reason_to_use_service" id="reason_to_use_service" onChange={(e) => handle(e)} defaultValue={6} />  {t("Profile.Learning about WOW")}
                                </label> </div> <div className="checkbox"> <label className htmlFor="id_reason_to_use_service_7"  onClick={(e) => specifydiv(e)}> <input type="checkbox" name="reason_to_use_service" id="reason_to_use_service" defaultValue={7} />  {t("Profile.Other")}
                                </label>
                              </div>
                            </div>
                            </Form.Item>
                            
                          </div>

                          <div id="div_id_specify_reason" className="form-group">
                                <Form.Item
                                  name="specify_reason"
                                  id="specify_reason"
                                   value="0"
                                   onChange={(e) => handle(e)}
                                  label={t("Profile.Specify Other")} >
                                  <Input/>
                                </Form.Item>
                                </div>

                          <div id="div_id_vasp_type" className="form-group">
                            <label htmlFor="id_vasp_type_0" className="control-label  requiredField">
                            {t("Profile.Are you considered as a Money Service Business or a Cryptoasset Business or a Virtual Asset Service Provider or similar of the jurisdiction you are located in or are you conducting any sort of brokering business")}
                              <span className="asteriskField">*</span>
                            </label>
                            <div className="controls ">
                              <div className="radio">
                                <label htmlFor="id_vasp_type_0" className>
                                  <input type="radio"  name="vasp_type" id="vasp_type" onChange={(e) => handle(e)} defaultValue={1} /> {t("Profile.Yes, I am a VASP/MSB")}
                                </label>
                              </div>
                              <div className="radio">
                                <label htmlFor="id_vasp_type_1" className>
                                  <input type="radio" name="vasp_type" id="vasp_type" onChange={(e) => handle(e)} defaultValue={2} /> {t("Profile.Yes, I am a broker")}
                                </label>
                              </div>
                              <div className="radio">
                                <label htmlFor="id_vasp_type_2" className>
                                  <input type="radio"  name="vasp_type" id="vasp_type" onChange={(e) => handle(e)} defaultValue={3}  /> {t("Profile.No, I am not a VASP/MSB or I do not conduct any brokering")}
                              
  </label>
                              </div>
                            </div>
                          </div>

                          <div id="div_id_vasp_status" className="form-group"> <label htmlFor="id_vasp_status_0" className="control-label ">
                          {t("Sellertrade.Are you registered or have you obtained a license where you are located")}
                          </label>
                            <div className="controls ">
                              <div className="radio">
                                <label htmlFor="vasp_status" className> <input type="radio" name="vasp_status" onChange={(e) => handle(e)} id="vasp_status" defaultValue={1} />Yes
                                </label>
                              </div>
                              <div className="radio">
                                <label htmlFor="vasp_status" className> <input type="radio" name="vasp_status" onChange={(e) => handle(e)} id="vasp_status" defaultValue={2} />No
                                </label>
                              </div>
                            </div>
                          </div>
                          <Button type="primary" htmlType="submit">
                          {t('Withdraw.Submit')}
                          </Button>
                          {/* <a href="/tier1_address_validation/">
                            <input type="button" class="btn btn-outline-primary btn-block mx-2" defaultValue="Submit" />
                          </a> */}
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
    </section>
  );
};

export default Identification;
