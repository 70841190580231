import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, NavLink } from "react-router-dom";
import { Tooltip } from "antd";
import { ToastContainer, toast } from "react-toastify";
import Search from "../../components/Search/Search";
import OfferListings from "../../components/OfferListings/OfferListings";
import ItemListings from "../../components/ItemListings/ItemListings";
import BuywowCoin from "../../components/BuywowCoin/BuywowCoin";
import SellwowCoin from "../../components/SellwowCoin/SellwowCoin";
import Kyccheck from "../../components/Kyccheck/Kyccheck";
import "./Offers.css";
import { useTranslation, withTranslation } from "react-i18next";
import SectionSix from "../../components/Home/sections/sectionsix";

const Offers = (props) => {
  const [t, i18n] = useTranslation("common");

  useEffect(() => {
    // if (sessionStorage.getItem("Approve") != '') {
    //   toast.success(sessionStorage.getItem("Approve"));
    //   sessionStorage.removeItem("Approve");
    // }

    if (sessionStorage.getItem("Loginmsg") != "") {
      toast.success(sessionStorage.getItem("Loginmsg"));
      sessionStorage.removeItem("Loginmsg");
    }
    if (sessionStorage.getItem("Approve") != "") {
      toast.success(sessionStorage.getItem("Approve"));
      sessionStorage.removeItem("Approve");
    }
  });

  return (
    <div className="main-container">
      <div className="upper-part"></div>
      <div className="offer-explore-container">
        <div>
          <Authcheck />
          <div>
            <div className="base-header">
              <Kyccheck />

              <div className="boxSections-sub-wrap">
                <div className="non-active-section">
                  <NavLink
                    style={{ textDecorationColor: "none", marginRight: "0" }}
                    to="/offers/buywowCoin?page=1"
                    activeClassName="active-section"
                  >
                    {t("Home.Buy WOW Coin")}
                  </NavLink>
                </div>
                <div
                  style={{ marginLeft: "20px" }}
                  className="non-active-section"
                >
                  <NavLink
                    style={{ textDecorationColor: "none", marginRight: "0" }}
                    to="/offers/sellwowCoin?page=1"
                    activeClassName="active-section"
                  >
                    {t("Home.Sell WOW Coin")}
                  </NavLink>
                </div>
              </div>

              <Route
                path="/offers/buywowCoin"
                render={(props) => <BuywowCoin {...props} />}
              />
              <Route
                path="/offers/sellwowCoin"
                render={(props) => <SellwowCoin {...props} />}
              />
            </div>
          </div>
        </div>
        <ToastContainer autoClose={1000} style={{ marginTop: "60px" }} />
      </div>
      <SectionSix />
    </div>
  );
};

export default Offers;
