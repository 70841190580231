import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { connect } from "react-redux";
import { Form, Input, Button, Select } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { doRegister } from "../../store/actions/authActions";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Route, NavLink, useLocation } from "react-router-dom";
// import Search from "../../components/Search/Search";
import SendWowCoin from "../../components/SendWowCoin/SendWowCoin";
import ReceiveWowCoin from "../../components/ReceiveWowCoin/ReceiveWowCoin";
import Transactions from "../../components/Transactions/Transactions";
import AdminTransactions from "../../components/Transactions/AdminTransactions";
import ItemListings from "../../components/ItemListings/ItemListings";
import { Tooltip } from "antd";
import { useTranslation, withTranslation } from "react-i18next";
import "./Wallet.css";
import SectionSix from "../../components/Home/sections/sectionsix";

// import "./Search.scss";
// import "./Signup.scss";
const { Option } = Select;

const Wallet = (_props) => {
  const [expand, setExpand] = useState(false);
  function onChange(_value) {
    // to add actionCreator needs to change slice of state
    // console.log(`selected ${value}`);
  }

  function onBlur() {
    // console.log("blur");
  }

  function onFocus() {
    // console.log("focus");
  }

  function onSearch(_val) {
    // console.log("search:", val);
  }

  const [t, i18n] = useTranslation("common");
  const { pathname } = useLocation();
  const path = pathname.split("/");
  return (
    <>
      {" "}
      <div className="wallet-page">
        <div className="wallet-upper"></div>
        <Authcheck />

        <div className="wallet-container ">
          <p className="profile-heading">{t("wallet.WOW WALLET")}</p>
          <div className="boxSections-sub-wrap">
            <div className="wallet-tags">
              <NavLink
                className={
                  path[2] === "SendWowCoin"
                    ? "active-section"
                    : "non-active-section"
                }
                to="/wallet/SendWowCoin"
              >
                {t("wallet.Send WOW Coin")}
              </NavLink>
            </div>
            <div className="wallet-tags">
              <NavLink
                className={
                  path[2] === "ReceiveWowCoin"
                    ? "active-section"
                    : "non-active-section"
                }
                to="/wallet/ReceiveWowCoin"
              >
                {t("wallet.Receive WOW Coin")}
              </NavLink>
            </div>
            <div className="wallet-tags">
              <NavLink
                className={
                  path[2] === "Transactions"
                    ? "active-section"
                    : "non-active-section"
                }
                to="/wallet/Transactions"
              >
                {t("wallet.Transactions")}
              </NavLink>
            </div>
            <div className="wallet-tags">
              <NavLink
                className={
                  path[2] === "AdminTransactions"
                    ? "active-section"
                    : "non-active-section"
                }
                to="/wallet/AdminTransactions"
              >
                {t("wallet.Admin Transactions")}
              </NavLink>
            </div>
          </div>
          {/* <Search /> */}
          <Route
            path="/wallet/SendWowCoin"
            render={(props) => <SendWowCoin {...props} />}
          />
          <Route
            path="/wallet/ReceiveWowCoin"
            render={(props) => <ReceiveWowCoin {...props} />}
          />
          <Route
            path="/wallet/Transactions"
            render={(props) => <Transactions {...props} />}
          />
          <Route
            path="/wallet/AdminTransactions"
            render={(props) => <AdminTransactions {...props} />}
          />
        </div>
      </div>
      <SectionSix />
    </>
  );
};

export default Wallet;