import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Redirect } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { adminApproveKycSub, getAdminkycapprove } from "../../helpers/CallAPi";

const PrivateRoute = ({ render: Component, ...rest }) => {
  const userId = localStorage.getItem("userId");

  const [kycRes, setKycRes] = useState(null);
// console.log("Kycres", kycRes);
  // console.log('rest:',rest);

  useEffect(()=>{
    adminApproveKycSub.subscribe((res)=>{
      // console.log('res.approve:',res);
      if (!res) {
        // console.log("Hello");
        getAdminkycapprove(userId);
      } else {
        // console.log("helloelse", res.approve)
        setKycRes(res.approve);
      }
    });
  },[]);

  return (
    <Route
      {...rest}
      render={(props) => {

        const { token } = localStorage;
        let result = false;
        if (!token) { result = false; }
        else {
          const decoded = jwtDecode(token);
          if (
            !decoded ||
            !decoded.username ||
            !decoded.userId ||
            !decoded.iat ||
            !decoded.exp
          )
            result = false;
          result = decoded.exp > Date.now() / 1000;
        }

        if (result) {
          // console.log('kycRes::::::::::::::::::::::::::::::::::::',kycRes);
          if (kycRes == "0") {
            // console.log('Verification...........................,:',['/verification', '/tier1_identification', '/tier1_address_validation', '/Kycid'].indexOf(rest.path) >= 0);
            if (['/verification', '/tier1_identification', '/notiall', '/tier1_address_validation', '/Kycid', '/my-account', '/guides/'].indexOf(rest.path) >= 0) {
              return <Component {...props} />; 
            } else {
              return <Redirect to="/verification" />
            }
          } else {
            return <Component {...props} />; 
          } 
        } else {
          return <Redirect to="/" />;
        }

      }}
    /> 
  );
};

export default PrivateRoute;
