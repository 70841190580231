import React, { useEffect, useRef } from "react";
import Authcheck from "../../../components/Authcheck/Authcheck";

import moment from "moment";

import "./ChatText.css";

const ChatText = ({ message, userId, handleNewChat }) => {
  const divRef = useRef(null);

  useEffect(() => {
    handleNewChat(1);
  }, []);

  useEffect(() => {
    const { text } = message;

    if (text)
      divRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
  }, []);

  const { created_at, text, author_id, username, documentfile } = message;
  console.log("message", message);
  if (documentfile) {
    var splitted = documentfile.split("/"); //this will output ["1234", "56789"]
    var filename = splitted[4];
  }
  return (
    <>
      {documentfile || text ? (
        <>
          <div>
            <Authcheck />
            {author_id == localStorage.getItem("username") ? (
              <div className={"my-message"} ref={divRef}>
                <p className="message-date">
                  {moment(created_at).format("DD-MM-YYYY")}
                </p>
                {documentfile ? (
                  <>
                    <a
                      href={documentfile}
                      download
                      target="_blank"
                      style={{
                        lineBreak: "anywhere",
                        fontSize: "13px",
                        color: "blue",
                      }}
                    >
                      {filename} &#8595;
                    </a>
                  </>
                ) : (
                  <></>
                )}
                {text && text !== "undefined" ? (
                  <>
                    <p className="message-text">{text}</p>
                  </>
                ) : (
                  <></>
                )}
                <p className="message-text" style={{ textAlign: "end" }}>
                  {username}&nbsp;&nbsp; {moment(created_at).format("h:mm a")}
                </p>
              </div>
            ) : (
              <div
                className={
                  "my-message lightred" + (+userId === author_id ? " self" : "")
                }
                // chat-text
                style={{ maxWidth: "90%", background: "none", float: "left" }}
                ref={divRef}
              >
                <p className="message-date">
                  {username} {moment(created_at).format("DD-MM-YYYY")}
                </p>
                {documentfile ? (
                  <>
                    <a href={documentfile} download target="_blank">
                      {filename} &#8595;
                    </a>
                  </>
                ) : (
                  <></>
                )}
                {text && text != "undefined" ? (
                  <>
                    <p className="message-text">{text}</p>
                  </>
                ) : (
                  <></>
                )}
                <p className="message-text" style={{ textAlign: "end" }}>
                  {username}&nbsp;&nbsp; {moment(created_at).format("h:mm a")}
                </p>
              </div>
            )}
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default ChatText;
