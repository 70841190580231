import React, { useState, useEffect } from 'react'
import Authcheck from "../../components/Authcheck/Authcheck";
import axios from 'axios'
import ReactPaginate from 'react-paginate';
// import "./Blockuser.scss";
import {useParams } from "react-router-dom";
import Axios from 'axios';
import { useHistory } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation, withTranslation } from "react-i18next";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem('userId');
    const  token = localStorage.getItem("token");

function Allbuyer() {
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState(null);
  const [pageCount, setPageCount] = useState(0)
  const [t, i18n] = useTranslation('common');
  const params = useParams();
  const history = useHistory();
  // console.log("useParams",params);

  const getPostData = (data) => {


    return (
      
      <section className=" hero--full hero--v10 d-flex align-items-center hidden" style={{ minHeight: '300px', paddingBottom: '0px',paddingTop: "100px" }}>
         <div class="container">
        <Authcheck />
          <div className="conuter-header">
            <h4 class="sendWOWb">Buyer Proceed for trade</h4>
          </div>
          <b><h4>Offer Id: {params?.offer_id}</h4><br/></b>
          <div className="counterdown-content">
                      <div class="row">
                        <div class="col-lg-12">
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th>{t("Transactions.No")}</th>
                                <th>{t("Withdraw.User Name")}</th>
                                <th>Date</th>
                                <th>WOW</th>
                                <th>Amount</th>
                                <th>{t("Withdraw.Action")}</th>
                              </tr>
                            </thead>
                            
                  {data ?
                    <tbody>
                      {data.map((item,index) => (
                        <tr key={item.id}>
                          <td>{ (index + 1) + (offset - 1) * postsPerPage}</td>
                          <td key={item.id} data-toggle="tooltip" data-placement="top" title={item?.hash} className="tran">{item?.usertaker}</td>
                          <td key={item.id} >{item?.date}</td>
                          <td key={item.id} >{item?.bch_amount}</td>
                          <td key={item.id} >{item?.fiat_amount}</td>
                          <td key={item.id}><button class="ant-btn ant-btn-primary" id="continue"
                            onClick={() => tradeProceed(item?.id,item?.p2_userid,item?.uniqueid,item?.usertaker)} ><span>Approve Trade</span></button></td>
                        </tr>
                      ))}
                    </tbody>
                    :
                    <tbody style={{ textAlign:'center' }} className="borderTab" >
                      <tr>
                        <td>{t("Transactions.No data Found")}</td>
                      </tr>
                    </tbody>
                  }
                          </table>
                        </div>
                      </div>
                    </div>
         
        </div>
        <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
      </section>

    )

  }

  const getAllPosts = async () => {
    const res = await axios.get(`${baseURL}/api/wallet/buyer_accept_offer/${UserId}/${params?.offer_id}`);
    // console.log("buy data", res);
    if (res.data.data) {
      const data = res.data.data;
      const slice = data.slice(offset - 1, offset - 1 + postsPerPage)
  
      // For displaying Data
  
      // Using Hooks to set value
      setAllPosts(slice)
      setPageCount(Math.ceil(data.length / postsPerPage)) 
    } else {
      setAllPosts(null)
    }
  }

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  const tradeProceed = (id,p2_userid,uniqueid,usertaker) =>{
    const button = document.getElementById('continue');
    button.disabled = true
      const data = {
        userid: UserId
      }
      console.log("chetna",id);
      // console.log("chetnauserid", UserId,"p2_userid", p2_userid,"uniqueid",uniqueid,"usertaker",usertaker);
    Axios.post(`${baseURL}/api/wallet/accept_request_for_buy/${id}`, data).then(response => {
      toast.success("User trade Proceed Successfully");
      console.log("response", response);
      getAllPosts();
      // history.push(`/trade/${'actives'}/${params?.offer_id}/${UserId}/${uniqueid}`);
      history.push(`/${'noti_'}/trade/actives/${params?.offer_id}/${usertaker}/${p2_userid}/${uniqueid}`);
    }).catch(error => {
      console.log("error", error);
      toast.error("Error in trade Proceed");
    })
  }

  useEffect(() => {
    getAllPosts()
  }, [offset])

  return (
    <div className="main-app">

      {/* Display all the posts */}
      {getPostData(posts)}

      {/* Using React Paginate */}
      <ReactPaginate
        previousLabel={t('Withdraw.Previous')}
        nextLabel={t('Withdraw.Next')}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"} />
    </div>
  );
}

export default Allbuyer;