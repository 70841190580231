import React, { useEffect, state, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Form, Input, Button, Select, Spin } from "antd";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./SendWowCoin.css";
import { useTranslation, withTranslation } from "react-i18next";
import { getWowBalance } from "../../helpers/CallAPi";
import { LoadingOutlined } from "@ant-design/icons";

// import "./Signup.scss";
const { Option } = Select;
const baseURL = process.env.REACT_APP_BASE_URL;

const SendWowCoin = (props) => {
  const [GetWowAddress, setGetWowAddress] = useState();
  const [GetBnbbalance, setGetBnbbalance] = useState();
  const [Getfinalamount, setfinalamount] = useState();

  const [TransactionFee, setTransactionFee] = useState();
  const [WalletBalance, setWalletBalance] = useState();
  const UserId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  // const url = "${baseURL}/api/wallet/sendcoin";
  // const urla = `http://localhost/api/wallet/getaddress/${UserId}`;
  // const urlb = `${baseURL}/api/transaction/gettranxfee`;
  // const urlc = `${baseURL}/api/auth/getuserbalance/${UserId}`, {

  const [data, setData] = useState({
    address: "",
    amount: "",
    description: "",
    // coin: "",
    // user_id: GetWowAddress,
    // fee: TransactionFee,
  });

  useEffect(() => {
    Axios.get(`${baseURL}/api/wallet/getaddress/${UserId}`).then(
      (response) => {
        // console.log('getaddress response:', response.data.wowaddress);
        setGetWowAddress(response.data.wowaddress);
      },
      (error) => {
        // console.log(error);
      }
    );

    Axios.get(`${baseURL}/api/wallet/get_bnb_balance/${UserId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (response) => {
        setGetBnbbalance(response.data.data);
      },
      (error) => {
        // console.log(error);
      }
    );

    Axios.get(`${baseURL}/api/transaction/gettranxfee`).then((response) => {
      // console.log("getTran response.....", response.data.fee);
      setTransactionFee(response.data.fee);
    });

    Axios.get(`${baseURL}/api/auth/getuserbalance/${UserId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      // const balance = (Math.round(res.data.balance * 100) / 100).toFixed(8);
      setWalletBalance(res.data.balance.toFixed(8));

      // console.log("res.data.balance", res.data.balance);
    });
  }, []);

  const [PendingMsg, setPendingMsg] = useState();
  const submit = (e) => {
    const button = document.getElementById("continue");
    button.disabled = true;
    setPendingMsg(true);
    const sendData = {
      address: data.address,
      amount: data.amount,
      description: data.description,
      user_id: UserId,
      fee: TransactionFee.toString(),
    };

    var mystr = data.amount;
    var check = mystr.indexOf(".");
    if (check > 0) {
      var myarr = mystr.split(".");
      var famount = myarr[1];
    } else {
      var famount = data.amount;
    }

    if (famount.length > 8) {
      toast.error(t("wallet.Amount should be less than 8 digits"));
      const button = document.getElementById("continue");
      button.disabled = false;
    } else {
      Axios.post(`${baseURL}/api/wallet/sendcoin`, sendData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          if (res.data.errorMessage) {
            toast.error(t("wallet." + res.data.errorMessage));
            const button = document.getElementById("continue");
            button.disabled = false;
            setPendingMsg(false);
          } else {
            getWowBalance(UserId);
            sessionStorage.setItem(
              "Sendmsg",
              t("wallet.Transaction Successfully")
            );
            props.history.push("/wallet/Transactions");
            setPendingMsg(false);
          }
        })
        .catch((error) => {
          console.log("error:", error);
          toast.error(t("wallet.Transaction Failed"));
          const button = document.getElementById("continue");
          button.disabled = false;
          setPendingMsg(false);
        });
    }
  };

  function handle(e) {
    const newdata = { ...data };
    if (typeof e == "string") {
      newdata["coin"] = e;
    } else {
      newdata[e.target.id] = e.target.value;
    }
    setData(newdata);
    // console.log("newdata......", newdata);
  }

  const [t, i18n] = useTranslation("common");

  if (PendingMsg) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: 72,
          flexDirection: "column",
        }}
      >
        <Spin indicator={antIcon} />
        <h6 style={{ fontSize: "15px", paddingTop: 8 }}>
        {t("notification.Please wait while your transaction is processing")}
        </h6>
      </div>
    );
  }

  return (
    <>
      <Authcheck />

      <div className="send-wow-container">
        <div className="wallet-stats">
          <div className="sub-send-wow-container">
            <div className="stats-container">
              <p className="stats-heading">
                {t("wallet.Balance in your wallet")}:
              </p>
              <p className="stats-heading-value">{WalletBalance} WOW</p>
            </div>
            <div className="stats-container">
              <p className="stats-heading">{t("wallet.Your MATIC Balance")}:</p>
              <p className="stats-heading-value">{GetBnbbalance} MATIC</p>
            </div>
          </div>
          <div className="sub-send-wow-container">
            <div className="stats-container">
              <p className="stats-heading">{t("wallet.Transaction fee")}:</p>
              <p className="stats-heading-value">{TransactionFee} WOW</p>
            </div>
            <div className="stats-container">
              <p className="stats-heading">{t("wallet.You can send upto")}:</p>
              <p className="stats-heading-value">
                {" "}
                {WalletBalance < TransactionFee ? (
                  <> 0 </>
                ) : (
                  <> {(WalletBalance - TransactionFee).toFixed(8)} </>
                )}{" "}
                WOW
              </p>
            </div>
          </div>
        </div>

        <div>
          <Form
            onFinish={(e) => {
              submit(e);
            }}
            // onClick={(e) => submit(e)}
            // onSubmit={submit}
          >
            <Form.Item
              name="address"
              onChange={(e) => handle(e)}
              id="address"
              defaultValue={data.address}
              rules={[
                {
                  required: true,
                  message: t("wallet.WOW coin address is required"),
                },
              ]}
              className="wallet-ipt mt-2 mt-md-0"
            >
              <div className="wallet-send-input-wrapper">
                <p className="wallet-send-input-header">
                  {t("wallet.Receiving WOW coin address")}
                </p>
                <input id="address" className="wallet-send-input-field" />
              </div>
            </Form.Item>
            <Form.Item
              onChange={(e) => handle(e)}
              id="amount"
              rules={[
                {
                  required: true,
                  message: "0.00",
                },
              ]}
              className="wallet-ipt"
            >
              <div className="wallet-send-input-wrapper">
                <p className="wallet-send-input-header">
                  {t("wallet.Amount in WOW coins")}
                </p>
                <input
                  className="wallet-send-input-field"
                  type="tel"
                  id="amount"
                />
              </div>
            </Form.Item>
            <p className="more-options">{t("wallet.More Options")}</p>
            <Form.Item
              name="description"
              onChange={(e) => handle(e)}
              id="Description"
              value="data.Description"
              rules={[
                {
                  required: true,
                  message: t("wallet.Appears in the transaction list"),
                },
              ]}
              className="wallet-ipt"
            >
              <div className="wallet-send-input-wrapper">
                <p className="wallet-send-input-header">
                  {t("wallet.Description")}
                </p>
                <input id="Description" className="wallet-send-input-field" />
              </div>
            </Form.Item>

            <Form.Item 
                className="wallet-ipt">
              <button
                htmlType="submit"
                type="primary"
                class="send-btn"
                id="continue"
              >
                {t("wallet.CONTINUE")}
              </button>
            </Form.Item>
            <h6 style={{ color: "red", fontSize: "15px" }}>{PendingMsg}</h6>
          </Form>
        </div>
      </div>

      <ToastContainer autoClose={10000} style={{ marginTop: "60px" }} />
    </>
  );
};

export default SendWowCoin;
