import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { connect } from "react-redux";
import { Form, Input, Button, Select } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { doRegister } from "../../store/actions/authActions";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Route, NavLink, useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
// import Search from "../../components/Search/Search";
import MyOffers from "../../views/MyOffers/MyOffers";
import Contract from "../../views/Contract/Contract";
import Trustuser from "../../views/Trustuser/Trustuser";
import Account from "../../views/Account/Account";
import MyTrades from "../../views/MyTrades/MyTrades";
import userImg from "../../views/Marketing/img/user.jpg";
import ItemListings from "../../components/ItemListings/ItemListings";
import { Tooltip } from "antd";
import Axios from "axios";
import { getEmailverify } from "../../views/Verification/Helper_veri";
import { Dropdown } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";
import "./Dashboard.css";
import SectionSix from "../../components/Home/sections/sectionsix";

// import "./Search.scss";
// import "./Signup.scss";
const { Option } = Select;

const Dashboard = (props) => {
  const [expand, setExpand] = useState(false);
  const [menuOpen, toggleMenuOpen] = useState(false);

  function onChange(value) {
    // to add actionCreator needs to change slice of state
    // console.log(`selected ${value}`);
  }

  function onBlur() {
    // console.log("blur");
  }

  function onFocus() {
    // console.log("focus");
  }

  function onSearch(val) {
    // console.log("search:", val);
  }
  const baseURL = process.env.REACT_APP_BASE_URL;
  const UserId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const { pathname } = useLocation();
  const path = pathname.split("/");
  const [t, i18n] = useTranslation("common");
  return (
    <>
      <div className="mytrade-page">
        <div className="mytrade-upper"></div>
        <div className="wallet-container ">
          <p className="profile-heading">{t("Dashboard.Dashboard")}</p>
          <div className="offers-container">
            <div className="boxSections-sub-wrap">
              <div className="wallet-tags">
                <NavLink className={"non-active-section"} to="/my-trades">
                  {t("Trades.Active Trades")}
                </NavLink>
              </div>
              <div className="wallet-tags">
                <NavLink className={"active-section"} to="/dashboard/contract">
                  {t("Trades.Past Trades")}
                </NavLink>
              </div>
            </div>

            <Route
              path="/dashboard/my-trades"
              render={(props) => <MyTrades {...props} />}
            />

            <Route
              path="/dashboard/contract"
              render={(props) => <Contract {...props} />}
            />
          </div>
        </div>
      </div>
      <SectionSix />
    </>
  );
};

export default Dashboard;