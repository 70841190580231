import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { fetchAccount } from "../../store/actions/myAccountActions";
import "./TwoFactor.scss";
import GoogleAuth from "../GoogleAuthentication/GoogleAuth";
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useTranslation, withTranslation} from "react-i18next";
import tofasetupanduse from "../../views/Marketing/2fa_setup_and_use_updated.pdf";
const baseURL = process.env.REACT_APP_BASE_URL;
const Account = (props) => {
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };
  const UserId = localStorage.getItem('userId');
    const  token = localStorage.getItem("token");
  const user = localStorage.getItem('username');
  const [QrResponse, setQrResponse] = useState();
  const [errMsg, seterrMsg] = useState();
  const [ErrorMsg, setErrorMsg] = useState();
  const [UserAccount, setUserAccount] = useState();
  // console.log("QrResponse...............", QrResponse);
  // const url = "${baseURL}/api/auth/tfaverify";
  // const url_ = "${baseURL}/api/auth/deletetfa";
  // const urlset = "${baseURL}/api/auth/tfasetup";

  const [data, setData] = useState({
    token: "",
    // UserId: "",
  });

  const buttona = document.getElementById('continuea');
  // buttona.disabled = true;
  const deleteData = {
    user_id: UserId,
  };

  const senduserDetail = {
    username: user,
    id: UserId,
  }
  
  // console.log("senduserDetail........", senduserDetail);
  const disable2fa = () => {
    Axios.post(`${baseURL}/api/auth/deletetfa`, deleteData)
      .then(response => {
        sessionStorage.setItem("2famsg", response.data.message);
        window.location.reload();
          }).catch(error => {
        // console.log("error:", error);
        sessionStorage.setItem("2faerror", error);
        window.location.reload();
         });
  }

  const submit = (e) => {
    // e.preventDefault();
    const button = document.getElementById('continue');
    button.disabled = true;
    const sendData = {
      token: data.token,
      password: data.password,
      user_id: UserId,
    };

    // console.log('sendocument.getElementById("demo")dData', sendData);
    Axios.post(`${baseURL}/api/auth/tfaverify`, sendData)
      .then(res => {
        // console.log("tfaverify data",res);
        if(res.data.status==200){
          sessionStorage.setItem("2famsg", t("Twofactor."+res.data.message));
        }else{
          sessionStorage.setItem("2faerror", t("Twofactor."+res.data.message));
        }
     
        window.location.reload();
        setErrorMsg(res.data.message);
      }).catch(error => {
        // console.log("error:", error);
        sessionStorage.setItem("2faerror", t("Twofactor."+error));
        window.location.reload();
       });
  }
  // console.log("UserAccount", UserAccount);


  useEffect(() => {

    if(sessionStorage.getItem("2famsg")){
      toast.success(sessionStorage.getItem("2famsg"));
      sessionStorage.removeItem("2famsg");
     }

     if(sessionStorage.getItem("2faerror")){
      toast.error(sessionStorage.getItem("2faerror"));
      sessionStorage.removeItem("2faerror");
     }

    Axios
      .get(`${baseURL}/api/auth/tfagetup/${UserId}`).then((response) => {
        // console.log('QR response', response.data);
        if (response.data && response.data.dataURL) {
          setQrResponse(response.data);
        } else {
          // console.log("this is null ");
          Axios.post(`${baseURL}/api/auth/tfasetup`, senduserDetail).then(res => {
            // props.history.push("/two_factor/");
            window.location.reload();
          }).catch(error => {
            // console.log("error:", error);
          })
        }

      }, error => {
        // console.log(error);
      });
    Axios
      .get(`${baseURL}/api/auth/useraccount/${UserId}`, {
        headers: {
           "Authorization" : `Bearer ${token}`
        }
         }).then((response) => {
        // console.log('QR response', response.data);
        setUserAccount(response.data.two_factor);
      }, error => {
        // console.log(error);
      });
  }, [])

  function handle(e) {
    // console.log('e.target:',e);
    const newdata = { ...data }
    if (typeof e == "string") {
      newdata['coin'] = e
    } else {
      newdata[e.target.id] = e.target.value
    }
    setData(newdata)
    // console.log("newdata......", newdata);
  }


  const [t, i18n] = useTranslation('common');
  return (

    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">
           <div className="two-factor-container">

              <div className="col-md-12">
                <h3 style={{ fontSize: '1.8rem' }}>{t("Twofactor.Two-factor authentication")}</h3>
                <p>
                  <a href="/settings/" style={{color: "blue"}}><i class="fa fa-arrow-left" /> {t("Twofactor.Back to Account security")} <options /></a>
                </p>
                <p>
                {t("Twofactor.Protect your account from unauthorized access by enabling two-factor authentication")}<br />{t("Twofactor.When two-factor authentication is active you need to enter a one time code every time you log in")}
                </p>
                <br />
                <h6>{t("Twofactor.Enable two-factor authentication")}</h6>
               
                <div id="activation-method">

                  <button className="btn btn-default" type="button" id="show-activation-key" disabled>
                    <i className="fa fa-key" />
                    {/* {t("Twofactor.Proceed to activation")} */}
                  </button>
                </div>
                <form className="display-none" id="mobile-two-factor-form" action method="POST">
                  <div id="enable_mobile_twofactor_qrcode" title="otpauth://totp/TruptiCyperts@LocalBitcoins?secret=MVDEG4LSNRZTEZBT"><canvas width={256} height={256} style={{ display: 'none' }} /><img src={QrResponse?.dataURL} style={{ display: 'block' }} /></div>
                  <input type="hidden" name="csrfmiddlewaretoken" defaultValue="e8nb2XlsMKvYo00SkXHpA1hkyLISZk8xzaW9miCfq5HL9xGbIipCoPZM0JnIT9hK" />
                  <div>
                    <input type="hidden" name="secret_key" defaultValue="eFCqrls2d3" readOnly id="id_secret_key" /> <div id="div_id_secret_key_b32" className="form-group">
                      <label htmlFor="id_secret_key_b32" className="control-label  requiredField">
                      {t("Twofactor.Authentication key")}
                        <span className="asteriskField">*</span>
                      </label>
                      <div className="controls "> <input type="text" name="secret_key_b32" defaultValue={QrResponse?.tempSecret} readOnly autoComplete="new-password" className="textinput textInput form-control" required id="id_secret_key_b32" /> </div>
                    </div>
                    <Form
                      onFinish={(e) => {
                        submit(e);
                      }}
                    >
                      {UserAccount == 1 ? null :
                        <Form.Item

                          label={t("Twofactor.Authentication code")}
                          name="token"
                          onChange={(e) => handle(e)}
                          id="token"
                          rules={[
                            {
                              required: true,
                              message: t("Twofactor.Please fill Authentication code"),
                            },
                          ]}
                        >

                          <Input />
                        </Form.Item>
                        
                      }


                          {UserAccount == 1 ? null :
                          <Form.Item

                          label={t("login.Password")}
                          name="password"
                          onChange={(e) => handle(e)}
                          id="password"
                          rules={[
                            {
                              required: true,
                              message: t("login.Please fill Password")
                            },
                          ]}
                          >

                          <Input type="password"/>
                          </Form.Item>
                            }
                      <Form.Item>{UserAccount == 1 ? <Button type="primary" class="ant-btn ant-btn-danger" id="continuea" onClick={disable2fa}  >{t("Twofactor.Disable 2FA")}</Button> : <Button htmlType="submit" type="primary" class="ant-btn ant-btn-primary" id="continue">{t("Twofactor.Enable 2FA")}</Button>}
                        {/* <Button htmlType="submit"  type="primary" class="ant-btn ant-btn-primary" id="continue">Enable 2FA</Button> */}
                      </Form.Item>
                    </Form>

                  </div>
                  <p style={{ color: "red" }}>{errMsg}</p>
                  {/* <p style={{color: "red"}}>{ErrorMsg}</p> */}

                </form>

              </div>
              <p>{t("Twofactor.Steps to enable Two-factor Authentication")}</p>
                <p>1. {t("Twofactor.Download the Google Authentication app for your mobile or tablet")} </p>
                <p>2. {t("Twofactor.Your backup code is")}: {QrResponse?.tempSecret}</p>
                <p>3. {t("Twofactor.Write this code down on a piece of paper and stor it safe")}</p>
                <p>4. {t("Twofactor.Launch the authenticator app on your mobile device")}</p>
                <p>5. {t("Twofactor.Enter the code given by your mobile app in the box above")}</p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
      style={{marginTop: "60px"}}
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </section>
  );
};

export default Account;
