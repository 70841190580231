import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./sections.css";
import { useTranslation, withTranslation } from "react-i18next";
import Axios from "axios";
import { balanceSub, getWowBalance } from "../../../helpers/CallAPi";

import { Auth, authData } from "../../../auth/Auth";
import { useDispatch, useSelector } from "react-redux";
function SectionSix() {
  const username = localStorage.getItem("username");
  const activeOffers = useSelector((state) => {
    if (state.myOffers.myOffers === undefined) {
      return 0;
    }
    return state.myOffers.myOffers.reduce((acc, cur) => {
      cur.pause === false ? (acc += 1) : (acc += 0);
      return acc;
    }, 0);
  });

  const [WalletBalance, setWalletBalance] = useState();
  const [isDisplayNav, setIsDisplayNav] = useState(true);
  const dispatch = useDispatch();
  const UserId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [notiPerson, setNotiperson] = useState();
  const [open, setOpen] = useState(false);

  const changeLanguage = (lng) => {
    localStorage.setItem("lng", lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    let lng = localStorage.getItem("lng");
    if (lng) {
      i18n.changeLanguage(lng);
    }
    checkAuth();
  }, []);

  useEffect(() => {
    if (UserId) {
      try {
        balanceSub.subscribe((res) => {
          const UserId = localStorage.getItem("userId");
          if (res == null && UserId) {
            getWowBalance(UserId);
          } else {
            setWalletBalance(res);
          }
        });
      } catch (error) {}
    }
    setInterval(() => {
      try {
        balanceSub.subscribe((res) => {
          const UserId = localStorage.getItem("userId");
          if (res == null && UserId) {
            getWowBalance(UserId);
          } else {
            setWalletBalance(res);
          }
        });
      } catch (error) {}
    }, 5000);
  });

  const checkAuth = () => {
    authData.subscribe((res) => {
      if (res == null) {
        Auth.isAuthenticated();
      } else {
        setIsDisplayNav(res);
      }
    });
  };

  const [t, i18n] = useTranslation("common");
  return (
    <div className="section6-main">
      <div className="section6-wrapper">
        <p className="section6-heading">
          {t("Home.Start Your Journey with")}
          <span style={{ color: "#2074F2" }}>{t("Home.Wowcoin")}</span>
        </p>
        {!isDisplayNav ? (
          <div>
            <Button as={Link} to="/login" className="section6-login-btn">
              {t("menu.Log in")}
            </Button>
            <Button as={Link} to="/signup" className="section6-register-btn">
              {t("menu.Register")}
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}

export default SectionSix;
