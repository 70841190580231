import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { fetchAccount } from "../../store/actions/myAccountActions";
import "./PasswordChange.scss";
import Axios from 'axios';
import {useTranslation, withTranslation} from "react-i18next";

const baseURL = process.env.REACT_APP_BASE_URL;
const  token= localStorage.getItem("token");

const Account = (props) => {
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };

  const [data, setData] = useState({});
  const formRef = React.useRef(null);
  // const url = "${baseURL}/api/auth/updatePassword";

  const submit = (e) => {
    const userId = localStorage.getItem("userId");
    // console.log('data:', data);
    const sendData = {
      id: userId,
      oldpassword: data.register_old_password,
      newpassword: data.register_password,
      confpassword: data.register_confirm,
    };
    // console.log("sendData", sendData)
    Axios.post(`${baseURL}/api/auth/updatePassword`, sendData, {
      headers: {
         "Authorization" : `Bearer ${token}`
      }
       })
      .then(res => {
        // console.log("changeres:", res);
        if (res.status == 200) {
         //toast.success("Password Changed Successfully!");
         sessionStorage.setItem("ChangedPassmsg", "Password Changed Successfully!");
         window.location.reload();
        }else{
          sessionStorage.setItem("ChangedPasserror", res.errorMessage);
          window.location.reload();
        }
         //console.log("res.data.......", res.data);
          }).catch(error => {
        // console.log("changeerror:", error);
        sessionStorage.setItem("ChangedPasserror", "Please input your correct password!");
        window.location.reload();
        //window.location.reload();
      })
  }

  useEffect(() => {

    if(sessionStorage.getItem("ChangedPassmsg")){
      toast.success(sessionStorage.getItem("ChangedPassmsg"));
      sessionStorage.removeItem("ChangedPassmsg");
     }

     if(sessionStorage.getItem("ChangedPasserror")){
      toast.error(sessionStorage.getItem("ChangedPasserror"));
      sessionStorage.removeItem("ChangedPasserror");
     }

     });

  function handle(e) {
    // console.log('e.target.id:', e.target.id);
    let newdata = data;
    newdata[e.target.id] = e.target.value
    setData(newdata)
    // console.log("newdata......", newdata);
  }

  const [t, i18n] = useTranslation('common');
  return (

    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">

            <div className="password-change-container">

              <div class="offset2 col-md-6">
                <h4>{t("passwordchange.Change your password")}</h4>
                <br />
                <Form
                  name="register"
                  id="password-changed-form"
                  initialValues={{
                    remember: true,
                  }}
                  ref={formRef}
                  onFinish={(e) => {
                    submit(e);
                  }}
                // onFinish={onFinish}
                >
                  <Form.Item
                    label={t("passwordchange.Old Password")}
                    name="old_password"
                    onChange={(e) => handle(e)}
                    rules={[
                      {
                        required: true,
                        message: t("signup.Please input your Password"),
                      },
                      {
                          min: 6,
                          pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
                          message: t('Security.passwordvalidation'),
                      },
                    ]}
                  >
                    <Input type="password"/>
                  </Form.Item>
                  <Form.Item
                    label={t("passwordchange.Add new password")}
                    name="password"
                    onChange={(e) => handle(e)}
                    rules={[
                      {
                        required: true,
                        message: t("signup.Please input your Password"),
                      },
                      {
                          min: 6,
                          pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
                          message: t('Security.passwordvalidation'),
                      },
                    ]}
                  >
                    <Input type="password" />
                  </Form.Item>
                  <Form.Item
                    name="confirm"
                    label={t("passwordchange.confirm new password")}
                    onChange={(e) => handle(e)}
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: t("signup.Please input your Password"),
                      },
                      ({ getFieldValue }) => ({
                        validator(rule, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            t("signup.The two passwords that you entered do not match")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input type="password"/>
                  </Form.Item>
                  {/* <h5>{errorMsg}</h5> */}

                  <Form.Item>
                    <Button type="primary" htmlType="submit" id="continue">{t("passwordchange.CHANGE PASSWORD")}</Button>
                  </Form.Item>
                </Form>
                <div id="div_id_new_password1" class="form-group">
                  {/* <label for="id_new_password1" class="control-label  requiredField">New password<span class="asteriskField">*</span> </label> */}
                  <div class="controls ">
                    {/* <input type="password" name="new_password1" autocomplete="new-password" class="textinput textInput form-control" required="" id="id_new_password1" /> */}
                    <div id="hint_id_new_password1" class="help-block">
                      <ul>
                        <li><b>{t("passwordchange.Instruction")}</b></li>
                        <li>{t("passwordchange.Password must contain at least one uppercase")}, {t("passwordchange.lowercase, numeric digit and a special character")}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div id="div_id_new_password2" class="form-group">
                  <label for="id_new_password2" class="control-label  requiredField">
                    New password confirmation<span class="asteriskField">*</span> </label>
                  <div class="controls ">
                    <input type="password" name="new_password2" autocomplete="new-password" class="textinput textInput form-control" required="" id="id_new_password2" />
                  </div>
                </div> */}
                {/* <form method="post" action="">
                  <input type="hidden" name="csrfmiddlewaretoken" value="Kx4Wy1yVI6liWVLcQ7PDMN4Et9WehpDP5zDUSmPImrx5HsrvesxQABM6V7B4beM2" />


                  <div id="div_id_old_password" class="form-group">
                    <label for="id_old_password" class="control-label  requiredField">Old password<span class="asteriskField">*</span> </label>
                    <div class="controls ">
                      <input type="password" name="old_password" autocomplete="current-password" autofocus="" class="textinput textInput form-control" required="" id="id_old_password" />
                    </div>
                  </div>
                  <div id="div_id_new_password1" class="form-group">
                    <label for="id_new_password1" class="control-label  requiredField">New password<span class="asteriskField">*</span> </label>
                    <div class="controls ">
                      <input type="password" name="new_password1" autocomplete="new-password" class="textinput textInput form-control" required="" id="id_new_password1" />
                      <div id="hint_id_new_password1" class="help-block">
                        <ul>
                          <li>Your password can’t be too similar to your other personal information.</li>
                          <li>Your password must contain at least 8 characters.</li>
                          <li>Your password can't contain more than 128 characters.</li>
                          <li>Your password can’t be a commonly used password.</li>
                          <li>Your password can’t be entirely numeric.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div id="div_id_new_password2" class="form-group">
                    <label for="id_new_password2" class="control-label  requiredField">
                      New password confirmation<span class="asteriskField">*</span> </label>
                    <div class="controls ">
                      <input type="password" name="new_password2" autocomplete="new-password" class="textinput textInput form-control" required="" id="id_new_password2" />
                    </div>
                  </div>
                  <p>
                    <button class="ant-btn ant-btn-primary">Change</button>
                  </p>
               </form> */}

              </div>

              <p class="muted">
              {t("passwordchange.The password change resets your third party application and API authentications")}
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
    </section>
  );
};

export default Account;
