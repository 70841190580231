import React, { useEffect, useState } from 'react'
import { Route, Link, useHistory } from "react-router-dom";
import { authData } from "../../auth/Auth";
import { kycData } from "../../views/Verification/Helper_veri";
import { getWowBalance, balanceSub } from "../../helpers/CallAPi"
import Axios from 'axios';
import { useDispatch } from "react-redux";

const Authcheck = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();

  useEffect(() => {
    checkBlock();
  }, []);


  const checkBlock = async () => {
    const UserId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    if (UserId && token) {
      const validunvalid = Axios.get(`${baseURL}/api/auth/user_valid_unvalid/${UserId}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      }).then((response) => {
        console.log("validunvalid", response.data);
        if (response.data.status == true) {
          authData.next(null);
          kycData.next(null);
          balanceSub.next(null);
          localStorage.clear();
          window.location.href = '/';
        }
      })
        .catch((err) => {
          console.log("validunvalid", err);
        });
    }
  }

  return (
    <>

    </>

  )
}

export default Authcheck
