
import React, { lazy, Suspense } from "react";
import Authcheck from "./components/Authcheck/Authcheck";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import { CheckLogin } from "./store/actions/authActions";
import { applyMiddleware, createStore } from "redux";
import { Provider } from "react-redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./store/reducers";
import common_ko from "./translations/ko/common.json";
import common_en from "./translations/en/common.json";

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      ko: {
          common: common_ko
      },
  },
});

const store = createStore(rootReducer, applyMiddleware(thunk, logger));

const checkuser = createStore(CheckLogin, applyMiddleware(thunk, logger));
console.log("checkuser",checkuser);

ReactDOM.render(
  <Provider store={store}>
  <Suspense fallback={<h1>Still Loading…</h1>}>
   <Authcheck />
    <Router>
    <I18nextProvider i18n={i18next}>
      <App />
      </I18nextProvider>
    </Router>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
