import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Form, Input, Button } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import Axios from 'axios';
import { useTranslation, withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
const baseURL = process.env.REACT_APP_BASE_URL;

export const LOGIN = "LOGIN";
export const LOADING_USER = "LOADING_USER";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const GET_USER = "GET_USER";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const USER_ERROR = "USER_ERROR";

// const url = "${baseURL}/api/auth/forgotpassword";
const Resetpassword = () => {

  const [data, setData] = useState({});
  const Email = localStorage.getItem('email');
  let history = useHistory();

  const submit = (e) => {
    const sendData = {
      email: Email,
      otp : data.normal_login_otp,
      password : data.normal_login_password,
      cpassword : data.normal_login_cpassword
    };
    // console.log("sendData", sendData);
    Axios.post(`${baseURL}/api/auth/resetpassword`, sendData)
      .then(res => {
         // console.log("reset password", res);
          localStorage.setItem("resetpassword", t('ResetPassword.'+res.data.msg));
          localStorage.removeItem("email");
          history.push('/login')
      }).catch(error => {
        // console.log("error", error);
        toast.error(t('ResetPassword.Error Occured in reset password'));
      })
  }

  useEffect(()=>{
    if(localStorage.getItem("email")){
        toast.success(t('ResetPassword.Successfully sent OTP to mail'));
       }
  },[])

  function handle(e) {
    // console.log('e.target.id:', e.target.id);
    let newdata = data;
    newdata[e.target.id] = e.target.value
    setData({ ...data, [e.target.id]: e.target.value })
    // console.log("newdata......", newdata);
  }

  const [t, i18n] = useTranslation('common');

  return (
    <div>
      <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
         <div className="container-fluid">
      <Authcheck />
          <div className="row flex-column-reverse flex-lg-row reveal">
            <div className="col-11 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">
              <div className="col-lg-6">
                <div className="login-container">
                  <div className="panel left">
                    <h3>{t('ResetPassword.Reset Password')}</h3>
                   
                    <Form
                      name="normal_login"
                      className="login-form"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={(e) => {
                        submit(e);
                      }}
                    >
                      <Form.Item
                        label={t('ResetPassword.Otp')}
                        name="otp"
                        onChange={(e) => handle(e)}
                         rules={[
                          {
                             required: true,
                             message:t('ResetPassword.Please input your otp'),
                           },
                         ]}
                      >
                      <Input placeholder={t('ResetPassword.Otp')}/>
                      </Form.Item>
                      <Form.Item
                        label={t('ResetPassword.Password')}
                        name="password"
                        onChange={(e) => handle(e)}
                        rules={[
                          {
                            required: true,
                            message: t('signup.Please input your Password'),
                          },
                          {
                            min: 6,
                            pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
                            message: t("Security.passwordvalidation"),
                          },
                        
                        ]}
                      >
                        <Input placeholder={t('ResetPassword.Password')}/>
                      </Form.Item>
                      <Form.Item
                        label={t('ResetPassword.Confirm Password')}
                        name="cpassword"
                        rules={[
                          {
                            required: true,
                            message: t('signup.Please confirm your password'),
                          },
                          ({ getFieldValue }) => ({
                            validator(rule, value) {
                              if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                t('signup.The two passwords that you entered do not match')
                              );
                            },
                          }),
                        ]}
                        onChange={(e) => handle(e)}
                      >
                        <Input placeholder={t('ResetPassword.Confirm Password')}/>
                      </Form.Item>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                          id="reset"
                        >
                          {t('ForgetPassword.Reset Password')}
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>

                </div>
              </div>
              <div className="col-lg-6">

              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
      </section>
    </div>
  )

}

export default Resetpassword;