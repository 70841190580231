import React, { useEffect } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { useDispatch, useSelector } from "react-redux";
import Kyccheck from "../../components/Kyccheck/Kyccheck";
import { getMyOrders } from "../../store/actions/ordersActions";
import MyActiveTrades from "../../components/Trades/MyActiveTrades";
import MyPastTrades from "../../components/Trades/MyPastTrades";

import "./MyTrades.css";
import SectionSix from "../../components/Home/sections/sectionsix";
import { useTranslation } from "react-i18next";

const MyTrades = (props) => {
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyOrders());
  }, []);
  const [t, i18n] = useTranslation("common");

  return (
    <>
      <Authcheck />

      {/* <Kyccheck /> */}

      <MyActiveTrades />
      {/* <MyPastTrades myOrders={myPastOrders} userId={userId} /> */}
    </>
  );
};

export default MyTrades;