import { axiosWithAuth } from "../../helpers/axiosWithAuth";
import { updateAction } from "../../helpers/updateActions";

const baseURL = process.env.REACT_APP_BASE_URL;

export const FETCH_MY_MESSAGES = "FETCH_MY_MESSAGES";
export const CREATE_MESSAGE = "CREATE_MESSAGE";

export const fetchMyMessages = (uniqueId) => async (dispatch) => {
  try {
    // console.log("taker...",taker);
    // console.log("maker....",maker);
    const  token= localStorage.getItem("token");
    const result = await axiosWithAuth().get(`${baseURL}/api/chat/${uniqueId}`, {
      headers: {
         "Authorization" : `Bearer ${token}`
      }
       });
    //const result = await axiosWithAuth().get(`${baseURL}/api/chat/${orderId}/${taker}/${maker}`);
    console.log("chat.....chat1", result);
    dispatch(updateAction(FETCH_MY_MESSAGES, result.data));
    // console.log("result.....chat1", result);
  } catch (error) {
    // console.log(error);
  }
};

export const createMessage = (chatBody,order_id) => async (dispatch) => {
  try {
    const  token= localStorage.getItem("token");
    console.log("chat result", chatBody)
    const result = await axiosWithAuth().post(
      `${baseURL}/api/chat/${order_id}`,
      chatBody, {
        headers: {
          "Authorization" : `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        }
    }
    );
     console.log("chat result", result)
    // console.log("chatBody....", chatBody);
    // dispatch(updateAction(CREATE_MESSAGE, result.data.addmsg));
    // window.location.reload()
    // return result
  } catch (error) {
    console.log("chat result", error)
  }
};

export const addMessage = (chatBody) => async (dispatch) => {
  try {
    dispatch(updateAction(CREATE_MESSAGE, chatBody));
  } catch (error) {
    // console.log(error);
  }
};
