import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { useTranslation } from "react-i18next";
import { fetchAccount } from "../../store/actions/myAccountActions";
import "./Advanced.scss";

const Account = (props) => {
  const [t, i18n] = useTranslation('common');
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };

  const [userInfo, setUserInfo] = useState({});


  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">

            <div className="notifications-container" style={{padding: '0%'}}>
              <div className="secondary-nav">
                <div className="tab-link">
                  <Link to="/my-account" activeClassName="is-active">
                    {t('Profile.Profile')}
                  </Link>
                </div>
                <div className="tab-link">
                  <Link to="/settings" activeClassName="is-active">
                  {t("Profile.Security")}
                  </Link>
                </div>

                <div className="tab-link">
                  <Link to="/verification" activeClassName="is-active">
                  {t("Profile.Verification")}
                  </Link>
                </div>

                {/* <div className="tab-link">
                  <Link to="/notifications" activeClassName="is-active">
                    Notifications
                  </Link>
                </div> */}

                {/* <div className="tab-link">
                  <Link to="/advanced" activeClassName="is-active">
                  {t("Profile.Advanced")}
                  </Link>
                </div> */}
              </div>

      </div>

      <div className="">
            

      <div class="row mt-2">
                <div class="col-12">

                    <div class="row py-3 my-2 mx-1 border rounded">
                        <div class="col-12 mt-2">
                            <p class="p-0 m-0">{t("Profile.Third-Party Applications")}</p>
                            <p class="pb-1 my-0 ml-2">
                                <small>{t("Profile.Third-party applications can")}
                                </small>
                            </p>
                        </div>
                        <div class="col col-sm-4 offset-sm-4 col-lg-2 offset-lg-8 mb-1">
                            <a href="/change-pincode/" class="btn btn-outline-primary btn-block float-right" style={{ padding: '.375rem .75rem' }}>{t("Profile.Set PIN")}
                            </a>
                        </div>
                        <div class="col col-sm-4 col-lg-2 mb-1">
                            <a href="/accounts/api/" class="btn btn-outline-primary btn-block float-right" style={{ padding: '.375rem .75rem' }}>{t("Profile.Manage Apps")}
                                
                            </a>
                        </div>

                        <div class="col-12 col-lg-8 mt-2">
                            <p class="p-0 m-0">{t("Profile.Premium Service Receipts")}</p>
                            <p class="pb-1 my-0 ml-2">
                                <small>{t("Profile.These are not the receipts")}
                                </small>
                            </p>
                        </div>
                        <div class="col-8 offset-4 col-sm-4 offset-sm-8 col-lg-2 offset-lg-10 mb-1">
                            <a href="/receipts/" class="btn btn-outline-primary btn-block float-right" style={{ padding: '.375rem .75rem' }}>{t("Trades.View")}
                            </a>
                        </div>
                    </div>

                </div>
            </div> </div>
               
               
                </div>
              </div>
            </div>
    </section>
          );
};

          export default Account;
