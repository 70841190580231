import React from 'react';
import Authcheck from "../../components/Authcheck/Authcheck";
import { useTranslation, withTranslation } from "react-i18next";
import "./Privacy.css";
import SectionSix from '../Home/sections/sectionsix';



const Privacy = () => {
    const [t, i18n] = useTranslation('common');
    return <div>
        <section className=" hero--full hero--v10 d-flex align-items-center hidden z-index2 " style={{ marginTop: "100px" }} >
            <div className="container privacy-body">
                <Authcheck />
                <div className="row flex-column-reverse flex-lg-row reveal">
                    <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 ">
                        <div className="col-12 col-md-10 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 " style={{ marginTop: '25px' }}>
                            <div className="ico-counter">
                                <div className="counter-down">
                                    <div className="content">
                                        <div className="conuter-header main-heading">
                                            <h4 style={{ aligned: 'left' }} className="sendWOW pr-head">{t('Footer.Privacy Policy')}</h4>
                                            <p>{t('Privacypolicy.OUR COMMITMENT TO PRIVACY')}</p>
                                        </div>
                                        <ul className='list-main'>
                                            <li className='list-txt'>{t('Privacypolicy.PRIVACY1')}</li><br />
                                            <li className='list-txt'>{t('Privacypolicy.PRIVACY2')}</li><br />
                                            <li className='list-txt'>{t('Privacypolicy.PRIVACY3')}</li><br />
                                            <li className='list-txt'>{t('Privacypolicy.PRIVACY4')}</li><br />
                                            <li className='list-txt'>{t('Privacypolicy.PRIVACY5')}</li><br />
                                            <li className='list-txt'>{t('Privacypolicy.PRIVACY6')}</li><br />
                                            <li className='list-txt'>{t('Privacypolicy.PRIVACY7')}</li>
                                        </ul>
                                        {/* <div className="signup-container">

                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </section>
        <SectionSix />
    </div>;
};

export default Privacy;
