import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link, useHistory, NavLink } from "react-router-dom";
import { Form, Input, Button, Select } from "antd";
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { fetchAccount } from "../../store/actions/myAccountActions";
import { FormInstance } from "antd/lib/form";
import "./Account.css";
import Kyccheck from "../../components/Kyccheck/Kyccheck";
import { getEmailverify } from "../../views/Verification/Helper_veri";
import { axiosWithAuth } from "../../helpers/axiosWithAuth";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation, withTranslation } from "react-i18next";
import SectionSix from "../../components/Home/sections/sectionsix";

const baseURL = process.env.REACT_APP_BASE_URL;

const Account = (props) => {
  const dispatch = useDispatch();
  const userName = localStorage.getItem("userName");
  const history = useHistory();
  const logoutHandler = () => {
    dispatch(doLogout());
  };
  const currentRoute = useHistory().location.pathname.toLowerCase();
  // console.log("cuudfssdfdf", currentRoute)
  const [getCountry, setGetCountry] = useState([]);
  const [data, setData] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const formRef = React.useRef(null);
  const [emailVerify, setemailVerify] = useState({});
  // console.log("emailVerify", emailVerify);
  // const url = "${baseURL}/api/auth/updateUser";
  const { Option } = Select;

  const useraccount = async () => {
    let myData = await fetchAccount();
    setUserInfo(myData);
    formRef.current.setFieldsValue({
      username: myData.username,
      user_fullname: myData.user_fullname,
      email: myData.email,
      phone: myData.phone_number,
      countrycode: myData.country,
      residence: myData.phone_country_code,
    });
  };

  useEffect(() => {
    const button = document.getElementById("email");
    button.disabled = true;

    if (sessionStorage.getItem("Profilemsg") != "") {
      toast.success(sessionStorage.getItem("Profilemsg"));
      sessionStorage.removeItem("Profilemsg");
    }

    useraccount();
    try {
      Axios.get(`${baseURL}/api/auth/getcountry`).then((response) => {
        // console.log("getTran response.....", response.data);
        setGetCountry(response.data);
      });
    } catch (error) {
      // console.log(error);
    }
  }, []);
  useEffect(() => {
    try {
      (async () => {
        try {
          const emailverify = await getEmailverify();
          // console.log("emailverify........................", emailverify);
          setemailVerify(emailverify.emailstate.data.status);
        } catch {
          // // console.log("error in tier 0");
        }
      })();
    } catch { }
  }, []);

  const submit = (e) => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    console.log("data:", data);
    const sendData = {
      id: userId,
      country: data.countrycode,
      user_fullname: data.user_fullname,
      email: data.email,
      phone_country_code: data.residence,
      phone_number: data.phone,
    };
    // console.log("sendData", sendData)
    Axios.post(`${baseURL}/api/auth/updateUser`, sendData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        //toast.success("Profile Updated Successfully");
        // console.log("res.data.......", res.data);
        sessionStorage.setItem(
          "Profilemsg",
          t("Profile.Profile Updated Successfully")
        );
        history.push("/verification");
      })
      .catch((error) => {
        // console.log("error:", error);
        toast.error("Profile Updation Failed");
        //window.location.reload();
      });
  };

  function emailEdit() {
    const button = document.getElementById("email");
    button.disabled = false;
  }

  function handle(e) {
    // console.log('e.target.id:', e.target.id);
    let newdata = data;
    newdata[e.target.id] = e.target.value;
    setData({ ...data, [e.target.id]: e.target.value });
    // console.log("newdata......", newdata);
  }

  const [t, i18n] = useTranslation("common");
  console.log(userInfo.usefullname);
  return (
    <>
      <div className="account-page">
        <div className="account-upper"></div>
        <div className="account-container">
          <Kyccheck />

          {currentRoute.includes("/dashboard/my-account") ? (
            ""
          ) : (
            <div className="boxSections-sub-wrap">
              <div className="non-active-section">
                <NavLink to="/my-account" activeClassName="active-section">
                  {t("Profile.Profile")}
                </NavLink>
              </div>
              <div className="non-active-section">
                <NavLink
                  style={{ color: "black" }}
                  to="/settings"
                  activeClassName="active-section"
                >
                  {t("Profile.Security")}
                </NavLink>
              </div>
              <div className="non-active-section">
                <NavLink
                  style={{ color: "black" }}
                  to="/verification"
                  activeClassName="active-section"
                >
                  {t("Profile.Verification")}
                </NavLink>
              </div>
            </div>
          )}

          <div class="row">
            <div class="col-12 col-lg-6 pr-lg-0">
              <p className="profile-heading">
                {" "}
                {t("Profile.Profile Information")}
              </p>
              <Form
                initialValues={{
                  remember: true,
                }}
                ref={formRef}
                onFinish={(e) => {
                  submit(e);
                }}
              >
                <p className="input-titles">{t("Profile.Fullname")}</p>{" "}
                <Form.Item
                  name="user_fullname"
                  id="user_fullname"
                  onChange={(e) => handle(e)}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Fullname!",
                    },
                  ]}
                >
                  <Input
                    style={{
                      maxWidth: "430px",
                      height: "50px",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#222222",
                      border: "1px solid #e9e9e9",
                      borderRadius: "45px",
                    }}
                    value={userInfo.usefullname}
                  />
                </Form.Item>
                <Form.Item
                  name="username"
                  id="username"
                  onChange={(e) => handle(e)}
                  rules={[
                    {
                      required: true,
                      message: "Please input your Username!",
                    },
                  ]}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <p className="input-titles">
                      {t("Profile.Username (visible to everyone)")}
                    </p>
                    <Input
                      style={{
                        maxWidth: "430px",
                        height: "50px",
                        fontFamily: "Poppins",
                        fontWeight: "400",
                        fontSize: "14px",
                        color: "#222222",
                        border: "1px solid #e9e9e9",
                        borderRadius: "45px",
                      }}
                      className="input-fields"
                      value={userInfo?.username}
                      disabled
                    />
                  </div>
                </Form.Item>
                <p className="input-titles">{t("Profile.E-mail")}</p>{" "}
                <Form.Item
                  name="email"
                  id="email"
                  onChange={(e) => handle(e)}
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input
                    style={{
                      maxWidth: "430px",
                      height: "50px",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#222222",
                      border: "1px solid #e9e9e9",
                      borderRadius: "45px",
                    }}
                    className="input-fields"
                    value={userInfo.email}
                    id="email"
                  />
                </Form.Item>
                <br />
                {userInfo?.email_verification == 1 ? (
                  <>
                    <span className="profile-verified">
                      {t("Profile.Verified")}
                    </span>
                  </>
                ) : (
                  <>
                    <span className="profile-verified">
                      {t("Profile.Not Verify")}
                    </span>
                  </>
                )}
                <span
                  className="profile-change-email"
                  onClick={() => {
                    emailEdit();
                  }}
                >
                  {t("Profile.Change E-mail")}
                </span>
                <br />
                <p style={{ marginTop: "25px" }} className="input-titles">
                  {t("Profile.Phone Number")}
                </p>
                <Form.Item
                  name="phone"
                  id="phone"
                  onChange={(e) => handle(e)}
                  rules={[
                    {
                      required: true,
                      message: t("Profile.Please input your Phone Number"),
                    },
                  ]}
                >
                  <Input
                    style={{
                      maxWidth: "430px",
                      height: "50px",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#222222",
                      border: "1px solid #e9e9e9",
                      borderRadius: "45px",
                    }}
                    className="input-fields"
                    type="number"
                    pattern="[0-9]*"
                    value={userInfo?.phone_number}
                  />
                </Form.Item>
                <p className="input-titles">
                  {t("Profile.Choose a country code")}
                </p>
                <Form.Item
                  name="countrycode"
                  value={data.countrycode}
                  id="countrycode"
                  rules={[
                    {
                      required: true,
                      message: t("Profile.Please select your Countries"),
                    },
                  ]}
                >
                  <Select
                    placeholder="Select country"
                    style={{
                      maxWidth: "430px",
                      height: "50px",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#222222",
                      border: "1px solid #e9e9e9",
                      borderRadius: "45px",
                    }}
                    value={userInfo?.country}
                    onChange={(e) => {
                      // console.log('countrycode e:', e);
                      setData({ ...data, countrycode: e });
                    }}
                    bordered={false}
                  >
                    {getCountry.map((value, index) => {
                      return <Option value={value.label}>{value.label}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <p className="input-titles">
                  {t("Profile.Country Of Residence")}
                </p>
                <Form.Item
                  name="residence"
                  id="residence"
                  rules={[
                    {
                      required: true,
                      message: t(
                        "Profile.Please select your Country Of Residence"
                      ),
                    },
                  ]}
                >
                  <Select
                    placeholder="Select country"
                    style={{
                      maxWidth: "430px",
                      height: "50px",
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "14px",
                      color: "#222222",
                      border: "1px solid #e9e9e9",
                      borderRadius: "45px",
                    }}
                    className="input-fields"
                    onChange={(e) => {
                      // console.log('residence e:', e);
                      setData({ ...data, residence: e });
                    }}
                    bordered={false}
                  >
                    {getCountry.map((value, index) => {
                      return <Option value={value.label}>{value.label}</Option>;
                    })}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <button
                    className="profile-btn"
                    type="primary"
                    htmlType="submit"
                    id="continue"
                  >
                    {t("Profile.UPDATE PROFILE")}
                  </button>
                </Form.Item>
              </Form>
            </div>

            <div class="col-12 col-lg-6">
              <div class="profile-right-side">
                <br />
                <br />
                <p className="profile-tagline">
                  {t("Security.Completed and canceled trade can be found here")}
                </p>

                <a href="/dashboard/my-account" style={{ color: "#000" }}>
                  <button className="right-side-btn">
                    {" "}
                    {t("Profile.Dashboard")}
                  </button>
                </a>
                <p className="profile-tagline">
                  {" "}
                  {t("Security.You can find all notification here")}
                </p>
                <a href="/notiall" style={{ color: "#000" }}>
                  <button className="right-side-btn">
                    {t("Profile.Notifications")}
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={10000} style={{ marginTop: "60px" }} />
      </div>

      <SectionSix />
    </>
  );
};

export default Account;