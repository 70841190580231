import React, { useEffect, state, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import Location from "../../assets/icons/location.svg";
import Email from "../../assets/icons/email.svg";
import Facebook from "../../assets/icons/facebook.svg";
import GooglePlay from "../../assets/icons/googleStore.svg";
import AppleStore from "../../assets/icons/appleStore.svg";
import Twitter from "../../assets/icons/twitter.svg";
import Instagram from "../../assets/icons/Instagram.svg";
import { Form, Input, Button, Select } from "antd";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation, withTranslation } from "react-i18next";
import "./Contact.css";
import { Link } from "react-router-dom";
import SectionSix from "../../components/Home/sections/sectionsix";

const { Option } = Select;
const baseURL = process.env.REACT_APP_BASE_URL;

const Contact = (props) => {
    const [data, setData] = useState({
        email: "",
        subject: "",
        message: "",
    });

    useEffect(() => {
        if (sessionStorage.getItem("Sendmsg")) {
            toast.success(sessionStorage.getItem("Sendmsg"));
            sessionStorage.removeItem("Sendmsg");
        }
    }, []);

    const [PendingMsg, setPendingMsg] = useState();
    const submit = (e) => {
        // e.preventDefault();
        // const button = document.getElementById('continue');
        // button.disabled = true
        // setPendingMsg("t("notification.Please wait while your transaction is processing")!");
        const sendData = {
            email: data.email,
            subject: data.subject,
            message: data.message,
        };
        // console.log('sendata', sendData);
        Axios.post(`${baseURL}/api/support/sendsupportmsg`, sendData)
            .then((res) => {
                // console.log("res.data.......", res.data);
                sessionStorage.setItem(
                    "Sendmsg",
                    t("contactdata.message sent successfully")
                );
                window.location.reload();
            })
            .catch((error) => {
                // console.log("error:", error);
                // props.history.push("/wallet/Transactions");
                toast.error(t("contactdata.Error in Submission"));
            });
    };

    function handle(e) {
        // console.log('e.target:',e);
        const newdata = { ...data };
        newdata[e.target.id] = e.target.value;
        setData(newdata);
        // console.log("newdata......", newdata);
    }

    const [t, i18n] = useTranslation("common");
    return (
        <>
            <div className="contact-page">
                <Authcheck />
                <div className="contact-upper"></div>
                <div className="signup-page">
                    <div className="signup-upper"></div>
                    <div className="signup-container">
                        <div className="left-panel">
                            <h4 className="signup-header">{t("contactdata.ContactUs")}</h4>
                            <Form
                                className="sign-form"
                                onFinish={(e) => {
                                    submit(e);
                                }}
                            >
                                <Form.Item
                                    label={t("contactdata.Email")}
                                    name="email"
                                    className="input-headers"
                                    onChange={(e) => handle(e)}
                                    id="email"
                                    defaultValue={data.email}
                                    rules={[
                                        {
                                            required: true,
                                            message: t("contactdata.Email is Required"),
                                        },
                                    ]}
                                />
                                <input className="input-field" placeholder="Enter your email" />
                                <Form.Item
                                    className="input-headers"
                                    label={t("contactdata.Subject")}
                                    name="subject"
                                    onChange={(e) => handle(e)}
                                    id="subject"
                                    value="data.subject"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("contactdata.Subject is Required"),
                                        },
                                    ]}
                                />
                                <input
                                    className="input-field"
                                    placeholder="Enter your subject"
                                />
                                <Form.Item
                                    label={t("contactdata.Message")}
                                    name="message"
                                    className="input-headers"
                                    onChange={(e) => handle(e)}
                                    id="message"
                                    value="data.message"
                                    rules={[
                                        {
                                            required: true,
                                            message: t("contactdata.Message is Required"),
                                        },
                                    ]}
                                />
                                <textarea
                                    className="textarea"
                                    placeholder="Enter your message"
                                />

                                <Form.Item>
                                    <button
                                        style={{ marginTop: "60px" }}
                                        htmlType="submit"
                                        className="signup-form-btn"
                                        id="continue"
                                    >
                                        {t("wallet.CONTINUE")}
                                    </button>
                                </Form.Item>
                            </Form>
                        </div>

                        <div class="signup-form-right">
                            <div className="right-panel">
                                <h5 className="right-panel-heading"> Get in touch</h5>
                                <br />
                                <p className="right-panel-sub-heading">
                                    <img
                                        alt="icon"
                                        src={Location}
                                        style={{
                                            width: "13px",
                                            height: "10px",
                                            marginRight: "14px",
                                        }}
                                    />
                                    {t("21, Vasili Michallidi, 3020 Limassol, Cyprus")}
                                </p>
                                <br />
                                <p className="right-panel-sub-heading">
                                    <img
                                        alt="icon"
                                        src={Email}
                                        style={{
                                            width: "13px",
                                            height: "10px",
                                            marginRight: "14px",
                                        }}
                                    />{" "}
                                    {t("walletdays@gmail.com")}{" "}
                                </p>
                                <div
                                    style={{
                                        marginTop: "20px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    {" "}
                                    <img
                                        alt="icon"
                                        src={Facebook}
                                        style={{
                                            marginRight: "14px",
                                        }}
                                    />{" "}
                                    <img
                                        alt="icon"
                                        src={Instagram}
                                        style={{
                                            marginRight: "14px",
                                        }}
                                    />{" "}
                                    <img
                                        alt="icon"
                                        src={Twitter}
                                        style={{
                                            marginRight: "14px",
                                        }}
                                    />
                                </div>
                                <div
                                    style={{
                                        marginTop: "20px",
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                >
                                    {" "}
                                    <img
                                        alt="icon"
                                        src={GooglePlay}
                                        style={{
                                            marginRight: "10px",
                                        }}
                                    />{" "}
                                    <img
                                        alt="icon"
                                        src={AppleStore}
                                        style={{
                                            marginRight: "14px",
                                        }}
                                    />{" "}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer autoClose={10000} style={{ marginTop: "60px" }} />

            <SectionSix />
        </>
    );
};

export default Contact;