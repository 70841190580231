import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import Kyccheck from '../../components/Kyccheck/Kyccheck';
import { Route, Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import "./Guides.scss";

const Account = (props) => {

  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">
          <Kyccheck />
               <div className="guide-container">
               <div id="guide" className="col-xs-12 col-sm-7 col-md-8 col-lg-10">
        <h3 className="mb-2">Wowcoin Guide to Account Verification</h3>
        <span id="toc0" /><h4 className="mb-4">Why is verification needed?</h4>
        <p>
          In order to comply with the applicable legal requirements and to meet anti-money laundering and terrorism funding prevention regulations’ criteria, Wowcoins requires verification to know who its users are. Wowcoin user verification system promotes a safer marketplace for every user by increasing the overall trust and inhibiting the usage of Wowcoin account for illicit purposes. Account verification is also an essential personal security feature, as it allows account recovery in case access is lost for any reason.
        </p>
        <p>
          In general terms, Wowcoin verification system works progressively, i.e. it requires more detailed verification items according to the user’s level of activity in the platform. All Wowcoin account verification data is stored and handled in compliance with the relevant legislation. More details can be found in <a href="/privacy_policy/">our Privacy Policy</a>.
        </p>
        <span id="toc1" /><h4 className="mb-4">How to verify?</h4>
        <p>
          Your Wowcoin account verification status can be seen and updated through the <a href="/settings/verification/">Verification tab</a> of your account’s settings.
        </p>
        <p>
          There you will find information on your incoming trade and transaction volume, which is used to calculate your account’s Tier level, as well as the list of your completed and (if any) pending verification items per Tier. By clicking the button “Proceed to the next level” in the Verification tab, you are able to submit the required verification items
        </p>
        <span id="toc2" /><h4 className="mb-4">Account level’s description and requirements</h4>
        <p>
          Wowcoins has 4 account verification Tiers: T0, T1, T2 and T3.
        </p>
        <p>
          T0 accounts, i.e. accounts that have completed Onboarding only, are able to buy Bitcoins and sell Bitcoins purchased in Wowcoins by replying to advertisements created by other users. In order to deposit Bitcoins in a Wowcoins receiving wallet address and create advertisements, an account must be at least T1 verified. T1, T2 and T3 accounts are able to use all wallet, advertising and trade features in Wowcoins.
        </p>
        <p>
          In the vast majority of cases, an account’s Tier is determined by its annual incoming trade and transaction volumes, i.e. how much has been credited to an account within the past 12 months including buy trades and received Bitcoin transactions. The next Tier verification is required when incoming trade and transaction volume reaches the current Tier limit. However, an account might be pushed to one of the following Tiers or required to complete additional verification under certain special cases. Accounts that have not completed the required Tier verification cannot use Wowcoin wallet, advertising and trade services.
        </p>
        <div className="table-responsive">
          <table className="table table-hover table-borderless table-striped">
            <thead className="thead">
              <tr style={{backgroundColor: '#284d6b', color: 'white'}}>
                <th scope="col" />
                <th scope="col">T0</th>
                <th scope="col">T1</th>
                <th scope="col">T2</th>
                <th scope="col">T3</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Full name</td>
                <td>●</td>
                <td>●</td>
                <td>●</td>
                <td>●</td>
              </tr>
              <tr>
                <td>Country of residence</td>
                <td>●</td>
                <td>●</td>
                <td>●</td>
                <td>●</td>
              </tr>
              <tr>
                <td>Email address</td>
                <td>●</td>
                <td>●</td>
                <td>●</td>
                <td>●</td>
              </tr>
              <tr>
                <td>Phone number</td>
                <td>●</td>
                <td>●</td>
                <td>●</td>
                <td>●</td>
              </tr>
              <tr>
                <td>Physical address check</td>
                <td />
                <td>●</td>
                <td>●</td>
                <td>●</td>
              </tr>
              <tr>
                <td>ID verification</td>
                <td />
                <td>●</td>
                <td>●</td>
                <td>●</td>
              </tr>
              <tr>
                <td>KYC info</td>
                <td />
                <td>●</td>
                <td>●</td>
                <td>●</td>
              </tr>
              <tr>
                <td>Proof of residence</td>
                <td />
                <td />
                <td>●</td>
                <td>●</td>
              </tr>
              <tr>
                <td>Extra ID verification</td>
                <td />
                <td />
                <td />
                <td>●</td>
              </tr>
            </tbody>
          </table>
        </div>
        <span id="toc3" /><h4 className="mb-4">Verification progress per Tier</h4>
        <span id="toc4" /><h3>T0</h3>
        <p>
          Accounts that have successfully completed Onboarding checks, i.e. have verified their email address and phone number and provided user’s full name and country of residence, are T0 verified.
        </p>
        <p>
          T0 accounts are apt to complete buy trades and sell trades by replying to offers posted by other users, and to send out Bitcoin transactions to other Bitcoin wallet addresses. In order to have a Wowcoins receiving wallet address and create ads in Wowcoins, accounts must be at least T1 verified.
        </p>
        <span id="toc5" /><h3>T1</h3>
        <p>
          T1 verification is meant for users who intend to buy and/or receive more than 1000 € but less than 20000 € annually and have already completed T0 verification, i.e. Onboarding, successfully. When verifying for T1, users will be asked to answer a short questionnaire, to inform their address and to verify their identity.
        </p>
        <p>
          When verifying your ID, make sure to use high resolution and unedited pictures of a valid <a href="/accounts/onfido/supported_documents/">supported document</a>. The images must be originals, not scanned or pictures of photocopies. Verifying for T1 is an easy and fast process, but some ID verification attempts might fail due to:
        </p>
        <ul>
          <li>Date of birth missing from document</li>
          <li>Expired document</li>
          <li>Poor image quality</li>
          <li>Unsupported document</li>
        </ul>
        <p>
          If you fail ID verification multiple times, please contact support for more detailed instructions. Note that we do not process ID verification manually but our agents can provide directions on how to proceed in each case.
        </p>
        <span id="toc6" /><h3>T2</h3>
        <p>
          T1 verified users are able to proceed to T2 if they wish to increase their trading limits or if T2 is required by their account’s status, e.g. newly registered company account.
        </p>
        <p>
          After completing T1 verification, users can verify for T2 by replying to a questionnaire and submitting a proof of address.
        </p>
        <p>
          Documents submitted as proof of address must be legible and preferably written in Latin characters. Documents in Russian, Chinese and Arabic are also accepted. The document must contain a date of issue no older than 3 months, the user’s real name and an address matching the account’s info. The following documents are generally accepted as proof of address:
        </p>
        <ul>
          <li>Bank statement</li>
          <li>Credit card statement</li>
          <li>Utility bill (such as gas, water, electricity)</li>
          <li>Phone bill (landline)</li>
          <li>Tax return</li>
        </ul>
        <p>
          In case your proof of address has been rejected, please try again to verify making sure that:
        </p>
        <ul>
          <li>The file is PNG or JPG</li>
          <li>The document type is supported</li>
          <li>The language and the address format are compatible with the information you provided previously</li>
          <li>The document is not older than 3 months.</li>
        </ul>
        <p>
          In some countries, there are distinct requirements for the documents accepted as proof of address, as listed below:
        </p>
        <div className="table-responsive">
          <table className="table table-hover table-borderless table-striped">
            <thead>
              <tr style={{backgroundColor: '#284d6b', color: 'white'}}>
                <th scope="col">Countries</th>
                <th scope="col">Supported documents</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>China</td>
                <td>
                  A tax invoice of utility bill (electricity or water) stamped by local tax bureau and local utility provider.
                </td>
              </tr>
              <tr>
                <td>Ghana</td>
                <td>
                  Bank account statement stamped and signed by the bank as a certified (notarized) true copy of the statement.
                </td>
              </tr>
              <tr>
                <td>Kenya</td>
                <td>
                  KRA vertificate or tax return.<br /> ZUKU (cable TV and internet provider) bill.<br /> KPLC (Kenya Power) Post pay power bill.
                </td>
              </tr>
              <tr>
                <td>Morocco</td>
                <td>
                  Electricity or water bill Landline phone bill Certificate of residence provided by Le Caïdat or the official body governing the place of residency
                </td>
              </tr>
              <tr>
                <td>Namibia</td>
                <td>
                  A Bank account statement with home address signed and stamped by the bank
                </td>
              </tr>
              <tr>
                <td>Nigeria</td>
                <td>
                  Bank account statement stamped and signed by the bank as a certified (notarized) true copy of the statement.<br /> Multichoice (DSTV) invoice.<br /> Power bill from Eko Electricity distribution company (EKEDC).
                </td>
              </tr>
              <tr>
                <td>South Africa</td>
                <td>
                  Bank account statement
                </td>
              </tr>
              <tr>
                <td>Venezuela</td>
                <td>
                  Utility bills.<br /> Bank account statement.<br /> Tax return.<br /> Tax registration card (RIF), the last modified date should not be older than 3 months.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span id="toc7" /><h3>T3</h3>
        <p>
          T2 verified users are able to verify for T3 once they reach 100000 € volume or in case the account has a status that falls into <a href="#special-cases">special cases</a>.
        </p>
        <p>
          T3 verification can be completed by passing a liveness test and filling up an additional questionnaire and submitting the required supporting documents. Like ID verification for T1, the liveness test is a hassle-free process but the most common issues reported and their solutions are listed below:
        </p>
        <ul>
          <li>Unsupported device</li>
          <ul><li>Make sure you are not using iPhone or iPad devices</li></ul>
          <li>Unsupported browser</li>
          <ul><li>Use only Chrome or Firefox browsers during the liveness test</li></ul>
          <li>Your device does not allow video recording</li>
          <ul><li>Make sure your device has a camera and that you have given permission for your device to access the camera and record a video</li></ul>
          <li>A browser extension is interfering with the camera</li>
          <ul><li>Make sure you have no browser extensions that might block access to the camera on your device</li></ul>
        </ul>
        <p>
          T3 questionnaires are reviewed manually. The application might take some time to be approved and our agents can request further clarification through the verification form. For this reason, we recommend that the application is submitted in a timely manner, before your account reaches the T2 limit.
        </p>
        <span id="toc8" /><h4>Company verification</h4>
        <p>
          You can operate as a company on Wowcoins, and provide and receive payments for your trades using your company’s payment details. Operating as a company works also well for sole entrepreneurs, for example.
        </p>
        <img src="/cached-static/img/guides/register_picture.873185eb9d2c.png" style={{maxWidth: '100%', maxHeight: '410px', margin: '1em 2em 2em 0', borderRadius: '4px', boxShadow: '0 0 8px -2px #333'}} />
        <p>
          To start trading as a company on Wowcoins, you can create a new company account. By creating a new company account, you must provide verification as the owner of the Master company account, i.e. the account operated by the main company representative.
        </p>
        <p>
          While multiple accounts are forbidden, it is allowed to have a personal and a company account as long as the payments are processed using details compatible with the account verification type, i.e. Wowcoins personal account completes payments with personal account and Wowcoins company account sends and receives payments with company details.
        </p>
        <p>
          If you have a personal account and want to use your company's payment details in trades you can create a new account as a master account for the company, so you will start using 2 accounts after that. This way you have to use only your personal payment details for your first account and only your company payment details for your company account.
        </p>
        <span id="toc9" /><h3>Tier A and Tier B verifications</h3>
        <p>
          Wowcoins has 2 account verification Tiers for the companies: Tier A and Tier B.
        </p>
        <ul>
          <li>Tier A for the start of the account’s activity,</li>
          <li>Tier B when the annual trading volume reaches 500,000 EUR.</li>
        </ul>
        <p>
          In both levels, the company’s representative should fill out the verification application form and provide supporting documents.
        </p>
        <p>
          The verification process for Master company account <b>requires T1 and T2 personal verification</b> before the submission of company information and documents for <b>Tier A</b> verification.
        </p>
        <p>
          This means that you can’t proceed with submitting your company’s documents before verifying the account as Tier 2. To complete a Master company account verification, your company must belong to your national company register and you must be the person legally responsible for the company or an employee authorized by the company to operate the main account.
        </p>
        <p>
          For Tier A verification, companies should provide
        </p>
        <ul>
          <li>Descriptive information</li>
          <li>Company’s registration documents</li>
          <li>Information about its beneficial owners</li>
          <li>Information about the board of directors of the company (if applicable)</li>
        </ul>
        <p>
          For Tier B verification, companies are being asked further information about the company’s activity and financial status:
        </p>
        <ul>
          <li>Last financial statement (it should be provided if it has been prepared according to the national law)</li>
          <li>Bank account statement</li>
          <li>Auditor’s report (if applicable)</li>
        </ul>
        <p>
          Once the application for company verification is submitted, all related information and questions should be sent through the application form itself.
        </p>
        <span id="toc10" /><h3>Company sub-accounts</h3>
        <p>
          You can create separate accounts for your employees (company sub-accounts) so that they can operate as the company account with their personal accounts.
        </p>
        <p>
          Employees can create their own personal accounts, verify them as T0, and after that they can join the company account as sub-accounts.
        </p>
        <p>
          To operate as a company sub-account, your company needs to have an existing company account. The sub-accounts need to be registered through the company’s Master account.
        </p>
        <p>
          A company account’s volume is calculated as the sum of the volumes of the Master account and its sub-accounts.
        </p>
        <span id="toc11" /><h4 id="special-cases">Special cases</h4>
        <p>
          Wowcoins might provide restricted or no service to residents of specific regions due to security and legal reasons. Geographic restrictions are applied according to lists defined by the relevant authorities and might be subject to updates from time to time.
        </p>
        <p>
          Wowcoins is currently <b>unable to provide service</b> to residents of:
        </p>
        <ul>
          <li>Cuba</li>
          <li>North Korea</li>
          <li>Iran</li>
          <li>Syria</li>
        </ul>
        <p>
          The restriction applies to residents of those countries and states, but nationals of non-served countries are able to use Wowcoins services if they reside in another country.
        </p>
        In the United States, Wowcoins provides service <b>only</b> to residents of:
        <p />
        <ul>
          <li>Illinois</li>
          <li>Kansas</li>
          <li>Montana</li>
          <li>New Hampshire</li>
          <li>New Jersey</li>
          <li>Pennsylvania</li>
          <li>Tennessee</li>
          <li>Texas</li>
          <li>Virginia</li>
          <li>Wisconsin</li>
        </ul>
        <p>
          Residents of the following countries are required to verify for <b>Tier 1</b> before they can start trading on the platform:
        </p>
        <ul>
          <li>Australia</li>
          <li>Belarus</li>
          <li>China</li>
          <li>Curaçao</li>
          <li>French Guiana</li>
          <li>French Polynesia</li>
          <li>Indonesia</li>
          <li>Kuwait</li>
          <li>Lebanon</li>
          <li>Maldives</li>
          <li>Mexico</li>
          <li>Oman</li>
          <li>Russia</li>
          <li>South Africa</li>
          <li>Sweden</li>
          <li>Turkey</li>
          <li>United Arab Emirates</li>
        </ul>
        <p>
          Wowcoins provides <b>service upon the submission of additional verification</b> to residents of:
        </p>
        <ul>
          <li>Afghanistan</li>
          <li>Bahamas</li>
          <li>Barbados</li>
          <li>Botswana</li>
          <li>Cambodia</li>
          <li>Ghana</li>
          <li>Iraq</li>
          <li>Jamaica</li>
          <li>Mauritius</li>
          <li>Mongolia</li>
          <li>Myanmar</li>
          <li>Nicaragua</li>
          <li>Pakistan</li>
          <li>Panama</li>
          <li>Trinidad and Tobago</li>
          <li>Uganda</li>
          <li>Vanuatu</li>
          <li>Yemen</li>
          <li>Zimbabwe</li>
        </ul>
        <p>
          Additionally, Wowcoins has an internal risk management system that identifies activities and profiles deemed as high risk from a security and/or legal standpoint.
        </p>
        When an account is flagged by our risk management system, additional verification items might be required in order to activate the account. In exceptional cases, Wowcoins might refuse to provide service to accounts that impose too high a risk to the services.
        <p />
      </div>
                  </div>
                </div>
              </div>
            </div>
    </section>
          );
};

          export default Account;
