import React, { useEffect, useState } from "react";
import "./sections.css";
import Section3 from "../../../assets/backgrounds/section-3.svg";
import WalletIcon from "../../../assets/icons/wallet-icon.svg";
import WalletIcon2 from "../../../assets/icons/wallet-icon-2.svg";
import WalletIcon3 from "../../../assets/icons/wallet-icon-3.svg";
import "antd/dist/antd.css";
import { useTranslation } from "react-i18next";
import { Auth, authData } from "../../../auth/Auth";
import { balanceSub, getWowBalance } from "../../../helpers/CallAPi";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

function SectionThree() {
  const username = localStorage.getItem("username");
  const activeOffers = useSelector((state) => {
    if (state.myOffers.myOffers === undefined) {
      return 0;
    }
    return state.myOffers.myOffers.reduce((acc, cur) => {
      cur.pause === false ? (acc += 1) : (acc += 0);
      return acc;
    }, 0);
  });

  const [WalletBalance, setWalletBalance] = useState();
  const [isDisplayNav, setIsDisplayNav] = useState(true);
  const dispatch = useDispatch();
  const UserId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [notiPerson, setNotiperson] = useState();
  const [open, setOpen] = useState(false);

  const changeLanguage = (lng) => {
    localStorage.setItem("lng", lng);
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    let lng = localStorage.getItem("lng");
    if (lng) {
      i18n.changeLanguage(lng);
    }
    checkAuth();
  }, []);

  useEffect(() => {
    if (UserId) {
      try {
        balanceSub.subscribe((res) => {
          const UserId = localStorage.getItem("userId");
          if (res == null && UserId) {
            getWowBalance(UserId);
          } else {
            setWalletBalance(res);
          }
        });
      } catch (error) {}
    }
    setInterval(() => {
      try {
        balanceSub.subscribe((res) => {
          const UserId = localStorage.getItem("userId");
          if (res == null && UserId) {
            getWowBalance(UserId);
          } else {
            setWalletBalance(res);
          }
        });
      } catch (error) {}
    }, 5000);
  });

  const checkAuth = () => {
    authData.subscribe((res) => {
      if (res == null) {
        Auth.isAuthenticated();
      } else {
        setIsDisplayNav(res);
      }
    });
  };

  const [t, i18n] = useTranslation("common");
  return (
    <div className="section3">
      <div className="section3-left-side">
        <p className="section3-heading">{t("Home.Wowcoin World Wide")}</p>
        <p className="section3-sub-heading">
          {t("Home.WalletDays provides a secure coin wallet, sells Wowcoin at a desired price, and provides various payment methods")}
        </p>
        <div className="feature-wrap">
          <div className="feature-icon">
            <img className="feature-img" alt="icon" src={WalletIcon} />
          </div>

          <div className="sub-feature-wrap">
            <p className="feature-heading">{t("Home.Secure Crypto Wallet")}</p>
            <p className="feature-sub-heading">
              {t("Home.We provide a safe coin wallet")}
            </p>
          </div>
        </div>
        <div className="feature-wrap">
          <div className="feature-icon">
            <img className="feature-img" alt="icon" src={WalletIcon2} />
          </div>

          <div className="sub-feature-wrap">
            <p className="feature-heading">
              {t("WowcoinWorldWide.Sell WOW coin")}
            </p>
            <p className="feature-sub-heading">
              {t(
                "WowcoinWorldWide.Sell WOW coins at the price you want and receive payment through various payment methods"
              )}
            </p>
          </div>
        </div>
        <div className="feature-wrap">
          <div className="feature-icon">
            <img className="feature-img" alt="icon" src={WalletIcon3} />
          </div>

          <div className="sub-feature-wrap">
            <p className="feature-heading">
              {t("WowcoinWorldWide.Various translations")}
            </p>
            <p className="feature-sub-heading">
              {t(
                "WowcoinWorldWide.Interfaces translated into various languages"
              )}
            </p>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            maxWidth: "245px",
          }}
        >
          {!isDisplayNav ? (
            <Link to="/signup">
              <button className="section-3-register">
                {t("menu.Register")}
              </button>
            </Link>
          ) : (
            ""
          )}
          {!isDisplayNav ? (
            <Link to="/signup">
              {" "}
              <button className="section-3-show">
                {t("Myoffers.View more")}
              </button>
            </Link>
          ) : (
            ""
          )}
        </div>
      </div>
      <img className="section3-right" src={Section3} alt="section3" />
    </div>
  );
}

export default SectionThree;
