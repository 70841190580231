import React, { useEffect, useState, useContext } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Form, Input, Button, Select, Spin } from "antd";
import { Link, NavLink, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Axios from "axios";
import { Breadcrumb } from "antd";
import { ToastContainer, toast } from "react-toastify";
import { useHistory } from "react-router-dom";
// import online from "../../views/Marketing/img/black-circle.png";
// import offline from "../../views/Marketing/img/red.png";
import Escrow from "../../components/Escrow/Escrow";
import { useTranslation } from "react-i18next";
import { getWowBalance } from "../../helpers/CallAPi";
// import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import {
  handleComplete,
  handleCancel,
  handlePaid,
  handleDispute,
} from "../../store/actions/ordersActions";
import "./OrderInfo.scss";
//import Feedback from "../../views/Feedback/Feedback";
import { parseTwoDigitYear } from "moment";
import { Auth, authData } from "../../auth/Auth";
import { TimeContext } from "../../helpers/TimeContext";

import ChatText from "./ChatText";
import Feedback from "./Feedback";
import { LoadingOutlined } from "@ant-design/icons";
import "./OrderInfo.css";
import { Alert } from "react-bootstrap";
const baseURL = process.env.REACT_APP_BASE_URL;
const nf = new Intl.NumberFormat("en-US");

const OrderInfo = ({ order, username, isUserBuying }) => {
  const [t, i18n] = useTranslation("common");
  const {
    bchAmount,
    cancelled,
    complete,
    paid,
    fiatAmount,
    priceBCH,
    userMaker,
    userTaker,
    fiat_amount,
    amount,
    currencyType,
    uniqueid,
    updatedAt,
  } = order;
  // console.log("isUserBuying::::Amrut:::::>>>>>>>>>>",isUserBuying);
  console.log("orderdata", order);

  const dispatch = useDispatch();
  const [geForm, setForm] = useState();
  const [getReportform, SetReportForm] = useState();
  const AuthUser = localStorage.getItem("username");
  const formatValue = parseInt(priceBCH).toFixed(2);
  const formRef = React.useRef(null);
  const [data, setData] = useState({});
  const { fromuser, orderId } = useParams("fromuser", "orderId");
  const offerid = useParams();
  const [allFile, setAllfile] = useState({});
  // console.log("offerid", offerid);
  const userId = localStorage.getItem("userId");
  const offerDetails = useSelector((state) => state.myOffers.offerDetails);
  const [allescrow, setAllEscrow] = useState([]);
  const [WalletBalance, setWalletBalance] = useState();
  const [paidStatus, setPaidstatus] = useState();
  // console.log("paidStatus",paidStatus);
  const [isDisplayNav, setIsDisplayNav] = useState(true);
  const [MakerUsername, setMakerUsername] = useState();
  const [userOnline, setUseronline] = useState();
  const [getMinute, setMinute] = useState();
  const [ratemessage, setMessage] = useState();

  const [feedbackdata, setFeedbackdata] = useState({});
  const [chatMessages, setchatMessages] = useState([]);
  const [tradeDetail, setTradedetail] = useState({});
  const [Offerdetail, setOfferdetail] = useState({});
  //console.log("chatMessages",chatMessages)
  let history = useHistory();
  // const { offerId, type } = useParams("id");
  const params = useParams();
  const noti_insta = params.main_type;
  const uniqueId = params.uniqueId;
  const token = localStorage.getItem("token");
  const [escrowbalance, setEscrowBalance] = useState("");

  const {
    limitMax,
    limitMin,
    currencySymbol,
    margin,
    marginAbove,
    makerId,
    id,
    buyBCH,
  } = offerDetails;

  const { time, setTime } = useContext(TimeContext);

  console.log("time....", time);

  useEffect(() => {
    if (localStorage.getItem("Canceltrade")) {
      toast.success(t("newoffers." + sessionStorage.getItem("Canceltrade")));
      localStorage.removeItem("Canceltrade");
    }

    if (localStorage.getItem("Canceltradeerror")) {
      toast.success(
        t("newoffers." + sessionStorage.getItem("Canceltradeerror"))
      );
      localStorage.removeItem("Canceltradeerror");
    }

    try {
      Axios.get(`${baseURL}/api/orders/get_order_detail/${uniqueId}`).then(
        (response) => {
          console.log("orderdetail", response.data.data);
          setTradedetail({
            ...response.data.data,
            bankname: null,
            bank_name_account: null,
            bank_account_number: null,
          });
          fetchBankDetails(response.data.data);
          if (userId === response.data.data.taker_id) {
            UserOnline(response.data.data.maker_id);
          } else {
            UserOnline(response.data.data.taker_id);
          }
          OfferData(response.data.data.offer_id);
          OrderData(response.data.data.id);
        }
      );

      Axios.get(`${baseURL}/api/orders/get_order_minites/${uniqueId}`).then(
        (response) => {
          const orderminites = response.data.minite;
          if (orderminites < 90) {
            let timeleft = 90 - orderminites;

            setTime(timeleft);

            var downloadTimer = setInterval(function () {
              if (timeleft <= 0) {
                clearInterval(downloadTimer);
              }
              setTime(timeleft);
              timeleft -= 1;
            }, 60000);
          } else {
            let timeleft = 0;
            setTime(timeleft);
          }

          // setTradedetail(response.data.data);
          //  / OfferData(response.data.data.offer_id);
        }
      );

      escrowData();

      Axios.get(`${baseURL}/api/wallet/p1_list_escrocoin/${userId}`).then(
        (response) => {
          setAllEscrow(response.data.data);
          // console.log("Allescrow........", response.data);
        }
      );

      Axios.get(`${baseURL}/api/auth/useraccount/${makerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(
        async (response) => {
          setMakerUsername(response.data);
        },
        (error) => {
          // console.log(error);
        }
      );

      Axios.get(`${baseURL}/api/auth/getuserbalance/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        // console.log("response..................", res.data.balance);

        setInterval(() => {
          setWalletBalance(res.data.balance);
          // console.log("chetnasdsssssss", res.data.balance)
        }, 1000);

        // console.log("res_offer", res.data.balance);
      });

      authData.subscribe((res) => {
        if (res == null) {
          Auth.isAuthenticated();
        } else {
          setIsDisplayNav(res);
        }
      });
    } catch (error) {
      // console.log(error);
    }
    // console.log("tradeuserid", userId);
    // console.log("tradeorder.id", makerId);
    // console.log("tradeparams.id", params.id);

    paidInstance();
    if (sessionStorage.getItem("disputemsg")) {
      toast.success(t("newoffers." + sessionStorage.getItem("disputemsg")));
      sessionStorage.removeItem("disputemsg");
    }
    if (sessionStorage.getItem("Approve")) {
      toast.success(sessionStorage.getItem("Approve"));
      sessionStorage.removeItem("Approve");
    }

    if (sessionStorage.getItem("Approveerror")) {
      toast.error(sessionStorage.getItem("Approveerror"));
      sessionStorage.removeItem("Approveerror");
    }
  }, []);

  const fetchBankDetails = async (tradeInfo) => {
    try {
      const { txn_type, userid, maker_id, taker_id, is_maker_buying } =
        tradeInfo;

      console.log("TRRRRR ==", tradeInfo);

      // const idToFetch = txn_type === "0" ? userid === taker_id ? maker_id : taker_id : userid;

      const idToFetch =
        is_maker_buying === 1
          ? userid === taker_id
            ? taker_id
            : maker_id
          : userid === taker_id
          ? taker_id
          : maker_id;

      console.log("IDDDDDDD ==", userid, taker_id, idToFetch);

      Axios.get(`${baseURL}/api/auth/get_user_bank_details/${idToFetch}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(
        async (response) => {
          const { Bank_name, Beneficiary_name, Iban } = response.data.data;
          if (Bank_name) {
            console.log("RESPONSE =======", Bank_name, tradeInfo);
            setTradedetail({
              ...tradeInfo,
              bankname: Bank_name,
              bank_name_account: Beneficiary_name,
              bank_account_number: Iban,
            });
          } else {
            setTradedetail({
              ...tradeInfo,
              bankname: null,
              bank_name_account: null,
              bank_account_number: null,
            });
          }
        },
        (error) => {
          console.log("RESPONSE =====", error);
        }
      );
    } catch (err) {
      console.log("RESPONSE =====", err);
    }
  };

  const UserOnline = async (id) => {
    await Axios.get(`${baseURL}/api/auth/get_use_online_status/${id}`).then(
      (response) => {
        setUseronline(response.data.user_login);
        console.log("UserOnline........", response.data.user_login);
      }
    );
  };

  const escrowData = async () => {
    await Axios.get(
      `${baseURL}/api/wallet/get_orderecrobalance/${uniqueId}`
    ).then((response) => {
      setEscrowBalance(response.data.data);
      console.log("escrowbalTotal........", response.data.data);
    });
  };

  const OfferData = async (offer_id) => {
    console.log("setoffer_id........", offer_id);
    await Axios.get(`${baseURL}/api/offers/get_offer_detail/${offer_id}`)
      .then((response) => {
        console.log("setOfferdetail........", response.data.data);
        setOfferdetail(response.data.data);
      })
      .catch((e) => {
        console.log("userData e:", e);
      });
  };

  const OrderData = async (order_id) => {
    console.log("setchatorderId", order_id);
    await Axios.get(`${baseURL}/api/chat/dispute_getmsg/${order_id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((response) => {
      //console.log("setchatMessages",response.data);
      setchatMessages(response.data);
    });
  };

  const paidInstance = () => {
    Axios.get(`${baseURL}/api/notifications/get_am_paid/${uniqueId}`)
      .then((response) => {
        // console.log("Paid balance", response.data);
        setPaidstatus(response.data.paid);
      })
      .catch((err) => {
        // console.log("paiderr", err);
      });
  };

  const submit = (e) => {
    const userId = localStorage.getItem("userId");
    // console.log("sendData", order);
    console.log("order.id", `${order.id}`);
    order.offerid = params.id;
    order.user_id = userId;
    order.dispute_msg = data.disputemsg;

    // console.log('order:', order);
    Axios.post(
      `${baseURL}/api/orders/senddisput/${tradeDetail.id}/${userId}`,
      order,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        setData({ ...data, dispSetDisputeStatusutemsg: "" });
        formRef.current.setFieldsValue({ disputemsg: "" });
        toast.success(`${res.data.msg}`);
        // console.log("res.data.......", res.data);
        window.location.reload();
      })
      .catch((error) => {
        // console.log("error:", error);
        // console.log('formRef.current.value:',formRef.current.getFieldsValue());
        setData({ ...data, dispSetDisputeStatusutemsg: "" });
        formRef.current.setFieldsValue({ disputemsg: "" });
        toast.error(t("newoffers.Dispute Submitted Failed"));
        //window.location.reload();
      });
  };

  const disputmsgsubmit = (e) => {
    const userId = localStorage.getItem("userId");
    // console.log("sendData", order);
    console.log("order.id", `${order.id}`);
    //order.offerid = params.id;
    //order.userid = userId;
    //order.text = data.disputemsg;

    const formData = new FormData();

    formData.append("documentfile", allFile.selectedFile);
    formData.append("offerid", params.id);
    formData.append("userid", userId);
    formData.append("text", data.disputemsg);

    Axios.post(`${baseURL}/api/chat/disputechat/${tradeDetail.id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
    })
      .then((res) => {
        setData({ ...data, dispSetDisputeStatusutemsg: "" });
        formRef.current.setFieldsValue({ disputemsg: "" });
        document.getElementById("disputefilename").innerHTML = "";
        console.log("res.data.......", res.data);
        sessionStorage.setItem("disputemsg", `${res.data.message}`);
        window.location.reload();
      })
      .catch((error) => {
        // console.log("error:", error);
        // console.log('formRef.current.value:',formRef.current.getFieldsValue());
        setData({ ...data, dispSetDisputeStatusutemsg: "" });
        formRef.current.setFieldsValue({ disputemsg: "" });
        document.getElementById("disputefilename").innerHTML = "";
        toast.error(t("newoffers.Dispute Submitted Failed"));
        //window.location.reload();
      });
  };

  const submit_ = (e) => {
    const UserId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const send_ = {
      userid: UserId,
      offerid: orderId,
      //message: data.message
    };
    // console.log("senddata", send_);
    Axios.post(`${baseURL}/api/orders/report_user`, send_, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        toast.success(`${res.data.message}`);
        // console.log("res.data.......", res.data);
      })
      .catch((error) => {
        // console.log("error:", error);
        toast.error(`${error}`);
      });
  };

  const cancelTrade = () => {
    // console.log("cancel trade");
    let data_ = {
      uniqueId: uniqueId,
      userId: userId,
    };
    // Axios.post(`${baseURL}/api/wallet/buy_get_last_trade/${uniqueId}`, data_)
    Axios.post(
      `${baseURL}/api/wallet/delete_transaction_from_p2_p1/${uniqueId}/${userId}`,
      data_,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response) => {
        console.log(response);
        sessionStorage.setItem(
          "Canceltrade",
          t("newoffers.Trade Cancelled Successfully")
        );
        window.location.reload();
        //history.push('/offers/trade')
      })
      .catch((error) => {
        console.log(error);
        sessionStorage.setItem("Canceltradeerror", error);
        window.location.reload();
      });
  };

  // console.log("order.id", params.id);
  const paidOffer = () => {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const data = {
      userid: userId,
      offerid: orderId,
      bchAmount: bchAmount,
      fiatAmount: fiatAmount,
    };
    // console.log("notidata", data, uniqueId);
    Axios.post(
      `${baseURL}/api/notifications/addnotify_complate_order/${uniqueId}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((res) => {
        // console.log("paidOfferchetnapaid", res.data);
        toast.success(t("newoffers.paid notification successfully sent"));
        paidInstance();
        // history.push('/offers/trade')
      })
      .catch((err) => {
        // console.log("errchetnaid", err);
        toast.error(`${err}`);
      });
  };

  const [PendingMsg, setPendingMsg] = useState(false);
  const escrowApprove = (id, userid, to_address, amount, currency_type) => {

    console.log("WORKING =========")

    const button = document.getElementById("approve");
    button.disabled = true;
    setPendingMsg(true);
    try {
      const data = {
        userid: userId,
        to_address: to_address,
        amount: amount,
        currencyType: currency_type,
      };
      //console.log("escrowApprovedata", data);
      Axios.post(`${baseURL}/api/wallet/p1_permission_p2/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          console.log("WORKING =========", response);
          if (
            response.data.success === false ||
            response.data.success === "false"
          ) {
            //toast.error(`${response.data.message}`);
            sessionStorage.setItem("Approveerror", response.data.errorMessage ?? response.data.message);
            setPendingMsg(false);
            window.location.reload();
          } else {
            getWowBalance(userId);
            console.log("escrowtransactiondone", response);
            //toast.success("Escrow released successfully!");
            sessionStorage.setItem(
              "Approve",
              t("newoffers.Escrow released successfully")
            );
            window.location.reload();
          }
          // console.log("Hello");
        })
        .catch((err) => {
          console.log("WORKING =========11", err)
          console.log("escrowtransactiondone", err);
          sessionStorage.setItem("Approveerror", err);
          setPendingMsg(false);
          window.location.reload();
        });
    } catch (error) {
      console.log("WORKING =========11", error)
      console.log("escrowtransactiondone", error);
      sessionStorage.setItem("Approveerror", error);
      setPendingMsg(false);
      window.location.reload();
      //localStorage.setItem("Approveerror", "Escrow released successfully!")
    }
  };

  const is_taker = userId == tradeDetail.taker_id ? 1 : 0;
  console.log("is_taker", is_taker);
  const check = is_taker && tradeDetail?.is_maker_buying;
  console.log(
    "userId == tradeDetail?.taker_id",
    userId == tradeDetail?.taker_id
  );
  console.log("tradeDetail?.is_maker_buying", tradeDetail?.is_maker_buying);

  console.log("check", check);

  const escrowTradeCancel = (id) => {
    const cancel = document.getElementById("cancel");
    cancel.disabled = true;
    Axios.get(`${baseURL}/api/wallet/delete_transaction_from_p1/${id}`)
      .then((res) => {
        getWowBalance(userId);
        // toast.success("Cancel Trade Successfully");
        // console.log("Canceltrade.......", res.data);
        window.location.reload();
      })
      .catch((error) => {
        // console.log("error:", error);
        toast.error("Trade Cancel Failed");
        window.location.reload();
      });
  };

  function handle(e) {
    // console.log('e.target.id:', e.target.id);
    let newdata = data;
    newdata[e.target.id] = e.target.value;
    // setData({ ...data, [e.target.id]: e.target.value })
    // console.log("newdata......", newdata);
  }

  const SetReportUser = (status) => {
    if (!status) {
      SetReportForm(true);
    } else {
      SetReportForm(null);
    }
  };

  const SetDisputeStatus = (status) => {
    if (!status) {
      setForm(true);
    } else {
      setForm(null);
    }
  };

  const onFileChange = (event) => {
    setAllfile({ selectedFile: event.target.files[0] });
    console.log("selectedFile", event.target.files[0].name);
    document.getElementById("disputefilename").innerHTML =
      event.target.files[0].name;
  };

  if (PendingMsg) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "60%",
          flexDirection: "column",
        }}
      >
        <Spin indicator={antIcon} />
        <h6 style={{ fontSize: "15px", paddingTop: 8 }}>
          {t("notification.Please wait while your transaction is processing")}
        </h6>
      </div>
    );
  }

  return (
    <div className="orderinfo-container">
      <Authcheck />

      {tradeDetail.closed_contract == 1 ||
      tradeDetail.cancelled_contract == 1 ? (
        <>
          <div>
            <p className="sub-heading-tag">{t("newoffers.Trade Info")}</p>
            <p
              className="sub-heading-tag"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {((userId == tradeDetail.taker_id ? 1 : 0) &&
                tradeDetail.is_maker_buying) == 1 ? (
                <>{t("newoffers.Buyer")} </>
              ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                  !tradeDetail.is_maker_buying) == 1 ? (
                <>{t("newoffers.Seller")} </>
              ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                  tradeDetail.is_maker_buying) == 1 ? (
                <>{t("newoffers.Seller")} </>
              ) : (
                <>{t("newoffers.Buyer")} </>
              )}

              {userId == tradeDetail.taker_id ? (
                <Link
                  to={`/user-profile/${tradeDetail?.maker_id}/${tradeDetail?.maker}`}
                  style={{ color: "#2074F2" }}
                >
                  &nbsp; {tradeDetail?.maker} &nbsp;
                </Link>
              ) : (
                <Link
                  to={`/user-profile/${tradeDetail?.taker_id}/${tradeDetail?.taker}`}
                  style={{ color: "#2074F2" }}
                >
                  &nbsp; {tradeDetail?.taker} &nbsp;
                </Link>
              )}
            </p>

            {((userId == tradeDetail.taker_id ? 1 : 0) &&
              tradeDetail.is_maker_buying) == 1 ? (
              <> </>
            ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                !tradeDetail.is_maker_buying) == 1 ? (
              <></>
            ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                tradeDetail.is_maker_buying) == 1 ? (
              <></>
            ) : (
              <> </>
            )}

            {userId == tradeDetail.maker_id ? (
              <>
                <p>
                  {userId == tradeDetail.maker_id &&
                  tradeDetail.is_maker_buying ? (
                    <></>
                  ) : (
                    <>
                      <p>
                        <b> {t("Sellertrade.Escrow Status")}:</b>
                        <p style={{ color: "blue" }}>
                          <b>
                            {t("newoffers.Total")}{" "}
                            {tradeDetail.escro_available == 1 ? (
                              <>0.00000000</>
                            ) : (
                              <>{escrowbalance.amount}</>
                            )}{" "}
                            {t("Sellertrade.WOW in Escrow")}
                          </b>
                        </p>
                      </p>
                      <>
                        <p className="sub-heading-tag">
                          {t("newoffers.Bank Information")}
                        </p>

                        <p>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Bank Name")}
                          </span>{" "}
                          : {tradeDetail?.bankname}{" "}
                        </p>
                        <p>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Name of account holder")}
                          </span>{" "}
                          : {tradeDetail?.bank_name_account}{" "}
                        </p>
                        <p>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Account Number")}{" "}
                          </span>{" "}
                          : {tradeDetail?.bank_account_number}{" "}
                        </p>
                        <p>
                          {tradeDetail?.bank_details ? (
                            <>
                              {" "}
                              <span
                                style={{ fontWeight: "500" }}
                                className="sub-heading-tag"
                              >
                                {" "}
                                {t("newoffers.Other Bank Details")}
                              </span>{" "}
                              : {tradeDetail?.bank_details}{" "}
                            </>
                          ) : (
                            ""
                          )}{" "}
                        </p>
                      </>
                    </>
                  )}
                </p>
              </>
            ) : (
              <>
                <p>
                  {userId == tradeDetail.taker_id &&
                  tradeDetail.is_maker_buying ? (
                    <>
                      <p>
                        <b> {t("Sellertrade.Escrow Status")}:</b>
                        <p style={{ color: "blue" }}>
                          <b>
                            {t("newoffers.Total")}{" "}
                            {tradeDetail.escro_available == 1 ? (
                              <>0.00000000</>
                            ) : (
                              <>{escrowbalance.amount}</>
                            )}{" "}
                            {t("Sellertrade.WOW in Escrow")}
                          </b>
                        </p>
                      </p>
                      <>
                        <p className="sub-heading-tag">
                          {t("newoffers.Bank Information")}
                        </p>

                        <p>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Bank Name")}
                          </span>{" "}
                          : {tradeDetail?.bankname}{" "}
                        </p>
                        <p>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Name of account holder")}
                          </span>{" "}
                          : {tradeDetail?.bank_name_account}{" "}
                        </p>
                        <p>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Account Number")}{" "}
                          </span>{" "}
                          : {tradeDetail?.bank_account_number}{" "}
                        </p>
                        <p>
                          {Offerdetail?.bank_details ? (
                            <>
                              {" "}
                              <span
                                style={{ fontWeight: "500" }}
                                className="sub-heading-tag"
                              >
                                {" "}
                                {t("newoffers.Other Bank Details")}
                              </span>{" "}
                              : {tradeDetail?.bank_details}{" "}
                            </>
                          ) : (
                            ""
                          )}{" "}
                        </p>
                      </>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </>
            )}
            <p className="sub-heading-tag">
              {t("newoffers.Payment Amount")} :{" "}
              <span style={{ fontWeight: "500" }}>{`${nf.format(
                tradeDetail?.fiat_amount
              )} ${tradeDetail?.currency_type}`}</span>
            </p>

            <p className="sub-heading-tag">
              {t("newoffers.Trade opening date and time")} :
              <span style={{ fontWeight: "500" }}>
                {" "}
                {tradeDetail.created_at}
              </span>
            </p>
          </div>
          {((userId == tradeDetail.taker_id ? 1 : 0) &&
            tradeDetail.is_maker_buying) == 1 ? (
            <> </>
          ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
              !tradeDetail.is_maker_buying) == 1 ? (
            <>
              <p>
                {Offerdetail?.payment_method == "Bank transfer" ? (
                  <>
                    <p className="sub-heading-tag">
                      {t("newoffers.Bank Information")}
                    </p>

                    <p>
                      <span
                        style={{ fontWeight: "500" }}
                        className="sub-heading-tag"
                      >
                        {t("newoffers.Bank Name")}
                      </span>{" "}
                      : {tradeDetail?.bankname}{" "}
                    </p>
                    <p>
                      <span
                        style={{ fontWeight: "500" }}
                        className="sub-heading-tag"
                      >
                        {t("newoffers.Name of account holder")}
                      </span>{" "}
                      : {tradeDetail?.bank_name_account}{" "}
                    </p>
                    <p>
                      <span
                        style={{ fontWeight: "500" }}
                        className="sub-heading-tag"
                      >
                        {" "}
                        {t("newoffers.Account Number")}
                      </span>{" "}
                      : {tradeDetail?.bank_account_number}{" "}
                    </p>
                    {/* <p>
                      {Offerdetail?.bank_details ? (
                        <>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {t("newoffers.Other Bank Details")}{" "}
                          </span>
                          : {Offerdetail?.bank_details}{" "}
                        </>
                      ) : (
                        ""
                      )}{" "}
                    </p> */}
                  </>
                ) : (
                  <></>
                )}
              </p>
            </>
          ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
              tradeDetail.is_maker_buying) == 1 ? (
            <>
              <p>
                {Offerdetail?.payment_method == "Bank transfer" ? (
                  <>
                    <p className="sub-heading-tag">
                      {t("newoffers.Bank Information")}
                    </p>

                    <p>
                      <span
                        style={{ fontWeight: "500" }}
                        className="sub-heading-tag"
                      >
                        {" "}
                        {t("newoffers.Bank Name")}
                      </span>{" "}
                      : {tradeDetail?.bankname}{" "}
                    </p>
                    <p>
                      <span
                        style={{ fontWeight: "500" }}
                        className="sub-heading-tag"
                      >
                        {" "}
                        {t("newoffers.Name of account holder")}
                      </span>{" "}
                      : {tradeDetail?.bank_name_account}{" "}
                    </p>
                    <p>
                      <span
                        style={{ fontWeight: "500" }}
                        className="sub-heading-tag"
                      >
                        {" "}
                        {t("newoffers.Account Number")}{" "}
                      </span>{" "}
                      : {tradeDetail?.bank_account_number}{" "}
                    </p>
                    {/* <p>
                      {Offerdetail?.bank_details ? (
                        <>
                          {" "}
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Other Bank Details")}
                          </span>{" "}
                          : {Offerdetail?.bank_details}{" "}
                        </>
                      ) : (
                        ""
                      )}{" "}
                    </p> */}
                  </>
                ) : (
                  <></>
                )}
              </p>
            </>
          ) : (
            <> </>
          )}
          {tradeDetail.cancelled_contract == 1 &&
          tradeDetail.closed_contract == 0 ? (
            <>
              {tradeDetail.automatic_cancelled == 1 ? (
                <>
                  {((userId == tradeDetail.taker_id ? 1 : 0) &&
                    tradeDetail.is_maker_buying) == 1 ? (
                    <>
                      {tradeDetail.closed_contract == 0 ? (
                        <>
                          <p>
                            <b>{t("newoffers.Release wowcoins")}: </b>
                            {paidStatus == 1 ? (
                              <>
                                {t(
                                  "newoffers.Buyer has marked the payment completed,  when you received the payment then release the wowcoin"
                                )}
                              </>
                            ) : (
                              <>
                                {t(
                                  "newoffers.Payment not marked completed and canceled automatically"
                                )}
                              </>
                            )}

                            <p>
                              <Button
                                onClick={() =>
                                  escrowApprove(
                                    escrowbalance.id,
                                    escrowbalance.taker_id,
                                    escrowbalance.to_address,
                                    escrowbalance.amount,
                                    tradeDetail?.currency_type
                                  )
                                }
                                id="approve"
                                style={{
                                  background: "#00cc90",
                                  color: "#fff",
                                }}
                              >
                                {t("newoffers.Release wowcoins")}
                              </Button>
                              <h6 style={{ color: "red", fontSize: "15px" }}>
                                {PendingMsg}
                              </h6>
                            </p>
                          </p>

                          <p>
                            {tradeDetail?.dispute != 1 ? (
                              <>
                                <p className="detail-heading">
                                  {t("newoffers.Disputing the trade")}:
                                </p>
                                {((userId == tradeDetail.taker_id ? 1 : 0) &&
                                  tradeDetail.is_maker_buying) == 1 ? (
                                  <p className="offer-tagline">
                                    {t(
                                      "newoffers.Payment not marked completed and canceled automatically"
                                    )}
                                  </p>
                                ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                                    !tradeDetail.is_maker_buying) == 1 ? (
                                  <p className="offer-tagline">
                                    {t(
                                      "newoffers.This trade has been canceled automatically because you did not press the I paid button on time"
                                    )}
                                  </p>
                                ) : ((userId !== tradeDetail.taker_id
                                    ? 1
                                    : 0) && tradeDetail.is_maker_buying) ==
                                  1 ? (
                                  <p className="offer-tagline">
                                    {t(
                                      "newoffers.This trade has been canceled automatically because you did not press the I paid button on time"
                                    )}
                                  </p>
                                ) : (
                                  <p className="offer-tagline">
                                    {t(
                                      "newoffers.Payment not marked completed and canceled automatically"
                                    )}
                                  </p>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                            {chatMessages.length != 0 ? (
                              <>
                                <p>
                                  <b>{t("newoffers.Dispute status")}: </b>
                                  {tradeDetail.dispute_solved == 1 ? (
                                    <>
                                      {t(
                                        "newoffers.Resolved By Admin Winner is"
                                      )}
                                      :{tradeDetail.dispuutresolved_name}
                                    </>
                                  ) : (
                                    t("newoffers.Pending")
                                  )}
                                </p>
                                <div className="dispute-message-actions">
                                  {chatMessages?.map((message) => {
                                    return (
                                      <>
                                        {/* <ChatText  key={message.id} userId={userId} message={message} /> */}

                                        <ChatText
                                          key={message.id}
                                          userId={userId}
                                          message={message}
                                        />
                                      </>
                                    );
                                  })}

                                  <span
                                    id="disputefilename"
                                    style={{ fontSize: "13px" }}
                                  ></span>
                                  <Form
                                    id="disputeform"
                                    initialValues={{ remember: true }}
                                    ref={formRef}
                                    onFinish={(e) => {
                                      disputmsgsubmit(e);
                                    }}
                                  >
                                    <Form.Item
                                      name="disputemsg"
                                      id="dispSetDisputeStatusutemsg"
                                      onChange={(e) => handle(e)}
                                      // value={data.dispSetDisputeStatusutemsg}
                                      rules={[
                                        {
                                          required: true,
                                          message: t(
                                            "newoffers.Please input your Dispute message"
                                          ),
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder={t(
                                          "newoffers.Write a message"
                                        )}
                                        autoFocus
                                      />
                                    </Form.Item>
                                    <Form.Item>
                                      <label
                                        class="filelabel"
                                        style={{ marginLeft: "0.5rem" }}
                                        title={t("newoffers.Attache File")}
                                      >
                                        &#x1f4ce;
                                        <input
                                          class="FileUpload1"
                                          id="FileInput"
                                          name="file"
                                          type="file"
                                          onChange={onFileChange}
                                          title={t("newoffers.Attache File")}
                                        />
                                      </label>
                                    </Form.Item>
                                    <Form.Item>
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        id="continue"
                                      >
                                        {t("newoffers.Send")}
                                      </Button>
                                    </Form.Item>
                                  </Form>
                                </div>
                              </>
                            ) : (
                              <>
                                {/* <Button
                                  onClick={() => {
                                    SetDisputeStatus(geForm);
                                  }}
                                  style={{
                                    background: "#f2c680",
                                    width: "25%",
                                  }}
                                >
                                  {t("Trades.DISPUTE")}
                                </Button> */}
                              </>
                            )}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                      !tradeDetail.is_maker_buying) == 1 ? (
                    <>
                      <p>
                        {tradeDetail?.dispute != 1 ? (
                          <>
                            <p className="detail-heading">
                              {t("newoffers.Disputing the trade")}:
                            </p>
                            {((userId == tradeDetail.taker_id ? 1 : 0) &&
                              tradeDetail.is_maker_buying) == 1 ? (
                              <p className="offer-tagline">
                                {t(
                                  "newoffers.Payment not marked completed and canceled automatically"
                                )}
                              </p>
                            ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                                !tradeDetail.is_maker_buying) == 1 ? (
                              <p className="offer-tagline">
                                {t(
                                  "newoffers.This trade has been canceled automatically because you did not press the I paid button on time"
                                )}
                              </p>
                            ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                                tradeDetail.is_maker_buying) == 1 ? (
                              <p className="offer-tagline">
                                {t(
                                  "newoffers.This trade has been canceled automatically because you did not press the I paid button on time"
                                )}
                              </p>
                            ) : (
                              <p className="offer-tagline">
                                {t(
                                  "newoffers. Payment not marked completed and canceled automatically. If buyer has already paid then release wowcoins otherwise start dispute and ask admin to return your escrow amount to wallet"
                                )}
                              </p>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {chatMessages.length != 0 ? (
                          <>
                            <p>
                              <b>{t("newoffers.Dispute status")}: </b>
                              {tradeDetail.dispute_solved == 1 ? (
                                <>
                                  {t("newoffers.Resolved By Admin Winner is")}:
                                  {tradeDetail.dispuutresolved_name}
                                </>
                              ) : (
                                t("newoffers.Pending")
                              )}
                            </p>
                            <div className="dispute-message-actions">
                              {chatMessages?.map((message) => {
                                return (
                                  <>
                                    {/* <ChatText  key={message.id} userId={userId} message={message} /> */}

                                    <ChatText
                                      key={message.id}
                                      userId={userId}
                                      message={message}
                                    />
                                  </>
                                );
                              })}
                              <span
                                id="disputefilename"
                                style={{ fontSize: "13px" }}
                              ></span>
                              <Form
                                id="disputeform"
                                initialValues={{ remember: true }}
                                ref={formRef}
                                onFinish={(e) => {
                                  disputmsgsubmit(e);
                                }}
                              >
                                <div className="row flex-column-reverse flex-lg-row reveal">
                                  <div className="col-9 col-md-9 col-lg-9 mx-auto mx-lg-0">
                                    <Form.Item
                                      name="disputemsg"
                                      id="dispSetDisputeStatusutemsg"
                                      onChange={(e) => handle(e)}
                                      // value={data.dispSetDisputeStatusutemsg}
                                      rules={[
                                        {
                                          required: true,
                                          message: t(
                                            "newoffers.Please input your Dispute message"
                                          ),
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder={t(
                                          "newoffers.Write a message"
                                        )}
                                        autoFocus
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="col-1 col-md-1 col-lg-1 mx-auto mx-lg-0">
                                    <Form.Item>
                                      <label
                                        class="filelabel"
                                        style={{ marginLeft: "0.5rem" }}
                                        title={t("newoffers.Attache File")}
                                      >
                                        &#x1f4ce;
                                        <input
                                          class="FileUpload1"
                                          id="FileInput"
                                          name="file"
                                          type="file"
                                          onChange={onFileChange}
                                          title={t("newoffers.Attache File")}
                                        />
                                      </label>
                                    </Form.Item>
                                  </div>
                                  <div className="col-2 col-md-2 col-lg-2 mx-auto mx-lg-0">
                                    <Form.Item>
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        id="continue"
                                      >
                                        {t("newoffers.Send")}
                                      </Button>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Form>
                            </div>
                          </>
                        ) : (
                          <>
                            {/* <Button
                              onClick={() => {
                                SetDisputeStatus(geForm);
                              }}
                              style={{
                                background: "#f2c680",
                                width: "25%",
                              }}
                            >
                              {t("Trades.DISPUTE")}
                            </Button> */}
                          </>
                        )}
                      </p>
                    </>
                  ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                      tradeDetail.is_maker_buying) == 1 ? (
                    <>
                      <p>
                        {tradeDetail?.dispute != 1 ? (
                          <>
                            <p className="detail-heading">
                              {t("newoffers.Disputing the trade")}:
                            </p>
                            {((userId == tradeDetail.taker_id ? 1 : 0) &&
                              tradeDetail.is_maker_buying) == 1 ? (
                              <p className="offer-tagline">
                                {t(
                                  "newoffers.Payment not marked completed and canceled automatically"
                                )}
                              </p>
                            ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                                !tradeDetail.is_maker_buying) == 1 ? (
                              <p className="offer-tagline">
                                {t(
                                  "newoffers.This trade has been canceled automatically because you did not press the I paid button on time"
                                )}
                              </p>
                            ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                                tradeDetail.is_maker_buying) == 1 ? (
                              <p className="offer-tagline">
                                {t(
                                  "newoffers.This trade has been canceled automatically because you did not press the I paid button on time"
                                )}
                              </p>
                            ) : (
                              <p className="offer-tagline">
                                {t(
                                  "newoffers.Payment not marked completed and canceled automatically"
                                )}
                              </p>
                            )}
                          </>
                        ) : (
                          <></>
                        )}

                        {chatMessages.length != 0 ? (
                          <>
                            <p>
                              <b> {t("newoffers.Dispute status")} : </b>
                              {tradeDetail.dispute_solved == 1 ? (
                                <>
                                  {t("newoffers.Resolved By Admin Winner is")} :
                                  {tradeDetail.dispuutresolved_name}
                                </>
                              ) : (
                                t("newoffers.Pending")
                              )}
                            </p>
                            <div className="dispute-message-actions">
                              {chatMessages?.map((message) => {
                                return (
                                  <>
                                    {/* <ChatText  key={message.id} userId={userId} message={message} /> */}

                                    <ChatText
                                      key={message.id}
                                      userId={userId}
                                      message={message}
                                    />
                                  </>
                                );
                              })}
                            </div>
                            <span
                              id="disputefilename"
                              style={{ fontSize: "13px" }}
                            ></span>
                            <Form
                              id="disputeform"
                              initialValues={{ remember: true }}
                              ref={formRef}
                              onFinish={(e) => {
                                disputmsgsubmit(e);
                              }}
                            >
                              <div className="row flex-column-reverse flex-lg-row reveal">
                                <div className="col-9 col-md-9 col-lg-9 mx-auto mx-lg-0">
                                  <Form.Item
                                    name="disputemsg"
                                    id="dispSetDisputeStatusutemsg"
                                    onChange={(e) => handle(e)}
                                    // value={data.dispSetDisputeStatusutemsg}
                                    rules={[
                                      {
                                        required: true,
                                        message: t(
                                          "newoffers.Please input your Dispute message"
                                        ),
                                      },
                                    ]}
                                  >
                                    <Input
                                      placeholder={t(
                                        "newoffers.Write a message"
                                      )}
                                      autoFocus
                                    />
                                  </Form.Item>
                                </div>
                                <div className="col-1 col-md-1 col-lg-1 mx-auto mx-lg-0">
                                  <Form.Item>
                                    <label
                                      class="filelabel"
                                      style={{ marginLeft: "0.5rem" }}
                                      title={t("newoffers.Attache File")}
                                    >
                                      &#x1f4ce;
                                      <input
                                        class="FileUpload1"
                                        id="FileInput"
                                        name="file"
                                        type="file"
                                        onChange={onFileChange}
                                        title={t("newoffers.Attache File")}
                                      />
                                    </label>
                                  </Form.Item>
                                </div>
                                <div className="col-2 col-md-2 col-lg-2 mx-auto mx-lg-0">
                                  <Form.Item>
                                    <Button
                                      type="primary"
                                      htmlType="submit"
                                      id="continue"
                                    >
                                      {t("newoffers.Send")}
                                    </Button>
                                  </Form.Item>
                                </div>
                              </div>
                            </Form>
                          </>
                        ) : (
                          <>
                            {/* <Button
                              onClick={() => {
                                SetDisputeStatus(geForm);
                              }}
                              style={{
                                background: "#f2c680",
                                width: "25%",
                              }}
                            >
                              {t("Trades.DISPUTE")}
                            </Button> */}
                          </>
                        )}
                      </p>
                    </>
                  ) : (
                    <>
                      {tradeDetail.closed_contract == 0 ? (
                        <>
                          <p>
                            <b>{t("newoffers.Release wowcoins")}: </b>
                            {paidStatus == 1 ? (
                              <>
                                {t(
                                  "newoffers.Buyer has marked the payment completed,  when you received the payment then release the wowcoin"
                                )}
                              </>
                            ) : (
                              <>
                                {t(
                                  "newoffers.Payment not marked completed and canceled automatically"
                                )}
                              </>
                            )}

                            <p>
                              <Button
                                onClick={() =>
                                  escrowApprove(
                                    escrowbalance.id,
                                    escrowbalance.taker_id,
                                    escrowbalance.to_address,
                                    escrowbalance.amount,
                                    tradeDetail?.currency_type
                                  )
                                }
                                id="approve"
                                style={{
                                  background: "#00cc90",
                                  color: "#fff",
                                }}
                              >
                                Release wowcoins
                              </Button>
                              <h6 style={{ color: "red", fontSize: "15px" }}>
                                {PendingMsg}
                              </h6>
                            </p>
                          </p>

                          <p>
                            {tradeDetail?.dispute != 1 ? (
                              <>
                                <p className="detail-heading">
                                  {t("newoffers.Disputing the trade")}:
                                </p>
                                {((userId == tradeDetail.taker_id ? 1 : 0) &&
                                  tradeDetail.is_maker_buying) == 1 ? (
                                  <>
                                    {t(
                                      "newoffers.Payment not marked completed and canceled automatically"
                                    )}
                                  </>
                                ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                                    !tradeDetail.is_maker_buying) == 1 ? (
                                  <>
                                    {t(
                                      "newoffers.This trade has been canceled automatically because you did not press the I paid button on time"
                                    )}
                                  </>
                                ) : ((userId !== tradeDetail.taker_id
                                    ? 1
                                    : 0) && tradeDetail.is_maker_buying) ==
                                  1 ? (
                                  <>
                                    {t(
                                      "newoffers.This trade has been canceled automatically because you did not press the I paid button on time"
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {t(
                                      "newoffers.Payment not marked completed and canceled automatically"
                                    )}
                                  </>
                                )}
                              </>
                            ) : (
                              <></>
                            )}

                            {chatMessages.length != 0 ? (
                              <>
                                <p>
                                  <b> {t("newoffers.Dispute status")}: </b>
                                  {tradeDetail.dispute_solved == 1 ? (
                                    <>
                                      {t(
                                        "newoffers.Resolved By Admin Winner is"
                                      )}
                                      :{tradeDetail.dispuutresolved_name}
                                    </>
                                  ) : (
                                    t("newoffers.Pending")
                                  )}
                                </p>
                                <div className="dispute-message-actions">
                                  {chatMessages?.map((message) => {
                                    return (
                                      <>
                                        {/* <ChatText  key={message.id} userId={userId} message={message} /> */}

                                        <ChatText
                                          key={message.id}
                                          userId={userId}
                                          message={message}
                                        />
                                      </>
                                    );
                                  })}
                                </div>
                                <span
                                  id="disputefilename"
                                  style={{ fontSize: "13px" }}
                                ></span>
                                <Form
                                  id="disputeform"
                                  initialValues={{ remember: true }}
                                  ref={formRef}
                                  onFinish={(e) => {
                                    disputmsgsubmit(e);
                                  }}
                                >
                                  <div className="row flex-column-reverse flex-lg-row reveal">
                                    <div className="col-9 col-md-9 col-lg-9 mx-auto mx-lg-0">
                                      <Form.Item
                                        name="disputemsg"
                                        id="dispSetDisputeStatusutemsg"
                                        onChange={(e) => handle(e)}
                                        // value={data.dispSetDisputeStatusutemsg}
                                        rules={[
                                          {
                                            required: true,
                                            message: t(
                                              "newoffers.Please input your Dispute message"
                                            ),
                                          },
                                        ]}
                                      >
                                        <Input
                                          placeholder={t(
                                            "newoffers.Write a message"
                                          )}
                                          autoFocus
                                        />
                                      </Form.Item>
                                    </div>
                                    <div className="col-1 col-md-1 col-lg-1 mx-auto mx-lg-0">
                                      <Form.Item>
                                        <label
                                          class="filelabel"
                                          style={{ marginLeft: "0.5rem" }}
                                          title={t("newoffers.Attache File")}
                                        >
                                          &#x1f4ce;
                                          <input
                                            class="FileUpload1"
                                            id="FileInput"
                                            name="file"
                                            type="file"
                                            onChange={onFileChange}
                                            title={t("newoffers.Attache File")}
                                          />
                                        </label>
                                      </Form.Item>
                                    </div>
                                    <div className="col-2 col-md-2 col-lg-2 mx-auto mx-lg-0">
                                      <Form.Item>
                                        <Button
                                          type="primary"
                                          htmlType="submit"
                                          id="continue"
                                        >
                                          {t("newoffers.Send")}
                                        </Button>
                                      </Form.Item>
                                    </div>
                                  </div>
                                </Form>
                              </>
                            ) : (
                              <>
                                {/* <Button
                                  onClick={() => {
                                    SetDisputeStatus(geForm);
                                  }}
                                  style={{
                                    background: "#f2c680",
                                    width: "25%",
                                  }}
                                >
                                  {t("Trades.DISPUTE")}
                                </Button> */}
                              </>
                            )}
                          </p>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </>
              ) : (
                <></>
              )}

              {geForm ? (
                <>
                  <h4>{t("Trades.Dispute")}</h4>

                  <span
                    id="disputefilename"
                    style={{ fontSize: "13px" }}
                  ></span>
                  <Form
                    id="disputeform"
                    initialValues={{
                      remember: true,
                    }}
                    ref={formRef}
                    onFinish={(e) => {
                      submit(e);
                    }}
                  >
                    <Form.Item
                      name="disputemsg"
                      id="dispSetDisputeStatusutemsg"
                      label={t("newoffers.Dispute message")}
                      onChange={(e) => handle(e)}
                      // value={data.dispSetDisputeStatusutemsg}
                      rules={[
                        {
                          required: true,
                          message: t(
                            "newoffers.Please input your Dispute message"
                          ),
                        },
                      ]}
                    >
                      <Input
                        placeholder={t("newoffers.Write a message")}
                        autoFocus
                      />
                    </Form.Item>

                    <Form.Item>
                      <Button type="primary" htmlType="submit" id="continue">
                        {t("newoffers.Send")}
                      </Button>
                    </Form.Item>
                  </Form>
                </>
              ) : null}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div>
            <p>
              <b>{t("newoffers.Trade Info")} </b>{" "}
            </p>
            <p>
              <b style={{ width: "12%" }}>
                {((userId == tradeDetail.taker_id ? 1 : 0) &&
                  tradeDetail.is_maker_buying) == 1 ? (
                  <>{t("newoffers.Buyer")} : </>
                ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                    !tradeDetail.is_maker_buying) == 1 ? (
                  <>{t("newoffers.Seller")} : </>
                ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                    tradeDetail.is_maker_buying) == 1 ? (
                  <>{t("newoffers.Seller")} : </>
                ) : (
                  <>{t("newoffers.Buyer")} : </>
                )}
              </b>
              {userId === tradeDetail.taker_id ? (
                <Link
                  to={`/user-profile/${tradeDetail?.maker_id}/${tradeDetail?.maker}`}
                  style={{ color: "blue" }}
                >
                  {tradeDetail?.maker} &nbsp;
                </Link>
              ) : (
                <Link
                  to={`/user-profile/${tradeDetail?.taker_id}/${tradeDetail?.taker}`}
                  style={{ color: "blue" }}
                >
                  &nbsp; {tradeDetail?.taker} &nbsp;
                </Link>
              )}
            </p>

            {((userId == tradeDetail.taker_id ? 1 : 0) &&
              tradeDetail.is_maker_buying) == 1 ? (
              <> </>
            ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                !tradeDetail.is_maker_buying) == 1 ? (
              <></>
            ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                tradeDetail.is_maker_buying) == 1 ? (
              <></>
            ) : (
              <> </>
            )}

            {userId == tradeDetail.maker_id ? (
              <>
                <p>
                  {userId == tradeDetail.maker_id &&
                  tradeDetail.is_maker_buying ? (
                    <></>
                  ) : (
                    <>
                      <p>
                        <b> {t("Sellertrade.Escrow Status")}:</b>
                        <p style={{ color: "blue" }}>
                          <b>
                            {t("newoffers.Total")}{" "}
                            {tradeDetail.escro_available == 1 ? (
                              <>0.00000000</>
                            ) : (
                              <>{escrowbalance.amount}</>
                            )}{" "}
                            {t("Sellertrade.WOW in Escrow")}
                          </b>
                        </p>
                      </p>
                      <>
                        <p className="sub-heading-tag">
                          {t("newoffers.Bank Information")}
                        </p>

                        <p>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Bank Name")}
                          </span>{" "}
                          : {tradeDetail?.bankname}{" "}
                        </p>
                        <p>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Name of account holder")}
                          </span>{" "}
                          : {tradeDetail?.bank_name_account}{" "}
                        </p>
                        <p>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Account Number")}{" "}
                          </span>{" "}
                          : {tradeDetail?.bank_account_number}{" "}
                        </p>
                        <p>
                          {tradeDetail?.bank_details ? (
                            <>
                              {" "}
                              <span
                                style={{ fontWeight: "500" }}
                                className="sub-heading-tag"
                              >
                                {" "}
                                {t("newoffers.Other Bank Details")}
                              </span>{" "}
                              : {tradeDetail?.bank_details}{" "}
                            </>
                          ) : (
                            ""
                          )}{" "}
                        </p>
                      </>
                    </>
                  )}
                </p>
              </>
            ) : (
              <>
                <p>
                  {userId == tradeDetail.taker_id &&
                  tradeDetail.is_maker_buying ? (
                    <>
                      <p>
                        <b> {t("Sellertrade.Escrow Status")}:</b>
                        <p style={{ color: "blue" }}>
                          <b>
                            {t("newoffers.Total")}{" "}
                            {tradeDetail.escro_available == 1 ? (
                              <>0.00000000</>
                            ) : (
                              <>{escrowbalance.amount}</>
                            )}{" "}
                            {t("Sellertrade.WOW in Escrow")}
                          </b>
                        </p>
                      </p>
                      <>
                        <p className="sub-heading-tag">
                          {t("newoffers.Bank Information")}
                        </p>

                        <p>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Bank Name")}
                          </span>{" "}
                          : {tradeDetail?.bankname}{" "}
                        </p>
                        <p>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Name of account holder")}
                          </span>{" "}
                          : {tradeDetail?.bank_name_account}{" "}
                        </p>
                        <p>
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Account Number")}{" "}
                          </span>{" "}
                          : {tradeDetail?.bank_account_number}{" "}
                        </p>
                        <p>
                          {tradeDetail?.bank_details ? (
                            <>
                              {" "}
                              <span
                                style={{ fontWeight: "500" }}
                                className="sub-heading-tag"
                              >
                                {" "}
                                {t("newoffers.Other Bank Details")}
                              </span>{" "}
                              : {tradeDetail?.bank_details}{" "}
                            </>
                          ) : (
                            ""
                          )}{" "}
                        </p>
                      </>
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </>
            )}

            <p>
              <b>{t("newoffers.Payment Amount")}</b> :{" "}
              {`${nf.format(tradeDetail?.fiat_amount)} ${
                tradeDetail?.currency_type
              }`}
            </p>
            <p>
              <b>{t("newoffers.Trade opening date and time")} : </b>
              {tradeDetail.created_at}
            </p>

            {((userId == tradeDetail.taker_id ? 1 : 0) &&
              tradeDetail.is_maker_buying) == 1 ? (
              <>
                {tradeDetail.escro_available == 0 ? (
                  <>
                    <p>
                      <b>{t("newoffers.Release wowcoins")}: </b>
                      {paidStatus == 1 ? (
                        <>
                          {t(
                            "newoffers.Buyer has marked the payment completed,  when you received the payment then release the wowcoin"
                          )}
                        </>
                      ) : (
                        <>
                          {t(
                            "newoffers.The buyer has not yet marked the payment complete and has"
                          )}{" "}
                          {paidStatus == 1 ? (
                            <></>
                          ) : (
                            <>
                              <b>{time}</b> minutes
                            </>
                          )}{" "}
                          {t(
                            "newoffers.to make the payment before the trade is automatically canceled"
                          )}
                        </>
                      )}

                      <p>
                        <Button
                          onClick={() =>
                            escrowApprove(
                              escrowbalance.id,
                              escrowbalance.taker_id,
                              escrowbalance.to_address,
                              escrowbalance.amount,
                              tradeDetail?.currency_type
                            )
                          }
                          id="approve"
                          style={{ background: "#00cc90", color: "#fff" }}
                        >
                          {t("newoffers.Release wowcoins")}
                        </Button>
                        <h6 style={{ color: "red", fontSize: "15px" }}>
                          {PendingMsg}
                        </h6>
                      </p>
                    </p>
                  </>
                ) : (
                  <></>
                )}

                <p>
                  {tradeDetail?.dispute != 1 ? (
                    <>
                      <p className="detail-heading">
                        {t("newoffers.Disputing the trade")}:
                      </p>
                      {((userId == tradeDetail.taker_id ? 1 : 0) &&
                        tradeDetail.is_maker_buying) == 1 ? (
                        <>
                          {paidStatus == 1 ? (
                            <>
                              {t(
                                "newoffers.If buyer has already paid then release wowcoins otherwise start dispute and ask admin to return your escrow amount to wallet"
                              )}
                            </>
                          ) : (
                            <>
                              {t(
                                "newoffers.If the buyer does not respond, does not pay or there is a disagreement regarding the terms of the trade, the seller can dispute this trade"
                              )}
                            </>
                          )}
                        </>
                      ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                          !tradeDetail.is_maker_buying) == 1 ? (
                        <>
                          {t(
                            "newoffers.If the seller does not respond or there is a disagreement regarding the terms of the trade"
                          )}
                        </>
                      ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                          tradeDetail.is_maker_buying) == 1 ? (
                        <>
                          {t(
                            "newoffers.If the seller does not respond or there is a disagreement regarding the terms of the trade"
                          )}
                        </>
                      ) : (
                        <>
                          {paidStatus == 1 ? (
                            <>
                              {t(
                                "newoffers.If buyer has already paid then release wowcoins otherwise start dispute and ask admin to return your escrow amount to wallet"
                              )}
                            </>
                          ) : (
                            <>
                              {t(
                                "newoffers.If the buyer does not respond, does not pay or there is a disagreement regarding the terms of the trade, the seller can dispute this trade"
                              )}
                            </>
                          )}
                        </>
                      )}

                      {paidStatus == 1 ? (
                        <></>
                      ) : (
                        <>
                          {t("newoffers.You can dispute this trade after")}{" "}
                          <b>{time}</b>{" "}
                          {t(
                            "newoffers.minutes which is counted from the time you start trading"
                          )}{" "}
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}

                  {paidStatus == 1 ? (
                    <>
                      {chatMessages.length != 0 ? (
                        <>
                          <p>
                            <b> {t("newoffers.Dispute status")}: </b>
                            {tradeDetail.dispute_solved == 1 ? (
                              <>
                                {t("newoffers.Resolved By Admin Winner is")}:
                                {tradeDetail.dispuutresolved_name}
                              </>
                            ) : (
                              t("newoffers.Pending")
                            )}
                          </p>
                          <div className="dispute-message-actions">
                            {chatMessages?.map((message) => {
                              return (
                                <>
                                  {/* <ChatText  key={message.id} userId={userId} message={message} /> */}

                                  <ChatText
                                    key={message.id}
                                    userId={userId}
                                    message={message}
                                  />
                                </>
                              );
                            })}
                          </div>
                          <span
                            id="disputefilename"
                            style={{ fontSize: "13px" }}
                          ></span>
                          <Form
                            id="disputeform"
                            initialValues={{ remember: true }}
                            ref={formRef}
                            onFinish={(e) => {
                              disputmsgsubmit(e);
                            }}
                          >
                            <div className="row flex-column-reverse flex-lg-row reveal">
                              <div className="col-9 col-md-9 col-lg-9 mx-auto mx-lg-0">
                                <Form.Item
                                  name="disputemsg"
                                  id="dispSetDisputeStatusutemsg"
                                  onChange={(e) => handle(e)}
                                  // value={data.dispSetDisputeStatusutemsg}
                                  rules={[
                                    {
                                      required: true,
                                      message: t(
                                        "newoffers.Please input your Dispute message"
                                      ),
                                    },
                                  ]}
                                >
                                  <Input
                                    placeholder={t("newoffers.Write a message")}
                                    autoFocus
                                  />
                                </Form.Item>
                              </div>
                              <div className="col-1 col-md-1 col-lg-1 mx-auto mx-lg-0">
                                <Form.Item>
                                  <label
                                    class="filelabel"
                                    style={{ marginLeft: "0.5rem" }}
                                    title={t("newoffers.Attache File")}
                                  >
                                    &#x1f4ce;
                                    <input
                                      class="FileUpload1"
                                      id="FileInput"
                                      name="file"
                                      type="file"
                                      onChange={onFileChange}
                                      title={t("newoffers.Attache File")}
                                    />
                                  </label>
                                </Form.Item>
                              </div>
                              <div className="col-2 col-md-2 col-lg-2 mx-auto mx-lg-0">
                                <Form.Item>
                                  <Button
                                    type="primary"
                                    htmlType="submit"
                                    id="continue"
                                  >
                                    {t("newoffers.Send")}
                                  </Button>
                                </Form.Item>
                              </div>
                            </div>
                          </Form>
                        </>
                      ) : (
                        <>
                          {/* <Button
                            onClick={() => {
                              SetDisputeStatus(geForm);
                            }}
                            style={{
                              background: "#f2c680",
                              width: "25%",
                            }}
                          >
                            {t("Trades.DISPUTE")}
                          </Button> */}
                        </>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </p>
              </>
            ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                !tradeDetail.is_maker_buying) == 1 ? (
              <></>
            ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                tradeDetail.is_maker_buying) == 1 ? (
              <></>
            ) : (
              <>
                {((userId == tradeDetail.taker_id ? 1 : 0) &&
                  tradeDetail.is_maker_buying) == 1 ? (
                  <>
                    {tradeDetail.escro_available == 0 ? (
                      <>
                        <p>
                          <b>{t("newoffers.Release wowcoins")}: </b>
                          {paidStatus == 1 ? (
                            <>
                              {t(
                                "newoffers.Buyer has marked the payment completed,  when you received the payment then release the wowcoin"
                              )}
                            </>
                          ) : (
                            <>
                              {t(
                                "newoffers.The buyer has not yet marked the payment complete and has"
                              )}{" "}
                              {paidStatus == 1 ? (
                                <></>
                              ) : (
                                <>
                                  <b>{time}</b> minutes
                                </>
                              )}{" "}
                              {t(
                                "newoffers.to make the payment before the trade is automatically canceled"
                              )}
                            </>
                          )}

                          <p>
                            <Button
                              onClick={() =>
                                escrowApprove(
                                  escrowbalance.id,
                                  escrowbalance.taker_id,
                                  escrowbalance.to_address,
                                  escrowbalance.amount,
                                  tradeDetail?.currency_type
                                )
                              }
                              id="approve"
                              style={{
                                background: "#00cc90",
                                color: "#fff",
                              }}
                            >
                              {t("newoffers.Release wowcoins")}
                            </Button>
                            <h6 style={{ color: "red", fontSize: "15px" }}>
                              {PendingMsg}
                            </h6>
                          </p>
                        </p>
                      </>
                    ) : (
                      <></>
                    )}

                    <p>
                      {tradeDetail?.dispute != 1 ? (
                        <>
                          <p className="detail-heading">
                            {t("newoffers.Disputing the trade")}:
                          </p>
                          {((userId == tradeDetail.taker_id ? 1 : 0) &&
                            tradeDetail.is_maker_buying) == 1 ? (
                            <>
                              {t(
                                "newoffers.Payment not marked completed and canceled automatically"
                              )}
                            </>
                          ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                              !tradeDetail.is_maker_buying) == 1 ? (
                            <>
                              {t(
                                "newoffers.If the seller does not respond or there is a disagreement regarding the terms of the trade. You can dispute this trade. Wowcoins are held safe in escrow until the dispute is resolved"
                              )}
                            </>
                          ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                              tradeDetail.is_maker_buying) == 1 ? (
                            <>
                              {t(
                                "newoffers.If the seller does not respond or there is a disagreement regarding the terms of the trade. You can dispute this trade. Wowcoins are held safe in escrow until the dispute is resolved"
                              )}
                            </>
                          ) : (
                            <>
                              {t(
                                "newoffers.Payment not marked completed and canceled automatically"
                              )}
                            </>
                          )}

                          {paidStatus == 1 ? (
                            <></>
                          ) : (
                            <>
                              {t("newoffers.You can dispute this trade after")}{" "}
                              <b>{time}</b>{" "}
                              {t(
                                "newoffers.minutes which is counted from the time you start trading"
                              )}
                              .{" "}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {paidStatus == 1 ? (
                        <>
                          {chatMessages.length != 0 ? (
                            <>
                              <p>
                                <b> {t("newoffers.Dispute status")}: </b>
                                {tradeDetail.dispute_solved == 1 ? (
                                  <>
                                    {t("newoffers.Resolved By Admin Winner is")}
                                    :{tradeDetail.dispuutresolved_name}
                                  </>
                                ) : (
                                  t("newoffers.Pending")
                                )}
                              </p>
                              <div className="dispute-message-actions">
                                {chatMessages?.map((message) => {
                                  return (
                                    <>
                                      {/* <ChatText  key={message.id} userId={userId} message={message} /> */}

                                      <ChatText
                                        key={message.id}
                                        userId={userId}
                                        message={message}
                                      />
                                    </>
                                  );
                                })}
                              </div>
                              <span
                                id="disputefilename"
                                style={{ fontSize: "13px" }}
                              ></span>
                              <Form
                                id="disputeform"
                                initialValues={{ remember: true }}
                                ref={formRef}
                                onFinish={(e) => {
                                  disputmsgsubmit(e);
                                }}
                              >
                                <div className="row flex-column-reverse flex-lg-row reveal">
                                  <div className="col-9 col-md-9 col-lg-9 mx-auto mx-lg-0">
                                    <Form.Item
                                      name="disputemsg"
                                      id="dispSetDisputeStatusutemsg"
                                      onChange={(e) => handle(e)}
                                      // value={data.dispSetDisputeStatusutemsg}
                                      rules={[
                                        {
                                          required: true,
                                          message: t(
                                            "newoffers.Please input your Dispute message"
                                          ),
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder={t(
                                          "newoffers.Write a message"
                                        )}
                                        autoFocus
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="col-1 col-md-1 col-lg-1 mx-auto mx-lg-0">
                                    <Form.Item>
                                      <label
                                        class="filelabel"
                                        style={{ marginLeft: "0.5rem" }}
                                        title={t("newoffers.Attache File")}
                                      >
                                        &#x1f4ce;
                                        <input
                                          class="FileUpload1"
                                          id="FileInput"
                                          name="file"
                                          type="file"
                                          onChange={onFileChange}
                                          title={t("newoffers.Attache File")}
                                        />
                                      </label>
                                    </Form.Item>
                                  </div>
                                  <div className="col-2 col-md-2 col-lg-2 mx-auto mx-lg-0">
                                    <Form.Item>
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        id="continue"
                                      >
                                        {t("newoffers.Send")}
                                      </Button>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Form>
                            </>
                          ) : (
                            <>
                              {/* <Button
                                onClick={() => {
                                  SetDisputeStatus(geForm);
                                }}
                                style={{
                                  background: "#f2c680",
                                  width: "25%",
                                }}
                              >
                                {t("Trades.DISPUTE")}
                              </Button> */}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </p>
                  </>
                ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                    !tradeDetail.is_maker_buying) == 1 ? (
                  <></>
                ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                    tradeDetail.is_maker_buying) == 1 ? (
                  <></>
                ) : (
                  <>
                    {tradeDetail.escro_available == 0 ? (
                      <>
                        <p>
                          <b> {t("newoffers.Release wowcoins")}: </b>
                          {paidStatus == 1 ? (
                            <>
                              {t(
                                "newoffers.Buyer has marked the payment completed,  when you received the payment then release the wowcoin"
                              )}
                            </>
                          ) : (
                            <>
                              {t(
                                "newoffers.The buyer has not yet marked the payment complete and has"
                              )}{" "}
                              {paidStatus == 1 ? (
                                <></>
                              ) : (
                                <>
                                  <b>{time}</b> minutes
                                </>
                              )}{" "}
                              {t(
                                "newoffers.to make the payment before the trade is automatically canceled"
                              )}
                            </>
                          )}

                          <p>
                            <Button
                              onClick={() =>
                                escrowApprove(
                                  escrowbalance.id,
                                  escrowbalance.taker_id,
                                  escrowbalance.to_address,
                                  escrowbalance.amount,
                                  tradeDetail?.currency_type
                                )
                              }
                              id="approve"
                              style={{
                                background: "#00cc90",
                                color: "#fff",
                              }}
                            >
                              {t("newoffers.Release wowcoins")}
                            </Button>
                            <h6 style={{ color: "red", fontSize: "15px" }}>
                              {PendingMsg}
                            </h6>
                          </p>
                        </p>
                      </>
                    ) : (
                      <></>
                    )}
                    <p>
                      {tradeDetail?.dispute != 1 ? (
                        <>
                          <p className="detail-heading">
                            {t("newoffers.Disputing the trade")}:
                          </p>
                          {((userId == tradeDetail.taker_id ? 1 : 0) &&
                            tradeDetail.is_maker_buying) == 1 ? (
                            <>
                              {paidStatus == 1 ? (
                                <>
                                  {t(
                                    "newoffers.If buyer has already paid then release wowcoins otherwise start dispute and ask admin to return your escrow amount to wallet"
                                  )}
                                </>
                              ) : (
                                <>
                                  {t(
                                    "newoffers.If the buyer does not respond, does not pay or there is a disagreement regarding the terms of the trade, the seller can dispute this trade"
                                  )}
                                </>
                              )}
                            </>
                          ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                              !tradeDetail.is_maker_buying) == 1 ? (
                            <>
                              {t(
                                "newoffers.If the seller does not respond or there is a disagreement regarding the terms of the trade. You can dispute this trade. Wowcoins are held safe in escrow until the dispute is resolved"
                              )}
                            </>
                          ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                              tradeDetail.is_maker_buying) == 1 ? (
                            <>
                              {t(
                                "newoffers.If the seller does not respond or there is a disagreement regarding the terms of the trade. You can dispute this trade. Wowcoins are held safe in escrow until the dispute is resolved"
                              )}
                            </>
                          ) : (
                            <>
                              {paidStatus == 1 ? (
                                <>
                                  {t(
                                    "newoffers.If buyer has already paid then release wowcoins otherwise start dispute and ask admin to return your escrow amount to wallet"
                                  )}
                                </>
                              ) : (
                                <>
                                  {t(
                                    "newoffers.If the buyer does not respond, does not pay or there is a disagreement regarding the terms of the trade, the seller can dispute this trade"
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {paidStatus == 1 ? (
                            <></>
                          ) : (
                            <>
                              {t("newoffers.You can dispute this trade after")}{" "}
                              <b>{time}</b>{" "}
                              {t(
                                "newoffers.minutes which is counted from the time you start trading"
                              )}{" "}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {paidStatus == 1 ? (
                        <>
                          {chatMessages.length != 0 ? (
                            <>
                              <p>
                                <b> {t("newoffers.Dispute status")}: </b>
                                {tradeDetail.dispute_solved == 1 ? (
                                  <>
                                    {t("newoffers.Resolved By Admin Winner is")}
                                    :{tradeDetail.dispuutresolved_name}
                                  </>
                                ) : (
                                  t("newoffers.Pending")
                                )}
                              </p>
                              <div className="dispute-message-actions">
                                {chatMessages?.map((message) => {
                                  return (
                                    <>
                                      {/* <ChatText  key={message.id} userId={userId} message={message} /> */}

                                      <ChatText
                                        key={message.id}
                                        userId={userId}
                                        message={message}
                                      />
                                    </>
                                  );
                                })}
                              </div>
                              <span
                                id="disputefilename"
                                style={{ fontSize: "13px" }}
                              ></span>
                              <Form
                                id="disputeform"
                                initialValues={{ remember: true }}
                                ref={formRef}
                                onFinish={(e) => {
                                  disputmsgsubmit(e);
                                }}
                              >
                                <div className="row flex-column-reverse flex-lg-row reveal">
                                  <div className="col-9 col-md-9 col-lg-9 mx-auto mx-lg-0">
                                    <Form.Item
                                      name="disputemsg"
                                      id="dispSetDisputeStatusutemsg"
                                      onChange={(e) => handle(e)}
                                      // value={data.dispSetDisputeStatusutemsg}
                                      rules={[
                                        {
                                          required: true,
                                          message: t(
                                            "newoffers.Please input your Dispute message"
                                          ),
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder={t(
                                          "newoffers.Write a message"
                                        )}
                                        autoFocus
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="col-1 col-md-1 col-lg-1 mx-auto mx-lg-0">
                                    <Form.Item>
                                      <label
                                        class="filelabel"
                                        style={{ marginLeft: "0.5rem" }}
                                        title={t("newoffers.Attache File")}
                                      >
                                        &#x1f4ce;
                                        <input
                                          class="FileUpload1"
                                          id="FileInput"
                                          name="file"
                                          type="file"
                                          onChange={onFileChange}
                                          title={t("newoffers.Attache File")}
                                        />
                                      </label>
                                    </Form.Item>
                                  </div>
                                  <div className="col-2 col-md-2 col-lg-2 mx-auto mx-lg-0">
                                    <Form.Item>
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        id="continue"
                                      >
                                        {t("newoffers.Send")}
                                      </Button>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Form>
                            </>
                          ) : (
                            <>
                              {/* <Button
                                onClick={() => {
                                  SetDisputeStatus(geForm);
                                }}
                                style={{
                                  background: "#f2c680",
                                  width: "25%",
                                }}
                              >
                                {t("Trades.DISPUTE")}
                              </Button> */}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </p>
                  </>
                )}
              </>
            )}
          </div>
          {((userId == tradeDetail.taker_id ? 1 : 0) &&
            tradeDetail.is_maker_buying) == 1 ? (
            <> </>
          ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
              !tradeDetail.is_maker_buying) == 1 ? (
            <>
              <p>
                {Offerdetail?.payment_method == "Bank transfer" ? (
                  <>
                    <p className="sub-heading-tag">
                      {t("newoffers.Bank Information")}
                    </p>

                    <p>
                      <span
                        style={{ fontWeight: "500" }}
                        className="sub-heading-tag"
                      >
                        {t("newoffers.Bank Name")}
                      </span>{" "}
                      : {tradeDetail?.bankname}{" "}
                    </p>
                    <p>
                      <span
                        style={{ fontWeight: "500" }}
                        className="sub-heading-tag"
                      >
                        {t("newoffers.Name of account holder")}
                      </span>{" "}
                      : {tradeDetail?.bank_name_account}{" "}
                    </p>
                    <p>
                      <span
                        style={{ fontWeight: "500" }}
                        className="sub-heading-tag"
                      >
                        {" "}
                        {t("newoffers.Account Number")}
                      </span>{" "}
                      : {tradeDetail?.bank_account_number}{" "}
                    </p>
                    {/* <p>
                      {Offerdetail?.bank_details ? (
                        <>
                          {" "}
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Other Bank Details")}{" "}
                          </span>{" "}
                          : {Offerdetail?.bank_details}{" "}
                        </>
                      ) : (
                        ""
                      )}{" "}
                    </p> */}
                  </>
                ) : (
                  <></>
                )}
              </p>
            </>
          ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
              tradeDetail.is_maker_buying) == 1 ? (
            <>
              <p>
                {Offerdetail?.payment_method == "Bank transfer" ? (
                  <>
                    <p className="sub-heading-tag">
                      {t("newoffers.Bank Information")}
                    </p>

                    <p>
                      <span
                        style={{ fontWeight: "500" }}
                        className="sub-heading-tag"
                      >
                        {t("newoffers.Bank Name")}
                      </span>{" "}
                      : {tradeDetail?.bankname}{" "}
                    </p>
                    <p>
                      <span
                        style={{ fontWeight: "500" }}
                        className="sub-heading-tag"
                      >
                        {t("newoffers.Name of account holder")} :{" "}
                      </span>
                      {tradeDetail?.bank_name_account}{" "}
                    </p>
                    <p>
                      <span
                        style={{ fontWeight: "500" }}
                        className="sub-heading-tag"
                      >
                        {t("newoffers.Account Number")}{" "}
                      </span>{" "}
                      : {tradeDetail?.bank_account_number}{" "}
                    </p>
                    {/* <p>
                      {Offerdetail?.bank_details ? (
                        <>
                          {" "}
                          <span
                            style={{ fontWeight: "500" }}
                            className="sub-heading-tag"
                          >
                            {" "}
                            {t("newoffers.Other Bank Details")}
                          </span>{" "}
                          : {Offerdetail?.bank_details}{" "}
                        </>
                      ) : (
                        ""
                      )}{" "}
                    </p> */}
                  </>
                ) : (
                  <></>
                )}
              </p>
            </>
          ) : (
            <> </>
          )}
          {isUserBuying ? (
            <>
              <div className="breadcrumb">
                <Breadcrumb separator=">">
                  <Breadcrumb.Item className="bread_red">
                    {/* WOW coin are held in escrow for  {escrowtime.hours} hrs {escrowtime.minutes} min {escrowtime.seconds} sec, during which it is safe to pay. */}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="order-info-main">
            {/* <div className="instruction-ctn">Next Steps</div> */}
            <div className="action-ctn" style={{ marginTop: "20px" }}>
              <div className="action-buttons">
                {((userId == tradeDetail.taker_id ? 1 : 0) &&
                  tradeDetail.is_maker_buying) == 1 ? (
                  <></>
                ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                    !tradeDetail.is_maker_buying) == 1 ? (
                  <>
                    {paidStatus == 1 ? (
                      <>
                        <p>
                          <b>
                            {t(
                              "newoffers.Waiting for the seller to confirm your payment"
                            )}{" "}
                            :{" "}
                          </b>
                          {t(
                            "newoffers.You have marked the payment completed , once seller confirm the payment wowcoin will be release to you"
                          )}
                          .
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="sub-heading-tag">
                          {t("newoffers.Pay to seller")}
                          {t(
                            "newoffers.The wowcoins are held in escrow for"
                          )}{" "}
                          {paidStatus == 1 ? (
                            <></>
                          ) : (
                            <>
                              <b>{time}</b> minutes
                            </>
                          )}
                          , {t("newoffers.during which it is safe to pay")}
                        </p>
                        <button onClick={paidOffer} className="paid_btn">
                          {t("newoffers.I HAVE PAID")}
                        </button>
                      </>
                    )}

                    <p>
                      <b>{t("newoffers.Cancel the trade")} :</b>{" "}
                      {t(
                        "newoffers.Never cancel the trade if you have already sent payment to the seller"
                      )}
                    </p>

                    <button
                      className="cancel_btn"
                      onClick={() => cancelTrade()}
                    >
                      {t("newoffers.CANCEL TRADE")}
                    </button>

                    <p>
                      {tradeDetail?.dispute != 1 ? (
                        <>
                          <b> {t("newoffers.Disputing the trade")}: </b>
                          {((userId == tradeDetail.taker_id ? 1 : 0) &&
                            tradeDetail.is_maker_buying) == 1 ? (
                            <>
                              {paidStatus == 1 ? (
                                <>
                                  {t(
                                    "newoffers.If buyer has already paid then release wowcoins otherwise start dispute and ask admin to return your escrow amount to wallet"
                                  )}
                                </>
                              ) : (
                                <>
                                  {t(
                                    "newoffers.If the buyer does not respond, does not pay or there is a disagreement regarding the terms of the trade, the seller can dispute this trade"
                                  )}
                                </>
                              )}
                            </>
                          ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                              !tradeDetail.is_maker_buying) == 1 ? (
                            <>
                              {t(
                                "newoffers.If the seller does not respond or there is a disagreement regarding the terms of the trade"
                              )}
                            </>
                          ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                              tradeDetail.is_maker_buying) == 1 ? (
                            <>
                              {t(
                                "newoffers.If the seller does not respond or there is a disagreement regarding the terms of the trade"
                              )}
                            </>
                          ) : (
                            <>
                              {paidStatus == 1 ? (
                                <>
                                  {t(
                                    "newoffers.If buyer has already paid then release wowcoins otherwise start dispute and ask admin to return your escrow amount to wallet"
                                  )}
                                </>
                              ) : (
                                <>
                                  {t(
                                    "newoffers.If the buyer does not respond, does not pay or there is a disagreement regarding the terms of the trade, the seller can dispute this trade"
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {paidStatus == 1 ? (
                            <></>
                          ) : (
                            <>
                              {t("newoffers.You can dispute this trade after")}{" "}
                              <b>{time}</b>{" "}
                              {t(
                                "newoffers.minutes which is counted from the time you start trading"
                              )}{" "}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {paidStatus == 1 ? (
                        <>
                          {chatMessages.length != 0 ? (
                            <>
                              <p>
                                <b>{t("newoffers.Dispute status")}: </b>
                                {tradeDetail.dispute_solved == 1
                                  ? "Resolved"
                                  : t("newoffers.Pending")}
                              </p>
                              <div className="dispute-message-actions">
                                {chatMessages?.map((message) => {
                                  return (
                                    <>
                                      {/* <ChatText  key={message.id} userId={userId} message={message} /> */}

                                      <ChatText
                                        key={message.id}
                                        userId={userId}
                                        message={message}
                                      />
                                    </>
                                  );
                                })}
                              </div>
                              <span
                                id="disputefilename"
                                style={{ fontSize: "13px" }}
                              ></span>
                              <Form
                                id="disputeform"
                                initialValues={{ remember: true }}
                                ref={formRef}
                                onFinish={(e) => {
                                  disputmsgsubmit(e);
                                }}
                              >
                                <div className="row flex-column-reverse flex-lg-row reveal">
                                  <div className="col-9 col-md-9 col-lg-9 mx-auto mx-lg-0">
                                    <Form.Item
                                      name="disputemsg"
                                      id="dispSetDisputeStatusutemsg"
                                      onChange={(e) => handle(e)}
                                      // value={data.dispSetDisputeStatusutemsg}
                                      rules={[
                                        {
                                          required: true,
                                          message: t(
                                            "newoffers.Please input your Dispute message"
                                          ),
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder={t(
                                          "newoffers.Write a message"
                                        )}
                                        autoFocus
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="col-1 col-md-1 col-lg-1 mx-auto mx-lg-0">
                                    <Form.Item>
                                      <label
                                        class="filelabel"
                                        style={{ marginLeft: "0.5rem" }}
                                        title={t("newoffers.Attache File")}
                                      >
                                        &#x1f4ce;
                                        <input
                                          class="FileUpload1"
                                          id="FileInput"
                                          name="file"
                                          type="file"
                                          onChange={onFileChange}
                                          title={t("newoffers.Attache File")}
                                        />
                                      </label>
                                    </Form.Item>
                                  </div>
                                  <div className="col-2 col-md-2 col-lg-2 mx-auto mx-lg-0">
                                    <Form.Item>
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        id="continue"
                                      >
                                        {t("newoffers.Send")}
                                      </Button>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Form>
                            </>
                          ) : (
                            <>
                              {/* <Button
                                onClick={() => {
                                  SetDisputeStatus(geForm);
                                }}
                                style={{
                                  background: "#f2c680",
                                  width: "25%",
                                }}
                              >
                                {t("Trades.DISPUTE")}
                              </Button> */}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </p>
                  </>
                ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                    tradeDetail.is_maker_buying) == 1 ? (
                  <>
                    {paidStatus == 1 ? (
                      <>
                        <p>
                          <b>
                            {t(
                              "newoffers.Waiting for the seller to confirm your payment"
                            )}{" "}
                            :{" "}
                          </b>
                          {t(
                            "newoffers.You have marked the payment completed , once seller confirm the payment wowcoin will be release to you"
                          )}
                        </p>
                      </>
                    ) : (
                      <>
                        <p className="sub-heading-tag">
                          {t("newoffers.Pay to seller")}
                          {t("newoffers.The wowcoins are held in escrow for")}
                          {paidStatus == 1 ? (
                            <></>
                          ) : (
                            <>
                              <b>{time}</b> minutes
                            </>
                          )}
                          , {t("newoffers.during which it is safe to pay")}
                        </p>

                        <button onClick={paidOffer} className="paid_btn">
                          {t("newoffers.I HAVE PAID")}
                        </button>
                      </>
                    )}

                    <p>
                      <b>{t("newoffers.Cancel the trade")}:</b>{" "}
                      {t(
                        "newoffers.Never cancel the trade if you have already sent payment to the seller"
                      )}
                    </p>

                    <button
                      className="cancel_btn"
                      onClick={() => cancelTrade()}
                    >
                      {t("newoffers.CANCEL TRADE")}
                    </button>

                    <p>
                      {tradeDetail?.dispute != 1 ? (
                        <>
                          <p className="detail-heading">
                            {t("newoffers.Disputing the trade")}:
                          </p>
                          {((userId == tradeDetail.taker_id ? 1 : 0) &&
                            tradeDetail.is_maker_buying) == 1 ? (
                            <>
                              {paidStatus == 1 ? (
                                <>
                                  {t(
                                    "newoffers. If buyer has already paid then release wowcoins otherwise start dispute and ask admin to return your escrow amount to wallet"
                                  )}
                                </>
                              ) : (
                                <>
                                  {t(
                                    "newoffers.If the buyer does not respond, does not pay or there is a disagreement regarding the terms of the trade, the seller can dispute this trade"
                                  )}
                                </>
                              )}
                            </>
                          ) : ((userId == tradeDetail.taker_id ? 1 : 0) &&
                              !tradeDetail.is_maker_buying) == 1 ? (
                            <>
                              {t(
                                "newoffers.Disputing the tradeIf the seller does not respond or there is a disagreement regarding the terms of the trade"
                              )}
                            </>
                          ) : ((userId !== tradeDetail.taker_id ? 1 : 0) &&
                              tradeDetail.is_maker_buying) == 1 ? (
                            <>
                              {t(
                                "newoffers.Disputing the tradeIf the seller does not respond or there is a disagreement regarding the terms of the trade"
                              )}
                            </>
                          ) : (
                            <>
                              {paidStatus == 1 ? (
                                <>
                                  {t(
                                    "newoffers.If buyer has already paid then release wowcoins otherwise start dispute and ask admin to return your escrow amount to wallet"
                                  )}
                                </>
                              ) : (
                                <>
                                  {t(
                                    "If the buyer does not respond, does not pay or there is a disagreement regarding the terms of the trade, the seller can dispute this trade"
                                  )}
                                </>
                              )}
                            </>
                          )}

                          {paidStatus == 1 ? (
                            <></>
                          ) : (
                            <>
                              {t("newoffers.You can dispute this trade after")}{" "}
                              <b>{time}</b>{" "}
                              {t(
                                "newoffers.minutes which is counted from the time you start trading"
                              )}{" "}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}

                      {paidStatus == 1 ? (
                        <>
                          {chatMessages.length != 0 ? (
                            <>
                              <p>
                                <b> {t("newoffers.Dispute status")}: </b>
                                {tradeDetail.dispute_solved == 1 ? (
                                  <>
                                    {t("newoffers.Resolved By Admin Winner is")}
                                    :{tradeDetail.dispuutresolved_name}
                                  </>
                                ) : (
                                  t("newoffers.Pending")
                                )}
                              </p>

                              <div className="dispute-message-actions">
                                {chatMessages?.map((message) => {
                                  return (
                                    <>
                                      {/* <ChatText  key={message.id} userId={userId} message={message} /> */}

                                      <ChatText
                                        key={message.id}
                                        userId={userId}
                                        message={message}
                                      />
                                    </>
                                  );
                                })}
                              </div>
                              <span
                                id="disputefilename"
                                style={{ fontSize: "13px" }}
                              ></span>
                              <Form
                                id="disputeform"
                                initialValues={{ remember: true }}
                                ref={formRef}
                                onFinish={(e) => {
                                  disputmsgsubmit(e);
                                }}
                              >
                                <div className="row flex-column-reverse flex-lg-row reveal">
                                  <div className="col-9 col-md-9 col-lg-9 mx-auto mx-lg-0">
                                    <Form.Item
                                      name="disputemsg"
                                      id="dispSetDisputeStatusutemsg"
                                      onChange={(e) => handle(e)}
                                      // value={data.dispSetDisputeStatusutemsg}
                                      rules={[
                                        {
                                          required: true,
                                          message: t(
                                            "newoffers.Please input your Dispute message"
                                          ),
                                        },
                                      ]}
                                    >
                                      <Input
                                        placeholder={t(
                                          "newoffers.Write a message"
                                        )}
                                        autoFocus
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="col-1 col-md-1 col-lg-1 mx-auto mx-lg-0">
                                    <Form.Item>
                                      <label
                                        class="filelabel"
                                        style={{ marginLeft: "0.5rem" }}
                                        title={t("newoffers.Attache File")}
                                      >
                                        &#x1f4ce;
                                        <input
                                          class="FileUpload1"
                                          id="FileInput"
                                          name="file"
                                          type="file"
                                          onChange={onFileChange}
                                          title={t("newoffers.Attache File")}
                                        />
                                      </label>
                                    </Form.Item>
                                  </div>
                                  <div className="col-2 col-md-2 col-lg-2 mx-auto mx-lg-0">
                                    <Form.Item>
                                      <Button
                                        type="primary"
                                        htmlType="submit"
                                        id="continue"
                                      >
                                        {t("newoffers.Send")}
                                      </Button>
                                    </Form.Item>
                                  </div>
                                </div>
                              </Form>
                            </>
                          ) : (
                            <>
                              {/* <Button
                                onClick={() => {
                                  SetDisputeStatus(geForm);
                                }}
                                style={{
                                  background: "#f2c680",
                                  width: "25%",
                                }}
                              >
                                {t("Trades.DISPUTE")}
                              </Button> */}
                            </>
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </p>
                  </>
                ) : (
                  <> </>
                )}
              </div>
            </div>

            {getReportform ? (
              <>
                <h4>I want to report a fraudulent User</h4>

                <Form
                  onFinish={(e) => {
                    submit_(e);
                  }}
                >
                  <Form.Item
                    label="Message"
                    name="Message"
                    onChange={(e) => setMessage(e.target.value)}
                    id="Message"
                    rules={[
                      {
                        required: true,
                        message: t("newoffers.Message is Required"),
                      },
                    ]}
                  >
                    <textarea
                      style={{ width: "100%", border: "1px solid #ddd" }}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      htmlType="submit"
                      type="primary"
                      class="ant-btn ant-btn-primary"
                    >
                      SUBMIT
                    </Button>
                  </Form.Item>
                </Form>
              </>
            ) : null}
            {geForm ? (
              <>
                <h4>{t("Trades.Dispute")}</h4>

                <span id="disputefilename" style={{ fontSize: "13px" }}></span>
                <Form
                  id="disputeform"
                  initialValues={{
                    remember: true,
                  }}
                  ref={formRef}
                  onFinish={(e) => {
                    submit(e);
                  }}
                >
                  <Form.Item
                    name="disputemsg"
                    id="dispSetDisputeStatusutemsg"
                    label={t("newoffers.Dispute message")}
                    onChange={(e) => handle(e)}
                    // value={data.dispSetDisputeStatusutemsg}
                    rules={[
                      {
                        required: true,
                        message: t(
                          "newoffers.Please input your Dispute message"
                        ),
                      },
                    ]}
                  >
                    <Input
                      placeholder={t("newoffers.Write a message")}
                      autoFocus
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit" id="continue">
                      {t("newoffers.Send")}
                    </Button>
                  </Form.Item>
                </Form>
              </>
            ) : null}

            <div className="details-ctn">
              <div className="terms-info">
                <p>
                  <b>{t("newoffers.Terms of trade")}:</b>{" "}
                  {Offerdetail?.trade_terms
                    ? Offerdetail?.trade_terms
                    : "-----"}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderInfo;
