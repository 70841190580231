import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import {useTranslation, withTranslation} from "react-i18next";
import { fetchAccount } from "../../store/actions/myAccountActions";
import "./Receipts.scss";

const Account = (props) => {
  const [t, i18n] = useTranslation('common');
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };

  const [userInfo, setUserInfo] = useState({});

  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">

            <div className="receipts-container">
              <div className="secondary-nav">
                <div className="tab-link">
                  <Link to="/my-account" activeClassName="is-active">
                  {t('Profile.Profile')}
                  </Link>
                </div>
                <div className="tab-link">
                  <Link to="/settings" activeClassName="is-active">
                  {t("Profile.Security")}
                  </Link>
                </div>

                <div className="tab-link">
                  <Link to="/verification" activeClassName="is-active">
                  {t("Profile.Verification")}
                  </Link>
                </div>
{/* 
                <div className="tab-link">
                  <Link to="/notifications" activeClassName="is-active">
                    Notifications
                  </Link>
                </div> */}

                {/* <div className="tab-link">
                  <Link to="/advanced" activeClassName="is-active">
                  {t("Profile.Advanced")}
                  </Link>
                </div> */}
              </div>

              <br></br>
              <div class="row">
                <div class="col-12 col-lg-12 pr-lg-0">

                  <h5>Receipts from localbitcoins services</h5>
                  <br/>
                  <table id="dashboard_contacts" class="table table-striped table-condensed">
                    <tbody><tr>
                      <th>Created at</th>
                      <th>{t("wallet.Description")}</th>
                      <th>Amount (BTC)</th>
                      <th>Amount (EUR)</th>
                      <th>Country</th>
                      <th>VAT%</th>
                      <th>VAT paid (EUR)</th>
                    </tr>

                    </tbody></table>
                </div>
                <br/><br/>
                <div class="col-12 col-lg-12 pr-lg-0">
                <p>
                  <b>Please note!</b> Because of a new VAT guidance, beginning <b>20th August 2014</b>, VAT is not paid on escrow fees any more. Some services, such as SMS fees etc still include VAT. More information about this will be available soon.
                </p>

                <p>
                  VAT is paid to Finland from EU trade. If you are a registered business in Europe, and interested in getting the VAT returns, <a href="/support/request/">please contact us</a>.
                </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Account;
