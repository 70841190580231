import React, { useEffect, useState } from 'react'
import Authcheck from "../../components/Authcheck/Authcheck";
import axios from 'axios';
import { Button } from "antd";
import { useParams } from "react-router-dom";
import tickIcon from "../../assets/marketing/check.png";
import closeIcon from "../../assets/marketing/close.png";
import Profile from "../../assets/marketing/profile-user.png";
import "./UserProfile.scss";
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import {useTranslation, withTranslation} from "react-i18next";

const baseURL = process.env.REACT_APP_BASE_URL;

const UserProfile = () => {
  const [userProfile, setUserprofile] = useState();
  console.log("userProfile", userProfile);
  const userId = useParams();
  let history = useHistory();
  const [t, i18n] = useTranslation('common');
  const UserId = localStorage.getItem("userId");
  const  token= localStorage.getItem("token");
  useEffect(() => {
    userData();
  }, [])


  const blockUser = () => {
    let block = {
      first_user_id: UserId,
      second_user_id: userId.id
    };

    axios.post(`${baseURL}/api/auth/first_user_block_to_second`, block, {
      headers: {
         "Authorization" : `Bearer ${token}`
      }
       })
      .then(response => {
        if (response.data.status == "false") {
          toast.error(`${response.data.errorMessage}`);
        } else {
          sessionStorage.setItem("Blockuser", "User Blocked Successfully");
          history.push('/blockuser')
        }
      }).catch(error => {
        // console.log(error);
        toast.error("Error in Blocking");
      })
  }

  const unBlockuser = () => {
    let block = {
      id: userId.id
    };
    axios.post(`${baseURL}/api/auth/unblock_user/${userId.id}`,block, {
      headers: {
         "Authorization" : `Bearer ${token}`
      }
       })
      .then(response => {
        toast.success(t("userprofile.User Unblocked Successfully"));
      }).catch(error => {
        toast.success(t("userprofile.Error in Unblocking"));
      })
  }

  const userData = async () => {
    const res = await axios.get(`${baseURL}/api/trades/get_userdetail_forall_user/${userId.id}`);
    setUserprofile(res.data);
  }

  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" >
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">
               <div className="profile-container" style={{ paddingTop: "0px" }}>
                 <div class="row">
                   <div class="col-md-10">
                       <div id="profile-info">
                    <h4>
                      <b>{t("userprofile.Username")} : </b><img src={Profile} alt="pic" style={{ height: "20px", width: "20px" }} /> {userId?.username}
                    </h4>
                    <br />

                    <div class="row shaded" id="trade_volume_row">
                      <div class="col-md-5 profile-label">
                        <b> {t("signup.Full Name")} </b>
                      </div>
                      <div class="col-md-6 profile-value">
                        {userProfile?.fullname}
                      </div>
                    </div>
                    <div class="row shaded" id="trade_volume_row">
                      <div class="col-md-5 profile-label">
                        <b> {t("userprofile.Trade volume")} </b>
                      </div>
                      <div class="col-md-6 profile-value">
                        20-50 Wow
                      </div>
                    </div>

                    <div class="row" id="confirmed_trades_row">
                      <div class="col-md-5 profile-label">
                        <b>{t("userprofile.Number of confirmed trades")}</b>
                      </div>
                      <div class="col-md-6 profile-value">
                        <strong class="F100_score"> {userProfile?.number_of_confirmed_trade}+ </strong>
                        <br />
                        …{t("userprofile.with")} <strong class="F100_score">{userProfile?.commonttradepersons}</strong> {t("userprofile.different partners")}
                      </div>
                    </div>

                    <div class="row shaded" id="feedback_score_row">
                      <div class="col-md-5 profile-label">
                        <b> {t("userprofile.Feedback score")} </b>
                      </div>
                      <div class="col-md-6 profile-value">
                        <strong class="F100_score">{userProfile?.feedback} %</strong>
                      </div>
                    </div>
                    <div class="row" id="first_buy_row">
                      <div class="col-md-5 profile-label">
                        <b>{t("userprofile.First purchase")}</b>
                      </div>
                      <div class="col-md-6 profile-value">
                        <abbr title="2020-05-19T06:11:55+00:00">
                          {userProfile?.first_buy_trade=="No Found"? t("userprofile.No Found") : userProfile?.first_buy_trade}
                        </abbr>
                      </div>
                    </div>


                    <div class="row" id="date_joined_row">
                      <div class="col-md-5 profile-label">
                        <b>{t("userprofile.Account created")}</b>
                      </div>
                      <div class="col-md-6 profile-value">
                        <abbr title="2020-05-11T09:14:47+00:00">
                          {userProfile?.account_create_date}
                        </abbr>
                      </div>
                    </div>
                    <div class="row shaded" id="last_seen_on_row">
                      <div class="col-md-5 profile-label"><b>{t("userprofile.Last seen")}</b></div>
                      <div class="col-md-6 profile-value">
                        <abbr title="2022-02-15T10:33:01+00:00">
                          {userProfile?.lastseen}
                        </abbr>
                      </div>
                    </div>
                    <div class="row" id="email_verified_row">
                      <div class="col-md-5 profile-label">
                        <b> {t("signup.E-mail")}  </b>
                      </div>
                      <div class="col-md-6 profile-value">

                        <i class="good fa fa-check-square"></i>
                        <abbr title="2020-05-11T09:15:21+00:00">
                        {t("userprofile."+userProfile?.email_verified)}
                        </abbr>

                      </div>
                    </div>
                    <div class="row" id="identity_verified_row">
                      <div class="col-md-5 profile-label">
                        <b>{t("userprofile.ID, Passport or Driver’s license")}</b>
                      </div>
                      <div class="col-md-6 profile-value">
                        <i class="good fa fa-check-square"></i>
                        <abbr title="2020-05-11T10:17:52+00:00">
                        {t("userprofile."+userProfile?.Id_photo_verified)}
                        </abbr>
                      </div>
                    </div>
                    <div class="row shaded" id="trusted_row">
                      <div class="col-md-5 profile-label">
                        <b> {t("userprofile.Blocks")}</b>
                      </div>
                      <div class="col-md-6 profile-value">
                      {t("userprofile."+userProfile?.block)}
                      </div>
                    </div>

                  </div>
                </div>
                <div class="col-md-2">
                  {
                    userId.id == UserId ? <></> :
                      <>
                        <Button onClick={blockUser} class="ant-btn ant-btn-danger" style={{ backgroundColor: "#d43f3a", borderColor: "#d43f3a", color: "#fff" }}>
                          <span>{t('Trades.BLOCK USER')}</span>
                        </Button>
                        {/*<Button class="ant-btn ant-btn-primary" onClick={() => unBlockuser()}  style={{backgroundColor: "#00cc90", borderColor: "#00cc9",color: "#fff" }}>
          <span>Unblock User</span>
      </Button>*/}
                      </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer autoClose={10000} style={{ marginTop: "60px" }} />

      </div>
    </section>
  )
}

export default UserProfile
