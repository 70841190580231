import React, { useState, useEffect } from "react";
import Authcheck from "../../../components/Authcheck/Authcheck";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./Shortcontract.scss";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation, withTranslation } from "react-i18next";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem("userId");
const token = localStorage.getItem("token");

function Shortclosecontract() {
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [t, i18n] = useTranslation("common");

  const getPostData = (data) => {
    return (
      <section
        className=" hero--full hero--v10 d-flex align-items-center hidden"
        style={{ minHeight: "300px", paddingBottom: "0px" }}
      >
        <div class="container">
          <Authcheck />
          <div className="conuter-header">
            <h4 class="sendWOWb">
              {t("closedcontract.Closed/Completed Contract list")}
            </h4>
          </div>
          <div class="table">
            <table class="table-responsive">
              <thead>
                <tr>
                  <th>{t("cancelledcontract.Trade Id")}</th>
                  <th className="tableCancel">
                    {t("cancelledcontract.Created date")}
                  </th>
                  <th className="tableCancel">
                    {t("cancelledcontract.Trade Type")}
                  </th>
                  <th className="tableAnd">
                    {t("cancelledcontract.Trade Partner")}
                  </th>
                  <th>{t("cancelledcontract.Transaction Status")}</th>
                  <th>{t("cancelledcontract.fiat Amount")}</th>
                  <th>{t("cancelledcontract.Trade WOWCoin Amount")}</th>
                  {/*<th>Exchange Rate</th>*/}
                </tr>
              </thead>
              {data ? (
                <tbody>
                  {data.map((item, i) => (
                    <tr key={item.id}>
                      <td key={item.id}>
                        <Link
                          to={`/trade/closed/${item?.offer_id}/${item?.maker_id}/${item?.uniqueid}`}
                          style={{ color: "blue" }}
                        >
                          {item?.id}
                        </Link>
                      </td>
                      <td key={item.id}>{item?.created_at}</td>
                      <td key={item.id}>
                        {UserId == item.maker_id ? (
                          <>
                            {UserId == item.maker_id && item.is_maker_buying ? (
                              <>{t("newoffers.Buying")}</>
                            ) : (
                              <>{t("newoffers.Selling")}</>
                            )}
                          </>
                        ) : (
                          <>
                            {UserId == item.taker_id && item.is_maker_buying ? (
                              <>{t("newoffers.Selling")}</>
                            ) : (
                              <>{t("newoffers.Buying")}</>
                            )}
                          </>
                        )}
                      </td>
                      <td key={item.id}>
                        {UserId == item.maker_id ? (
                          <>{item?.usertaker}</>
                        ) : (
                          <>{item?.usermaker}</>
                        )}
                      </td>
                      <td key={item.id}>
                        {t("closedcontract.Wowcoin released")}
                      </td>
                      <td key={item.id}>{item?.fiat_amount}</td>
                      <td key={item.id}>{item?.bch_amount}</td>
                    </tr>
                  ))}
                </tbody>
              ) : (
                <tbody>
                  <tr>
                    <td>{t("Transactions.No data Found")}</td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </div>
        <ToastContainer autoClose={10000} style={{ marginTop: "60px" }} />
      </section>
    );
  };

  const getAllPosts = async () => {
    const UserId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const res = await axios.get(
      `${baseURL}/api/trades/gettwoclosedcontract/${UserId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("closed data", res);
    if (res.data.data) {
      const data = res.data.data;
      const slice = data.slice(offset - 1, offset - 1 + postsPerPage);

      // For displaying Data

      // Using Hooks to set value
      setAllPosts(slice);
      setPageCount(Math.ceil(data.length / postsPerPage));
    } else {
      setAllPosts(null);
    }
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
  };

  const unBlockuser = (id) => {
    Axios.post(`${baseURL}/api/auth/unblock_user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        toast.success(t("userprofile.User Unblocked Successfully"));
        // console.log("response", response);
        getAllPosts();
        // history.push('/offers/trade')
      })
      .catch((error) => {
        toast.success("Error in Unblocking");
      });
  };

  useEffect(() => {
    getAllPosts();
  }, [offset]);

  return (
    <div className="main-app">
      {/* Display all the posts */}
      {getPostData(posts)}

      {/* Using React Paginate */}
      {/* <ReactPaginate
        previousLabel={t('Withdraw.Previous')}
        nextLabel={t('Withdraw.Next')}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"} /> */}
    </div>
  );
}

export default Shortclosecontract;