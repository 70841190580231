import React, {useEffect} from 'react';
import ImageUploading from 'react-images-uploading';
import { useTranslation, withTranslation } from "react-i18next";
import { Route, Link } from "react-router-dom";
import Axios from 'axios';

const baseURL = process.env.REACT_APP_BASE_URL;

export default function KycImage() {
    const [images, setImages] = React.useState([]);
    const maxNumber = 69;
    const  token= localStorage.getItem("token");

    useEffect(() => {
        
    }, [])
  
    const onChange = (imageList, addUpdateIndex) => {
      // data for submit
      // console.log("chetna", imageList);
      try {
          const sendData = {
            // userid: "14",
            
            passport: imageList,
            license: imageList,
            identitycard: imageList,
            residencepermit: imageList
          };
          // console.log("sendData", sendData);
        Axios.post(`${baseURL}/api/verification/uploadofficialphotoid`, sendData, {
          headers: {
            "Authorization" : `Bearer ${token}`,
            "Content-Type": "multipart/form-data"
          }
        })
        .then((response) => {
            // console.log("response", response);
        });
      }catch (error) {
        // console.log(error);
      }
       
      setImages(imageList);
    };
    return (
        <div className="App" style={{paddingTop: "100px"}}>
     Kyc needs to be Completed
    </div>
    );
}
