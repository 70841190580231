import React, { useState, useEffect } from 'react'
import Authcheck from "../../components/Authcheck/Authcheck";
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import "./Noti.scss";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem('userId');
    const  token = localStorage.getItem("token");

function Noti() {
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0)
  const [t, i18n] = useTranslation('common');
  const getPostData = (data) => {
    return (
      <section className="  hero--v10 d-flex align-items-center hidden" style={{ minHeight: '300px', paddingTop: '100px' }}>
         <div class="container">
        <Authcheck />
            <div className="row flex-column-reverse flex-lg-row reveal">
                <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">
                <div className="conuter-header">
            <h4 class="sendWOWb">{t("noti.Notification")}</h4>
          </div>
          <div class="table">
            <table class="table-responsive">
              <thead>
                <tr>
                  <th>{t("noti.Id")}</th>
                  <th style={{width: "100%"}}>{t("noti.Message")}</th>
                  <th>{t("noti.Date")}</th>
                </tr>
              </thead>
              {data ?
                <tbody>
                  {data.map((item, id_=1) => (
                    <tr key={item.id}>

                      <td key={item.id} data-toggle="tooltip" data-placement="top" title={item?.hash} className="tran">{id_+1}</td>
                      <td key={item.id} >{item?.msg}</td>
                      {item?.status == 1 ? 
                      <td key={item.id} >       
                        <Link to={`/noti_detail/${item?.id}`}><span style={{color: "green"}}><b>{t('notification.Viewed')}</b></span></Link></td>:
                       <td key={item.id} >  <Link to={`/noti_detail/${item?.id}`}><span style={{color: "red"}}><b>{t("Trades.View")}</b></span></Link></td>}
                      
                      {/* <td key={item.id}>{item?.created_at ? new Date(item?.created_at).toLocaleDateString("en-US") : null}</td> */}
                    </tr>
                  ))}
                </tbody>
                :
                <tbody>
                  <tr>
                    <td>{t("Transactions.No data Found")}</td>
                  </tr>
                </tbody>
              }
            </table>
          </div>
                </div>
          
            </div>
          
        </div>

      </section>

    )

  }

  const getAllPosts = async () => {
    const  token= localStorage.getItem("token");
    const res = await axios.get(`${baseURL}/api/notifications/getall_user_notification/${UserId}`, {
      headers: {
         "Authorization" : `Bearer ${token}`
      }
       })
    const data = res.data.data;
    // console.log(res)
    const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

    // For displaying Data
    const postData = getPostData(slice)

    // Using Hooks to set value
    setAllPosts(postData)
    setPageCount(Math.ceil(data.length / postsPerPage))
  }

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1)
  };

  useEffect(() => {
    getAllPosts()
  }, [offset])

  return (
    <div className="main-app">

      {/* Display all the posts */}
      {posts}

      {/* Using React Paginate */}
      <ReactPaginate
        previousLabel={t('Withdraw.Previous')}
        nextLabel={t('Withdraw.Next')}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"} />
    </div>
  );
}

export default Noti;