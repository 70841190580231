import React, { useState, useEffect } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import axios from "axios";
import { Button } from "antd";
import ReactPaginate from "react-paginate";
import "./Withdrawtranscations.css";
import { fetchAccount } from "../../store/actions/myAccountActions";
import { useTranslation, withTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem("userId");
const token = localStorage.getItem("token");

function Withdrawtranscations() {
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const [pageCount, setPageCount] = useState(0);
  const [t, i18n] = useTranslation("common");
  const [data, setData] = useState([]);

  // console.log('pageCount:',pageCount);

  // console.log('offset:',offset);
  useEffect(() => {
    if (sessionStorage.getItem("Sendmsg")) {
      toast.success(sessionStorage.getItem("Sendmsg"));
      sessionStorage.removeItem("Sendmsg");
    }
  }, []);

  const getPostData = (data) => {
    return (
      <>
        <Authcheck />

        <div className="transaction-header">
          <p
            style={{ maxWidth: "40px", minWidth: "40px" }}
            className="heading-no"
          >
            {t("Transactions.No")}
          </p>
          <p
            style={{ maxWidth: "77px", minWidth: "77px" }}
            className="heading-other-tags"
          >
            {t("Withdraw.Beneficiary Name")}
          </p>
          <p
            style={{ maxWidth: "109px", minWidth: "109px" }}
            className="heading-other-tags"
          >
            {t("Withdraw.Account Number or IBAN")}
          </p>
          <p
            style={{ maxWidth: "68px", minWidth: "68px" }}
            className="heading-other-tags"
          >
            {t("Withdraw.BIC/SWIFT Code")}
          </p>
          <p
            style={{ maxWidth: "119px", minWidth: "119px" }}
            className="heading-other-tags"
          >
            {t("Withdraw.National Clearing Code")}
          </p>
          <p
            style={{ maxWidth: "99px", minWidth: "99px" }}
            className="heading-other-tags"
          >
            {t("Withdraw.Transactions Date")}
          </p>
          <p
            style={{ maxWidth: "62px", minWidth: "62px" }}
            className="heading-no"
          >
            {t("Withdraw.Amount")}
          </p>
          <p
            style={{ maxWidth: "82px", minWidth: "82px" }}
            className="heading-other-tags"
          >
            {t("Withdraw.Transactions Hash")}
          </p>
          <p
            style={{ maxWidth: "97px", minWidth: "97px" }}
            className="heading-other-tags"
          >
            {t("Withdraw.Admin Transaction Id")}
          </p>
          <p
            style={{ maxWidth: "108px", minWidth: "108px" }}
            className="heading-other-tags"
          >
            {" "}
            {t("Withdraw.Payment Status")}
          </p>
        </div>

        {data ? (
          <>
            {data.map((item, index) => (
              <>
                <div key={item.id} className="transaction-row">
                  <p
                    style={{ maxWidth: "40px", minWidth: "40px" }}
                    className="transaction-data"
                  >
                    {index + 1 + (offset - 1) * postsPerPage}
                  </p>
                  <p
                    style={{ maxWidth: "77px", minWidth: "77px" }}
                    className="transaction-data"
                    key={item.id}
                  >
                    {item?.Beneficiary_name}
                  </p>
                  <p
                    style={{ maxWidth: "109px", minWidth: "109px" }}
                    className="transaction-data"
                    key={item.id}
                  >
                    {item?.Iban}
                  </p>
                  <p
                    style={{ maxWidth: "68px", minWidth: "68px" }}
                    className="transaction-data"
                    key={item.id}
                  >
                    {item?.Bic_swift_code}
                  </p>
                  <p
                    style={{ maxWidth: "119px", minWidth: "119px" }}
                    className="transaction-data"
                    key={item.id}
                  >
                    {item?.National_clearing_code}
                  </p>
                  <p
                    style={{ maxWidth: "99px", minWidth: "99px" }}
                    className="transaction-data"
                    key={item.id}
                  >
                    {item?.created_at
                      ? new Date(item?.created_at).toLocaleDateString("en-US")
                      : null}
                  </p>
                  <p
                    style={{ maxWidth: "62px", minWidth: "62px" }}
                    className="transaction-price"
                    key={item.id}
                  >
                    {item?.amount}
                  </p>
                  <p
                    style={{ maxWidth: "82px", minWidth: "82px" }}
                    className="transaction-data"
                    key={item.id}
                    title={item?.hash}
                  >
                    {item?.hash?.slice(0, -57)}...
                  </p>
                  <p
                    style={{ maxWidth: "97px", minWidth: "97px" }}
                    className="transaction-data"
                    key={item.id}
                  >
                    {item?.banktransactioncode ? (
                      <>{item?.banktransactioncode}</>
                    ) : (
                      <>----</>
                    )}
                  </p>

                  {item?.status == 1 ? (
                    <>
                      <p
                        key={item.id}
                        style={{ maxWidth: "108px", minWidth: "108px" }}
                        className="transaction-completed"
                      >
                        Completed
                      </p>
                    </>
                  ) : (
                    <>
                      <p
                        key={item.id}
                        style={{ maxWidth: "108px", minWidth: "108px" }}
                        className="transaction-pending"
                      >
                        Pending
                      </p>
                    </>
                  )}
                </div>
                <div key={item.id} className="transaction-row-mobile">
                  <div key={item.id} className="transaction-row-mobile-wrap">
                    <div className="heading-wrapper">
                      <p
                        style={{ maxWidth: "40px", minWidth: "40px" }}
                        className="heading-no"
                      >
                        {t("Transactions.No")}
                      </p>
                      <p
                        style={{ maxWidth: "40px", minWidth: "40px" }}
                        className="transaction-data"
                      >
                        {index + 1 + (offset - 1) * postsPerPage}
                      </p>
                    </div>
                    <div className="heading-wrapper">
                      <p
                        style={{ maxWidth: "77px", minWidth: "77px" }}
                        className="heading-other-tags"
                      >
                        {t("Withdraw.Beneficiary Name")}
                      </p>
                      <p
                        style={{ maxWidth: "77px", minWidth: "77px" }}
                        className="transaction-data"
                        key={item.id}
                      >
                        {item?.Beneficiary_name}
                      </p>
                    </div>
                    <div className="heading-wrapper">
                      <p
                        style={{ maxWidth: "109px", minWidth: "109px" }}
                        className="heading-other-tags"
                      >
                        {t("Withdraw.Account Number or IBAN")}
                      </p>
                      <p
                        style={{ maxWidth: "109px", minWidth: "109px" }}
                        className="transaction-data"
                        key={item.id}
                      >
                        {item?.Iban}
                      </p>
                    </div>
                  </div>

                  <div key={item.id} className="transaction-row-mobile-wrap">
                    <div className="heading-wrapper">
                      <p
                        style={{ maxWidth: "68px", minWidth: "68px" }}
                        className="heading-other-tags"
                      >
                        {t("Withdraw.BIC/SWIFT Code")}
                      </p>
                      <p
                        style={{ maxWidth: "68px", minWidth: "68px" }}
                        className="transaction-data"
                        key={item.id}
                      >
                        {item?.Bic_swift_code}
                      </p>
                    </div>
                    <div className="heading-wrapper">
                      <p
                        style={{ maxWidth: "119px", minWidth: "119px" }}
                        className="heading-other-tags"
                      >
                        {t("Withdraw.National Clearing Code")}
                      </p>{" "}
                      <p
                        style={{ maxWidth: "119px", minWidth: "119px" }}
                        className="transaction-data"
                        key={item.id}
                      >
                        {item?.National_clearing_code}
                      </p>{" "}
                    </div>
                    <div className="heading-wrapper">
                      <p
                        style={{ maxWidth: "99px", minWidth: "99px" }}
                        className="heading-other-tags"
                      >
                        {t("Withdraw.Transactions Date")}
                      </p>
                      <p
                        style={{ maxWidth: "99px", minWidth: "99px" }}
                        className="transaction-data"
                        key={item.id}
                      >
                        {item?.created_at
                          ? new Date(item?.created_at).toLocaleDateString(
                            "en-US"
                          )
                          : null}
                      </p>{" "}
                    </div>
                  </div>

                  <div key={item.id} className="transaction-row-mobile-wrap">
                    {" "}
                    <div className="heading-wrapper">
                      <p
                        style={{ maxWidth: "62px", minWidth: "62px" }}
                        className="heading-no"
                      >
                        {t("Withdraw.Amount")}
                      </p>
                      <p
                        style={{ maxWidth: "62px", minWidth: "62px" }}
                        className="transaction-price"
                        key={item.id}
                      >
                        {item?.amount}
                      </p>{" "}
                    </div>
                    <div className="heading-wrapper">
                      <p
                        style={{ maxWidth: "82px", minWidth: "82px" }}
                        className="heading-other-tags"
                      >
                        {t("Withdraw.Transactions Hash")}
                      </p>{" "}
                      <p
                        style={{ maxWidth: "82px", minWidth: "82px" }}
                        className="transaction-data"
                        key={item.id}
                        title={item?.hash}
                      >
                        {item?.hash?.slice(0, -57)}...
                      </p>{" "}
                    </div>
                    <div className="heading-wrapper">
                      <p
                        style={{ maxWidth: "97px", minWidth: "97px" }}
                        className="heading-other-tags"
                      >
                        {t("Withdraw.Admin Transaction Id")}
                      </p>{" "}
                      <p
                        style={{ maxWidth: "97px", minWidth: "97px" }}
                        className="transaction-data"
                        key={item.id}
                      >
                        {item?.banktransactioncode ? (
                          <>{item?.banktransactioncode}</>
                        ) : (
                          <>----</>
                        )}
                      </p>
                    </div>
                  </div>

                  <div key={item.id} className="transaction-row-mobile-wrap">
                    <div className="heading-wrapper">
                      <p
                        style={{ maxWidth: "108px", minWidth: "108px" }}
                        className="heading-other-tags"
                      >
                        {" "}
                        {t("Withdraw.Payment Status")}
                      </p>
                      {item?.status == 1 ? (
                        <>
                          <p
                            key={item.id}
                            style={{ maxWidth: "108px", minWidth: "108px" }}
                            className="transaction-completed"
                          >
                            Completed
                          </p>
                        </>
                      ) : (
                        <>
                          <p
                            key={item.id}
                            style={{ maxWidth: "108px", minWidth: "108px" }}
                            className="transaction-pending"
                          >
                            Pending
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))}
          </>
        ) : (
          <tr>
            <td>{t("Transactions.No data Found")}</td>
          </tr>
        )}

        <ToastContainer autoClose={10000} style={{ marginTop: "60px" }} />
      </>
    );
  };

  const getAllPosts = async () => {
    let myData = await fetchAccount();
    console.log("myData:", myData);
    setUserInfo(myData);

    const res = await axios.get(
      `${baseURL}/api/wallet/usertoadmin_paymentlist/${UserId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("transaction data", res.data.data);
    if (res.data.data) {
      const data = res.data.data;
      setData(res.data.data);
      const slice = data.slice(offset - 1, offset - 1 + postsPerPage);
      // console.log('slice:',slice);

      // For displaying Data
      const postData = getPostData(slice);

      // Using Hooks to set value
      setAllPosts(postData);
      setPageCount(Math.ceil(data.length / postsPerPage));
    } else {
      setAllPosts(null);
    }
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    // console.log("selectedPage",selectedPage);
    setOffset(selectedPage + 1);

    let start = selectedPage * postsPerPage;
    let end = start + postsPerPage;
    const slice = data.slice(start, end);
    // console.log('slice:',slice);
    const postData = getPostData(slice);
    setAllPosts(postData);
    setPageCount(Math.ceil(data.length / postsPerPage));
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  useEffect(() => {
    let start = (offset - 1) * postsPerPage;
    let end = start + postsPerPage;
    const slice = data.slice(start, end);
    // console.log('slice:',slice);
    const postData = getPostData(slice);
    setAllPosts(postData);
    setPageCount(Math.ceil(data.length / postsPerPage));
  }, [offset]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
      }}
    >
      {/* Display all the posts */}
      {posts}
      <div className="pagination-row">
        {/* Using React Paginate */}
        <ReactPaginate
          previousLabel={t("Withdraw.Previous")}
          nextLabel={t("Withdraw.Next")}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination-container"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div>
    </div>
  );
}

export default Withdrawtranscations;