import React, {useEffect, useState} from 'react'
import Authcheck from "../../components/Authcheck/Authcheck";
import Axios from 'axios';
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import { Form, Input, Button, Select } from "antd";
const baseURL = process.env.REACT_APP_BASE_URL;

const Disputesingle = () => {
const [userProfile, setUserprofile] = useState();
// console.log("userProfile", userProfile);
const [data, setData] = useState({});
const formRef = React.useRef(null);
const userId = useParams();
const offerId = userId.id;
// console.log("User", userId.id);
  useEffect(() => {
    userData();
  }, [])

  const userData = async() =>{
  const res = await Axios.get(`${baseURL}/api/orders/getorderdetail_single/${userId?.id}`)
  // console.log("orderDetail", res.data.data);
  setUserprofile(res.data.data);
}

const submit = (e) => {
  const userId = localStorage.getItem("userId");
  const  token= localStorage.getItem("token");
  const order = {
    dispute_msg : data.disputemsg
  }
  // console.log("oreder....", order);

  Axios.post(`${baseURL}/api/orders/senddisput/${offerId}/${userId}`, order, {
    headers: {
       "Authorization" : `Bearer ${token}`
    }
     })
    .then(res => {
      // console.log("dispute msg", res);
      setData({ ...data, 'dispSetDisputeStatusutemsg': '' });
      formRef.current.setFieldsValue({ disputemsg:'' });
      toast.success(`${res.data.msg}`);
      localStorage.setItem("Disputemsg", "Dispute Submitted successfully");
    }).catch(error => {
      // console.log('formRef.current.value:',formRef.current.getFieldsValue());
      setData({ ...data, 'dispSetDisputeStatusutemsg': '' });
      formRef.current.setFieldsValue({ disputemsg:'' });
      toast.error("Dispute Submitted Failed");
    })
}

function handle(e) {
  // console.log('e.target.id:', e.target.id);
  let newdata = data;
  newdata[e.target.id] = e.target.value
  // setData({ ...data, [e.target.id]: e.target.value })
  // console.log("newdata......", newdata);
} 
 
  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" >
      <div className="container">
                <Authcheck />
      <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">

            <div className="profile-container" style={{paddingTop: "0px"}}>
           
            
            <h4><b>Trade Detail</b> </h4>

              <br></br>
              <div class="row">
              <div class="col-12 col-lg-6">
                  <div class="row p-2 mx-1 border rounded">
                  
                    <div class="col-12 ">
                      <p style={{height: "130px"}}>
                      <h6><b>Maker Name:</b>{userProfile?.maker}</h6>
                      <h6><b>Taker Name:</b>{userProfile?.taker}</h6>
                      <h6><b>Fiat Amount</b> {userProfile?.fiat_amount}</h6>
                      <h6><b>BCH Amount:</b> {userProfile?.bch_amount}</h6>
                      <h6><b>Trade Type:</b> {userProfile?.txn_type == 1 ? <>Sell</>: <>Buy</>}</h6>
                      {/* <h6>Sell all Offer: {userProfile?.sellalloffers}</h6> */}
                         <br/>
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-6">
                  <div class="row mx-1 border rounded">
                  
                    <div class="col-12 "><br/>
                      <p>
                      <>
                      <h4>Dispute Message</h4>
                      <Form
                        id="disputeform"
                        initialValues={{
                          remember: true,
                        }}
                        ref={formRef}
                        onFinish={(e) => {
                          submit(e);
                        }}
                      >
                        <Form.Item
                          name="disputemsg"
                          id="dispSetDisputeStatusutemsg"
                          // label="Dispute message"
                          onChange={(e) => handle(e)}
                          rules={[
                            {
                              required: true,
                              message: "Please input your Dispute message!",
                            },
                          ]}
                        > 
                          <textarea rows="4" style={{width: "415px"}}/>
                        </Form.Item>

                        <Form.Item>
                          <Button type="primary" htmlType="submit" id="continue">
                            Submit
                          </Button>
                        </Form.Item>
                      </Form>
                    </>

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
      </div>
    </section>
  )
}

export default Disputesingle
