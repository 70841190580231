import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link, NavLink } from "react-router-dom";
import { Form, Input, Button } from "antd";
import Select from "react-select";
import axios from "axios";
import { useDispatch } from "react-redux";
import tickIcon from "../../assets/marketing/check.png";
import closeIcon from "../../assets/marketing/close.png";
import { doLogout } from "../../store/actions/authActions";
import { fetchAccount } from "../../store/actions/myAccountActions";
import { ToastContainer, toast } from "react-toastify";
import "./Verification.css";
import SectionSix from "../../components/Home/sections/sectionsix";

import {
  getTier0,
  getTier1,
  getTier2,
  getKycprocess,
  kycData,
  getKycstatus,
} from "./Helper_veri.js";
import { useTranslation, withTranslation } from "react-i18next";
import { Breadcrumb } from "antd";
import guide from "./Account_verification_guide.pdf";
import { adminApproveKycSub, getAdminkycapprove } from "../../helpers/CallAPi";
const baseURL = process.env.REACT_APP_BASE_URL;
const Account = (props) => {
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };

  const [userInfo, setUserInfo] = useState({});
  const [t, i18n] = useTranslation("common");
  const [tierzero, setTierzero] = useState("");
  const [tierone, setTierone] = useState("");
  const [tiertwo, setTiertwo] = useState("");
  const [kycApprove, setKycapprove] = useState("");
  const [kycProcess, setkycprocess] = useState();
  const [kycStatus, setKycstatus] = useState();
  // console.log("kycStatus", kycStatus);

  useEffect(() => {
    getData();
    // const instance = axios.get(`${baseURL}/api/identi/get_form_filled/${userId}`);
    // console.log("instance", instance);
  }, []);

  const getData = async () => {
    try {
      kycData.subscribe((res) => {
        if (!res || !res.tier_z) {
          getTier0();
        } else {
          // console.log('kycData res:',res);
          setTierzero(res.tier_z);
        }
      });
    } catch {
      // console.log("error in tier 0");
    }

    try {
      kycData.subscribe((res) => {
        if (!res || !res.dataa) {
          getTier1();
        } else {
          // console.log("kycData tier1.....", res)
          setTierone(res.dataa);
        }
      });
    } catch {
      // console.log("error in tier 1");
    }

    try {
      kycData.subscribe((res) => {
        if (!res || !res.data) {
          getTier2();
        } else {
          // console.log("kycData tier2.....", res)
          setTiertwo(res.data);
        }
      });
    } catch {
      // console.log("error in tier 2");
    }

    try {
      (async () => {
        try {
          const kycStatus = await getKycstatus();
          // console.log("kycStatus........................", kycStatus.reskyc.data);
          setKycstatus(kycStatus.reskyc.data);
          // setTwofactor(enableTwofactor.data.on);
        } catch {
          // // console.log("error in tier 0");
        }
      })();
    } catch {}
  };

  useEffect(() => {
    if (sessionStorage.getItem("kyc_docs")) {
      toast.success(sessionStorage.getItem("kyc_docs"));
      sessionStorage.removeItem("kyc_docs");
    }

    if (sessionStorage.getItem("Profilemsg") != "") {
      toast.success(sessionStorage.getItem("Profilemsg"));
      sessionStorage.removeItem("Profilemsg");
    }
  }, []);

  return (
    <>
      <div className="verification-page">
        <div className="verification-upper"></div>
        <Authcheck />
        <div className="verification-container">
          {tierone.identy_verification == 1 ? (
            <></>
          ) : (
            <>
              <div className="breadcrumb">
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>
                    <h6>
                      {t(
                        "Profile.Please upload at least one document in the field of identity verification to complete your verification process"
                      )}
                    </h6>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
            </>
          )}

          <div className="boxSections-sub-wrap">
            <div className="non-active-section">
              <NavLink
                style={{ color: "black" }}
                to="/my-account"
                activeClassName="active-section"
              >
                {t("Profile.Profile")}
              </NavLink>
            </div>
            <div style={{ marginLeft: "38px" }} className="non-active-section">
              <NavLink
                style={{ color: "black" }}
                to="/settings"
                activeClassName="active-section"
              >
                {t("Profile.Security")}
              </NavLink>
            </div>
            <div style={{ marginLeft: "38px" }} className="non-active-section">
              <NavLink to="/verification" activeClassName="active-section">
                {t("Profile.Verification")}
              </NavLink>
            </div>
          </div>

          <p className="profile-heading"> Verification </p>
          <div className="verification-sub-container">
            <div class="col-12 col-lg-6 order-3 order-lg-2">
              <div class="left-verification-box">
                {tierzero?.tier0_complate == 1 ? (
                  <span class="verified-badge">
                    {t("verification.VERIFIED")}
                  </span>
                ) : (
                  <span class="non-verified-badge">
                    {t("verification.Not verified")}
                  </span>
                )}

                <div>
                  <p className="tier-heading"> {t("verification.Tier 0")}</p>
                  <p
                    style={{
                      marginLeft: "0px",
                      marginTop: "12px",
                    }}
                    className="tiers-tags"
                  >
                    {t("verification.Up to 1,000€/year trade volume")}
                  </p>
                  <div style={{ marginTop: "25px" }} class="row">
                    <div class="col-12">
                      <div className="tiers-tags-flex">
                        {tierzero.email_check == 1 ? (
                          <img
                            src={tickIcon}
                            alt="icon"
                            style={{ height: "15px", width: "15px" }}
                          />
                        ) : (
                          <img
                            src={closeIcon}
                            alt="icon"
                            style={{ height: "10px", width: "10px" }}
                          />
                        )}{" "}
                        <p className="tiers-tags"> {t("verification.Email")}</p>
                      </div>
                      <div className="tiers-tags-flex">
                        {tierzero.username_check == 1 ? (
                          <img
                            src={tickIcon}
                            alt="icon"
                            style={{ height: "15px", width: "15px" }}
                          />
                        ) : (
                          <img
                            src={closeIcon}
                            alt="icon"
                            style={{ height: "10px", width: "10px" }}
                          />
                        )}

                        <p className="tiers-tags">
                          {t("verification.Full name")}
                        </p>
                      </div>
                      <div
                        style={{ marginBottom: "25px" }}
                        className="tiers-tags-flex"
                      >
                        {tierzero.country_residance_check == 1 ? (
                          <img
                            src={tickIcon}
                            alt="icon"
                            style={{ height: "15px", width: "15px" }}
                          />
                        ) : (
                          <img
                            src={closeIcon}
                            alt="icon"
                            style={{ height: "10px", width: "10px" }}
                          />
                        )}

                        <p className="tiers-tags">
                          {t("verification.Country of residence")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="left-verification-box">
                {tierone.tier0_complate == 1 &&
                kycStatus?.identificationform_filled == 1 &&
                tierone.identy_verification == 1 ? (
                  <span class="verified-badge">
                    {t("verification.VERIFIED")}
                  </span>
                ) : (
                  <span class="non-verified-badge">
                    {t("verification.Not verified")}
                  </span>
                )}

                <div>
                  <p className="tier-heading">{t("verification.Tier 1")}</p>

                  <p
                    style={{
                      marginLeft: "0px",
                      marginTop: "12px",
                      marginBottom: "16px",
                    }}
                    className="tiers-tags"
                  >
                    {" "}
                    {t("verification.This level requires the following")}
                  </p>

                  <div class="row">
                    <div class="col-12">
                      <div className="tiers-tags-flex">
                        {tierone.tier0_complate == 1 ? (
                          <img
                            src={tickIcon}
                            alt="icon"
                            style={{ height: "15px", width: "15px" }}
                          />
                        ) : (
                          <img
                            src={closeIcon}
                            alt="icon"
                            style={{ height: "10px", width: "10px" }}
                          />
                        )}

                        <p className="tiers-tags">
                          {t("verification.Tier 0 requirements")}
                        </p>
                      </div>
                      <div className="tiers-tags-flex">
                        {kycStatus?.identificationform_filled == 1 ? (
                          <img
                            src={tickIcon}
                            alt="icon"
                            style={{ height: "15px", width: "15px" }}
                          />
                        ) : (
                          <img
                            src={closeIcon}
                            alt="icon"
                            style={{ height: "15px", width: "15px" }}
                          />
                        )}

                        <p className="tiers-tags">
                          {" "}
                          {t("verification.Tier 1 form")}
                        </p>
                      </div>
                      <div className="tiers-tags-flex">
                        {kycStatus?.photoform_filled == 1 ? (
                          <img
                            src={tickIcon}
                            alt="icon"
                            style={{ height: "15px", width: "15px" }}
                          />
                        ) : (
                          <img
                            src={closeIcon}
                            alt="icon"
                            style={{ height: "10px", width: "10px" }}
                          />
                        )}

                        <p className="tiers-tags">
                          {" "}
                          {t("verification.Identity verification")}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 order-2 order-lg-3">
              <div className="verify-top-right">
                {tierone.identy_verification == 1 ||
                kycStatus?.direct_approve == 1 ? (
                  <>
                    <span className="verify-userKyc">
                      {t("Trades.User KYC Done!")}
                    </span>
                  </>
                ) : (
                  <></>
                )}
                {tierone.tier0_complate == 1 &&
                kycStatus?.identificationform_filled == 1 &&
                kycStatus?.photoform_filled == 1 ? (
                  <></>
                ) : (
                  <>
                    {tierone.tier0_complate == 1 ? (
                      <></>
                    ) : (
                      <Link to="/my-account">
                        <button class="verify-top-right-btn">
                          {t("Trades.Proceed to Tier 0 verification")}
                        </button>
                      </Link>
                    )}

                    {kycStatus?.identificationform_filled == 1 ? (
                      <></>
                    ) : (
                      <Link to="/tier1_identification/">
                        <button class="verify-top-right-btn">
                          {t("Trades.Proceed to Tier 1 form")}
                        </button>
                      </Link>
                    )}
                    {kycStatus?.photoform_filled == 1 ? (
                      <></>
                    ) : (
                      <Link to="/Kycid">
                        <button
                          style={{ marginBottom: "38px" }}
                          class="verify-top-right-btn"
                        >
                          {t("Trades.Proceed to Identity verification")}
                        </button>
                      </Link>
                    )}
                  </>
                )}
              </div>

              <div class="verify-right-bottom">
                <div class="col-12 mt-2">
                  <a
                    class="verification-guide-button"
                    href={guide}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="account-guide">
                      {t("verification.Account verification guide")}
                    </p>
                    <p className="account-guide">
                      {t(
                        "Trades.Everything you need to know about verifying your account"
                      )}{" "}
                    </p>
                  </a>
                </div>
              </div>

              <div class="verify-right-bottom" style={{ borderColor: "red" }}>
                <div class="col-12 mt-2">
                  <p className="account-guide" style={{ color: "red" }}>
                    If you are a FXBulls member, you will be automatically
                    approved if you make a request to FXBulls Customer Center.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer
          style={{ marginTop: "60px" }}
          position="top-right"
          autoClose={10000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
      <SectionSix />
    </>
  );
};

export default Account;
