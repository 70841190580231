import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { fetchAccount } from "../../store/actions/myAccountActions";
import {useTranslation, withTranslation} from "react-i18next";
import "./Passport.scss";

const Account = (props) => {
  const [t, i18n] = useTranslation('common');
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };

 return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">

            <div className="profile-container">
              <div className="secondary-nav">
                <div className="tab-link">
                  <Link to="/my-account" activeClassName="is-active">
                  {t('Profile.Profile')}
                  </Link>
                </div>
                <div className="tab-link">
                  <Link to="/settings" activeClassName="is-active">
                  {t("Profile.Security")}
                  </Link>
                </div>

                <div className="tab-link">
                  <Link to="/verification" activeClassName="is-active">
                  {t("Profile.Verification")}
                  </Link>
                </div>

                {/* <div className="tab-link">
                  <Link to="/notifications" activeClassName="is-active">
                    Notifications
                  </Link>
                </div> */}

                {/* <div className="tab-link">
                  <Link to="/advanced" activeClassName="is-active">
                  {t("Profile.Advanced")}
                  </Link>
                </div> */}
              </div>

              <br></br>
              <div class="row">
                <div class="offset-3 col-12 col-lg-6 pr-lg-0">
                <div className="onfido-sdk-ui-Modal-inner">
        <div className="onfido-sdk-ui-Theme-step onfido-sdk-ui-Theme-defaultLogo" tabIndex={-1}>
          <div className="onfido-sdk-ui-Theme-navigationBar onfido-sdk-ui-NavigationBar-navigation">
            <button type="button" aria-label="back" className="onfido-sdk-ui-NavigationBar-back onfido-sdk-ui-NavigationBar-backHoverDesktop" style={{ float: 'left'}}>
              <span className="onfido-sdk-ui-NavigationBar-iconBack">
              </span><span className="onfido-sdk-ui-NavigationBar-label" aria-hidden="true">back</span>
            </button>
          </div>
          <div className="onfido-sdk-ui-Theme-content onfido-sdk-ui-Theme-scrollableContent">
            <div className="onfido-sdk-ui-Theme-fullHeightMobileContainer onfido-sdk-ui-crossDevice-Intro-container">
              <div className="onfido-sdk-ui-PageTitle-titleWrapper">
                <div className="onfido-sdk-ui-PageTitle-title" role="heading" aria-level={1} aria-live="assertive">
                  <span className="onfido-sdk-ui-PageTitle-titleSpan" tabIndex={-1} style={{ fontSize: '20px',fontWeight: '900' }}>Continue on your phone</span>
                </div>
                <br/>
                <div className="onfido-sdk-ui-PageTitle-subTitle" role="heading" aria-level={2}  style={{ fontWeight: '900' }}>Here’s how to do it:</div>
              </div>
              
              <br/>
              <br/>
              <ol aria-label="Steps required to continue verification on your mobile" className="onfido-sdk-ui-crossDevice-Intro-content onfido-sdk-ui-crossDevice-Intro-list">
                <li className="onfido-sdk-ui-crossDevice-Intro-stage"><div className="onfido-sdk-ui-crossDevice-Intro-stageIcon onfido-sdk-ui-crossDevice-Intro-stageIcon-sms" />
                  <div className="onfido-sdk-ui-crossDevice-Intro-stageMessage onfido-sdk-ui-crossDevice-Intro-stageMessage-sms">Send a secure link to your phone</div>
                </li>
                <li className="onfido-sdk-ui-crossDevice-Intro-stage">
                  <div className="onfido-sdk-ui-crossDevice-Intro-stageIcon onfido-sdk-ui-crossDevice-Intro-stageIcon-take-photos">
                  </div>
                  <div className="onfido-sdk-ui-crossDevice-Intro-stageMessage onfido-sdk-ui-crossDevice-Intro-stageMessage-take-photos">Open the link and complete the tasks</div>
                </li>
                <li className="onfido-sdk-ui-crossDevice-Intro-stage"><div className="onfido-sdk-ui-crossDevice-Intro-stageIcon onfido-sdk-ui-crossDevice-Intro-stageIcon-return-to-computer" />
                  <div className="onfido-sdk-ui-crossDevice-Intro-stageMessage onfido-sdk-ui-crossDevice-Intro-stageMessage-return-to-computer">Check back here to finish the submission</div>
                </li></ol>
              <div className="onfido-sdk-ui-crossDevice-Intro-buttonContainer"><button data-onfido-qa="cross-device-continue-btn" className="btn btn-outline-primary btn-block mx-2">Get secure link</button>
              </div>
            </div>
          </div>
          <div className="onfido-sdk-ui-Theme-footer">
            <div className="onfido-sdk-ui-Theme-logo">
            </div>
          </div>
        </div>
      </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Account;
