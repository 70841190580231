import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Form, Input, Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { doRegister } from "../../store/actions/authActions";
import "./Signup.css";
import { NavLink, Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation, withTranslation } from "react-i18next";
import axios from "axios";
import { updateAction } from "../../helpers/updateActions";
import { useForm } from "react-hook-form";
import footmailLogo from "../../views/Marketing/img/blackemail.png";
import SectionSix from "../Home/sections/sectionsix";

const baseURL = process.env.REACT_APP_BASE_URL;
export const LOGIN = "LOGIN";
export const LOADING_USER = "LOADING_USER";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const GET_USER = "GET_USER";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const USER_ERROR = "USER_ERROR";

const Signup = (props) => {
  const [captVal, setCaptVal] = useState("0");
  const [isCap, setIsCap] = useState(true);
  const [errorMsg, setErrorMsg] = useState([]);
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
  } = useForm({});

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    if (captVal && captVal != 0) {
      // props.doRegister(values, props.history);
      // console.log('onFinish called!');
      const button = document.getElementById("register");
      button.disabled = true;
      doRegister(values);
    } else {
      // alert('Please select recaptcha!');
      setLoading(false);
      setIsCap(false);
    }
  };

  const onChange = (value) => {
    // console.log("Captcha value:", value);
    if (value) {
      setCaptVal(value);
    }
  };
  const doRegister = (user) => {
    axios
      .post(`${baseURL}/api/auth/register`, user)
      .then((response) => {
        setLoading(false);

        // console.log("chetnaregister");
        console.log("HEll", response.data);
        if (response.data.status == false) {
          toast.error(response.data.errorMessage.Error);
          const button = document.getElementById("register");
          button.disabled = false;
        } else {
          const { token, user } = response.data;
          // localStorage.setItem("token", token);
          // localStorage.setItem("userId", user.id);
          sessionStorage.setItem(
            "Registrationmsg",
            t("signup." + response.data.message)
          );
          props.history.push("/login");
        }
      })
      .catch((error) => {
        setLoading(false);

        console.log("HElle", error.response.data.errorMessage);
        let errorMessage = error.response.data.errorMessage.Error
          ? error.response.data.errorMessage.Error
          : error.response.data.errorMessage;
        // console.log("errorMessageaa............", errorMessage.errorMessage);
        toast.error(t("signup." + errorMessage));
        const button = document.getElementById("register");
        button.disabled = false;
        setErrorMsg(errorMessage);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  // const onFinishFailed = errorInfo => {
  //   console.log("Failed:", errorInfo);
  // };

  //may need to refactor left and right panels as form could be reused in modal
  const [t, i18n] = useTranslation("common");
  return (
    <>
      <div className="signup-page">
        <div className="signup-upper"></div>
        <div className="signup-container">
          <div className="left-panel">
            <h3 className="signup-header">{t("signup.Create an account")}</h3>
            <Form
              className="sign-form"
              name="register"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
            >
              <Form.Item
                className="input-headers"
                style={{ margin: 0 }}
                name="user_fullname"
                rules={[
                  {
                    required: true,
                    message: t("signup.Please input your Fullname"),
                  },
                ]}
              >
                <div className="input-wrap">
                  <p className="input-label">{t("signup.Full Name")}</p>{" "}
                  <input
                    placeholder="Enter full name"
                    className="input-field"
                  />
                </div>
              </Form.Item>
              <Form.Item
                className="input-headers"
                style={{ margin: 0 }}
                name="username"
                rules={[
                  {
                    required: true,
                    message: t("signup.Please input your Username"),
                  },
                ]}
              >
                <div className="input-wrap">
                  <p className="input-label">{t("signup.Username")}</p>{" "}
                  <input placeholder="Enter user ID" className="input-field" />
                </div>
              </Form.Item>

              <Form.Item
                style={{ margin: 0 }}
                className="input-headers"
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("signup.Please input your Password"),
                  },
                  {
                    min: 6,
                    pattern:
                      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
                    message: t("Security.passwordvalidation"),
                  },
                ]}
              >
                <div className="input-wrap">
                  <p className="input-label">{t("signup.Password")}</p>{" "}
                  <input
                    className="input-field"
                    type="password"
                    placeholder="Enter password"
                    name="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                  />
                </div>
              </Form.Item>

              <Form.Item
                style={{ margin: 0 }}
                name="confirm"
                className="input-headers"
                dependencies={["password"]}
                hasFeedback
                rules={[
                  {
                    required: true,
                    message: t("signup.Please confirm your password"),
                  },
                  ({ getFieldValue }) => ({
                    validator(rule, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        t(
                          "signup.The two passwords that you entered do not match"
                        )
                      );
                    },
                  }),
                ]}
              >
                <div className="input-wrap">
                  <p className="input-label">{t("signup.Confirm password")}</p>{" "}
                  <input
                    className="input-field"
                    type="password"
                    placeholder="Re-enter password"
                  />
                </div>
              </Form.Item>

              <Form.Item
                name="email"
                style={{ margin: 0 }}
                className="input-headers"
                rules={[
                  {
                    type: "email",
                    message: t("signup.The input is not valid E-mail"),
                  },
                  {
                    required: true,
                    message: t("signup.Please input your E-mail"),
                  },
                ]}
              >
                <div className="input-wrap">
                  <p className="input-label">{t("signup.E-mail")}</p>{" "}
                  <input
                    className="input-field"
                    placeholder="Enter your email"
                  />
                </div>
              </Form.Item>

              <ReCAPTCHA
                style={{ background: "none" }}
                sitekey="6LfqtKIgAAAAAADTuBpOEnLlqZpTf96bOR4R26ZI"
                onChange={onChange}
              />
              {!isCap ? (
                <span style={{ color: "red" }}>
                  {t("login.Please select recaptcha")}
                </span>
              ) : (
                <></>
              )}
              <Form.Item>
                <button
                  style={{ marginTop: "20px" }}
                  className="signup-form-btn"
                  type="primary"
                  htmlType="submit"
                  id="register"
                >
                  {loading ? "Loading..." : <>{t("signup.CREATE ACCOUNT")} </>}
                </button>

                <h6 className="signupval" style={{ marginTop: "10px" }}>
                  {t("signup.Already have an account")}
                  <span>
                    <Link to="/login">
                      <a className="loginlink">{t("signup.Log In")}</a>
                    </Link>
                  </span>
                </h6>
                {/* <h6 className="signupval">{t('signup.Forgot password')}
                   <span>
                   <Link to="/Forgetpass">
                      <a className="loginlink">{t('signup.Reset your password')}.</a>
                    </Link>
                   </span>
                
                    </h6> */}
              </Form.Item>
            </Form>
          </div>

          <div class="signup-form-right">
            <div className="right-panel">
              <h5 className="right-panel-heading">
                {" "}
                {t("signup.Register a New Account")}:
              </h5>
              <br />
              <p className="right-panel-sub-heading">
                {t(
                  "signup.After completing the registration process, click the confirmation link sent to your registered email ID to activate your account"
                )}
              </p>
              <br />
              <p className="right-panel-sub-heading">
                {t(
                  "signup.If you do not receive a confirmation email, please contact us or send an email to"
                )}{" "}
                <span style={{ fontWeight: "600" }}> walletdays@gmail.com</span>
                <Link to="/contact">
                  <button className="right-panel-btn">
                    {t("Footer.Contact")}
                  </button>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer autoClose={2000} style={{ marginTop: "60px" }} />
      <SectionSix />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loadingUser: state.auth.loadingUser,
    register: state.auth.registerError,
  };
};

export default connect(mapStateToProps, { doRegister })(Signup);
