import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb } from "antd";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Chat from "../../components/OrderInfo/Chat/Chat";
import { ToastContainer, toast } from "react-toastify";
import OrderInfo from "../../components/OrderInfo/OrderInfo";
import { getCurrentOrder } from "../../store/actions/ordersActions";
import "./OrderDetails.css";
import Axios from "axios";
import SectionSix from "../../components/Home/sections/sectionsix";

const OrderDetails = (props) => {
  const [t, i18n] = useTranslation("common");
  const id = useParams().orderId;
  const fromid = useParams().fromid;
  // console.log("fromid", fromid);
  const us_name = useParams().fromuser;
  const from = useParams().from;
  const makerId = useParams().makerId;
  const uniqueId = useParams().uniqueId;
  // console.log("usePar", useParams());
  const baseURL = process.env.REACT_APP_BASE_URL;
  // const id = "119";
  // console.log('useParams id:',id);
  const userId = localStorage.getItem("userId");
  const [tradeDetail, setTradedetail] = useState({});
  const order = useSelector((state) => state.orders.currentOrder);
  // console.log("orderabc", order);
  const dispatch = useDispatch();
  const offerDetails = useSelector((state) => state.myOffers.offerDetails);
  const orderDetails = useSelector((state) => state.orders.order);
  const orderErrors = useSelector((state) => state.orders.errorMessages);
  const [getLiveprice, setLiveprice] = useState("");
  const [getOfferData, setOfferData] = useState("");
  const [MakerUsername, setMakerUsername] = useState();
  const [paidStatus, setPaidstatus] = useState();
  // console.log("MakerUsername1", MakerUsername);
  const [tradeStatus, setTradestatus] = useState("");
  const token = localStorage.getItem("token");
  const {
    //   limitMax,
    //   limitMin,
    //  currencyType,
    //   currencySymbol,
    //   margin,
    //   marginAbove,
    //   makerId,
    //   id,
    //   buyBCH,
  } = offerDetails;

  const [isUserBuying, setIsUserBuying] = useState();
  const [checkBuying, setCheckBuying] = useState();
  const {
    bchAmount,
    cancelled,
    complete,
    paid,
    fiatAmount,
    priceBCH,
    userMaker,
    userTaker,
    fiat_amount,
    amount,
    currencyType,
    //uniqueId,
    updatedAt,
  } = order;
  //console.log("isUserBuying", order);
  const [username, setUserName] = useState(false);
  const coinStatsAPI = "https://openapiv1.coinstats.app/coins/usd?currency=";
  // console.log("checkBuying", checkBuying);
  useEffect(() => {
    // console.log('useEffect id:', id,'props.history:',props.history);
    paidInstance();
    userData();
    dispatch(getCurrentOrder(id, props.history));
    Axios.get(`${baseURL}/api/auth/useraccount/${makerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      (response) => {
        setMakerUsername(response.data);
        // console.log("MakerId", response.data);
      },
      (error) => {}
    );

    Axios.get(`${baseURL}/api/orders/msgget_for_disppute/${userId}/${id}`)
      .then((res) => {
        console.log("Tradestatus:", res);
        setTradestatus(res.data);
      })
      .catch((error) => {
        console.log("Tradestatus:", error);
      });
  }, []);

  const paidInstance = () => {
    Axios.get(`${baseURL}/api/notifications/get_am_paid/${uniqueId}`)
      .then((response) => {
        // console.log("Paid balance", response.data);
        setPaidstatus(response.data.paid);
      })
      .catch((err) => {
        // console.log("paiderr", err);
      });
  };

  const userData = async () => {
    await Axios.get(`${baseURL}/api/orders/get_order_detail/${uniqueId}`)
      .then((response) => {
        console.log("userData........", response.data.data);
        setTradedetail(response.data.data);
        LivePrice(
          response.data.data.offer_id,
          response.data.data.currency_type
        );
      })
      .catch((e) => {
        console.log("userData e:", e);
      });
  };

  console.log("offer_id", tradeDetail.offer_id);

  const LivePrice = async (offer_id, currency_type) => {
    try {
      const OfferData = await Axios.get(
        `${baseURL}/api/offers/get_offer_detail/${offer_id}`
      );

      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "X-API-KEY": "6UEisKAzxdGYrzCYCGJTFIBPQMY8XGGtFDHkEPU4Fgc=",
        },
      };
      const result = await Axios.get(`${coinStatsAPI}${currency_type}`, options);
      let price = result.data.price * 100;
      if(currency_type === 'USD'){
        const resp = await Axios.get(`${baseURL}/api/wowprice/`)
        price = Number(resp.data.price);
      }
      setOfferData(OfferData.data.data);
      let adjPrice =
        price +
        (OfferData.data.data.margin_above
          ? price * (OfferData.data.data.margin / 100)
          : -price * (OfferData.data.data.margin / 100));
      console.log("adjPrice", adjPrice);
      setLiveprice(adjPrice.toFixed(2));
    } catch (e) {
      console.log("error in LivePrice", e);
    }
  };

  useEffect(() => {
    // console.log('order ::::::::', order);
    if (localStorage.getItem("Disputemsg")) {
      toast.success(localStorage.getItem("Disputemsg"));
      localStorage.removeItem("Disputemsg");
    }
    setIsUserBuying(order.isMakerBuying ? false : true);
    setUserName(!order.isMakerBuying ? order.usermaker : order.usertaker);
    setCheckBuying(userId == order.makerId && order.isMakerBuying);
  }, [order]);

  // console.log('isUserBuying...............:',isUserBuying);

  return (
    <>
      <div className="order-page">
        <div className="wallet-upper"></div>
        <Authcheck />

        <div className="order-container">
          {from == "noti_" ? (
            <div>
              {/* <div
                className="breadcrumb"
                style={{ marginBottom: "5px", background: "#f2e9c1" }}
              >
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>
                    <Link to="/offers">{t("Sellertrade.Trade status")}</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {tradeDetail.closed_contract == 1 ? (
                      <>
                        <div className="paid">
                          {t("Sellertrade.Wowcoin release")}
                        </div>
                      </>
                    ) : (
                      <>
                        {tradeDetail.cancelled_contract == 1 ? (
                          <>
                            <div className="paid">
                              {t("closedcontract." + tradeDetail?.perform_by)}
                            </div>
                          </>
                        ) : (
                          <>
                            {tradeDetail.dispute == 1 ? (
                              <>
                                {tradeDetail.dispute_solved == 1 ? (
                                  <div className="paid">
                                    {" "}
                                    <div className="paid">
                                      {" "}
                                      {t("Trades.Dispute Solved by Admin")}
                                    </div>
                                  </div>
                                ) : (
                                  <div className="paid">
                                    {" "}
                                    {t("Sellertrade.Under Dispute")}
                                  </div>
                                )}
                              </>
                            ) : (
                              <>
                                {paidStatus == 1 ? (
                                  <>
                                    <div className="paid">
                                      {" "}
                                      {t(
                                        "Sellertrade.Payment Marked as completed, waiting for escrow release"
                                      )}{" "}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div>
                                      {t(
                                        "Sellertrade.Waiting for buyer to confirm payment"
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {tradeStatus?.Disputemsg ? (
                      <div className="dispute"> {t("Trades.Disputed")}</div>
                    ) : (
                      <> </>
                    )}
                    {tradeStatus?.solvedisputemsg ? (
                      <div className="paid">
                        {" "}
                        {t("Trades.Dispute Solved by Admin")}
                      </div>
                    ) : (
                      <> </>
                    )}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div> */}

              <div className="order-details-wrapper">
                <Chat orderId={id} maker={fromid} taker={userId} />

                <OrderInfo
                  order={order}
                  username={username}
                  isUserBuying={userId == order.makerId && !order.isMakerBuying}
                />
              </div>
            </div>
          ) : (
            <div>
              {/* <div
                className="breadcrumb"
                style={{ marginBottom: "5px", background: "#f2e9c1" }}
              >
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>
                    <Link to="/offers">{t("Sellertrade.Trade status")}</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {tradeDetail.closed_contract == 1 ? (
                      <>
                        <div className="paid">
                          {t("Sellertrade.Wowcoin release")}
                        </div>
                      </>
                    ) : (
                      <>
                        {tradeDetail.dispute == 1 ? (
                          <>
                            {tradeDetail.dispute_solved == 1 ? (
                              <div className="paid">
                                {" "}
                                <div className="paid">
                                  {" "}
                                  {t("Trades.Dispute Solved by Admin")}
                                </div>
                              </div>
                            ) : (
                              <div className="paid">
                                {" "}
                                {t("Sellertrade.Under Dispute")}
                              </div>
                            )}
                          </>
                        ) : (
                          <>
                            {tradeDetail.cancelled_contract == 1 ? (
                              <>
                                <div className="paid">
                                  {t(
                                    "closedcontract." + tradeDetail.perform_by
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                {paidStatus == 1 ? (
                                  <>
                                    <div className="paid">
                                      {" "}
                                      {t(
                                        "Sellertrade.Payment Marked as completed, waiting for escrow release"
                                      )}{" "}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div>
                                      {" "}
                                      {t(
                                        "Sellertrade.Waiting for buyer to confirm payment"
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {tradeStatus?.Disputemsg ? (
                      <div className="dispute"> {t("Trades.Disputed")}</div>
                    ) : (
                      <> </>
                    )}
                    {tradeStatus?.solvedisputemsg ? (
                      <div className="paid">
                        {" "}
                        {t("Trades.Dispute Solved by Admin")}
                      </div>
                    ) : (
                      <> </>
                    )}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>
              <br /> */}

              <div className="order-details-wrapper">
                <Chat orderId={id} maker={MakerUsername?.id} taker={userId} />{" "}
                <OrderInfo
                  order={order}
                  username={username}
                  isUserBuying={userId == order.makerId && !order.isMakerBuying}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <SectionSix />
    </>
  );
};

export default OrderDetails;
