import React from "react";
import { Button } from "react-bootstrap";
import ContentBox from "./contentBox";
import ContentBoxOnePic from "../../../assets/backgrounds/content-box-1.svg";
import ContentBoxTwoPic from "../../../assets/backgrounds/content-box-2.svg";
import ContentBoxThreePic from "../../../assets/backgrounds/content-box-3.svg";
import ContentBoxFourPic from "../../../assets/backgrounds/content-box-4.svg";
import ContentBoxFivePic from "../../../assets/backgrounds/content-box-5.svg";
import ContentBoxSixPic from "../../../assets/backgrounds/content-box-6.svg";
import { Link } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";

function SectionTwo() {
  const [t, i18n] = useTranslation("common");

  return (
    <div className="section-2">
      <p className="section-2-heading">{t("Home.Trade Crypto with Wowcoin")}</p>
      <p className="section-2-sub-heading">
        {t(
          "Home.Buy and sell digital currencies using over 10 different payment methods"
        )}
      </p>
      <div className="content-box-wrapper">
        <ContentBox
          pic={ContentBoxOnePic}
          heading={t("Home.Blockchain escrow service")}
          line={t("Home.Escrow accounts are secured , making it impossible for hackers to withdraw")}
        />
        <ContentBox
          pic={ContentBoxTwoPic}
          heading={t("Home.Online wallets")}
          line={t("Home.Buy wowcoin using a online wallets, including local bank,cash, and paypal")}
        />
        <ContentBox
          pic={ContentBoxThreePic}
          heading={t("Home.Fast, no middlemen")}
          line={t("Home.On walletdays, Most trades are over in ten minutes")}
        />
        <ContentBox
          pic={ContentBoxFourPic}
          heading={t("Home.Cash Payment")}
          line={t("Home.Cash is accepted here too no bank account needed")}
        />
        <ContentBox
          pic={ContentBoxFivePic}
          heading={t("Home.8+ ways to pay")}
          line={t("Home.Buy and sell cryptos using any payment method")}
        />
        <ContentBox
          pic={ContentBoxSixPic}
          heading={t("Home.Encrypted messages")}
          line={t("Home.Messages between users are end-to-end encrypted")}
        />
      </div>

      <Button as={Link} to="/my-offers" className="section-2-btn">
        {t("Home.View all offers")}
      </Button>
    </div>
  );
}

export default SectionTwo;
