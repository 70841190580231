import React, { useEffect, state, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { NavLink, Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { CheckLogin } from "../../store/actions/authActions";
import userImg from "../../views/Marketing/img/user.jpg";
import notiImg from "../../views/Marketing/img/bell-ring.png";
import Axios from 'axios';
import { Auth, authData } from '../../auth/Auth';
import { useTranslation, withTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import "../../views/Marketing/fonts/sfuidisplay.css";
import "../../views/Marketing/css/plugins.min.css";
import "../../views/Marketing/css/app.css";
import "../../views/Marketing/css/custom.css";
import brandLogo from "../../views/Marketing/img/logo-01.png";
import settiLogo from "../../views/Marketing/img_/gear.png";
import dashLogo from "../../views/Marketing/img_/monitor.png";
import walletLogo from "../../views/Marketing/img_/wallet.png";
import logout from "../../views/Marketing/img_/logout.png";
import blockImg from "../../views/Marketing/img/block.png";
import giveMoney from "../../views/Marketing/img_/give-money.png";
import En from "../../views/Marketing/img/en.jpg";
import Ko from "../../views/Marketing/img/ko.jpg";
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import "./Navigationa.scss";
import Notibar from '../../views/Notifications/Notibar/Notibar';
import { getWowBalance, balanceSub } from "../../helpers/CallAPi";

const baseURL = process.env.REACT_APP_BASE_URL;

function Navigation() {
  const username = localStorage.getItem("username");
  const activeOffers = useSelector((state) => {
    if (state.myOffers.myOffers === undefined) {
      return 0;
    }
    return state.myOffers.myOffers.reduce((acc, cur) => {
      cur.pause === false ? (acc += 1) : (acc += 0);
      return acc;
    }, 0);
  });

  const [WalletBalance, setWalletBalance] = useState();
  const [isDisplayNav, setIsDisplayNav] = useState(true);
  const dispatch = useDispatch();
  const UserId = localStorage.getItem('userId');
  const token = localStorage.getItem("token");
  const [notiPerson, setNotiperson] = useState();
  const history = useHistory();

  const changeLanguage = lng => {
    localStorage.setItem("lng", lng);
    i18n.changeLanguage(lng);
  };
  
  const logoutHandler = () => {
    // dispatch(doLogout(dispatch,history));
    doLogout(dispatch, history);
  };

  useEffect(() => {
    let lng = localStorage.getItem('lng');
    if (lng) {
      i18n.changeLanguage(lng);
    }
  checkAuth();
  }, []);

  useEffect(() => {
    if (UserId) {
      try {
        balanceSub.subscribe((res) => {
          const UserId = localStorage.getItem('userId');
          if (res == null && UserId) {
            getWowBalance(UserId);
          } else {
            setWalletBalance(res);
          }
        });
      } catch (error) {
      }

      getAllCommanNoti();
    }
    setInterval(() => {
      try {
        balanceSub.subscribe((res) => {
          const UserId = localStorage.getItem('userId');
          if (res == null && UserId) {
            getWowBalance(UserId);
            } else {
            setWalletBalance(res);
          }
        });
      } catch (error) {
      }
      getAllCommanNoti();
    }, 5000)



  });

  const checkAuth = () => {
    authData.subscribe((res) => {
      if (res == null) {
        Auth.isAuthenticated();
      } else {
        setIsDisplayNav(res);
      }
    });
  }

  const getAllCommanNoti = () => {
    const UserId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    if(UserId && token){
      Axios.get(`${baseURL}/api/notifications/getall_commannotification/${UserId}`, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
      .then((res) => {
          setNotiperson(res.data.notification_count);
        }).catch((err) => {
        });
    }
    
  }

  const [t, i18n] = useTranslation('common');

  return (
    <>
      <Navbar collapseOnSelect fixed='top' expand='sm' className="header-box mob-back" style={{ paddingTop: "0px", paddingBottom: "0px", backgroundColor: "#fff", position: "fixed" }}>
        <Container>
        
          <Link to="/">
            <img className="navbar-brand__regular" src={brandLogo} alt="brand-logo" style={{ width: '180px' }} />
          </Link>
          <img className="navbar-brand__regular mob-en-flag" onClick={() => changeLanguage('en')} src={En} alt="brand-logo" style={{ width: '50px' }} />

          <img className="navbar-brand__regular mob-en-flag2" onClick={() => changeLanguage('ko')} src={Ko} alt="brand-logo" style={{ width: '50px' }} />
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav' style={{ flexGrow: "0" }} >
            <Nav className="justify-content-end " activeKey="/home" >
              {!isDisplayNav ? (
                <>
                  <ul className="navbar-nav" id="navbar-nav">
                    <li className="nav-item" style={{ paddingTop: '2%', width: "96px" }}>
                      <Link to="/">
                        <a className="nav-link">{t('menu.HOME')}</a>
                      </Link>
                    </li>
                    <li className="nav-item" style={{ paddingTop: '2%', width: "96px" }}>
                      <Link to="/offers/trade">
                        <a className="nav-link" >{t('menu.EXPLORE')}</a>
                      </Link>
                    </li>
                    <li>
                      <NavLink to="/login">
                        <a className="btn btn-bg--cta--41 btn-hover--3d rounded--full sign-btn" style={{ boxShadow: "0 16px 24px rgba(51,51,51,0.1)", width: "130px" }}>
                          <span className="btn__text">{t('menu.SIGN IN')}</span>
                        </a>

                      </NavLink>

                    </li>
                    <li>

                      <NavLink to="/signup" style={{ width: "110px" }}>
                        <a className="btn btn-bg--cta--4 btn-hover--3d rounded--full">
                          <span className="btn__text">{t('menu.REGISTER')}</span>
                        </a>
                      </NavLink>
                    </li>
                    <li onClick={() => changeLanguage('en')} className="nav-item mob-flag-none" style={{ marginTop: '1%', fontWeight: '900', paddingLeft: '12px' }}>
                      <img className="navbar-brand__regular" src={En} alt="brand-logo" style={{ width: '50px' }} />

                    </li>
                    <li onClick={() => changeLanguage('ko')} className="nav-item mob-flag-none" style={{ marginTop: '1%', fontWeight: '900' }}>
                      <img className="navbar-brand__regular" src={Ko} alt="brand-logo" style={{ width: '50px' }} />

                    </li>

                  </ul>
                </>
              ) : (
                <>
                  <ul className="navbar-nav" id="navbar-nav" >
                    <li className="nav-item">
                      <Link to="/">
                        <a className="nav-link log-nav" style={{ marginTop: "12px" }}>{t('menu.HOME')}</a>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/offers/trade">
                        <a className="nav-link nav1a log-nav" style={{ width: "96px", marginTop: "12px" }}>{t('menu.EXPLORE')}</a>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/my-trades">
                        <a className="nav-link log-nav" style={{ width: "96px", marginTop: "12px" }}>{t('menu.TRADES')}</a>
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/my-offers">
                        <a className="nav-link log-nav" style={{ width: "96px", marginTop: "12px" }}>{t('menu.OFFERS')}</a>
                      </NavLink>
                    </li>


                    <li class="nav-item dropdown mob-dropa" style={{ marginTop: "10px" }}>
                      <a class="nav-link dropdown-toggle" href="javascript:;" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        {t('wallet.WALLET')}
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <NavLink to="/wallet/SendWowCoin" style={{ color: '#212529' }}>
                            <a class="dropdown-item" style={{ paddingTop: "0px", paddingBottom: "0px" }}>{t('wallet.Wow wallet')}</a>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/wallet/SendBnbCoin" style={{ color: '#212529' }}>
                            <a class="dropdown-item" style={{ paddingTop: "0px", paddingBottom: "0px" }}>{t('wallet.MATIC wallet')}</a>
                          </NavLink>
                        </li>
                      </ul>
                    </li>

                    {/* <li className="nav-item" style={{ width: '130px', marginTop: '18px', fontWeight: '900' }}>
                      {WalletBalance} wow
                    </li> */}
                    <li>

                    </li>


                    <li class="nav-item dropdown mob-dropa" style={{ marginTop: "12px" }}>
                      <a class="nav-link dropdown-toggle" href="javascript:;" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        {notiPerson ? <div style={{ background: "red", marginBottom: "-12px", color: "#fff", borderRadius: "20px", marginLeft: "10px" }}><span style={{ paddingLeft: "3px", }}>{notiPerson}</span></div> : <></>}
                        <img src={notiImg} style={{ width: '21px' }}></img>
                      </a>
                      <ul class="dropdown-menu drop-lap">
                        <li>
                          <NavLink to="/noti-all" style={{ color: '#212529' }}>
                            <a class="dropdown-item"  >
                              {/* <img src="/static/media/user.2ddb333a.jpg" height="25px" width="25px" style={{ marginRight: "5px" }} /> */}
                              <Notibar /></a>
                          </NavLink>
                        </li>
                        <NavLink to="/notiall" style={{ color: '#212529' }}>
                          <button style={{ marginLeft: "90px" }}>{t("closedcontract.View All")}</button>
                        </NavLink>
                      </ul>
                    </li>
                    <li class="nav-item dropdown mob-dropa" style={{ marginTop: "10px" }}>
                      <a class="nav-link dropdown-toggle" href="javascript:;" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <img src={userImg} style={{ width: '27px' }}></img>
                      </a>
                      <ul class="dropdown-menu">
                        <li>
                          <NavLink to="/my-account" style={{ color: '#212529' }}>
                            <a class="dropdown-item" style={{ paddingTop: "0px", paddingBottom: "0px" }}><img src="/static/media/user.2ddb333a.jpg" height="25px" width="25px" style={{ marginRight: "5px" }} />{username}</a>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/my-account" style={{ color: '#212529' }}>
                            <a class="dropdown-item" style={{ paddingTop: "0px", paddingBottom: "0px" }}><img src={settiLogo} height="15px" width="15px" style={{ marginRight: "5px" }} />{t('menu.Setting')}</a>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/dashboard/my-account" style={{ color: '#212529' }}>
                            <a class="dropdown-item" style={{ paddingTop: "0px", paddingBottom: "0px" }}><img src={dashLogo} height="15px" width="15px" style={{ marginRight: "5px" }} />{t('Dropdown.Dashboard')}</a>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/wallet/SendWowCoin" style={{ color: '#212529' }}>
                            <a class="dropdown-item" style={{ paddingTop: "0px", paddingBottom: "0px" }}><img src={walletLogo} height="15px" width="15px" style={{ marginRight: "5px" }} />{t('menu.Wallet')}: {WalletBalance} WOW</a>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/withdraw/SendWowCoin" style={{ color: '#212529' }}>
                            <a class="dropdown-item" style={{ paddingTop: "0px", paddingBottom: "0px" }}><img src={walletLogo} height="15px" width="15px" style={{ marginRight: "5px" }} />{t('Dropdown.Withdraw')}</a>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/blockuser" style={{ color: '#212529' }}>
                            <a class="dropdown-item" style={{ paddingTop: "0px", paddingBottom: "0px" }}><img src={blockImg} height="15px" width="15px" style={{ marginRight: "5px" }} />{t('Dropdown.Blocked User')}</a>
                          </NavLink>
                        </li>
                        <li>
                          <a class="dropdown-item" style={{ paddingTop: "0px", paddingBottom: "0px" }} onClick={() => logoutHandler()}><img src={logout} height="15px" width="15px" style={{ marginRight: "5px" }} />{t('menu.Logout')}</a>
                        </li>

                      </ul>
                    </li>

                    <Dropdown className="desk-dropa">
                      <Dropdown.Toggle style={{ background: "#fff" }} id="dropdown-basic">
                        {notiPerson ? <div style={{ background: "red", marginBottom: "-12px", color: "#fff", borderRadius: "20px", marginLeft: "10px" }}><span style={{ paddingLeft: "3px", }}>{notiPerson}</span></div> : <></>}
                        <img src={notiImg} style={{ width: '27px' }}></img>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item >
                          <Notibar />
                        </Dropdown.Item>
                        <NavLink to="/notiall" style={{ color: '#212529' }}>
                          <button style={{ marginLeft: "90px" }}>{t("closedcontract.View All")}</button>
                        </NavLink>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className="desk-dropa">
                      <Dropdown.Toggle style={{ background: "#fff" }} id="dropdown-basic">
                        <img src={userImg} style={{ width: '21px' }}></img>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item >
                          <NavLink to="#">{username}</NavLink></Dropdown.Item>
                        <Dropdown.Item >
                          <NavLink to="/my-account">{t('menu.Setting')}</NavLink></Dropdown.Item>
                        <Dropdown.Item >
                          <NavLink to="/dashboard/my-account">Dashboard</NavLink></Dropdown.Item>
                        <Dropdown.Item > <NavLink to="/wallet/SendWowCoin">{t('menu.Wallet')}: {WalletBalance} WOW</NavLink></Dropdown.Item>
                        <Dropdown.Item > <NavLink to="/withdraw/SendWowCoin">{t("Withdraw.Withdraw")}</NavLink></Dropdown.Item>
                        <Dropdown.Item > <NavLink to="/blockuser">Blocked Users</NavLink></Dropdown.Item>
                        <Dropdown.Item > <NavLink to="/escrow">Escrow</NavLink></Dropdown.Item>
                        <Dropdown.Item onClick={() => logoutHandler()}>{t('menu.Logout')}</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>



                    <li className="nav-item" style={{ marginTop: "10px", paddingRight: "1%" }}>
                      <Link to="/new-offer">
                        <button type="button" class="ant-btn ant-btn-primary" style={{ borderRadius: "20px" }}><span>+ {t('menu.NEW OFFER')}</span></button>
                      </Link>
                    </li>

                    <li onClick={() => changeLanguage('en')} className="nav-item mob-flag-none" style={{ marginTop: '1%', fontWeight: '900' }}>
                      <img className="navbar-brand__regular" src={En} alt="brand-logo" style={{ width: '50px' }} />

                    </li>
                    <li onClick={() => changeLanguage('ko')} className="nav-item mob-flag-none" style={{ marginTop: '1%', fontWeight: '900' }}>
                      <img className="navbar-brand__regular" src={Ko} alt="brand-logo" style={{ width: '50px' }} />

                    </li>

                  </ul>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      {/* <header className="navbar navbar-sticky sticky-bg-color--primary navbar-expand-lg navbar-light header-box" style={{ paddingTop: '0px' , paddingBottom: '0px' }}>
    <div className="container position-relative">
         <a className="navbar-brand" href="/">
         <img className="navbar-brand__regular" src={brandLogo}  alt="brand-logo" style={{ width: '180px' }}/>
         <img className="navbar-brand__sticky" src={brandLogo}  alt="sticky brand-logo" style={{ width: '180px' }} />
         </a>
     
        <div>
          <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation" style={{ marginTop: '-6%' }}>
            <span className="navbar-toggler-icon" />
          </button>
          <div className="navbar-inner mr-lg-auto pl-lg-2 pl-xl-6">
            <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation" style={{ marginTop: '-6%' }}>
              <span className="navbar-toggler-icon" />
            </button>
            <nav>
             
              {!isDisplayNav ? (
                 <ul className="navbar-nav" id="navbar-nav">
                <li className="nav-item" style={{ paddingTop: '2%',width: "96px" }}>
                  <Link to="/">
                    <a className="nav-link">{t('menu.HOME')}</a>
                  </Link>
                </li>
                <li className="nav-item" style={{ paddingTop: '2%',width: "96px" }}>
                  <Link to="/offers/trade">
                    <a className="nav-link" >{t('menu.EXPLORE')}</a>
                  </Link>
                </li>
                <li>
                  <NavLink to="/login">
                    <a className="btn btn-size--md rounded--full bg-white btn-hover--3d color--primary" ><span className="btn__text font-w--500" style={{width: "80px", padding: "4px"}}>{t('menu.SIGN IN')}</span></a>
                  </NavLink>

                </li>
                <li>

                  <NavLink to="/signup" style={{ width: "110px" }}>
                    <a className="btn btn-bg--cta--4 btn-hover--3d rounded--full">
                      <span className="btn__text">{t('menu.REGISTER')}</span>
                    </a>
                  </NavLink>
                </li>
                <li   onClick={() => changeLanguage('en')} className="nav-item" style={{marginTop: '1%',fontWeight: '900',paddingLeft: '12px' }}>
                <img className="navbar-brand__regular" src={En}  alt="brand-logo" style={{ width: '50px' }}/>
        
               </li>
               <li  onClick={() => changeLanguage('ko')} className="nav-item" style={{marginTop: '1%',fontWeight: '900' }}>
                <img className="navbar-brand__regular" src={Ko}  alt="brand-logo" style={{ width: '50px' }}/>
        
               </li>

                </ul>
                 ) : (
                  <ul className="navbar-nav" id="navbar-nav">
                  <li className="nav-item">
                  <Link to="/">
                    <a className="nav-link">{t('menu.HOME')}</a>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/offers/trade">
                    <a className="nav-link nav1a" style={{width: "96px"}}>{t('menu.EXPLORE')}</a>
                  </Link>
                </li>
                <li className="nav-item">
                  <NavLink to="/my-trades">
                    <a className="nav-link" style={{width: "96px"}}>{t('menu.TRADES')}</a>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/my-offers">
                    <a className="nav-link" style={{width: "96px"}}>{t('menu.OFFERS')}</a>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/wallet/SendWowCoin">
                    <a className="nav-link" style={{width: "96px"}}>{t('menu.WALLET')}</a>
                  </NavLink>
                </li>
                <li className="nav-item" style={{ width: '130px',marginTop: '1%',fontWeight: '900' }}>
                {WalletBalance} wow
               </li>
                <li class="nav-item dropdown">
                              <a class="nav-link dropdown-toggle" href="javascript:;" data-toggle="dropdown"
                                  aria-haspopup="true" aria-expanded="false">
                                    <img src={ userImg  } style={{ width: '27px' }}></img>
                                    </a>
                              <ul class="dropdown-menu">
                                  <li>
                                  <NavLink to="/my-account" style={{ color:'#212529' }}>
                                      <a class="dropdown-item" >{t('menu.Setting')}</a>
                                  </NavLink>
                                  </li>

                                  <li>
                                  <NavLink to="/wallet/SendWowCoin" style={{ color:'#212529' }}>
                                      <a class="dropdown-item" >&nbsp; {t('menu.Wallet')}: {WalletBalance} WOW</a>
                                  </NavLink>
                                  </li>
                                  
                                  <li>
                                      <a class="dropdown-item" onClick={() => logoutHandler()}>{t('menu.Logout')}</a>
                                  </li>
                                  
                              </ul>
                          </li>

                <li className="nav-item">
                  <Link to="/new-offer">
                    <a className="nav-link" style={{width: "126px"}}>+ {t('menu.NEW OFFER')}</a>
                  </Link>
                </li>
                
                <li   onClick={() => changeLanguage('en')} className="nav-item" style={{marginTop: '1%',fontWeight: '900' }}>
                <img className="navbar-brand__regular" src={En}  alt="brand-logo" style={{ width: '50px' }}/>
        
               </li>
               <li  onClick={() => changeLanguage('ko')} className="nav-item" style={{marginTop: '1%',fontWeight: '900' }}>
                <img className="navbar-brand__regular" src={Ko}  alt="brand-logo" style={{ width: '50px' }}/>
        
               </li>
              </ul>
              )}
            </nav>
          </div>
        </div>

    </div>
  </header> */}
    </>
  );
}


export default Navigation;
