import React, { useEffect, useState } from "react";
import userImg from "../../views/Marketing/img/user.jpg";
import notiImg from "../../views/Marketing/img/bell-ring.png";
import brandLogo from "../../views/Marketing/img/logo-01.png";
import settiLogo from "../../views/Marketing/img_/gear.png";
import dashLogo from "../../views/Marketing/img_/monitor.png";
import walletLogo from "../../views/Marketing/img_/wallet.png";
import logout from "../../views/Marketing/img_/logout.png";
import blockImg from "../../views/Marketing/img/block.png";
import giveMoney from "../../views/Marketing/img_/give-money.png";
import En from "../../views/Marketing/img/en.jpg";
import Ko from "../../views/Marketing/img/ko.jpg";
import { Button, Dropdown } from "react-bootstrap";
import { ReactComponent as Logo } from "../../assets/logos/footer.svg";
import { ReactComponent as Menu } from "../../assets/icons/menu.svg";
import { ReactComponent as DownArrow } from "../../assets/icons/downarrow.svg";
import { ReactComponent as Polygon } from "../../assets/icons/Polygon.svg";
import { ReactComponent as Avatar } from "../../assets/icons/avatar.svg";
import { ReactComponent as Logout } from "../../assets/icons/logout.svg";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Settings } from "../../assets/icons/settings.svg";
import { ReactComponent as Wallet } from "../../assets/icons/wallet.svg";
import { ReactComponent as Dashboard } from "../../assets/icons/dashboard.svg";
import { ReactComponent as Withdraw } from "../../assets/icons/withdraw.svg";
import LogoMob from "../../assets/logos/footer.svg";
import English from "../../assets/icons/english.svg";
import Korean from "../../assets/icons/korean.svg";
import "./header.css";
import { Modal } from "antd";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import Axios from "axios";
import { Auth, authData } from "../../auth/Auth";
import { balanceSub, getWowBalance } from "../../helpers/CallAPi";
import { doLogout } from "../../store/actions/authActions";
import Notibar from "../../views/Notifications/Notibar/Notibar";

const baseURL = process.env.REACT_APP_BASE_URL;
function HeaderComp() {
  const username = localStorage.getItem("username");
  const activeOffers = useSelector((state) => {
    if (state.myOffers.myOffers === undefined) {
      return 0;
    }
    return state.myOffers.myOffers.reduce((acc, cur) => {
      cur.pause === false ? (acc += 1) : (acc += 0);
      return acc;
    }, 0);
  });

  const [WalletBalance, setWalletBalance] = useState();
  const [isDisplayNav, setIsDisplayNav] = useState(true);
  const dispatch = useDispatch();
  const UserId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [notiPerson, setNotiperson] = useState();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { pathname } = useLocation();
  const path = pathname.split("/");
  const changeLanguage = (lng) => {
    localStorage.setItem("lng", lng);
    i18n.changeLanguage(lng);
  };
  const logoutHandler = () => {
    // dispatch(doLogout(dispatch,history));
    doLogout(dispatch, history);
  };

  useEffect(() => {
    let lng = localStorage.getItem("lng");
    if (lng) {
      i18n.changeLanguage(lng);
    }
    checkAuth();
  }, []);

  useEffect(() => {
    if (UserId) {
      try {
        balanceSub.subscribe((res) => {
          const UserId = localStorage.getItem("userId");
          if (res == null && UserId) {
            getWowBalance(UserId);
          } else {
            setWalletBalance(res);
          }
        });
      } catch (error) { }

      getAllCommanNoti();
    }
    setInterval(() => {
      try {
        balanceSub.subscribe((res) => {
          const UserId = localStorage.getItem("userId");
          if (res == null && UserId) {
            getWowBalance(UserId);
          } else {
            setWalletBalance(res);
          }
        });
      } catch (error) { }
      getAllCommanNoti();
    }, 5000);
  });

  const checkAuth = () => {
    authData.subscribe((res) => {
      if (res == null) {
        Auth.isAuthenticated();
      } else {
        setIsDisplayNav(res);
      }
    });
  };

  const getAllCommanNoti = () => {
    const UserId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    if (UserId && token) {
      Axios.get(
        `${baseURL}/api/notifications/getall_commannotification/${UserId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((res) => {
          setNotiperson(res.data.notification_count);
        })
        .catch((err) => { });
    }
  };

  const [t, i18n] = useTranslation("common");
  console.log(path);
  const [modal1Visible, setModal1Visible] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const changeBackground = () => {
    console.log(window.scrollY);
    if (window.scrollY >= 66) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });
  return (
    <>
      {!isDisplayNav ? (
        <>

          <div
            className="main-web-header"
            style={{ background: navbar ? "#ffffff" : "none" }}
          >

            <div className="web-header" style={{ display: 'flex' }}>
              <Link
                style={{ textDecoration: "none", background: "none" }}
                to="/"
              >
                {" "}
                <Logo style={{ cursor: "pointer", minWidth: "174px" }} />
              </Link>

              <div className="web-header-right">
                <Link
                  style={{
                    background: "none",
                    color: path[1] === "" ? "#2074F2" : "#000000",
                  }}
                  to="/"
                >
                  <p
                    style={{
                      color: path[1] === "" ? "#2074F2" : "#000000",
                    }}
                    className="tagsLogin"
                  >
                    {t("menu.Home")}
                  </p>
                </Link>

                <Link
                  style={{
                    background: "none",
                  }}
                >
                  <p
                    style={{
                      minWidth: "75px",
                      color: path[1] === "offers" ? "#2074F2" : "#000000",
                    }}
                    as={Link}
                    to="/offers/buywowCoin?page=1"
                    onClick={() => {
                      window.location.href = `/offers/buywowCoin?page=1`;
                    }}
                    className="tags"
                  >
                    {t("menu.Explore")}
                  </p>
                </Link>

                <Link style={{ background: "none" }}>
                  <p
                    style={{
                      minWidth: "75px",
                      color: path[1] === "contact" ? "#2074F2" : "#000000",
                    }}
                    as={Link}
                    to="/contact"
                    className="tags"
                    onClick={() => {
                      window.location.href = `/contact`;
                    }}
                  // className="tags"
                  // onClick={() => {
                  //   window.scrollTo(0, document.body.scrollHeight);
                  // }}
                  >
                    {t("menu.Contact")}
                  </p>
                </Link>

                <Link to="/login">
                  <Button className="login-btn">{t("menu.Log in")}</Button>
                </Link>
                <Link to="/signup">
                  <Button className="register-btn">{t("menu.Register")}</Button>
                </Link>
                <img
                  className="flag-img"
                  onClick={() => changeLanguage("en")}
                  src={English}
                  alt="eng"
                />
                <img
                  className="flag-img"
                  onClick={() => changeLanguage("ko")}
                  src={Korean}
                  alt="eng"
                />
              </div>
            </div>

          </div>
          <div
            className="main-mob-header"
            style={{ background: navbar ? "#ffffff" : "none" }}
          >
            <div className="mob-header">
              <Link
                style={{ textDecoration: "none", background: "none" }}
                to="/"
              >
                <img src={LogoMob} alt="logo" style={{ cursor: "pointer" }} />
              </Link>
              <div className="mob-header-right">
                <img
                  className="flag-img"
                  onClick={() => changeLanguage("en")}
                  src={English}
                  alt="eng"
                />
                <img
                  className="flag-img"
                  onClick={() => changeLanguage("ko")}
                  src={Korean}
                  alt="eng"
                />
                <Menu
                  style={{ cursor: "pointer" }}
                  onClick={() => setModal1Visible(true)}
                />
                <Modal
                  footer={false}
                  style={{
                    top: 0,
                    minWidth: "100vw",
                  }}
                  visible={modal1Visible}
                  onOk={() => setModal1Visible(false)}
                  onCancel={() => setModal1Visible(false)}
                >
                  <Link
                    style={{
                      background: "none",
                      color: path[1] === "" ? "#2074F2" : "#000000",
                    }}
                    to="/"
                  >
                    <p
                      style={{
                        color: path[1] === "" ? "#2074F2" : "#000000",
                      }}
                      className="tagsLogin"
                    >
                      {t("menu.Home")}
                    </p>
                  </Link>

                  <Link
                    style={{ background: "none" }}
                    to="/offers/buywowCoin?page=1"
                  >
                    <p
                      style={{
                        color: path[1] === "offers" ? "#2074F2" : "#000000",
                      }}
                      as={Link}
                      to="/offers/buywowCoin?page=1"
                      onClick={() => {
                        window.location.href = `/offers/buywowCoin?page=1`;
                      }}
                      className="tags"
                    >
                      {t("menu.Explore")}
                    </p>
                  </Link>

                  <Link style={{ background: "none" }}>
                    <p
                      style={{
                        color: path[1] === "contact" ? "#2074F2" : "#000000",
                      }}
                      as={Link}
                      to="/contact"
                      className="tags"
                      onClick={() => {
                        window.location.href = `/contact`;
                      }}
                    // className="tags"
                    // onClick={() => {
                    //   window.scrollTo(0, document.body.scrollHeight);
                    // }}
                    >
                      {t("menu.Contact")}
                    </p>
                  </Link>

                  <Link to="/login">
                    <Button
                      className="login-btn"
                      style={{ marginLeft: "0px", marginBottom: "10px" }}
                    >
                      {t("menu.Log in")}
                    </Button>
                  </Link>
                  <Link to="/signup">
                    <Button
                      style={{ marginLeft: "0px" }}
                      className="register-btn"
                    >
                      {t("menu.Register")}
                    </Button>
                  </Link>
                </Modal>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="main-web-header-login"
            style={{ background: navbar ? "#ffffff" : "none" }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="tag mt-1">
                <p>WOW COIN: {WalletBalance ?? '0'}</p>
              </div>
              <div className="web-header">
                <Link
                  style={{ textDecoration: "none", background: "none" }}
                  to="/"
                >
                  <Logo style={{ cursor: "pointer", minWidth: "174px" }} />
                </Link>

                <div className="web-header-right">
                  <Link style={{ background: "none" }} to="/">
                    <p
                      style={{
                        background: "none",
                        color: path[1] === "" ? "#2074F2" : "#000000",
                      }}
                      className="tagsLogin"
                    >
                      {t("menu.Home")}
                    </p>
                  </Link>
                  <Link style={{ background: "none" }}>
                    <p
                      style={{
                        background: "none",
                        color: path[1] === "offers" ? "#2074F2" : "#000000",
                        minWidth: "70px",
                      }}
                      as={Link}
                      to="/offers/buywowCoin?page=1"
                      onClick={() => {
                        window.location.href = `/offers/buywowCoin?page=1`;
                      }}
                      className="tagsLogin"
                    >
                      {t("menu.Explore")}
                    </p>
                  </Link>
                  <Link style={{ background: "none" }}>
                    <p
                      style={{
                        background: "none",
                        color: path[1] === "my-trades" ? "#2074F2" : "#000000",
                        minWidth: "35px",
                      }}
                      onClick={() => {
                        window.location.href = `/my-trades`;
                      }}
                      as={Link}
                      to="/my-trades"
                      className="tagsLogin"
                    >
                      {t("menu.Trades")}
                    </p>
                  </Link>
                  <Link style={{ background: "none" }}>
                    <p
                      style={{
                        minWidth: "35px",
                        color: path[1] === "my-offers" ? "#2074F2" : "#000000",
                      }}
                      onClick={() => {
                        window.location.href = `/my-offers`;
                      }}
                      as={Link}
                      to="/my-offers"
                      className="tagsLogin"
                    >
                      {t("menu.Offers")}
                    </p>
                  </Link>
                  <div class="dropdown">
                    <div
                      style={{
                        minWidth: "50px",
                        color: path[1] === "wallet" ? "#2074F2" : "#000000",
                      }}
                      className="link-head"
                    >
                      {t("wallet.Wallets")}
                      <DownArrow style={{ marginLeft: "5px" }} />
                    </div>
                    <div class="dropdown-content">
                      <p>
                        <NavLink
                          style={{ color: "#000000" }}
                          to="/wallet/SendWowCoin"
                        >
                          {t("wallet.Wow wallet")}
                        </NavLink>
                      </p>
                      <p>
                        <NavLink
                          style={{ color: "#000000" }}
                          to="/wallet/SendBnbCoin"
                        >
                          {t("wallet.MATIC wallet")}
                        </NavLink>
                      </p>
                    </div>
                  </div>
                  <Link style={{ background: "none" }}>
                    <p
                      style={{
                        minWidth: "75px",
                        color: path[1] === "contact" ? "#2074F2" : "#000000",
                      }}
                      as={Link}
                      to="/contact"
                      className="tagsLogin"
                      onClick={() => {
                        window.location.href = `/contact`;
                      }}
                    >
                      {t("menu.Contact")}
                    </p>
                  </Link>
                  <div class="dropdown">
                    <div className="row-notifications">
                      {" "}
                      <div className="notification-head">
                        {notiPerson ? (
                          <>
                            <div
                              style={{
                                marginLeft: "5px",
                                transform: "translate(15px, -5px)",
                                minWidth: "13px",
                                minHeight: "13px",
                                borderRadius: "5px",
                                textAlign: "center",
                                background: "#FF0000",
                                fontFamily: "Poppins",
                                fontWeight: "600",
                                fontSize: "10px",
                                color: "#FFFFFF",
                              }}
                            >
                              {notiPerson}
                            </div>
                            <div
                              style={{
                                transform: "translateY(-10px)",
                                marginLeft: "10px",
                                width: "28px",
                                height: "28px",
                                borderRadius: "25px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                background: " #FFFFFF",
                                boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.15)",
                              }}
                            >
                              <img
                                src={notiImg}
                                style={{
                                  minWidth: "16px",
                                  maxWidth: "16px",
                                  minHeight: "15px",
                                  maxHeight: "15px",
                                }}
                              />
                            </div>
                          </>
                        ) : (
                          <div
                            style={{
                              width: "28px",
                              height: "28px",
                              marginLeft: "10px",
                              borderRadius: "25px",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              background: " #FFFFFF",
                              boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.15)",
                            }}
                          >
                            {" "}
                            <img
                              src={notiImg}
                              style={{
                                minWidth: "16px",
                                maxWidth: "16px",
                                minHeight: "15px",
                                maxHeight: "15px",
                              }}
                            />
                          </div>
                        )}
                      </div>
                      <Polygon style={{ marginLeft: "5px" }} />
                    </div>

                    <div
                      style={{ minWidth: "279px", overflowWrap: "break-word" }}
                      class="dropdown-content"
                    >
                      <NavLink
                        style={{ color: "#000000", overflow: "auto" }}
                        to="/noti-all"
                      >
                        <Notibar />
                      </NavLink>

                      <NavLink style={{ color: "#000000" }} to="/notiall">
                        <button style={{ marginLeft: "90px" }}>
                          {t("closedcontract.View All")}
                        </button>
                      </NavLink>
                    </div>
                  </div>
                  <div class="dropdown">
                    <div className="row-notifications">
                      <Avatar style={{ minWidth: "45px", marginTop: "5px" }} />
                    </div>

                    <div
                      style={{
                        maxWidth: "194px",
                        minWidth: "194px",
                        background: "#FFFFFF",
                        borderRadius: "4px",
                      }}
                      class="dropdown-content"
                    >
                      <NavLink to="/my-account" style={{ color: "#212529" }}>
                        <p
                          class="avatar-item"
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <User style={{ marginRight: "15px" }} />
                          {username}
                        </p>
                      </NavLink>
                      <NavLink
                        to="/dashboard/contract"
                        style={{ color: "#212529" }}
                      >
                        <p
                          class="avatar-item"
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <Dashboard style={{ marginRight: "15px" }} />
                          {t("Dropdown.Dashboard")}
                        </p>
                      </NavLink>
                      <NavLink to="/my-account" style={{ color: "#212529" }}>
                        <p
                          class="avatar-item"
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <Settings style={{ marginRight: "15px" }} />
                          {t("menu.Setting")}
                        </p>
                      </NavLink>

                      <NavLink
                        to="/wallet/SendWowCoin"
                        style={{ color: "#212529" }}
                      >
                        <p
                          class="avatar-item"
                          style={{
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            color: path[1] === "wallet" ? "#2074F2" : "#000000",
                          }}
                        >
                          <Wallet style={{ marginRight: "15px" }} />
                          {t("menu.Wallet")}
                        </p>
                      </NavLink>

                      <NavLink
                        to="/withdraw/SendWowCoin"
                        style={{ color: "#212529" }}
                      >
                        <p
                          class="avatar-item"
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <Withdraw style={{ marginRight: "15px" }} />
                          {t("Dropdown.Withdraw")}
                        </p>
                      </NavLink>

                      <NavLink to="/blockuser" style={{ color: "#212529" }}>
                        <p
                          class="avatar-item"
                          style={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <img
                            src={blockImg}
                            height="15px"
                            width="15px"
                            style={{ marginRight: "15px" }}
                          />
                          {t("Dropdown.Blocked User")}
                        </p>
                      </NavLink>

                      <p
                        class="avatar-item"
                        style={{
                          paddingTop: "0px",
                          paddingBottom: "0px",
                          cursor: "pointer",
                        }}
                        onClick={() => logoutHandler()}
                      >
                        <Logout style={{ marginRight: "15px" }} />
                        {t("menu.Logout")}
                      </p>
                    </div>
                  </div>

                  <Link to="/new-offer">
                    <button type="button" class="new-offer-btn">
                      <span>+ {t("menu.New Offer")}</span>
                    </button>
                  </Link>

                  <img
                    className="flag-img"
                    onClick={() => changeLanguage("en")}
                    src={English}
                    alt="eng"
                  />
                  <img
                    className="flag-img"
                    onClick={() => changeLanguage("ko")}
                    src={Korean}
                    alt="eng"
                  />
                </div>
              </div>
            </div>

            <ul className="navbar-nav" id="navbar-nav"></ul>
          </div>
          <div
            className="main-mob-header-login"
            style={{ background: navbar ? "#ffffff" : "none" }}
          >
            <div className="mob-header">
              <Link
                style={{ textDecoration: "none", background: "none" }}
                to="/"
              >
                <img src={LogoMob} alt="logo" style={{ cursor: "pointer" }} />
              </Link>
              <div className="mob-header-right">
                <img
                  className="flag-img"
                  onClick={() => changeLanguage("en")}
                  src={English}
                  alt="eng"
                />
                <img
                  className="flag-img"
                  onClick={() => changeLanguage("ko")}
                  src={Korean}
                  alt="eng"
                />
                <Menu
                  style={{ cursor: "pointer" }}
                  onClick={() => setModal1Visible(true)}
                />
                <Modal
                  footer={false}
                  style={{
                    top: 0,
                    minWidth: "100vw",
                  }}
                  visible={modal1Visible}
                  onOk={() => setModal1Visible(false)}
                  onCancel={() => setModal1Visible(false)}
                >
                  <div className="tag mt-1">
                    <p>WOW COIN: {WalletBalance ?? '0'}</p>
                  </div>
                  <p
                    style={{ maxWidth: "48px", marginBottom: "15px" }}
                    className="tagsLogin"
                  >
                    <Link style={{ background: "none" }} to="/">
                      <p
                        style={{
                          background: "none",
                          color: path[1] === "" ? "#2074F2" : "#000000",
                        }}
                        className="tagsLogin"
                      >
                        {t("menu.Home")}
                      </p>
                    </Link>
                  </p>

                  <Link style={{ background: "none" }}>
                    <p
                      style={{
                        maxWidth: "65px",
                        color: path[1] === "offers" ? "#2074F2" : "#000000",
                        marginBottom: "15px",
                      }}
                      as={Link}
                      to="/offers/buywowCoin?page=1"
                      onClick={() => {
                        window.location.href = `/offers/buywowCoin?page=1`;
                      }}
                      className="tagsLogin"
                    >
                      {t("menu.Explore")}
                    </p>
                  </Link>
                  <Link style={{ background: "none" }}>
                    <p
                      style={{
                        maxWidth: "65px",
                        color: path[1] === "my-trades" ? "#2074F2" : "#000000",
                        marginBottom: "15px",
                      }}
                      onClick={() => {
                        window.location.href = `/my-trades`;
                      }}
                      as={Link}
                      to="/my-trades"
                      className="tagsLogin"
                    >
                      {t("menu.Trades")}
                    </p>
                  </Link>
                  <Link style={{ background: "none" }}>
                    <p
                      style={{
                        maxWidth: "75px",
                        color: path[1] === "my-offers" ? "#2074F2" : "#000000",
                        marginBottom: "15px",
                      }}
                      onClick={() => {
                        window.location.href = `/my-offers`;
                      }}
                      as={Link}
                      to="/my-offers"
                      className="tags"
                    >
                      {t("menu.Offers")}
                    </p>
                  </Link>
                  <div class="dropdown">
                    <div
                      style={{
                        marginBottom: "15px",
                        color: path[1] === "wallet" ? "#2074F2" : "#000000",
                      }}
                      className="link-head"
                    >
                      {t("wallet.Wallets")}
                      <DownArrow style={{ marginLeft: "5px" }} />
                    </div>
                    <div class="dropdown-content">
                      <p>
                        <NavLink
                          style={{ color: "#000000" }}
                          to="/wallet/SendWowCoin"
                        >
                          {t("wallet.Wow wallet")}
                        </NavLink>
                      </p>
                      <p>
                        <NavLink
                          style={{ color: "#000000" }}
                          to="/wallet/SendBnbCoin"
                        >
                          {t("wallet.MATIC wallet")}
                        </NavLink>
                      </p>
                    </div>
                  </div>
                  <Link style={{ background: "none" }}>
                    <p
                      style={{
                        color: path[1] === "contact" ? "#2074F2" : "#000000",
                      }}
                      as={Link}
                      to="/contact"
                      className="tagsLogin"
                      onClick={() => {
                        window.location.href = `/contact`;
                      }}
                    >
                      {t("menu.Contact")}
                    </p>
                  </Link>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div class="dropdown">
                      <div
                        style={{
                          marginBottom: "0",
                        }}
                        className="row-notifications"
                      >
                        {" "}
                        <div className="notification-head">
                          {notiPerson ? (
                            <>
                              <div
                                style={{
                                  marginLeft: "5px",
                                  transform: "translate(15px, -5px)",
                                  minWidth: "13px",
                                  minHeight: "13px",
                                  borderRadius: "5px",
                                  textAlign: "center",
                                  background: "#FF0000",
                                  fontFamily: "Poppins",
                                  fontWeight: "600",
                                  fontSize: "10px",
                                  color: "#FFFFFF",
                                }}
                              >
                                {notiPerson}
                              </div>
                              <div
                                style={{
                                  transform: "translateY(-10px)",
                                  marginLeft: "10px",
                                  width: "28px",
                                  height: "28px",
                                  borderRadius: "25px",
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  background: " #FFFFFF",
                                  boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.15)",
                                }}
                              >
                                <img
                                  src={notiImg}
                                  style={{
                                    minWidth: "16px",
                                    maxWidth: "16px",
                                    minHeight: "15px",
                                    maxHeight: "15px",
                                  }}
                                />
                              </div>
                            </>
                          ) : (
                            <div
                              style={{
                                width: "28px",
                                height: "28px",
                                marginLeft: "10px",
                                borderRadius: "25px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                background: " #FFFFFF",
                                boxShadow: "0px 3px 14px rgba(0, 0, 0, 0.15)",
                              }}
                            >
                              {" "}
                              <img
                                src={notiImg}
                                style={{
                                  minWidth: "16px",
                                  maxWidth: "16px",
                                  minHeight: "15px",
                                  maxHeight: "15px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <Polygon style={{ marginLeft: "5px" }} />
                      </div>

                      <div
                        style={{
                          minWidth: "279px",
                          overflowWrap: "break-word",
                          overflowX: "auto",
                        }}
                        class="dropdown-content"
                      >
                        <NavLink style={{ color: "#000000" }} to="/noti-all">
                          <Notibar />
                        </NavLink>

                        <NavLink style={{ color: "#000000" }} to="/notiall">
                          <button style={{ marginLeft: "90px" }}>
                            {t("closedcontract.View All")}
                          </button>
                        </NavLink>
                      </div>
                    </div>
                    <div class="dropdown">
                      <div className="row-notifications2">
                        <Avatar
                          style={{ minWidth: "45px", marginTop: "5px" }}
                        />
                      </div>

                      <div
                        style={{
                          maxWidth: "194px",
                          minWidth: "194px",
                          background: "#FFFFFF",
                          borderRadius: "4px",
                        }}
                        class="dropdown-content"
                      >
                        <NavLink to="/my-account" style={{ color: "#212529" }}>
                          <p
                            class="avatar-item"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <User style={{ marginRight: "15px" }} />
                            {username}
                          </p>
                        </NavLink>
                        <NavLink
                          to="/dashboard/contract"
                          style={{ color: "#212529" }}
                        >
                          <p
                            class="avatar-item"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <Dashboard style={{ marginRight: "15px" }} />
                            {t("Dropdown.Dashboard")}
                          </p>
                        </NavLink>
                        <NavLink to="/my-account" style={{ color: "#212529" }}>
                          <p
                            class="avatar-item"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <Settings style={{ marginRight: "15px" }} />
                            {t("menu.Setting")}
                          </p>
                        </NavLink>

                        <NavLink
                          to="/wallet/SendWowCoin"
                          style={{ color: "#212529" }}
                        >
                          <p
                            class="avatar-item"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <Wallet style={{ marginRight: "15px" }} />
                            {t("menu.Wallet")}
                          </p>
                        </NavLink>

                        <NavLink
                          to="/withdraw/SendWowCoin"
                          style={{ color: "#212529" }}
                        >
                          <p
                            class="avatar-item"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <Withdraw style={{ marginRight: "15px" }} />
                            {t("Dropdown.Withdraw")}
                          </p>
                        </NavLink>

                        <NavLink to="/blockuser" style={{ color: "#212529" }}>
                          <p
                            class="avatar-item"
                            style={{ paddingTop: "0px", paddingBottom: "0px" }}
                          >
                            <img
                              src={blockImg}
                              height="15px"
                              width="15px"
                              style={{ marginRight: "15px" }}
                            />
                            {t("Dropdown.Blocked User")}
                          </p>
                        </NavLink>

                        <p
                          class="avatar-item"
                          style={{
                            paddingTop: "0px",
                            paddingBottom: "0px",
                            cursor: "pointer",
                          }}
                          onClick={() => logoutHandler()}
                        >
                          <Logout style={{ marginRight: "15px" }} />
                          {t("menu.Logout")}
                        </p>
                      </div>
                    </div>
                  </div>

                  <Link to="/new-offer">
                    <button type="button" class="new-offer-btn">
                      <span>+ {t("menu.New Offer")}</span>
                    </button>
                  </Link>
                </Modal>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default HeaderComp;
