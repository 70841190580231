import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import Axios from "axios";
import { Link, useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import "./Notidetail.scss";
import { useTranslation } from "react-i18next";
// import { fetchOffer } from "../../store/actions/myOffersActions";
import { fetchOffer } from "../../store/actions/myOffersActions";
const baseURL = process.env.REACT_APP_BASE_URL;
const Notidetail = (props) => {
  const [t, i18n] = useTranslation("common");
  const offerDetails = useSelector((state) => state.myOffers.offerDetails);
  const orderDetails = useSelector((state) => state.orders.order);
  const orderErrors = useSelector((state) => state.orders.errorMessages);
  const {
    // limitMax,
    // limitMin,
    // currencyType,
    // currencySymbol,
    // margin,
    // marginAbove,
    makerId,
    // id,
    buyBCH,
  } = offerDetails;
  console.log("truptinotioffer.......", orderDetails);
  const dispatch = useDispatch();
  const { offerId, type, fromuser, fromid, offer_id } = useParams(
    "id",
    "fromuser"
  );
  // console.log("useParams.........................:", offer_id);
  // console.log("fromuserdetail", fromuser);
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const [notiDetail, setNotidetail] = useState({});
  const [notiDetail_, setNotidetail_] = useState("");
  const [id, setid] = useState("");
  const [msg, setmsg] = useState("");
  const [name, setname] = useState("");
  const [notify, setNotification] = useState("");

  let history = useHistory();
  // console.log("chetna.....", notiDetail);
  useEffect(() => {
    dispatch(fetchOffer(offer_id));
    try {
      Axios.get(
        `${baseURL}/api/notifications/get_common_notification_get_detail/${offerId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        // console.log("notification", response);
        setNotidetail(response.data.data[0]);
        setNotidetail_(response.data);
        setNotification(response.data);

        if (type == "Complate_order") {
          const Complateordermsg = response.data.data[0]?.msg;
          var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
          if (splitted.length == 4) {
            var msg = "Paid successfully by";
            var name = splitted[3];
            setmsg(msg);
            setname(name);
          }
        }

        if (type == "Dispute") {
          const Complateordermsg = response.data.data[0]?.msg;
          var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
          const msg = "A dispute request has been sent against you from";
          const name = splitted[10];
          setmsg(msg);
          setname(name);
        }

        if (type == "Conversation") {
          const Complateordermsg = response.data.data[0]?.msg;
          var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
          var msg = "Trade# new message from";
          var id = splitted[1];
          var name = splitted[5];
          setmsg(msg);
          setname(name);
          setid(id);
        }

        if (type == "Dispute_Conversation") {
          const Complateordermsg = response.data.data[0]?.msg;
          var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
          var msg = "Trade# new message from Dispute";
          var id = splitted[1];
          var name = splitted[6];
          setmsg(msg);
          setname(name);
          setid(id);
        }

        if (type == "Dispute Solve Admin") {
          const Complateordermsg = response.data.data[0]?.msg;
          var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
          var msg = "Admin resolved the dispute in favor of";
          if (splitted[7]) {
            var name = splitted[7];
          } else {
            var name = splitted[8];
          }
          setmsg(msg);
          setname(name);
        }

        if (type == "Approve") {
          const Complateordermsg = response.data.data[0]?.msg;
          var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
          var msg = "Trade# released escrow from";
          var id = splitted[1];
          var name = splitted[5];
          setmsg(msg);
          setname(name);
          setid(id);
        }

        if (type == "MaticTransaction") {
          const Complateordermsg = response.data.data[0]?.msg;
          var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
          var msg = "Matic received from";
          var name = splitted[3];
          setmsg(msg);
          setname(name);
        }

        if (type == "Trade") {
          const Complateordermsg = response.data.data[0]?.msg;
          var splitted = Complateordermsg.split(" ");
          let result1 = Complateordermsg.substring(6, 0);
          if (splitted[8] == "Sell") {
            var result = Complateordermsg.substring(59, 11);
          } else {
            var result = Complateordermsg.substring(58, 11);
          }
          //this will output ["1234", "56789"]
          var msg = result1 + " " + result;
          var id = splitted[1];
          var name = splitted[12];
          setmsg(msg);
          setname(name);
          setid(id);
        }

        if (type == "Cancel") {
          const Complateordermsg = response.data.data[0]?.msg;
          console.log("Complateordermsg", Complateordermsg);
          var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
          var msg = splitted[0] + " " + splitted[2] + " " + splitted[3];
          var id = splitted[1];
          if (splitted[4]) {
            var name = splitted[4];
          } else {
            var name = splitted[5];
          }

          setmsg(msg);
          setname(name);
          setid(id);
        }

        if (type == "Transaction") {
          const Complateordermsg = response.data.data[0]?.msg;
          var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
          var msg = "Wowcoin received from";
          var name = splitted[3];
          setmsg(msg);
          setname(name);
        }

        if (type == "Trade Cancelled In 90 Minutes") {
          const Complateordermsg = response.data.data[0]?.msg;
          var msg = Complateordermsg.substring(62, 0);
          //console.log("Trade Cancelled In 90 Minutes",response);
          setmsg(msg);
        }

        if (type == "Kyc_approve_byadmin") {
          const Complateordermsg = response.data.data[0]?.msg;
          if (Complateordermsg.length) {
            var msg = response.data.data[0]?.msg;
          } else {
            var msg = Complateordermsg.substring(38, 0);
          }
          setmsg(msg);
        }

        // console.log("notiDETAIL........chetna", response);
      });
    } catch (error) {
      // console.log(error);
    }
  }, []);
  const openTrade = () => {
    history.push(
      `/${notiDetail?.fromuser}/${notify?.fromid}/${notiDetail?.main_type}/offer-details/${notiDetail?.offer_id}/${notiDetail?.uniqueid}`
    );
    sessionStorage.setItem("sell/buy", `${notiDetail?.fromuser}`);
  };

  const openTrade_ = () => {
    history.push(
      `/${notiDetail?.main_type}/${notiDetail?.type}/actives/${notiDetail?.offer_id}/${notiDetail?.fromuser}/${notiDetail_?.fromid}/${notiDetail?.uniqueid}`
    );
    sessionStorage.setItem("sell/buy", `${notiDetail?.fromuser}`);
  };

  const openWOWTransaction = () => {
    history.push(`/wallet/AdminTransactions`);
  };

  const openMaticTransaction = () => {
    history.push(`/wallet/MaticTransactions`);
  };
  const openTransaction = () => {
    history.push(`/wallet/Transactions`);
  };
  const openUserMTransaction = () => {
    history.push(`/wallet/BnbTransactions`);
  };

  const viewBuyer = (offer_id) => {
    history.push(`/allbuyer/${offer_id}`);
  };

  return (
    <div class="notiall-page">
      <Authcheck />
      <div className="notiall-upper"></div>
      <div className="notiall-container">
        <p className="profile-heading ">{t("Trades.Notification Detail")}</p>

        <div class="card">
          <div class="card-header">{t("Trades.Detail")}</div>
          {type == "Conversation" || type == "Dispute_Conversation" ? (
            <div class="card-body">
              <h6>
                {" "}
                {t("Trades.Message")}:{" "}
                {t("notification." + msg, { name: name, id: id })}
              </h6>
              <button
                class="ant-btn ant-btn-primary"
                onClick={openTrade_}
                className="notibutton"
              >
                {t("Trades.Reply")}
              </button>
            </div>
          ) : (
            <></>
          )}
          {type == "Trade" ? (
            <div class="card-body">
              <h6>
                {t("Trades.Message")}:{" "}
                {t("notification." + msg, { name: name, id: id })}
              </h6>

              <Link
                to={`/trade/${type}/${offerId}/${makerId}/${notiDetail?.uniqueid}`}
              >
                <button
                  id="continue"
                  type="submit"
                  class="ant-btn ant-btn-primary"
                >
                  <span>{t("Trades.View")}</span>
                </button>
              </Link>
            </div>
          ) : (
            <></>
          )}
          {type == "Complate_order" ||
          type == "Dispute" ||
          type == "Dispute Solve Admin" ? (
            <div class="card-body">
              <h6>
                {t("Trades.Message")}:{" "}
                {t("notification." + msg, { name: name })}{" "}
              </h6>
              <button class="ant-btn ant-btn-primary" onClick={openTrade_}>
                {t("Trades.View Trade")}
              </button>
            </div>
          ) : (
            <></>
          )}

          {type == "Trade Cancelled In 90 Minutes" ? (
            <div class="card-body">
              <h6>
                {t("Trades.Message")}: {t("notification." + msg)}
              </h6>
              <button class="ant-btn ant-btn-primary" onClick={openTrade_}>
                {t("Trades.View Trade")}
              </button>
            </div>
          ) : (
            <></>
          )}
          {notiDetail?.msg == "Wowcoin received from admin" ? (
            <div class="card-body">
              <h6>
                {t("Trades.Message")}:{" "}
                {t("notification." + msg, { name: name })}
              </h6>

              <button
                class="ant-btn ant-btn-primary"
                onClick={openWOWTransaction}
              >
                {t("Trades.View Transaction")}
              </button>
            </div>
          ) : (
            <></>
          )}

          {type == "Transaction" &&
          notiDetail?.msg != "Wowcoin received from admin" ? (
            <div class="card-body">
              <h6>
                {t("Trades.Message")}:{" "}
                {t("notification." + msg, { name: name })}
              </h6>

              <button class="ant-btn ant-btn-primary" onClick={openTransaction}>
                {t("Trades.View Transaction")}
              </button>
            </div>
          ) : (
            <></>
          )}

          {type == "MaticTransaction" ? (
            <div class="card-body">
              <h6>
                {t("Trades.Message")}:{" "}
                {t("notification." + msg, { name: name })}
              </h6>

              <button
                class="ant-btn ant-btn-primary"
                onClick={openUserMTransaction}
              >
                {t("Trades.View Transaction")}
              </button>
            </div>
          ) : (
            <></>
          )}

          {type == "Approve" ? (
            <div class="card-body">
              <h6>
                {t("Trades.Message")}:{" "}
                {t("notification." + msg, { name: name, id: id })}
              </h6>
              <button class="ant-btn ant-btn-primary" onClick={openTrade_}>
                {t("Trades.View Trade")}
              </button>
            </div>
          ) : (
            <></>
          )}
          {type == "Cancel" ? (
            <div class="card-body">
              <h6>
                {t("Trades.Message")}:{" "}
                {t("notification." + msg, { name: name, id: id })}
              </h6>
              <button class="ant-btn ant-btn-primary" onClick={openTrade_}>
                {t("Trades.View Trade")}
              </button>
            </div>
          ) : (
            <></>
          )}
          {type == "Buy_Trade_Cancel" ? (
            <div class="card-body">
              <h6>
                {t("Trades.Message")}: {notiDetail?.msg}
              </h6>
              <button class="ant-btn ant-btn-primary" onClick={openTrade_}>
                {t("Trades.View Trade")}
              </button>
            </div>
          ) : (
            <></>
          )}
          {type == "Accept_trade_offer" ? (
            <div class="card-body">
              <h6>
                {t("Trades.Message")}: {notiDetail?.msg}
              </h6>
              <button class="ant-btn ant-btn-primary" onClick={openTrade_}>
                {t("Trades.View Trade")}
              </button>
            </div>
          ) : (
            <></>
          )}

          {type == "Kyc_approve_byadmin" || type == "Kyc_Declined_byadmin" ? (
            <div class="card-body">
              <h6>
                {t("Trades.Message")}: {t("notification." + notiDetail?.msg)}
              </h6>
              <Link to={`/verification`}>
                <button
                  id="continue"
                  type="submit"
                  class="ant-btn ant-btn-primary"
                >
                  <span>{t("Trades.View")}</span>
                </button>
              </Link>{" "}
            </div>
          ) : (
            <></>
          )}

          {type == "withdrawal" ? (
            <div class="card-body">
              <h6>
                {t("Trades.Message")}: {t("notification." + notiDetail?.msg)}
              </h6>
              <Link to={`/withdraw/Transcations`}>
                <button
                  id="continue"
                  type="submit"
                  class="ant-btn ant-btn-primary"
                >
                  <span>{t("Trades.View")}</span>
                </button>
              </Link>{" "}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notidetail;
