import React, { useState, useEffect } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ReactPaginate from "react-paginate";
import { Button } from "antd";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "./MyOffers.css";
import MyOfferCard from "../../components/MyOfferCard/MyOfferCard";
import { updateState } from "../../store/actions/myOffersActions";
import { useTranslation, withTranslation } from "react-i18next";
import Axios from "axios";
import {
  EyeOutlined,
  PlayCircleOutlined,
  EditOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import { ReactComponent as ResumeIcon } from "../../assets/icons/resume-icon.svg";
import { ReactComponent as PauseIcon } from "../../assets/icons/pause-icon.svg";

import SectionSix from "../../components/Home/sections/sectionsix";
const token = localStorage.getItem("token");
const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem("userId");

function MyOffers() {
  const dispatch = useDispatch();
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState(null);
  const [pageCount, setPageCount] = useState(0);

  const updateOfferHandler = (pausedOffer) => {
    pausedOffer.pause = !pausedOffer.pause;
    dispatch(updateState(pausedOffer));
  };

  const updateOffers = (bool) => {
    console.log("bool", bool);
    const sendData = {
      userid: UserId,
    };
    if (bool == true) {
      Axios.post(`${baseURL}/api/offers/pause_alloffer`, sendData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          sessionStorage.setItem("Pausemsg", "Pause All Offers Successfully");
          window.location.reload();
          // console.log("res.data.......", res.data);
        })
        .catch((error) => {
          sessionStorage.setItem("Pauseerror", "Pause All Offer Failed");
          window.location.reload();
        });
    } else {
      Axios.post(`${baseURL}/api/offers/resume_alloffer`, sendData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          sessionStorage.setItem("Resumemsg", "Resume All Offers Successfully");
          window.location.reload();
          // console.log("res.data.......", res.data);
        })
        .catch((error) => {
          sessionStorage.setItem("Resumeerror", "Resume All Offer Failed");
          window.location.reload();
        });
    }
  };

  const [t, i18n] = useTranslation("common");

  useEffect(() => {
    if (sessionStorage.getItem("Resumemsg")) {
      toast.success(t("newoffers." + sessionStorage.getItem("Resumemsg")));
      sessionStorage.removeItem("Resumemsg");
    }
    if (sessionStorage.getItem("Pausemsg")) {
      toast.success(t("newoffers." + sessionStorage.getItem("Pausemsg")));
      sessionStorage.removeItem("Pausemsg");
    }
    if (sessionStorage.getItem("Pauseerror")) {
      toast.success(t("newoffers." + sessionStorage.getItem("Pauseerror")));
      sessionStorage.removeItem("Pauseerror");
    }

    if (sessionStorage.getItem("Resumeerror")) {
      toast.success(t("newoffers." + sessionStorage.getItem("Resumeerror")));
      sessionStorage.removeItem("Resumeerror");
    }
    if (sessionStorage.getItem("trademsg")) {
      toast.success(t("newoffers." + sessionStorage.getItem("trademsg")));
      sessionStorage.removeItem("trademsg");
    }

    if (sessionStorage.getItem("Offermsg")) {
      toast.success(t("newoffers." + sessionStorage.getItem("Offermsg")));
      sessionStorage.removeItem("Offermsg");
    }

    if (sessionStorage.getItem("Offererror")) {
      toast.success(t("newoffers." + sessionStorage.getItem("Offererror")));
      sessionStorage.removeItem("Offererror");
    }

    if (sessionStorage.getItem("tradeerror")) {
      toast.error(t("newoffers." + sessionStorage.getItem("tradeerror")));
      sessionStorage.removeItem("tradeerror");
    }
  }, []);

  const getPostData = (data) => {
    return (
      <>
        <Authcheck />

        <div className="myoffers-table">
          <div className="offers-header">
            <p class="profile-heading">{t("Myoffers.My offers")}</p>
            {data?.length > 0 ? (
              <div className="offers-header-buttons">
                {" "}
                <button
                  disabled={!data?.some((item) => item.pause !== true)}
                  onClick={() => updateOffers(false)}
                  className="resume-all"
                >
                  <div className="resume-all-wrap">
                    <ResumeIcon style={{ marginRight: "5px" }} />{" "}
                    {t("Myoffers.Resume All")}
                  </div>
                </button>
                <button
                  disabled={!data?.some((item) => item.pause !== false)}
                  onClick={() => updateOffers(true)}
                  className="pause-all"
                >
                  <div className="resume-all-wrap">
                    <PauseIcon
                      style={{ marginRight: "5px", marginLeft: "5px" }}
                    />
                    {t("Myoffers.Pause All")}
                  </div>
                </button>
              </div>
            ) : (
              <></>
            )}
          </div>

          <div class="row">
            <div class="col-lg-12">
              <table class="table ">
                <thead className="myoffers-table-header">
                  <tr className="header-row" style={{ border: "none" }}>
                    <th
                      style={{
                        border: "none",
                        minWidth: "61px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                      className="myoffers-table-heading"
                    >
                      {t("Myoffers.Offer Id")}
                    </th>
                    <th
                      style={{
                        border: "none",
                        minWidth: "103px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                      className="myoffers-table-heading"
                    >
                      {t("Myoffers.Created Date")}
                    </th>
                    <th
                      style={{
                        border: "none",
                        minWidth: "125px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                      className="myoffers-table-heading"
                    >
                      {t("Myoffers.Type")}
                    </th>
                    <th
                      style={{
                        border: "none",
                        minWidth: "125px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                      className="myoffers-table-heading"
                    >
                      {t("Myoffers.Payment method")}
                    </th>
                    <th
                      style={{
                        border: "none",
                        minWidth: "63px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                      className="myoffers-table-heading"
                    >
                      {t("Myoffers.Location")}
                    </th>
                    <th
                      style={{
                        border: "none",
                        minWidth: "70px",
                        paddingLeft: "0px",
                        paddingRight: "0px",
                      }}
                      className="myoffers-table-heading"
                    >
                      {t("Myoffers.Rate")}
                    </th>
                    <th
                      style={{ border: "none", width: "100%" }}
                      className="myoffers-table-heading"
                    >
                      {t("newoffers.Action")}
                    </th>
                    {/*<th>Exchange Rate</th>*/}
                  </tr>
                </thead>

                {data ? (
                  <tbody>
                    {data.map((item, i) => (
                      <>
                        {" "}
                        <tr className="data-row" key={item.id}>
                          <td
                            style={{
                              border: "none",
                              minWidth: "61px",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                            className="my-offers-data"
                            key={item.id}
                          >
                            {item?.id}
                          </td>
                          <td
                            style={{
                              border: "none",
                              minWidth: "103px",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                            className="my-offers-data"
                            key={item.id}
                          >
                            {moment(item.created_at).format("D MMM YYYY")}
                          </td>
                          <td
                            style={{
                              border: "none",
                              minWidth: "125px",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                            className="my-offers-data"
                            key={item.id}
                          >
                            {item.buyBCH ? (
                              <>{t("Myoffers.Buying WOW")}</>
                            ) : (
                              <>{t("Myoffers.Selling WOW")}</>
                            )}
                          </td>
                          <td
                            style={{
                              border: "none",
                              minWidth: "125px",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                            className="my-offers-data"
                            key={item.id}
                          >
                            {t("newoffers." + item.payment_method)}
                          </td>
                          <td
                            style={{
                              border: "none",
                              minWidth: "63px",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                            className="my-offers-data"
                            key={item.id}
                          >
                            {item.city}
                          </td>
                          <td
                            style={{
                              border: "none",
                              minWidth: "70px",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                            className="my-offers-data"
                            key={item.id}
                          >
                            {item.margin}%{" "}
                            {item.margin_above
                              ? t("newoffers.above")
                              : t("newoffers.below")}{" "}
                            {item.market_exchange}
                          </td>
                          <td
                            style={{
                              border: "none",
                              width: "100%",
                              paddingLeft: "0px",
                              paddingRight: "0px",
                            }}
                            className="my-offers-data"
                            key={item.id}
                          >
                            <div className="action-btn-wraps">
                              <button type="link" className="view-btn">
                                <Link to={`/offer-details/${item.id}`}>
                                  {t("Myoffers.View")}
                                </Link>
                              </button>
                              {item.pause ? (
                                <button className="resume" type="link">
                                  {" "}
                                  <div
                                    className="resume-all-wrap"
                                    type="link"
                                    onClick={() => updateOfferHandler(item)}
                                  >
                                    <ResumeIcon /> {t("Myoffers.Resume")}
                                  </div>
                                </button>
                              ) : (
                                <button
                                  className="pause-all"
                                  type="link"
                                  onClick={() => updateOfferHandler(item)}
                                >
                                  <div className="resume-all-wrap">
                                    <PauseIcon
                                      style={{
                                        marginRight: "5px",
                                        marginLeft: "5px",
                                      }}
                                    />{" "}
                                    {t("Myoffers.Pause")}
                                  </div>
                                </button>
                              )}
                              <button type="link" className="view-btn">
                                <Link to={`/edit-offer/${item.id}`}>
                                  {t("Myoffers.Edit")}
                                </Link>
                              </button>
                            </div>
                          </td>
                        </tr>
                        <div className="data-row-mobile">
                          <div className="mobile-wrap">
                            <div className="sub-mobile-wrap">
                              <p className="heading-mobile">
                                {" "}
                                {t("Myoffers.Offer Id")}
                              </p>
                              <p className="mobile-data" key={item.id}>
                                {item?.id}
                              </p>
                            </div>
                            <div className="sub-mobile-wrap">
                              <p className="heading-mobile">
                                {" "}
                                {t("Myoffers.Created Date")}
                              </p>
                              <p className="mobile-data" key={item.id}>
                                {moment(item.created_at).format("D MMM YYYY")}
                              </p>
                            </div>
                            <div className="sub-mobile-wrap">
                              <p className="heading-mobile">
                                {" "}
                                {t("Myoffers.Type")}
                              </p>
                              <p className="mobile-data" key={item.id}>
                                {item.buyBCH ? (
                                  <>{t("Myoffers.Buying WOW")}</>
                                ) : (
                                  <>{t("Myoffers.Selling WOW")}</>
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="mobile-wrap">
                            <div className="sub-mobile-wrap">
                              <p className="heading-mobile">
                                {" "}
                                {t("Myoffers.Payment method")}{" "}
                              </p>
                              <p className="mobile-data" key={item.id}>
                                {t("newoffers." + item.payment_method)}
                              </p>
                            </div>
                            <div className="sub-mobile-wrap">
                              <p className="heading-mobile">
                                {" "}
                                {t("Myoffers.Location")}{" "}
                              </p>
                              <p className="mobile-data" key={item.id}>
                                {item.city}
                              </p>
                            </div>
                            <div className="sub-mobile-wrap">
                              <p className="heading-mobile">
                                {" "}
                                {t("Myoffers.Rate")}
                              </p>
                              <p className="mobile-data" key={item.id}>
                                {item.margin}%{" "}
                                {item.margin_above
                                  ? t("newoffers.above")
                                  : t("newoffers.below")}{" "}
                                {item.market_exchange} WOW/{item.currency_type}
                              </p>
                            </div>
                          </div>
                          <div className="mobile-wrap">
                            <div className="sub-mobile-wrap" key={item.id}>
                              <button type="link" className="view-btn">
                                <Link to={`/offer-details/${item.id}`}>
                                  {t("Myoffers.View")}
                                </Link>
                              </button>
                            </div>
                            <div className="sub-mobile-wrap">
                              {item.pause ? (
                                <button
                                  className="resume"
                                  type="link"
                                  onClick={() => updateOfferHandler(item)}
                                >
                                  {" "}
                                  <div className="resume-all-wrap">
                                    <ResumeIcon
                                      style={{ marginRight: "5px" }}
                                    />
                                    {t("Myoffers.Resume")}
                                  </div>
                                </button>
                              ) : (
                                <button
                                  className="pause-all"
                                  type="link"
                                  onClick={() => updateOfferHandler(item)}
                                >
                                  <div className="resume-all-wrap">
                                    <PauseIcon
                                      style={{
                                        marginRight: "5px",
                                        marginLeft: "5px",
                                      }}
                                    />{" "}
                                    {t("Myoffers.Pause")}
                                  </div>
                                </button>
                              )}
                            </div>
                            <div className="sub-mobile-wrap">
                              <button type="link" className="view-btn">
                                <Link to={`/edit-offer/${item.id}`}>
                                  {t("Myoffers.Edit")}
                                </Link>
                              </button>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </tbody>
                ) : (
                  <tbody style={{ textAlign: "center" }}>
                    <tr style={{ border: "none" }}>
                      <td className="my-offers-data" colspan="7">
                        {t("Myoffers.You don't have any offers yet")}.
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>

        <ToastContainer autoClose={10000} style={{ marginTop: "60px" }} />
      </>
    );
  };

  const getAllPosts = async () => {
    const UserId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const res = await Axios.get(`${baseURL}/api/offers/${UserId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    console.log("offer data", res);
    if (res.data) {
      const data = res.data;
      const slice = data.slice(offset - 1, offset - 1 + postsPerPage);

      // For displaying Data

      // Using Hooks to set value
      setAllPosts(slice);
      setPageCount(Math.ceil(data.length / postsPerPage));
    } else {
      setAllPosts(null);
    }
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);
  };

  const unBlockuser = (id) => {
    Axios.post(`${baseURL}/api/auth/unblock_user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        toast.success(t("userprofile.User Unblocked Successfully"));
        // console.log("response", response);
        getAllPosts();
        // history.push('/offers/trade')
      })
      .catch((error) => {
        toast.success("Error in Unblocking");
      });
  };

  useEffect(() => {
    getAllPosts();
  }, [offset]);

  return (
    <>
      {" "}
      <div className="myoffers-page">
        <div className="myoffers-upper"></div>
        <div className="myoffers-container">
          {/* Display all the posts */}
          {getPostData(posts)}

          {/* Using React Paginate */}
          <div className="pagination-row">
            <ReactPaginate
              previousLabel={t("Withdraw.Previous")}
              nextLabel={t("Withdraw.Next")}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={"pagination-container"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <SectionSix />
    </>
  );
}

export default MyOffers;