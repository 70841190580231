import React, { useState, useEffect } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import axios from "axios";
import ReactPaginate from "react-paginate";
import "./BnbTransactions.css";
import { useTranslation, withTranslation } from "react-i18next";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem("userId");
const token = localStorage.getItem("token");

function BnbTransactions() {
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [t, i18n] = useTranslation("common");
  const [data, setData] = useState([]);

  // console.log('pageCount:',pageCount);

  // console.log('offset:',offset);

  const getPostData = (data) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <div className="transaction-table-head">
          <p
            style={{ maxWidth: "45px", minWidth: "45px" }}
            className="heading-tags"
          >
            {t("Transactions.No")}
          </p>

          <p
            className="heading-tags"
            style={{ maxWidth: "100px", minWidth: "100px" }}
          >
            {t("Transactions.Transaction ID")}
          </p>

          <p
            style={{ maxWidth: "76px", minWidth: "76px" }}
            className="heading-tags"
          >
            {t("Transactions.Date")}
          </p>
          <p
            style={{ maxWidth: "76px", minWidth: "76px" }}
            className="heading-tags"
          >
            {t("Transactions.Total Send MATIC coin")}
          </p>
          <p
            style={{ maxWidth: "101px", minWidth: "101px" }}
            className="heading-tags"
          >
            {t("Transactions.Total Received MATIC coin")}
          </p>
          <p
            style={{ maxWidth: "102px", minWidth: "102px" }}
            className="heading-tags"
          >
            {t("Transactions.Sender Address")}
          </p>
          <p
            style={{ maxWidth: "111px", minWidth: "111px" }}
            className="heading-tags"
          >
            {t("Transactions.Description")}
          </p>
          <p
            style={{ maxWidth: "99px", minWidth: "99px" }}
            className="heading-tags"
          >
            {t("Transactions.Receiver Address")}
          </p>
        </div>

        {data ? (
          <>
            {data.map((item, index) => (
              <>
                <div key={item.id} className="transaction-data-row-mobile">
                  <div className="table-data-col-wrapper">
                    <div className="table-data-wrapper">
                      <p className="table-title-tag"> {t("Transactions.No")}</p>
                      <p
                        style={{ maxWidth: "45px", minWidth: "45px" }}
                        className="data-tag"
                      >
                        {index + 1 + (offset - 1) * postsPerPage}
                      </p>
                    </div>
                    <div className="table-data-wrapper">
                      <p className="table-title-tag">
                        {" "}
                        {t("Transactions.Transaction ID")}
                      </p>
                      <p
                        style={{ maxWidth: "100px", minWidth: "100px" }}
                        data-toggle="tooltip"
                        data-placement="top"
                        className="data-tag"
                      >
                        {item.userid=UserId ? item.to_user : item.from_user}
                      </p>
                    </div>
                    <div className="table-data-wrapper">
                      <p className="table-title-tag">
                        {" "}
                        {t("Transactions.Date")}
                      </p>
                      <p
                        style={{ maxWidth: "76px", minWidth: "76px" }}
                        className="data-tag"
                      >
                        {item?.date
                          ? new Date(item?.date).toLocaleDateString("en-US")
                          : null}
                      </p>
                    </div>
                  </div>
                  <div className="table-data-col-wrapper">
                    {" "}
                    <div className="table-data-wrapper">
                      <p className="table-title-tag">
                        {" "}
                        {t("Transactions.Total Send MATIC coin")}
                      </p>
                      {item?.userid == UserId ? (
                        <p
                          key={item.id}
                          style={{ maxWidth: "76px", minWidth: "76px" }}
                          className="data-tag"
                        >
                          {item?.amount}
                        </p>
                      ) : (
                        <p
                          style={{ maxWidth: "76px", minWidth: "76px" }}
                          className="data-tag"
                          key={item.id}
                        >
                          ----
                        </p>
                      )}
                    </div>
                    <div className="table-data-wrapper">
                      <p className="table-title-tag">
                        {" "}
                        {t("Transactions.Total Received MATIC coin")}
                      </p>
                      {item?.userid == UserId ? (
                        <p
                          style={{ maxWidth: "101px", minWidth: "101px" }}
                          className="data-tag"
                          key={item.id}
                        >
                          ----
                        </p>
                      ) : (
                        <p
                          style={{ maxWidth: "101px", minWidth: "101px" }}
                          className="data-tag"
                          key={item.id}
                        >
                          {item?.amount}
                        </p>
                      )}
                    </div>
                    <div className="table-data-wrapper">
                      <p className="table-title-tag">
                        {" "}
                        {t("Transactions.Sender Address")}
                      </p>
                      <p
                        key={item.id}
                        title={item?.from_address}
                        style={{ maxWidth: "102px", minWidth: "102px" }}
                        className="data-tag"
                      >
                        {item?.from_address?.slice(0, -33)}...
                      </p>
                    </div>
                  </div>
                  <div className="table-data-col-wrapper">
                    {" "}
                    <div className="table-data-wrapper">
                      <p className="table-title-tag">
                        {" "}
                        {t("Transactions.Description")}
                      </p>
                      <p
                        key={item.id}
                        style={{ maxWidth: "111px", minWidth: "111px" }}
                        data-toggle="tooltip"
                        data-placement="top"
                        className="data-tag"
                      >
                        {item?.description}
                      </p>
                    </div>
                    <div className="table-data-wrapper">
                      <p className="table-title-tag">
                        {t("Transactions.Receiver Address")}
                      </p>
                      <p
                        key={item.id}
                        style={{ maxWidth: "99px", minWidth: "99px" }}
                        className="data-tag"
                        data-toggle="tooltip"
                        data-placement="top"
                        title={item?.to_address}
                      >
                        {item?.to_address?.slice(0, -33)}...
                      </p>
                    </div>
                  </div>
                </div>
                <div key={item.id} className="transaction-data-row ">
                  <p
                    style={{ maxWidth: "45px", minWidth: "45px" }}
                    className="data-tag"
                  >
                    {index + 1 + (offset - 1) * postsPerPage}
                  </p>
                  <p
                    key={item.id}
                    style={{ maxWidth: "100px", minWidth: "100px" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    className="data-tag"
                  >
                  {item.userid=UserId ? item.to_user : item.from_user}
                  </p>
                  <p
                    key={item.id}
                    style={{ maxWidth: "76px", minWidth: "76px" }}
                    className="data-tag"
                  >
                    {item?.date
                      ? new Date(item?.date).toLocaleDateString("en-US")
                      : null}
                  </p>
                  {item?.userid == UserId ? (
                    <p
                      key={item.id}
                      style={{ maxWidth: "76px", minWidth: "76px" }}
                      className="data-tag"
                    >
                      {item?.amount}
                    </p>
                  ) : (
                    <p
                      style={{ maxWidth: "76px", minWidth: "76px" }}
                      className="data-tag"
                      key={item.id}
                    >
                      ----
                    </p>
                  )}

                  {item?.userid == UserId ? (
                    <p
                      style={{ maxWidth: "101px", minWidth: "101px" }}
                      className="data-tag"
                      key={item.id}
                    >
                      ----
                    </p>
                  ) : (
                    <p
                      style={{ maxWidth: "101px", minWidth: "101px" }}
                      className="data-tag"
                      key={item.id}
                    >
                      {item?.amount}
                    </p>
                  )}

                  <p
                    key={item.id}
                    title={item?.from_address}
                    style={{ maxWidth: "102px", minWidth: "102px" }}
                    className="data-tag"
                  >
                    {item?.from_address?.slice(0, -33)}...
                  </p>

                  <p
                    key={item.id}
                    style={{ maxWidth: "111px", minWidth: "111px" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    className="data-tag"
                  >
                    {item?.description}
                  </p>

                  <p
                    key={item.id}
                    style={{ maxWidth: "99px", minWidth: "99px" }}
                    className="data-tag"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={item?.to_address}
                  >
                    {item?.to_address?.slice(0, -33)}...
                  </p>
                </div>
              </>
            ))}
          </>
        ) : (
          <div>
            <p>{t("Transactions.No data Found")}</p>
          </div>
        )}
      </div>
    );
  };

  const getAllPosts = async () => {
    const UserId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const res = await axios.get(
      `${baseURL}/api/transaction/getbnbtransaction/${UserId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("transaction data", res.data.data);
    if (res.data.data) {
      const data = res.data.data;
      setData(res.data.data);
      const slice = data.slice(offset - 1, offset - 1 + postsPerPage);
      // console.log('slice:',slice);

      // For displaying Data
      const postData = getPostData(slice);

      // Using Hooks to set value
      setAllPosts(postData);
      setPageCount(Math.ceil(data.length / postsPerPage));
    } else {
      setAllPosts(null);
    }
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    // console.log("selectedPage",selectedPage);
    setOffset(selectedPage + 1);

    let start = selectedPage * postsPerPage;
    let end = start + postsPerPage;
    const slice = data.slice(start, end);
    // console.log('slice:',slice);
    const postData = getPostData(slice);
    setAllPosts(postData);
    setPageCount(Math.ceil(data.length / postsPerPage));
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  useEffect(() => {
    let start = (offset - 1) * postsPerPage;
    let end = start + postsPerPage;
    const slice = data.slice(start, end);
    // console.log('slice:',slice);
    const postData = getPostData(slice);
    setAllPosts(postData);
    setPageCount(Math.ceil(data.length / postsPerPage));
  }, [offset]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {/* Display all the posts */}
      {posts}

      {/* Using React Paginate */}
      <ReactPaginate
        previousLabel={t("Withdraw.Previous")}
        nextLabel={t("Withdraw.Next")}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination-container"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
}

export default BnbTransactions;