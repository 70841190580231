import React, { Component } from 'react'
 
class QrCode extends Component {

  
 
  render() {
    return (
      <div className="container-fluid" style={{paddingTop: "200px"}}>
        <div className="text-center">
          {/* Get a Placeholder image initially,
	this will change according to the
	data entered later */}
          <img src=
"https://chart.googleapis.com/chart?cht=qr&chl=Trupti@123&chs=160x160&chld=L|0"
		class="qr-code img-thumbnail img-responsive" />
        </div>
      
      </div>
    )
  }
}

export default QrCode;