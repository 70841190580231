import React, { useState, useEffect } from 'react'
import Authcheck from "../../components/Authcheck/Authcheck";
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import "./LoginHistory.scss";
import { useTranslation, withTranslation } from "react-i18next";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem('userId');
    const  token = localStorage.getItem("token");

function Transactions() {
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState(null);
  const [pageCount, setPageCount] = useState(0)
  const [t, i18n] = useTranslation('common');
  const [data, setData] =  useState([]);

  // console.log('pageCount:',pageCount);

  // console.log('offset:',offset);

  const getPostData = (data) => {


    return (
      <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="login-guard-container">
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div class="authorized_browsers-container">
           
            <div class="col-md-12">
            <h4>{t("Loginhistory.Login history")}</h4>
              <br />
              <p>
                <a href="/settings/"><i class="fa fa-arrow-left"></i> {t("Twofactor.Back to Account security")}</a>
              </p>
              <table class="table table-striped table-condensed table-hover">
              <thead>
              <tr>
                  <th class="date-column">{t("Loginhistory.Date")}</th>
                  <th>{t("Loginhistory.Type")}</th>
                  <th>{t("Loginhistory.IP address")}</th>
                </tr>
              </thead>
              {data ?
                <tbody>
                  {data.map((item,index) => (
                    <tr key={item.id}>
                     <td key={item.id}>{item?.datetime}</td>
                     <td key={item.id}>{t("login."+item?.type)}</td>
                     <td key={item.id}>{item?.ipaddress}</td>
                    </tr>
                  ))}
                </tbody>
                :
                <tbody>
                  <tr>
                    <td>{t("Transactions.No data Found")}</td>
                  </tr>
                </tbody>
              }
            </table>
          </div>
          </div>
        </div>
      </div>
    </section>

    )

  }

  const getAllPosts = async () => {
    const res = await axios.get(`${baseURL}/api/auth/loginhistory/${UserId}`);
     console.log("loginhistory data", res.data.LoginHis);
    if (res.data.LoginHis) {
      const data = res.data.LoginHis;
      setData(res.data.LoginHis);
      const slice = data.slice(offset - 1, offset - 1 + postsPerPage)
      // console.log('slice:',slice);
  
  
      // For displaying Data
      const postData = getPostData(slice)
  
      // Using Hooks to set value
      setAllPosts(postData)
      setPageCount(Math.ceil(data.length / postsPerPage))
    }else{
      setAllPosts(null)
    }
   
  }

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    // console.log("selectedPage",selectedPage);
    setOffset(selectedPage + 1);

    let start = selectedPage * postsPerPage;
    let end  = start+ postsPerPage;
    const slice = data.slice(start, end);
    // console.log('slice:',slice);
    const postData = getPostData(slice);
    setAllPosts(postData)
    setPageCount(Math.ceil(data.length / postsPerPage))
  };

  useEffect(() => {
    getAllPosts()
  }, [])

  useEffect(()=>{
    let start = (offset - 1) * postsPerPage;
    let end  = start + postsPerPage;
    const slice = data.slice(start, end);
    // console.log('slice:',slice);
    const postData = getPostData(slice);
    setAllPosts(postData)
    setPageCount(Math.ceil(data.length / postsPerPage))
  }, [offset])

  return (
    <div className="main-app">

      {/* Display all the posts */}
      {posts}

      {/* Using React Paginate */}
      <ReactPaginate
         previousLabel={t('Withdraw.Previous')}
         nextLabel={t('Withdraw.Next')}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"} />
    </div>
  );
}

export default Transactions;