import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link, useHistory } from "react-router-dom";
import { Form, Input, Button, Select, Spin } from "antd";
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { fetchWithdraw } from "../../store/actions/myAccountActions";
import { FormInstance } from "antd/lib/form";
import "./Withdrawupdate.css";
import { axiosWithAuth } from "../../helpers/axiosWithAuth";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation, withTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";

const baseURL = process.env.REACT_APP_BASE_URL;
const token = localStorage.getItem("token");

const Withdrawupdate = (props) => {
  const dispatch = useDispatch();
  const userName = localStorage.getItem("userName");
  const logoutHandler = () => {
    dispatch(doLogout());
  };
  const currentRoute = useHistory().location.pathname.toLowerCase();
  // console.log("cuudfssdfdf", currentRoute)
  let history = useHistory();
  const [getCountry, setGetCountry] = useState([]);
  const [data, setData] = useState({});
  const [withdrawInfo, setWithdrawInfo] = useState({});
  const formRef = React.useRef(null);
  const [PendingMsg, setPendingMsg] = useState();
  // const url = "${baseURL}/api/auth/updateUser";
  const { Option } = Select;

  const useraccount = async () => {
    let myData = await fetchWithdraw();
    // console.log('fetchWithdraw:', myData);
    setWithdrawInfo(myData);
    formRef.current.setFieldsValue({
      bankname: myData?.Bank_name ? myData?.Bank_name : "",
      beneficiaryname: myData?.Beneficiary_name ? myData?.Beneficiary_name : "",
      bicswiftcode: myData?.Bic_swift_code ? myData?.Bic_swift_code : "",
      iban: myData?.Iban ? myData?.Iban : "myData?.Iban",
      naticleancode: myData?.National_clearing_code
        ? myData?.National_clearing_code
        : "",
    });
  };

  useEffect(() => {
    if (sessionStorage.getItem("updatemsg") != "") {
      toast.success(sessionStorage.getItem("updatemsg"));
      sessionStorage.removeItem("updatemsg");
    }

    useraccount();
    // try {
    //   Axios.get(`${baseURL}/api/auth/getcountry`)
    //     .then((response) => {
    //       console.log("getTran response.....", response.data);
    //       setGetCountry(response.data);
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  }, []);

  const submit = (e) => {
    const userId = localStorage.getItem("userId");
    console.log("bank_detail_update:", e);

    const sendData = {
      userid: userId,
      Beneficiary_name: e.beneficiaryname,
      Bank_name: e.bankname,
      Bic_swift_code: e.bicswiftcode,
      National_clearing_code: e.naticleancode,
      Iban: e.iban,
    };
    // console.log("sendData", sendData)
    Axios.post(`${baseURL}/api/auth/user_bank_detail_update`, sendData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.data.status == false) {
          sessionStorage.setItem(
            "sendaccounterror",
            t("Withdraw." + res.data.errorMessage)
          );
          window.location.reload();
        } else {
          sessionStorage.setItem(
            "updatemsg",
            t("Withdraw.Withdraw detail updated successfully")
          );
          const button = document.getElementById("continue");
          button.disabled = true;
          setPendingMsg(false);
          history.push("/withdraw/SendWowCoin");
        }
      })
      .catch((error) => {
        // console.log("error:", error);
        toast.error(t("Withdraw.Withraw detail Updation Failed"));
        //window.location.reload();
        setPendingMsg(false);
      });
  };

  function handle(e) {
    // console.log('e.target.id:', e.target.id);
    let newdata = data;
    newdata[e.target.id] = e.target.value;
    setData({ ...data, [e.target.id]: e.target.value });
    // console.log("newdata......", newdata);
  }

  const [t, i18n] = useTranslation("common");

  if (PendingMsg) {
    const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          marginTop: 60,
          flexDirection: "column",
        }}
      >
        <Spin indicator={antIcon} />
        <h6 style={{ fontSize: "15px", paddingTop: 8 }}>
        {t("notification.Please wait while your transaction is processing")}
        </h6>
      </div>
    );
  }

  return (
    <>
      <div className="withdraw-container">
        <Authcheck />

        <div class="row">
          <div class="col-12 col-lg-6 pr-lg-0">
            <Form
              initialValues={{
                remember: true,
              }}
              ref={formRef}
              onFinish={(e) => {
                submit(e);
              }}
            >
              <Form.Item
                name="beneficiaryname"
                id="beneficiaryname"
                onChange={(e) => handle(e)}
                rules={[
                  {
                    required: true,
                    message: t("Withdraw.Beneficiary Name *"),
                  },
                ]}
              >
                <div className="input-wrapper-withdraw-coin">
                  <p className="input-label-withdraw-coin">
                    {t("Withdraw.Beneficiary Name *")}
                  </p>
                  <input
                    className="withdraw-coin-input-amount"
                    value={withdrawInfo?.beneficiaryname}
                  />
                </div>
              </Form.Item>

              <Form.Item
                name="bankname"
                id="bankname"
                onChange={(e) => handle(e)}
                rules={[
                  {
                    required: true,
                    message: t("Withdraw.Please input your Bank Name"),
                  },
                ]}
              >
                <div className="input-wrapper-withdraw-coin">
                  <p className="input-label-withdraw-coin">
                    {t("Withdraw.Bank Name *")}
                  </p>
                  <input
                    className="withdraw-coin-input-amount"
                    value={withdrawInfo?.bankname}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="bicswiftcode"
                id="bicswiftcode"
                onChange={(e) => handle(e)}
                rules={[
                  {
                    required: true,
                    message: t("Withdraw.Please input your BIC/SWIFT Code"),
                  },
                ]}
              >
                <div className="input-wrapper-withdraw-coin">
                  <p className="input-label-withdraw-coin">
                    {t("Withdraw.BIC/SWIFT Code *")}
                  </p>
                  <input
                    className="withdraw-coin-input-amount"
                    type="text"
                    value={withdrawInfo?.bicswiftcode}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="naticleancode"
                onChange={(e) => handle(e)}
                id="naticleancode"
              >
                <div className="input-wrapper-withdraw-coin">
                  <p className="input-label-withdraw-coin">
                    {t("Withdraw.National Clearing Code")}
                  </p>
                  <input
                    className="withdraw-coin-input-amount"
                    type="text"
                    value={withdrawInfo?.naticleancode}
                  />
                </div>
              </Form.Item>

              <Form.Item
                name="iban"
                id="iban"
                onChange={(e) => handle(e)}
                rules={[
                  {
                    required: true,
                    message: t(
                      "Withdraw.Please input your Account Number or IBAN"
                    ),
                  },
                ]}
              >
                <div className="input-wrapper-withdraw-coin">
                  <p className="input-label-withdraw-coin">
                    {t("Withdraw.Account Number or IBAN *")}
                  </p>
                  <input
                    className="withdraw-coin-input-amount"
                    type="text"
                    value={withdrawInfo?.iban}
                  />
                </div>
              </Form.Item>

              <Form.Item>
                <button
                  className="submit-coin-btn"
                  htmlType="submit"
                  id="continue"
                >
                  {t("Withdraw.UPDATE")}
                </button>
              </Form.Item>
              <h6 style={{ color: "red", fontSize: "15px" }}>{PendingMsg}</h6>
            </Form>
          </div>
        </div>
      </div>

      <ToastContainer autoClose={10000} style={{ marginTop: "60px" }} />
    </>
  );
};

export default Withdrawupdate;
