import React from "react";
import "./sections.css";

function ContentBox({ pic, heading, line }) {
  return (
    <div className="content-box-container">
      <img className="content-box-img" src={pic} alt="pic" />
      <p className="content-box-heading">{heading}</p>
      <p className="content-box-line">{line}</p>
    </div>
  );
}

export default ContentBox;
