import axios from "axios";
import { authData } from "../../auth/Auth";
import { updateAction } from "../../helpers/updateActions";
import { kycData } from "../../views/Verification/Helper_veri";
import { getWowBalance, balanceSub } from "../../helpers/CallAPi"

const baseURL = process.env.REACT_APP_BASE_URL;

export const LOGIN = "LOGIN";
export const LOADING_USER = "LOADING_USER";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const GET_USER = "GET_USER";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const USER_ERROR = "USER_ERROR";

export const doLogin = (user, history) => (dispatch) => {
  dispatch(updateAction(LOADING_USER, true));
  axios.post(`${baseURL}/api/auth/login`, user)
    .then((response) => {
      const { token, userId, username,delete_status } = response.data;
      // console.log("delete_status",delete_status);
      if(delete_status==1){
        let errorMessage="Your account is deleted, "
        dispatch(updateAction(LOGIN_ERROR, errorMessage));
        dispatch(updateAction(LOADING_USER, false));
      }
      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId);
      localStorage.setItem("username", username);
      // payload currently is the token being saved as userId at the moment
      dispatch(updateAction(LOGIN));
      getWowBalance(localStorage.getItem('userId'));
      history.push("/offers/trade"); 
      window.location.reload();
    })
    .catch((error) => {
      // console.log(error);
      let errorMessage = error.response.data;
      // console.log("errorMessage...............", errorMessage);
      dispatch(updateAction(LOGIN_ERROR, errorMessage));
    })
    .finally(() => dispatch(updateAction(LOADING_USER, false)));
};

export const doRegister = (user, history) => (dispatch) => {
  dispatch(updateAction(LOADING_USER, true));
  axios.post(`${baseURL}/api/auth/register`, user)
    .then((response) => {
      // console.log(response);
      const { token, user } = response.data;
      localStorage.setItem("token", token);
      localStorage.setItem("userId", user.id);
      dispatch(updateAction(LOGIN, token));
      history.push("/offers/trade");
    })
    .catch((error) => {
      // console.log(error);
      let errorMessage = error.response.data;
      // console.log("errorMessageaa............", errorMessage.errorMessage);
      dispatch(updateAction(REGISTER_ERROR, errorMessage));
    })
    .finally(() => dispatch(updateAction(LOADING_USER, false)));
};

export const doLogout = (dispatch,history) => {
  const UserId = localStorage.getItem('userId');
    const  token = localStorage.getItem("token");
  dispatch(updateAction(LOGOUT));
  authData.next(null);
  kycData.next(null);
  balanceSub.next(null);
  localStorage.clear();
  history.push(''); 
  window.location.reload();
  try {
    axios.get(`${baseURL}/api/auth/user_set_offline/${UserId}`)
      .then((response) => {
        // console.log("user offline set........", response);
      })
  } catch (error) {
    // console.log(error);
  }
};

export const CheckLogin = (dispatch,history) => {
 
  const UserId = localStorage.getItem('userId');
    const  token = localStorage.getItem("token");
  if (UserId && token) {
  axios.get(`${baseURL}/api/auth/user_valid_unvalid/${UserId}` , {
    headers: {
       "Authorization" : `Bearer ${token}`
    }
     })
  .then(res => {
    console.log("CheckLogin",res.data.status);
     if(res.data.status===true){
      dispatch(updateAction(LOGOUT));
      authData.next(null);
      kycData.next(null);
      balanceSub.next(null);
      localStorage.clear();
      history.push(''); 
      window.location.reload();
      try {
        axios.get(`${baseURL}/api/auth/user_set_offline/${UserId}`)
          .then((response) => {
           
          })
      } catch (error) {
        // console.log(error);
      }
     }
    // console.log("res.data.......", res.data);
  }).catch(error => {
  })
}
};
 