// import React from 'react';
import { Form, Input, Button, Select } from "antd";
import React, { useEffect, state, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import Axios from 'axios';
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import { updateAction } from "../../helpers/updateActions";
import { doLogout } from "../../store/actions/authActions";
import { useTranslation, withTranslation } from "react-i18next";
const baseURL = process.env.REACT_APP_BASE_URL;
export const LOGOUT = "LOGOUT";

const Accountdelete = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const UserId = localStorage.getItem('userId');
    const  token = localStorage.getItem("token");
    const [data, setData] = useState({
        password: "",
    });
    

    const [PendingMsg, setPendingMsg] = useState();
    const submit = (e) => {
        // e.preventDefault();
        // const button = document.getElementById('continue');
        // button.disabled = true
        // setPendingMsg("t("notification.Please wait while your transaction is processing")!");
        const sendData = {
            userid: UserId,
            password: data.password
        };
     
        Axios.post(`${baseURL}/api/auth/user_delete_permant`, sendData, {
            headers: {
                // "Accept": "*/*",
                "Authorization" : `Bearer ${token}`
            }
        }).then(res => {
           // console.log("resdelete", res);
            if (res.data.status == "fail") {
                toast.error(`${res.data.message}`);
            } else {
                sessionStorage.setItem("DeleteAccount", 'Account Deleted Successfully');
                localStorage.clear();
                dispatch(updateAction(LOGOUT));
                history.push('/')
            }

        }).catch(error => {
            // console.log("errorpass", error);
            toast.error(`${error}`);
        })
    }

    function handle(e) {
        // console.log('e.target:',e);
        const newdata = { ...data }
        newdata[e.target.id] = e.target.value
        setData(newdata)
        // console.log("newdata......", newdata);
    }

    const [t, i18n] = useTranslation('common');
    return <div>

        <section className=" hero--full hero--v10 d-flex align-items-center hidden z-index2 " style={{ marginTop: "100px" }} >
            <div className="container sidebox">
            <Authcheck />
            <div className="row flex-column-reverse flex-lg-row reveal">
                    <div className="col-11 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 ">
                        <div className="col-11 col-md-10 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 " style={{ marginTop: '25px' }}>
                            <div className="ico-counter">
                                <div className="counter-down">
                                    <div className="content">
                                        <div className="conuter-header">
                                            <h4 style={{color: "#00cc90"}}>{t('Security.Account Deletion')}</h4>
                                            <p>{t('Security.Request account deletion')}</p>
                                        </div>
                                        <div className="signup-container">
                                            <Form
                                                onFinish={(e) => {
                                                    submit(e);
                                                }}
                                            >
                                                <Form.Item

                                                    label={t('Security.Your Password')}
                                                    name="password"
                                                    onChange={(e) => handle(e)}
                                                    id="password"
                                                    defaultValue={data.password}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: t('Security.password is Required')
                                                        },
                                                        {
                                                            min: 6,
                                                            pattern: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{7,15}$/,
                                                            message: t('Security.passwordvalidation'),
                                                        },
                                                    ]}
                                                >
                                                    <Input type="password" />
                                                </Form.Item>

                                                <Form.Item>
                                                    <Button htmlType="submit" type="primary" class="ant-btn ant-btn-danger" id="continue">{t('Security.Delete my account')}</Button>
                                                </Form.Item>

                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                </div>
            </div>
            <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
        </section>
    </div>;
};

export default Accountdelete;
