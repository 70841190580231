import React, { useState, useEffect } from 'react'
import Authcheck from "../../components/Authcheck/Authcheck";
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import "./Dispute.scss";
import { useHistory } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem('userId');
    const  token = localStorage.getItem("token");

function Disputeall() {
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState(null);
  const [pageCount, setPageCount] = useState(0)
  const [t, i18n] = useTranslation('common');
  const [data, setData] =  useState([]);
  let history = useHistory();

  // console.log('pageCount:',pageCount);

  // console.log('offset:',offset);

  const getPostData = (data) => {


    return (
      <section className=" hero--full hero--v10 d-flex align-items-center hidden" style={{ minHeight: '300px', paddingBottom: '0px', marginTop: "100px" }}>
         <div class="container">
        <Authcheck />
          <div className="conuter-header">
            <h4 class="sendWOWb"><b>List of Order for Dispute</b></h4>
          </div>
          <div class="table">
            <table class="table-responsivea">
              <thead>
                <tr>
                <th scope="col" className="padabc">{t("Transactions.No")}</th>
                      <th scope="col" className="padabc">{t('newoffers.Trade Id')}</th>
                      <th scope="col" className="padabc">Maker</th>
                      <th scope="col" className="padabc">Fiat Amount</th>
                      <th scope="col" className="padabc">BCH Amount</th>
                      <th scope="col" className="padabc">Action</th>
                </tr>
              </thead>
              {data ?
                <tbody>
                  {data.map((item,index) => (
                    <tr key={item.id}>
                     <td>{ (index + 1) + (offset - 1) * postsPerPage}</td>
                              <th scope="row" key={item.id} className="padabc">{item?.id}</th>
                              <td key={item.id} className="padabc">{item?.maker_id}</td>
                              <td key={item.id} className="padabc">{item?.fiat_amount}</td>
                              <td key={item.id} className="padabc">{item?.bch_amount}</td>
                              <td key={item.id} className="padabc" onClick={()=>orderDetail(item?.id)}><button className="btn btn-success" style={{padding: "7px"}}>View Detail</button></td>
                              
                    </tr>
                  ))}
                </tbody>
                :
                <tbody>
                  <tr>
                    <td>{t("Transactions.No data Found")}</td>
                  </tr>
                </tbody>
              }
            </table>
          </div>
        </div>

      </section>

    )

  }

  const getAllPosts = async () => {
    const res = await axios.get(`${baseURL}/api/orders/get_add_for_dispute_seller_or_buyer/${UserId}`, {
      headers: {
         "Authorization" : `Bearer ${token}`
      }
       });
    // console.log("transaction data", res.data.data);
    if (res.data.data) {
      const data = res.data.data;
      setData(res.data.data);
      const slice = data.slice(offset - 1, offset - 1 + postsPerPage)
      // console.log('slice:',slice);
  
  
      // For displaying Data
      const postData = getPostData(slice)
  
      // Using Hooks to set value
      setAllPosts(postData)
      setPageCount(Math.ceil(data.length / postsPerPage))
    }else{
      setAllPosts(null)
    }
   
  }

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    // console.log("selectedPage",selectedPage);
    setOffset(selectedPage + 1);

    let start = selectedPage * postsPerPage;
    let end  = start+ postsPerPage;
    const slice = data.slice(start, end);
    // console.log('slice:',slice);
    const postData = getPostData(slice);
    setAllPosts(postData)
    setPageCount(Math.ceil(data.length / postsPerPage))
  };

  const orderDetail = (id) =>{
    history.push(`/disputesingle/${id}`)
  }

  useEffect(() => {
    getAllPosts()
  }, [])

  useEffect(()=>{
    let start = (offset - 1) * postsPerPage;
    let end  = start + postsPerPage;
    const slice = data.slice(start, end);
    // console.log('slice:',slice);
    const postData = getPostData(slice);
    setAllPosts(postData)
    setPageCount(Math.ceil(data.length / postsPerPage))
  }, [offset])

  return (
    <div className="main-app">

      {/* Display all the posts */}
      {posts}

      {/* Using React Paginate */}
      <ReactPaginate
        previousLabel={t('Withdraw.Previous')}
        nextLabel={t('Withdraw.Next')}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"} />
    </div>
  );
}

export default Disputeall;