import React from "react";
import SectionFive from "./sections/sectionFive";
import SectionFour from "./sections/sectionFour";
import SectionOne from "./sections/sectionOne";
import SectionSix from "./sections/sectionsix";
import SectionThree from "./sections/sectionThree";
import SectionTwo from "./sections/sectiontwo";
import { useTranslation, withTranslation } from "react-i18next";

function Home() {
  const [t, i18n] = useTranslation('common');
  return (
    <div>
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
      <SectionSix />
    </div>
  );
}

export default Home;
