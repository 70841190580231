import React, { useState, useEffect } from "react";
import Authcheck from "../Authcheck/Authcheck";
import axios from "axios";
import ReactPaginate from "react-paginate";
import { Link, useHistory } from "react-router-dom";
// import "../../views/Contract/Contractall/";
// import "./Blockuser.scss";
import Axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation, withTranslation } from "react-i18next";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { getMyOrders } from "../../store/actions/ordersActions";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem("userId");
const token = localStorage.getItem("token");

const coinStatsAPI = "https://openapiv1.coinstats.app/coins/usd?currency=";

function ActiveTradeList() {
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(0);
  const [posts, setAllPosts] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [t, i18n] = useTranslation("common");

  const { myActiveOrders } = useSelector((state) => state.orders);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyOrders());
  }, [dispatch]);

  const livePrice = async (item) => {
      
    try{
        if (item?.currency_type === "USD") {
            const resp = await axios.get(`${baseURL}/api/wowprice/`);
            let price = Number(resp.data.price);
            
            return price;
          }else{
            const options = {
              method: "GET",
              headers: {
                accept: "application/json",
                "X-API-KEY": "6UEisKAzxdGYrzCYCGJTFIBPQMY8XGGtFDHkEPU4Fgc=",
              },
            };
            const result = await axios.get(`${coinStatsAPI}${item?.currency_type}`, options);
            let price = result.data.price * 100;
            return price;
          }
    }catch(err){
        return 'Error'
    }
  };

  const PostData = ({ item }) => {

    const [price, setPrice] = useState(0);

    useEffect(() => {
        (async() => {
            const wowPrice = await livePrice(item);
            setPrice(wowPrice.toFixed(2))
        })()
    },[])

    return (
      <>
        <Authcheck />
        <>
          {console.log("ITEM =====", item)}
          <div key={item.id} className="close-contract-row">
            <p
              key={item.id}
              style={{ maxWidth: "60px", minWidth: "60px" }}
              className="close-contract-row-data-tag"
            >
              <Link
                to={`/user-profile/${item?.makerId}/${item?.usermaker}`}
                style={{ color: "blue" }}
              >
                {item?.usermaker}
              </Link>
            </p>
            <p
              key={item.id}
              style={{ maxWidth: "100px", minWidth: "100px" }}
              className="close-contract-row-data-tag"
            >
              {item?.isMakerBuying ? "Buyer" : "Seller"}
            </p>
            <p
              key={item.id}
              style={{ maxWidth: "94px", minWidth: "94px" }}
              className="close-contract-row-data-tag"
            >
              {item?.createdAt}
            </p>
            <p
              key={item.id}
              style={{ maxWidth: "95px", minWidth: "95px" }}
              className="close-contract-row-data-tag"
            >
              {item?.currency_symbol}
              {item?.fiatAmount}
            </p>
            <p
              key={item.id}
              style={{ maxWidth: "150px", minWidth: "150px" }}
              className="close-contract-row-data-tag"
            >
              {item?.currency_type}
            </p>
            <p
              key={item.id}
              style={{ maxWidth: "85px", minWidth: "85px" }}
              className="close-contract-row-data-tag"
            >
              {price}
            </p>
            <p
              key={item.id}
              style={{ maxWidth: "175px", minWidth: "175px" }}
              className="close-contract-row-data-tag"
            >
              {item?.payment_method}
            </p>
            <button className="close-contract-row-btn">
              <Link
                to={`/trade/actives/${item.offerId}/${item.makerId}/${item.uniqueid}`}
                style={{ color: "blue" }}
              >
                View
              </Link>
            </button>
          </div>
        </>

        <ToastContainer autoClose={2000} style={{ marginTop: "60px" }} />
      </>
    );
  };

  const getAllPosts = async () => {
    const UserId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const res = await axios.get(
      `${baseURL}/api/trades/getallclosedcontract/${UserId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("transaction data", res);
    if (res.data.data) {
      const data = res.data.data;
      const startIndex = offset * postsPerPage;
      const slice = data.slice(startIndex, startIndex + postsPerPage);
      setAllPosts(slice);
      setPageCount(Math.ceil(data.length / postsPerPage));
    } else {
      setAllPosts(null);
    }
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage);
  };

  const unBlockuser = (id) => {
    Axios.post(`${baseURL}/api/auth/unblock_user/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        toast.success(t("userprofile.User Unblocked Successfully"));
        // console.log("response", response);
        getAllPosts();
        // history.push('/offers/trade')
      })
      .catch((error) => {
        toast.success("Error in Unblocking");
      });
  };

  useEffect(() => {
    setAllPosts(myActiveOrders);
    // LivePrice();
    // getAllPosts();
  }, [myActiveOrders]);

  //   const LivePrice = async () => {
  //     try {
  //         const coinStatsAPI =
  //         "https://api.coinstats.app/public/v1/coins/usd?currency=";
  //       const result = await axios.get(`${coinStatsAPI}${currencyType}`);
  //         let price = (result.data.coin.price)*100;
  //         if(currencyType === 'USD'){
  //           const resp = await axios.get(`${baseURL}/api/wowprice/`)
  //           price = Number(resp.data.price);
  //         }
  //      let adjPrice =
  //         price +
  //         (marginAbove ? price * (margin / 100) : -price * (margin / 100));
  //       setLiveprice(adjPrice.toFixed(2));
  //     } catch {
  //       // console.log("error in tier 0");
  //     }
  //   };

  const Show = () => {};

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      {/* Display all the posts */}
      <div className="close-contract-table">
        <div className="close-contract-header">
          <p
            style={{ maxWidth: "60px", minWidth: "60px" }}
            className="close-contract-header-tag"
          >
            {t("offerTrade.Username")}
          </p>
          <p
            style={{ maxWidth: "100px", minWidth: "100px" }}
            className="close-contract-header-tag"
          >
            {t("offerTrade.Type")}
          </p>
          <p
            style={{ maxWidth: "94px", minWidth: "94px" }}
            className="close-contract-header-tag"
          >
            {t("offerTrade.Opening date and time")}
          </p>
          <p
            style={{ maxWidth: "95px", minWidth: "95px" }}
            className="close-contract-header-tag"
          >
            {t("closedcontract.fiat Amount")}
          </p>
          <p
            style={{ maxWidth: "150px", minWidth: "150px" }}
            className="close-contract-header-tag"
          >
            {t("offerTrade.Currency")}
          </p>
          <p
            style={{ maxWidth: "85px", minWidth: "85px" }}
            className="close-contract-header-tag"
          >
            {t("offerTrade.Price/Wowcoin")}
          </p>
          <p
            style={{ maxWidth: "175px", minWidth: "175px" }}
            className="close-contract-header-tag"
          >
            {t("offerTrade.Payment Method")}
          </p>
          <p
            style={{ maxWidth: "45px", minWidth: "45px" }}
            className="close-contract-header-tag"
          >
            Action
          </p>
        </div>
        {posts?.length > 0 ? (
          posts?.map((item) => <PostData item={item} />)
        ) : (
          <div>
            {/* <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> */}
            <p>{t("Transactions.No data Found")}</p>
          </div>
        )}
      </div>

      {/* Using React Paginate */}
      <ReactPaginate
        previousLabel={t("Withdraw.Previous")}
        nextLabel={t("Withdraw.Next")}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination-container"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
    </div>
  );
}

export default ActiveTradeList;
