import React, { useEffect, useState }  from 'react'
import { Route, Link,useHistory } from "react-router-dom";
import { Breadcrumb } from "antd";
import Axios from 'axios';
import { getTier0, getTier1, getTier2, getKycprocess, kycData } from '../../views/Verification/Helper_veri';
import { Auth, authData } from '../../auth/Auth';
import { doLogout } from "../../store/actions/authActions";
import { useTranslation, withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

const Kyccheck = () => { 
  const [isDisplayNav, setIsDisplayNav] = useState(true);
    const [tierzero, setTierzero] = useState({});
    const [tierone, setTierone] = useState({});
    const [tiertwo, setTiertwo] = useState({});
    // const [kycApprove, setKycapprove] = useState({});
    // const [kycProcess, setkycprocess] = useState();
    const [t, i18n] = useTranslation('common');
    const baseURL = process.env.REACT_APP_BASE_URL;
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
      checkAuth();
     // checkBlock();
      getData();

      return () => {
        setIsDisplayNav(true);
        setTierzero({});
        setTierone({});
        setTiertwo({});
      }
    }, []);

    // console.log('tiertwo:',tiertwo);

    // console.log('(tierzero?.tier0_complate != 1) && (tierone?.tier1_complate != 1) && (tiertwo?.tier2_complate != 1):',(tierzero.tier0_complate != 1) && (tierone.tier1_complate != 1) && (tiertwo.tier2_complate != 1));
    
    const checkAuth = () => {
      authData.subscribe((res) => {
        if (res == null) {
          Auth.isAuthenticated();
        } else {
          setIsDisplayNav(res);
        }
      });
    }

   
    const getData = async() => {
      try {
        kycData.subscribe((res)=>{
          if (!res || !res.tier_z) {
            getTier0();
          } else {
            // console.log('kycData res:',res);
            setTierzero(res.tier_z);
          }
        });
      } catch {
        // console.log("error in tier 0");
      }

      try {
        kycData.subscribe((res)=>{
          if (!res || !res.dataa) {
            getTier1();
          }else {
            // console.log("kycData tier1.....", res)
            setTierone(res.dataa);
          }
        });
      } catch {
        // console.log("error in tier 1");
      }

      try {
        kycData.subscribe((res)=>{
          if (!res || !res.data) {
            getTier2();
          } else{
            // console.log("kycData tier2.....", res)
            setTiertwo(res.data);
          }
        });
      } catch {
        // console.log("error in tier 2");
      }

      // try {
      //   const kyc = await getKycprocess();
      //   // console.log("kyc........................", kyc.data.data.msg)
      //   setkycprocess(kyc.data.data.msg);
      // } catch {
      //   // console.log("error in tier 2");
      // }
    }
    // console.log("tierzero.tier0_complate", tierzero.tier0_complate);  
    // console.log("tiertwo.tier2_complate", tiertwo);

  return (
    <>
      {!isDisplayNav ? <></> :
        <>
          { (tierone.identy_verification == 1) ? <>
           
          </> : <>
          <div className="breadcrumb" style={{ zIndex: "999", }}>
              <Breadcrumb separator=">">
                <Breadcrumb.Item>
                  <h6 style={{ color: "red", textAlign: "center" }}><Link to="/verification" activeClassName="is-active" style={{ color: "red" }}>{t("noti.You must at least update your identity verification status to continue using WalletDays")}
                  <span style={{ color: "blue" }}> {t("noti.Proceed to verify")}</span></Link></h6>
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </>
          }
        </>
      }
    </>

  )
}

export default Kyccheck
