import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Breadcrumb, Input, Button, Divider, Avatar } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link, NavLink, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchOffer } from "../../store/actions/myOffersActions";
import jwtDecode from "jwt-decode";
import Axios from "axios";
import { Auth, authData } from "../../auth/Auth";
// import online from "../../views/Marketing/img/black-circle.png";
// import offline from "../../views/Marketing/img/red.png";
import Escrow from "../../components/Escrow/Escrow";
import { ToastContainer, toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { getWowBalance } from "../../helpers/CallAPi";
import { ReactComponent as ArrowBack } from "../../assets/icons/arrow-back.svg";
// import { Tradestatus } from '../../components/Trades/Tradestatus';
import "./OfferDetails.css";
import {
  inputChangeHandler,
  fetchMarketPrice,
  createTrade,
  createTrade_,
  TRADE_INPUT_RESET,
} from "../../store/actions/ordersActions";
import { updateAction } from "../../helpers/updateActions";
import SectionSix from "../../components/Home/sections/sectionsix";
import axios from "axios";
// console.log("inputChangeHandler", inputChangeHandler());
const baseURL = process.env.REACT_APP_BASE_URL;
const COINSTAT_API_KEY = process.env.COINSTAT_API;
const coinStatsAPI = "https://openapiv1.coinstats.app/coins/usd?currency=";

const OfferDetails = (props) => {
  const [t, i18n] = useTranslation("common");
  const { offerId, main_type, fromuser, fromid, uniqueid } = useParams(
    "main_type",
    "fromuser",
    "fromid",
    "uniqueid"
  );

  const { TextArea } = Input;
  const offerDetails = useSelector((state) => state.myOffers.offerDetails);
  console.log("TruptiofferDetails", offerDetails);
  const orderDetails = useSelector((state) => state.orders.order);
  console.log("chetnaorderDetails", orderDetails);
  // const [PendingMsg, setPendingMsg] = useState();
  const orderErrors = useSelector((state) => state.orders.errorMessages);
  const [isAlive, setIsAlive] = useState();
  const username = localStorage.getItem("username");
  const [MakerUsername, setMakerUsername] = useState();

  // console.log("MakerUsername", MakerUsername);
  const makeronline = MakerUsername?.id;
  // console.log("makeronline", makeronline);

  // console.log(".....", makeronline);
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const [allescrow, setAllEscrow] = useState([]);
  const [WalletBalance, setWalletBalance] = useState();
  const [isDisplayNav, setIsDisplayNav] = useState(true);
  const [tradeStatus, setTradestatus] = useState("");
  const [userOnline, setUseronline] = useState();
  const [livePrice, setLivePrice] = useState();
  const [feedback, setFeedback] = useState();
  const [userinfo, setUserinfo] = useState();
  const [wowPrice, setWowPrice] = useState(1);
  // console.log("{livePrice?.fiat_amount}", userinfo);
  const {
    limitMax,
    limitMin,
    currencyType,
    currencySymbol,
    margin,
    marginAbove,
    makerId,
    id,
    buyBCH,
  } = offerDetails;
  console.log("currencyTypecurrencyType", currencyType);
  console.log("buyBCH", buyBCH);
  const Auth = {
    isAuthenticated() {
      const { token } = localStorage;
      if (!token) return false;
      const decoded = jwtDecode(token);
      if (
        !decoded ||
        !decoded.username ||
        !decoded.userId ||
        !decoded.iat ||
        !decoded.exp
      )
        return false;
      return decoded.exp > Date.now() / 1000;
    },
  };
  // console.log("Auth", Auth);
  const dispatch = useDispatch();

  const order = useSelector((state) => state.orders.currentOrder);
  // console.log("orderabc", order);

  useEffect(() => {
    dispatch(updateAction(TRADE_INPUT_RESET));

    dispatch(fetchOffer(offerId));

    setInterval(() => {
      if (orderErrors) {
        setIsAlive(false);
      }
    }, 1000);

    try {
      Axios.get(`${baseURL}/api/wallet/p1_list_escrocoin/${userId}`).then(
        (response) => {
          setAllEscrow(response.data.data);
          // console.log("Allescrowbal........", response.data);
        }
      );

      Axios.get(`${baseURL}/api/auth/getuserbalance/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((res) => {
        setWalletBalance(res.data.balance.toFixed(8));
        // console.log("res_offer", res.data.balance);
      });
      authData.subscribe((res) => {
        if (res == null) {
          Auth.isAuthenticated();
        } else {
          setIsDisplayNav(res);
        }
      });
    } catch (error) {
      // console.log(error);
    }
  }, []);
  // useEffect(async() => {
  //   console.log("onlineid", makeronline);

  // }, [])

  const escrowApprove = (id, userid, to_address, amount) => {
    // console.log('escrowApprove id:',id)
    const approve = document.getElementById("approve");
    approve.disabled = true;
    try {
      const data = {
        userid: userid,
        to_address: to_address,
        amount: amount,
      };
      // console.log("sdata", data);
      Axios.post(`${baseURL}/api/wallet/p1_permission_p2/${id}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        getWowBalance(userId);
        // console.log("escrowtransactiondone", response);
        window.location.reload();
        // console.log("Hello");
      });
    } catch (error) {
      // console.log(error);
    }
  };

  const escrowTradeCancel = (id) => {
    const cancel = document.getElementById("cancel");
    cancel.disabled = true;
    Axios.get(`${baseURL}/api/wallet/delete_transaction_from_p1/${id}`)
      .then((res) => {
        getWowBalance(userId);
        // toast.success("Cancel Trade Successfully");
        // console.log("Canceltrade.......", res.data);
        window.location.reload();
      })
      .catch((error) => {
        // console.log("error:", error);
        // toast.error("Trade Cancel Failed");
        window.location.reload();
      });
  };

  useEffect(() => {
    llivePrice();

    Axios.get(`${baseURL}/api/orders/msgget_for_disppute/${userId}/${id}`)
      .then((res) => {
        // console.log("Tradestatus.......", res.data);
        setTradestatus(res.data);
      })
      .catch((error) => {
        // console.log("error:", error);
      });

    Axios.get(`${baseURL}/api/offers/offer/${offerId}`)
      .then((response) => {
        // console.log("chetnaTradestatus.......", response);
        setUserinfo(response.data[0]);
        setFeedback(response.data[1]);
      })
      .catch((error) => {
        // console.log("error:", error);
      });
    console.log("typeeeeeeeeeuniqueid", uniqueid);
    Axios.get(
      `${baseURL}/api/wallet/get_tade_with_offer/${offerId}/${uniqueid}`
    )
      .then((response) => {
        console.log("Tradestatus.......", response.data.data.fiat_amount);
        setLivePrice(response.data.data.fiat_amount);
      })
      .catch((error) => {
        // console.log("error:", error);
      });

    if (currencyType) {
      dispatch(fetchMarketPrice(currencyType, margin, marginAbove));
    }

    Axios.get(`${baseURL}/api/auth/useraccount/${makerId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then(
      async (response) => {
        // console.log('MakerPRofile', response.data);
        setMakerUsername(response.data);

        const onlineuser = await Axios.get(
          `${baseURL}/api/auth/get_use_online_status/${response.data.id}`
        );
        // console.log("onlineuser", onlineuser);
        setUseronline(onlineuser.data.user_login);
      },
      (error) => {
        // console.log(error);
      }
    );

    Axios.get(`${baseURL}/api/auth/getuserbalance/${userId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((res) => {
      // console.log("response..................", res.data.balance);
      setInterval(() => {
        setWalletBalance(res.data.balance.toFixed(8));
      }, 1000);

      // console.log("res_offer", res.data.balance);
    });
    authData.subscribe((res) => {
      if (res == null) {
        Auth.isAuthenticated();
      } else {
        setIsDisplayNav(res);
      }
    });
  }, [currencyType]);

  // useEffect(() => {

  // }, [])

  const llivePrice = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "X-API-KEY": "6UEisKAzxdGYrzCYCGJTFIBPQMY8XGGtFDHkEPU4Fgc=",
        },
      };

      let price = 0;

      if (currencyType === "USD") {
        const resp = await axios.get(`${baseURL}/api/wowprice/`);
        price = Number(resp.data.price);
      } else {
        const result = await axios.get(
          `${coinStatsAPI}${currencyType}`,
          options
        );
        price = result.data.price * 100;
      }
      let adjPrice =
        price +
        (marginAbove ? price * (margin / 100) : -price * (margin / 100));
      console.log("adjPrice ===", adjPrice);
      setWowPrice(adjPrice.toFixed(2));
    } catch {
      // console.log("error in tier 0");
    }
  };

  const inputChange = (e) => {
    console.log("orderDetails", orderDetails);
    console.log(
      "eeeeee",
      e,
      "livePriceBCH",
      orderDetails.livePriceBCH,
      "livePrice",
      livePrice
    );
    dispatch(inputChangeHandler(e, orderDetails.livePriceBCH, livePrice));
  };

  const createOrder = () => {
    setIsAlive(true);
    // setPendingMsg("t("notification.Please wait while your transaction is processing")!");
    let newOrderDetails = {
      fiatAmount: orderDetails.fiatAmount,
      cryptoAmount: orderDetails.fiatAmount / Number(wowPrice),
      initialMessage: orderDetails.initialMessage,
      livePriceBCH: orderDetails.livePriceBCH,
      isMakerBuying: orderDetails.isMakerBuying,
    };
    dispatch(
      createTrade(
        newOrderDetails,
        limitMin,
        limitMax,
        makerId,
        id,
        buyBCH,
        props.history,
        offerDetails.currencyType
      )
    );
  };

  const createOrder_ = () => {
    // setPendingMsg("t("notification.Please wait while your transaction is processing")!");
    dispatch(
      createTrade_(
        orderDetails,
        limitMin,
        limitMax,
        makerId,
        id,
        buyBCH,
        props.history,
        fromuser,
        fromid,
        offerDetails.currencyType
      )
    );
  };
  let limitText = `${currencySymbol}${limitMin} to ${currencySymbol}${limitMax}`;
  if (!limitMax && !limitMin) {
    limitText = "No limits";
  } else if (!limitMax) {
    limitText = `Minimum trade of ${currencySymbol}${limitMin}`;
  } else if (!limitMin) {
    limitText = `Up to ${currencySymbol}${limitMax}`;
  }
  // console.log("limitMax", limitMax);

  let instructionText =
    "Arrange a meeting time and place to exchange face-to-face.";

  if (offerDetails.paymentMethod !== "Cash in person") {
    if (buyBCH) {
      instructionText = `Send fiat using ${t(
        "newoffers." + offerDetails.paymentMethod
      )}`;
    } else {
      instructionText = `Receive fiat by ${t(
        "newoffers." + offerDetails.paymentMethod
      )}`;
    }
  }

  return (
    <>
      {" "}
      <div className="offerdetails-page">
        <div className="offerdetails-upper"></div>
        <Authcheck />

        <div className="offerdetails-container ">
          {main_type ? (
            <>
              {/* <Tradestatus/> */}

              <div className="breadcrumb">
                <Breadcrumb separator=">">
                  <Breadcrumb.Item>
                    <Link to="/offers"> {t("Sellertrade.Offers")}</Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {buyBCH ? (
                      <>{t("Sellertrade.Buy WOW from")}</>
                    ) : (
                      <>{t("Sellertrade.Sell WOW to")}</>
                    )}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>{fromuser}</Breadcrumb.Item>
                  <Breadcrumb.Item>
                    {t("Sellertrade.with")}{" "}
                    {t("newoffers." + offerDetails.paymentMethod)}
                  </Breadcrumb.Item>
                </Breadcrumb>
              </div>

              <div>
                <h4>{t("Sellertrade.Open Trade")}</h4>
                <br />
                <form>
                  {!buyBCH ? (
                    <div className="trade-label">
                      <h5>
                        <b>{t("Sellertrade.Buyer want's to buy at")}:</b>
                      </h5>
                      <h5>
                        <b>
                          {offerDetails.currencySymbol}
                          {livePrice}
                        </b>
                      </h5>
                    </div>
                  ) : (
                    <></>
                  )}
                  <div className="trade-label">
                    <p>{t("Sellertrade.Trade Amount")}</p>
                    <p>{limitText}</p>
                  </div>
                  {buyBCH ? (
                    <div className="trade-input">
                      <Input
                        name="cryptoAmount"
                        placeholder="0.00000000"
                        prefix="WOW"
                        value={orderDetails.cryptoAmount}
                        onChange={(e) => inputChange(e)}
                      />

                      <Input
                        name="fiatAmount"
                        type="number"
                        placeholder="$ 0.00"
                        prefix={offerDetails.currencyType}
                        value={Math.trunc(orderDetails.fiatAmount)}
                        onChange={inputChange}
                      />
                      <p>
                        {t("Sellertrade.My Current Wowcoin Balance")}:{" "}
                        {WalletBalance}
                      </p>
                    </div>
                  ) : (
                    <div className="trade-input">
                      <Input
                        type="number"
                        name="fiatAmount"
                        placeholder={`${offerDetails?.currencySymbol} 0.00`}
                        prefix={offerDetails.currencyType}
                        value={orderDetails.fiatAmount}
                        onChange={inputChange}
                      />
                      <Input
                        name="cryptoAmount"
                        placeholder="0.00000000"
                        prefix="WOW"
                        value={orderDetails.cryptoAmount}
                        onChange={(e) => inputChange(e)}
                      />
                    </div>
                  )}

                  {orderErrors.length > 0 && (
                    <div className="">
                      {orderErrors.map((error) => (
                        <p key={error} style={{ color: "red" }}>
                          * {t("Sellertrade." + error)}
                        </p>
                      ))}
                    </div>
                  )}
                  {isDisplayNav ? (
                    <>
                      <div className="trade-text">
                        <label>{t("Sellertrade.Send a message")}</label>
                        <TextArea
                          name="initialMessage"
                          rows={4}
                          placeholder={t("Sellertrade.Message")}
                          value={orderDetails.initialMessage}
                          onChange={(e) => inputChange(e)}
                        />
                      </div>

                      {makerId == userId ? (
                        <Button
                          type="primary"
                          disabled
                          style={{
                            color: "#ffffff",
                            borderColor: "#23d99",
                            backgroundColor: "#23d99c",
                          }}
                        >
                          <span
                            style={{
                              fontSize: "13px",
                              Color: "#23d99",
                            }}
                          >
                            {t(
                              "Sellertrade.You cannot make request to your own offer"
                            )}
                          </span>
                        </Button>
                      ) : (
                        <Button
                          type="primary"
                          onClick={(e) => {
                            createOrder();
                          }}
                          disabled={isAlive}
                        >
                          {token ? (
                            <>{t("Sellertrade.Open Trade")}</>
                          ) : (
                            <>{t("Sellertrade.Register")}</>
                          )}
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      <h4
                        style={{
                          marginBottom: "10px",
                          color: "#87d068",
                        }}
                      >
                        {t("Sellertrade.Register and do trading")}
                      </h4>
                      <NavLink to="/signup">
                        <Button type="primary" style={{ width: "100%" }}>
                          {t("Sellertrade.Register")}
                        </Button>
                      </NavLink>
                    </>
                  )}
                </form>
              </div>

              <div className="trade-details">
                <h5>
                  {`1 WOW = ${offerDetails.currencySymbol} 
                      ${
                        orderDetails.livePriceBCH &&
                        orderDetails.livePriceBCH.toFixed(2)
                      }`}
                </h5>
                <p>
                  The{" "}
                  {!buyBCH ? t("Sellertrade.seller") : t("Sellertrade.buyer")}{" "}
                  {t("Sellertrade.chose this price - only continue")}
                </p>
                <br />

                <Divider />
                <div className="details-info">
                  <div className="counterparty">
                    <p>
                      <b>{t("Sellertrade.Offer info")} </b>
                    </p>
                    <p>
                      {" "}
                      <Link
                        to={`/user-profile/${makerId}/${MakerUsername?.username}`}
                      >
                        {!buyBCH
                          ? t("Sellertrade.seller")
                          : t("Sellertrade.buyer")}
                        :{" "}
                        <span style={{ color: "blue" }}>
                          {MakerUsername?.username}
                        </span>{" "}
                      </Link>{" "}
                    </p>
                    <p>
                      {!buyBCH
                        ? t("Sellertrade.seller")
                        : t("Sellertrade.buyer")}{" "}
                      Active hours: {offerDetails.openHours}{" "}
                      {offerDetails.closeHours}
                    </p>
                    <br />

                    <p>
                      <b>{t("Sellertrade.Headline")} : </b>
                      {offerDetails.headline ? (
                        <> {offerDetails.headline} </>
                      ) : (
                        <> --- </>
                      )}
                    </p>

                    <br />
                    <p>
                      <b>{t("Sellertrade.Trade Terms")} : </b>
                      {offerDetails.tradeTerms ? (
                        <> {offerDetails.tradeTerms} </>
                      ) : (
                        <> --- </>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <>
                <Link to="/">
                  <div className="go-back-wrap">
                    <ArrowBack />
                    <p className="go-back">Back</p>
                  </div>
                </Link>
              </>

              <div className="sub-offerdetails">
                <div className="sub-offerdetails-left">
                  <p className="profile-heading">
                    {t("Sellertrade.Open Trade")}{" "}
                  </p>

                  <form style={{ width: "100%", maxWidth: "430px" }}>
                    {livePrice == "" ? (
                      <>
                        {buyBCH ? (
                          <div className="trade-label">
                            <h5>
                              <b>Buyer want's to buy at:</b>
                            </h5>
                            <h5>
                              <b>
                                {offerDetails.currencySymbol}
                                {livePrice}
                              </b>
                            </h5>
                          </div>
                        ) : (
                          <></>
                        )}
                      </>
                    ) : (
                      <></>
                    )}

                    <div className="trade-amount-row">
                      <p className="offer-tags ">
                        {t("Sellertrade.Trade Amount")}
                      </p>
                      <p className="offer-tags ">{limitText}</p>
                    </div>
                    {buyBCH ? (
                      <div>
                        <div
                          style={{ marginBottom: "15px" }}
                          className="offers-input-fields-wrappers "
                        >
                        {console.log('orderDetails.cryptoAmount / Number(wowPrice)', orderDetails.cryptoAmount, Number(wowPrice))}
                          <p className="offer-wrapper-tags">{"WOW"}</p>
                          <input
                            className="offers-input-fields"
                            name="cryptoAmount"
                            placeholder="0.00000000"
                            value={orderDetails.fiatAmount / Number(wowPrice)}
                            onChange={(e) => inputChange(e)}
                          />
                        </div>
                        <div className="offers-input-fields-wrappers ">
                          <p className="offer-wrapper-tags">
                            {offerDetails.currencyType}
                          </p>
                          <input
                            type="number"
                            name="fiatAmount"
                            className="offers-input-fields"
                            placeholder={`${offerDetails?.currencySymbol} 0.00`}
                            value={Math.trunc(orderDetails.fiatAmount)}
                            onChange={inputChange}
                          />
                        </div>
                        <p>
                          {t("Sellertrade.My Current Wowcoin Balance")}:{" "}
                          {WalletBalance}
                        </p>
                      </div>
                    ) : (
                      <div>
                        <div
                          style={{ marginBottom: "15px" }}
                          className="offers-input-fields-wrappers "
                        >
                          <p className="offer-wrapper-tags">
                            {" "}
                            {offerDetails.currencyType}
                          </p>
                          {console.log('oddd ===', offerDetails?.currencySymbol)}
                          <input
                            type="number"
                            name="fiatAmount"
                            className="offers-input-fields"
                            placeholder={`${offerDetails?.currencySymbol} 0.00`}
                            defaultValue={orderDetails.fiatAmount}
                            onChange={inputChange}
                          />
                        </div>
                        <div className="offers-input-fields-wrappers ">
                          <p className="offer-wrapper-tags">{"WOW"}</p>
                          <input
                            name="cryptoAmount"
                            placeholder="0.00000000"
                            className="offers-input-fields"
                            value={orderDetails.fiatAmount / Number(wowPrice)}
                            onChange={inputChange}
                          />
                        </div>
                      </div>
                    )}
                    {isDisplayNav ? (
                      <>
                        <div
                          style={{ marginTop: "30px", marginBottom: "30px" }}
                        >
                          <label
                            style={{ marginBottom: "12px" }}
                            className="offer-tags "
                          >
                            {t("Sellertrade.Send a message")}
                          </label>
                          <textarea
                            className="offer-textarea"
                            name="initialMessage"
                            rows={4}
                            placeholder={t("Sellertrade.Message")}
                            value={orderDetails.initialMessage}
                            onChange={(e) => inputChange(e)}
                          />
                        </div>
                        {orderErrors.length > 0 && (
                          <div className="">
                            {orderErrors.map((error) => (
                              <p key={error} style={{ color: "red" }}>
                                * {t("Sellertrade." + error)}
                              </p>
                            ))}
                          </div>
                        )}
                        {makerId == userId ? (
                          <Button
                            onClick={(e) => {
                              e.target.setAttribute("disabled", true);
                            }}
                            style={{
                              color: "#ffffff",
                              borderColor: "#23d99",
                              backgroundColor: "#23d99c",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "13px",
                                Color: "#23d99",
                              }}
                            >
                              {t(
                                "Sellertrade.You cannot make request to your own offer"
                              )}
                            </span>
                          </Button>
                        ) : (
                          <button
                            className="offers-btn"
                            type="primary"
                            id="OpenTrade"
                            onClick={(e) => {
                              createOrder();
                            }}
                            disabled={isAlive}
                          >
                            {token ? (
                              <>{t("Sellertrade.Open Trade")}</>
                            ) : (
                              <>{t("Sellertrade.Register")}</>
                            )}
                          </button>
                        )}
                      </>
                    ) : (
                      <>
                        <h4
                          style={{
                            marginBottom: "10px",
                            color: "#87d068",
                          }}
                        >
                          {t("Sellertrade.Register and do trading")}
                        </h4>
                        <NavLink to="/signup">
                          <Button type="primary" style={{ width: "100%" }}>
                            {t("Sellertrade.Register")}
                          </Button>
                        </NavLink>
                      </>
                    )}
                  </form>
                </div>
                <div className="sub-offerdetails-right">
                  <div className="sub-offerdetails-right-container">
                    <p className="wow-dollars">
                      {`1 WOW = ${offerDetails.currencySymbol} 
                       ${
                         orderDetails.livePriceBCH &&
                         orderDetails.livePriceBCH.toFixed(2)
                       }`}
                    </p>
                    <p className="wow-line-tag">
                      The{" "}
                      {!buyBCH
                        ? t("Sellertrade.seller")
                        : t("Sellertrade.buyer")}{" "}
                      {t("Sellertrade.chose this price - only continue")}
                    </p>

                    <>
                      <div className="details-container">
                        <p className="offerInfo">
                          <b>{t("Sellertrade.Offer info")} </b>
                        </p>
                        <p className="details-tag">
                          {" "}
                          <Link
                            style={{ color: "black" }}
                            to={`/user-profile/${makerId}/${MakerUsername?.username}`}
                          >
                            {!buyBCH
                              ? t("Sellertrade.seller")
                              : t("Sellertrade.buyer")}
                            :{" "}
                            <span
                              className="details-tag-span"
                              style={{ color: "blue" }}
                            >
                              {MakerUsername?.username}
                            </span>{" "}
                          </Link>{" "}
                        </p>
                        <p className="details-tag">
                          {!buyBCH
                            ? t("Sellertrade.seller")
                            : t("Sellertrade.buyer")}{" "}
                          {t("Sellertrade.Active hours")}:{" "}
                          <span className="details-tag-span">
                            {" "}
                            {offerDetails.openHours} {offerDetails.closeHours}
                          </span>
                        </p>

                        <p className="details-tag">
                          {t("Sellertrade.Headline")} :
                          <span className="details-tag-span">
                            {" "}
                            {offerDetails.headline ? (
                              <> {offerDetails.headline} </>
                            ) : (
                              <> --- </>
                            )}
                          </span>
                        </p>

                        <p className="details-tag">
                          {t("Sellertrade.Trade Terms")} :
                          <span className="details-tag-span">
                            {" "}
                            {offerDetails.tradeTerms ? (
                              <> {offerDetails.tradeTerms} </>
                            ) : (
                              <> --- </>
                            )}
                          </span>
                        </p>
                      </div>
                    </>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <ToastContainer autoClose={2000} style={{ marginTop: "60px" }} />
      </div>
      <SectionSix />
    </>
  );
};

export default OfferDetails;
