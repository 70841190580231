import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { fetchAccount } from "../../store/actions/myAccountActions";
import {useTranslation, withTranslation} from "react-i18next";

import "./Notifications.scss";

const Account = (props) => {
  const [t, i18n] = useTranslation('common');
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };

  const [userInfo, setUserInfo] = useState({});


  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">

            <div className="notifications-container" style={{padding: '0%'}}>
              <div className="secondary-nav">
                <div className="tab-link">
                  <Link to="/my-account" activeClassName="is-active">
                  {t('Profile.Profile')}
                  </Link>
                </div>
                <div className="tab-link">
                  <Link to="/settings" activeClassName="is-active">
                  {t("Profile.Security")}
                  </Link>
                </div>

                <div className="tab-link">
                  <Link to="/verification" activeClassName="is-active">
                  {t("Profile.Verification")}
                  </Link>
                </div>

                {/* <div className="tab-link">
                  <Link to="/notifications" activeClassName="is-active">
                    Notifications
                  </Link>
                </div> */}

                {/* <div className="tab-link">
                  <Link to="/advanced" activeClassName="is-active">
                  {t("Profile.Advanced")}
                  </Link>
                </div> */}
              </div>

      </div>

      <div className="">
            

              <div className="row mt-4">
              <div class="col-12 col-lg-12 pr-lg-0">
        <div className="col border rounded">
          <form method="post" className="table-responsive-sm" style={{ width: '100% !important' }}>
            <input type="hidden" name="csrfmiddlewaretoken" defaultValue="zhU2nPnocvMvhk5GMUaSTuTDSxbhgDXeUjt0HaEbQQYi2RLZafS5HiB5kvQ7as6r" />
            <table className="table">
              <thead>
                <tr>
                  <th scope="col" style={{width: '75%'}}>Notification type</th>
                  <th scope="col">Email</th>
                  <th scope="col">SMS/Telegram</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>New trade contacts</td>
                  <td><input type="checkbox" name="new_contact_email" id="id_new_contact_email" defaultChecked /></td>
                  <td><input type="checkbox" name="new_contact_sms" id="id_new_contact_sms" /></td>
                </tr>
                <tr className="help-block">
                  <td colSpan={3}>Receive notifications of new contact requests.</td>
                </tr>
                <tr>
                  <td>New online payments</td>
                  <td><input type="checkbox" name="contact_marked_paid_email" id="id_contact_marked_paid_email" defaultChecked /></td>
                  <td><input type="checkbox" name="contact_marked_paid_sms" id="id_contact_marked_paid_sms" /></td>
                </tr>
                <tr className="help-block">
                  <td colSpan={3}>Receive notifications of new online payments in your advertisements.</td>
                </tr>
                <tr>
                  <td>Online escrow released</td>
                  <td><input type="checkbox" name="escrow_released_email" id="id_escrow_released_email" defaultChecked /></td>
                  <td><input type="checkbox" name="escrow_released_sms" id="id_escrow_released_sms" /></td>
                </tr>
                <tr className="help-block">
                  <td colSpan={3}>Receive notifications of new online escrow releases.</td>
                </tr>
                <tr>
                  <td>New disputes</td>
                  <td><input type="checkbox" name="dispute_started_email" id="id_dispute_started_email" defaultChecked /></td>
                  <td><input type="checkbox" name="dispute_started_sms" id="id_dispute_started_sms" defaultChecked /></td>
                </tr>
                <tr className="help-block">
                  <td>Receive notifications of new disputes. It is highly recommended to have at least one of the options selected.</td>
                </tr>
                <tr className="margin-split">
                  <th colSpan={3}>Other notification settings.</th>
                </tr>
                <tr>
                  <td>Disable sensitive information in email notifications.</td>
                  <td><input type="checkbox" name="disable_sensitive_emails" id="id_disable_sensitive_emails" /></td>
                </tr>
                <tr className="help-block">
                  <td colSpan={3}>The e-mails will just ask you to log in to the site, where the actual notifications will be.</td>
                </tr>
                <tr>
                  <td>Receive updates and communication from LocalBitcoins occasionally</td>
                  <td><input type="checkbox" name="marketing_consent" id="id_marketing_consent" /></td>
                </tr>
                <tr>
                  <td />
                  <td colSpan={2} style={{textAlign: 'right'}}>
                    <button className="btn btn-block btn-outline-primary mb-2" type="submit">
                      Save changes
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="col mt-4">
              <p>
                Note that some notifications are not send by email or sms if you are at the moment active on the site. You will receive only on-site notifications instead.
              </p>
            </div>
          </form>
          <table className="table mt-5">
            <tbody>
              <tr>
                <th scope="row" style={{width: '75%', paddingBottom: '.20rem'}}>Telegram</th>
                <td style={{paddingBottom: '.20rem'}}>
                  <a href="/telegram/telegram-activate/" className="btn btn-outline-primary btn-block float-right">
                    Enable
                  </a>
                </td>
              </tr>
              <tr className="help-block">
                <td colSpan={2}>
                  Once you have activated LocalBitcoins Telegram Bot, notifications will be sent by the bot instead of SMS.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
      </div>
      </div>
                </div>
              </div>
            </div>
    </section>
          );
};

          export default Account;
