import React, { useEffect, useState } from 'react'
import diagonalshape from "../../views/Marketing/img/layout/diagonal-shape-1.svg";
import { Link } from "react-router-dom";
import { dataMapper } from "../../helpers/dataMapper";
import { useTranslation, withTranslation } from "react-i18next";
import { Button } from "antd";
import './Banner.scss';
const axios = require('axios');
const baseURL = process.env.REACT_APP_BASE_URL;

const Bannerbuy = () => {
  let userId = localStorage.getItem("userId");
  
  const [t, i18n] = useTranslation('common');
  const [buyoffer, setBuyoffer] = useState([]);
  useEffect(() => {
    buyTwo();
  }, [])

  const buyTwo = async() => {
    userId = localStorage.getItem("userId");
    console.log('buyTwo userId:',userId);
    if (userId) {
      console.log('going to if');
      const result = await axios.get(`${baseURL}/api/offers/all-offers/${userId}`);
      // console.log("result", result);
      let buyOffers = [];
      let sellOffers = [];
      result.data.forEach((offer) => {
        if (sellOffers.length!=2){
          if (!offer.buyBCH) sellOffers.push(dataMapper(offer));
         }
      });
      console.log(sellOffers);
      setBuyoffer(sellOffers);
    } else {
      const result = await axios.get(`${baseURL}/api/offers/all-offers/${0}`);
      let buyOffers = [];
      let sellOffers = [];
      result.data.forEach((offer) => {
        if (sellOffers.length!=2){
          if (!offer.buyBCH){ sellOffers.push(dataMapper(offer))};
         }
      });
      console.log('going to else:,',sellOffers);
      setBuyoffer(sellOffers);

    }

  }
  return (
    <div>

      <h6>{t('Home.Buy WOW Coin')}</h6>

      <div className="ico-counter" >
        <div className="counter-down">
          <div className="content">
            <div className="conuter-header">
              {/* <h3 class="text-center">TOKEN SALE ENDS IN</h3> */}
            </div>
            <div className="offer-card mob_abca" style={{ overFlow: "auto", padding: "0% 5%" }}>
              <div className="user box webbox">
                <div className="username useaa" >
                  <b>{t('newoffers.Seller')}</b>
                </div>
              </div>
              <div className="user box webbox">
                <div className="username useaa" >
                  <b>{t('newoffers.Currency')}</b>
                </div>
              </div>
              <div className="user box webbox">
                <div className="username useaa" >
                  <b>{t('Explore.Country')}</b>
                </div>
              </div>
              <div className="user box webbox">
                <div className="username useaa" >
                  <b>{t('Myoffers.Limits')}</b>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {buyoffer.map(item => (
         <Link key={item.id} to={`/offer-details/${item.id}`}>
          
        <div className="ico-counter">
          <div className="counter-down">
            <div className="content">
              <div className="conuter-header">
                {/* <h3 class="text-center">TOKEN SALE ENDS IN</h3> */}
              </div>
              <div className="offer-card mob_abca" style={{ overFlow: "auto", padding: "0% 1%" }}>
                <div className="user box webbox">
                  <div className="user box webbox useaa" key={item.id}>
                    {item?.username}
                  </div>
                </div>
                <div className="user box webbox useaa" key={item.id}>{item?.currencyType} ({item?.currencySymbol})</div>
                <div className="user box webbox useaa" key={item.id}>{item?.country}</div>
                <div className="user box webbox useaa" key={item.id}>{item?.limitMin}  to {item?.limitMax}</div>
              </div>
            </div>
          </div>
        </div>
        </Link>
      ))}
      <Link to="/offers/buywowCoin?page=1">
        <button style={{ float: "right" }} className="ant-btn ant-btn-primary">{t('Myoffers.View More')}</button>
      </Link>

    </div>
  )
}

export default Bannerbuy
