export const orderMapper = (order) => {
  console.log("order ====", order);
  const {
    id,
    usertaker,
    usermaker,
    cancelled,
    complete,
    uniqueid,
    currency_symbol,
    currency_type,
    payment_method,
    perform_by,
    amount,
  } = order;
  // console.log("usermaker...........chetna..", order);
  return {
    id,
    usertaker,
    usermaker,
    cancelled,
    complete,
    paid: order.paid,
    bchAmount: order.bch_amount,
    createdAt: order.created_at,
    updatedAt: order.updated_at,
    fiatAmount: order.fiat_amount,
    makerId: order.maker_id,
    offerId: order.offer_id,
    priceBCH: order.price_bch,
    takerId: order.taker_id,
    isMakerBuying: order.is_maker_buying,
    uniqueid: uniqueid,
    currency_symbol: currency_symbol,
    currency_type,
    payment_method,
    perform_by,
    amount
  };
};
