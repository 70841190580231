import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link } from "react-router-dom";
import { Form, Input, Button } from "antd";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { fetchAccount } from "../../store/actions/myAccountActions";
import "./OnfidoDocument.scss";
import passport from "../../views/Marketing/img/passport.png";
import car from "../../views/Marketing/img/sedan.png";
import idcard from "../../views/Marketing/img/id-card.png";
import {useTranslation, withTranslation} from "react-i18next";
import permit from "../../views/Marketing/img/permit.png";

const Account = (props) => {
  const [t, i18n] = useTranslation('common');
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };



  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">

            <div className="profile-container">
              <div className="secondary-nav">
                <div className="tab-link">
                  <Link to="/my-account" activeClassName="is-active">
                  {t('Profile.Profile')}
                  </Link>
                </div>
                <div className="tab-link">
                  <Link to="/settings" activeClassName="is-active">
                  {t("Profile.Security")}
                  </Link>
                </div>

                <div className="tab-link">
                  <Link to="/verification" activeClassName="is-active">
                  {t("Profile.Verification")}
                  </Link>
                </div>

                {/* <div className="tab-link">
                  <Link to="/notifications" activeClassName="is-active">
                    Notifications
                  </Link>
                </div> */}

                {/* <div className="tab-link">
                  <Link to="/advanced" activeClassName="is-active">
                  {t("Profile.Advanced")}
                  </Link>
                </div> */}
              </div>

              <br></br>
              <div class="row">
                <div className="col-md-12">
                  <div id="base-status-messages" className="alert alert-info ">
                    <a className="close" href="#" data-dismiss="alert">×</a>
                    Supported documents for India are: National ID Card, Passport, Tax ID document, Voter ID. See full list of supported documents <b><a href="/accounts/onfido/supported_documents/">Here</a></b>
                  </div>
                </div>

                <div class="col-12 col-lg-12 pr-lg-0">
                  <div className="onfido-sdk-ui-Modal-inner">
                    <div className="onfido-sdk-ui-Theme-step onfido-sdk-ui-Theme-defaultLogo" tabIndex={-1}>
                      <div className="onfido-sdk-ui-Theme-navigationBar onfido-sdk-ui-NavigationBar-navigation">
                        <button type="button" aria-label="back" className="onfido-sdk-ui-NavigationBar-back onfido-sdk-ui-NavigationBar-backHoverDesktop" style={{ float: 'left'  }}>
                        <span className="onfido-sdk-ui-NavigationBar-iconBack" />
                        <span className="onfido-sdk-ui-NavigationBar-label" aria-hidden="true">back</span>
                      </button>
                      </div>
                      <div className="onfido-sdk-ui-Theme-content onfido-sdk-ui-Theme-scrollableContent">
                        <div>
                          <div className="onfido-sdk-ui-PageTitle-titleWrapper">
                            <div className="onfido-sdk-ui-PageTitle-title" role="heading" aria-level={1} aria-live="assertive">
                              <span className="onfido-sdk-ui-PageTitle-titleSpan" tabIndex={-1} style={{ fontSize : '20px',fontWeight:'900'}}>Choose your document</span>
                            </div>
                           
                            <div className="onfido-sdk-ui-PageTitle-subTitle" role="heading" aria-level={2} style={{  fontWeight:'900'}}>It must be an official photo ID</div>
                          </div>
                          <br/>
                          <ul aria-label="Documents you can use to verify your identity" className="onfido-sdk-ui-DocumentSelector-list">
                            <li>
                            <a href="/passport/">
                              <button type="button" className="onfido-sdk-ui-DocumentSelector-option onfido-sdk-ui-DocumentSelector-optionHoverDesktop" data-onfido-qa="passport">
                              <div className="onfido-sdk-ui-DocumentSelector-icon onfido-sdk-ui-DocumentSelector-icon-passport">
                              <img className="navbar-brand__regular" src={passport}  alt="brand-logo" style={{ width: '50px' }}/>
                              </div>
                              <div className="onfido-sdk-ui-DocumentSelector-content" style={{paddingLeft:'25px'}}>
                                <div className="onfido-sdk-ui-DocumentSelector-optionMain">
                                  <p className="onfido-sdk-ui-DocumentSelector-label">Passport</p>
                                  <div className="onfido-sdk-ui-DocumentSelector-hint">Photo page</div>
                                  </div>
                                  </div>
                                  </button>

                               </a>
                            </li>
                            <li>
                            <a href="/onfido_document/">
                              <button type="button" className="onfido-sdk-ui-DocumentSelector-option onfido-sdk-ui-DocumentSelector-optionHoverDesktop" data-onfido-qa="driving_licence"><div className="onfido-sdk-ui-DocumentSelector-icon onfido-sdk-ui-DocumentSelector-icon-driving-licence">
                            <img className="navbar-brand__regular" src={car}  alt="brand-logo" style={{ width: '50px' }}/>
                            </div>
                              <div className="onfido-sdk-ui-DocumentSelector-content" style={{paddingLeft:'25px'}}>
                                <div className="onfido-sdk-ui-DocumentSelector-optionMain">
                                <p className="onfido-sdk-ui-DocumentSelector-label">Driver’s license</p>
                                <div className="onfido-sdk-ui-DocumentSelector-hint">Front and back</div></div></div>
                            </button>
                             </a>
                            </li>
                            <li>
                            <a href="/onfido_document/">
                              <button type="button" className="onfido-sdk-ui-DocumentSelector-option onfido-sdk-ui-DocumentSelector-optionHoverDesktop" data-onfido-qa="national_identity_card">
                                <div className="onfido-sdk-ui-DocumentSelector-icon onfido-sdk-ui-DocumentSelector-icon-national-identity-card">
                                <img className="navbar-brand__regular" src={idcard}  alt="brand-logo" style={{ width: '40px' }}/>
                                </div>
                                <div className="onfido-sdk-ui-DocumentSelector-content" style={{paddingLeft:'25px'}}>
                                  <div className="onfido-sdk-ui-DocumentSelector-optionMain">
                                    <p className="onfido-sdk-ui-DocumentSelector-label">Identity card</p>
                                    <div className="onfido-sdk-ui-DocumentSelector-hint">Front and back</div></div>
                                </div></button>
                                </a>
                            </li>
                            <li>
                            <a href="/onfido_document/">
                              <button type="button" className="onfido-sdk-ui-DocumentSelector-option onfido-sdk-ui-DocumentSelector-optionHoverDesktop" data-onfido-qa="residence_permit">
                              <div className="onfido-sdk-ui-DocumentSelector-icon onfido-sdk-ui-DocumentSelector-icon-residence-permit">
                              <img className="navbar-brand__regular" src={permit}  alt="brand-logo" style={{ width: '40px' }}/>
                               </div>
                              <div className="onfido-sdk-ui-DocumentSelector-content" style={{paddingLeft:'25px'}}>
                                <div className="onfido-sdk-ui-DocumentSelector-optionMain">
                                <p className="onfido-sdk-ui-DocumentSelector-label">Residence permit</p><div className="onfido-sdk-ui-DocumentSelector-hint">Front and back</div></div></div>
                            </button>
                            </a>
                            </li>
                          </ul>
                          </div>
                      </div>
                      
                      <div className="onfido-sdk-ui-Theme-footer">
                        <div className="onfido-sdk-ui-Theme-logo">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Account;
