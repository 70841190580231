import React, { useEffect, useState, useRef } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Form, Input, Button, Select } from "antd";
import searchLogo from "../../views/Marketing/img/search.png";
import { Route, NavLink } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import "./ReceiveBnbCoin";
import "./ReceiveBnbCoin.css";
import QRcodePic from "../../assets/icons/qr-code.svg";
import QrCode from "../../views/QrCode/QrCode";
// import "./Signup.scss";
const { Option } = Select;
const axios = require("axios");
const baseURL = process.env.REACT_APP_BASE_URL;

const ReceiveBnbCoin = (props) => {
  const [GetWowAddress, setGetWowAddress] = useState();
  const [seconds, setSeconds] = useState();

  const textAreaRef = useRef(null);
  const UserId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  // const url = `${baseURL}/api/wallet/getaddress/${UserId}`;
  const getData = () => {
    axios.get(`${baseURL}/api/wallet/getaddress/${UserId}`).then(
      (response) => {
        // console.log('response:', response.data.wowaddress);
        setGetWowAddress(response.data.wowaddress);
      },
      (error) => {
        // console.log(error);
      }
    );
  };
  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setSeconds(t("wallet.Address Copied"));
  }

  useEffect(() => getData(), []);

  var qrCodeurl =
    "https://chart.googleapis.com/chart?cht=qr&chl=" +
    GetWowAddress +
    "&chs=160x160&chld=L|0";
  // console.log(qecodeurl);

  const [t, i18n] = useTranslation("common");
  return (
    <>
      <Authcheck />
      <div className="receive-address">
        <input
          type="text"
          className="copy-address"
          ref={textAreaRef}
          value={GetWowAddress}
          style={{ width: "100%" }}
          id="myInput"
          readOnly
        />
        <button onClick={copyToClipboard} className="copy-btn">
          {t("wallet.Copy Address")}
        </button>{" "}
      </div>
      <h6 style={{ color: "#00cc90" }}>{seconds}</h6>
      <div class="old-address-card">
        <p class="old-address-header">{t("wallet.Old Addresses")}</p>

        <p class="old-header-line">
          {t("wallet.To protect your privacy matic")}
        </p>
        <p className="old-header-note">
          * {t("wallet.Note that addresses are valid only for 12 months")}
        </p>
      </div>
      <div class="qr-card">
        <p class="old-address-header">{t("wallet.QR Code for Mobile")}</p>

        <p class="old-header-line">
          {t("wallet.This is your receiving address as a QR code")}
        </p>
        <img style={{ marginBottom: "20px" }} src={QRcodePic} alt="qr" />
      </div>
    </>
  );
};

export default ReceiveBnbCoin;