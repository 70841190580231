import React, { useEffect, useRef, useState } from "react";
import Authcheck from "../../../components/Authcheck/Authcheck";
import { Form } from "antd";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import openSocket from "socket.io-client";
import { useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import ChatText from "./ChatText";
import { ReactComponent as File } from "../../../assets/icons/file.svg";
import RefreshICon from "../../../assets/icons/refresh.svg";
import {
  createMessage,
  fetchMyMessages,
  addMessage,
} from "../../../store/actions/chatActions";
import "./Chat.css";
const baseURL = process.env.REACT_APP_BASE_URL;

const Chat = ({ orderId, taker, maker }) => {
  const [t] = useTranslation("common");

  const userId = localStorage.getItem("userId");
  // console.log("takertaker...", taker);
  // console.log("makermaker", maker);
  const usePar = useParams();
  const uniqueId = usePar.uniqueId;
  console.log("usePar_", usePar);
  // console.log("usePar", usePar.fromuser);
  const chatMessages = useSelector(
    (state) => state.chat.chatMessages,
    shallowEqual
  );
  const [form] = Form.useForm();
  const [allFile, setAllfile] = useState({});
  const dispatch = useDispatch();
  const [newChat, setNewChat] = useState(0);
  // console.log("chatMessages", chatMessages);
  const [chats, setChats] = useState([]);

  console.log("CHAT ====", chatMessages);

  useEffect(() => {
    dispatch(fetchMyMessages(uniqueId));
    setChats(chatMessages);
  }, [taker, maker, dispatch, uniqueId, chatMessages]);

  useEffect(() => {
    const socket = openSocket(baseURL);
    socket.emit("joinRoom", orderId, taker, maker);
    socket.on("newMessage", (data) => {
      if (data.action === "create") {
        dispatch(addMessage(...data.message));
      }
    });
  }, []);


  const onFinish = async ({ message }) => {
    const newArr = [...chats];
    const formData = new FormData();
    formData.append("documentfile", allFile.selectedFile);
    formData.append("text", message);
    formData.append("author_id", userId);
    formData.append("order_id", orderId);
    formData.append("uniqueid", usePar.uniqueId);
    console.log("chatBody", formData);
    dispatch(createMessage(formData, orderId));

    form.resetFields();
  };

  const onFileChange = (event) => {
    setAllfile({ selectedFile: event.target.files[0] });
    console.log("selectedFile", event.target.files[0].name);
    // document.getElementById("filename").innerHTML = event.target.files[0].name;
  };

  useEffect(() => {
    setTimeout(function () {
      var elem = document.getElementById("explorchat");
      elem.scrollTop = elem.scrollHeight;
    }, 1500); //run this after 3 seconds
  }, []);

  const handleNewChat = (val) => setNewChat(newChat + val);

  return (
    <div className="chat-wrapper">
      <Authcheck />

      <p className="chat-heading">{t("newoffers.Conversation")}</p>

      <p className="chat-line-tags">{t("newoffers.Say hello and exchange")}</p>
      <p className="remember-chat-tag">{t("newoffers.Remember")}:</p>
      <ul>
        <li className="chat-group-tags">{t("newoffers.Always use escrow")}</li>
        <li className="chat-group-tags">{t("newoffers.Open a payment")}</li>
      </ul>
      <hr />

      <div className="chat-box" id="explorchat">
        {chats?.map((message) => {
          return (
            <>
              <ChatText
                id="ChatText"
                key={message.id}
                userId={userId}
                message={message}
                handleNewChat={handleNewChat}
              />
              
            </>
          );
        })}
      </div>

      <Form
        className="chat-message-actions"
        name="normal_message"
        onFinish={onFinish}
        form={form}
      >
        <Form.Item name="message">
          <input
            className="chat-input"
            placeholder={t("newoffers.Write a message")}
            autoFocus
          />
        </Form.Item>
        <Form.Item>
          <label
            style={{ marginTop: "5px" }}
            title={t("newoffers.Attache File")}
          >
            <File />
            <input
              style={{ display: "none" }}
              id="FileInput"
              name="file"
              type="file"
              onChange={onFileChange}
              title={t("newoffers.Attache File")}
            />
          </label>
        </Form.Item>
        <Form.Item className="chat__submit">
          <button className="chat-send-btn" htmlType="submit">
            {t("newoffers.Send")}
          </button>
          <button
            className="refresh-btn"
            onClick={() => {
              dispatch(fetchMyMessages(uniqueId));
            }}
          >
            <img src={RefreshICon} height="15px" width="15px" alt="refresh" />
          </button>
        </Form.Item>
      </Form>

      <ToastContainer autoClose={10000} style={{ marginTop: "60px" }} />
    </div>
  );
};

export default Chat;
