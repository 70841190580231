import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link, useLocation } from "react-router-dom";
import { Form, Input, Button } from "antd";
import Select from 'react-select';
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { fetchAccount } from "../../store/actions/myAccountActions";
import "./LoginGuard.scss";
import { setInputSelection } from "rc-mentions/lib/util";
import {useTranslation, withTranslation} from "react-i18next";
import Axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import { browserName, browserVersion } from "react-device-detect";

const baseURL = process.env.REACT_APP_BASE_URL;

const Account = (props) => {
  const dispatch = useDispatch();
  const logoutHandler = () => {
    dispatch(doLogout());
  };

  const [userInfo, setUserInfo] = useState({});
  const [data, setData] = useState({});
  const [ip, setIP] = useState('');
  const [country, setcountry] = useState('');
  const [t, i18n] = useTranslation('common');
  const formRef = React.useRef(null);
  const location = useLocation();
  
  useEffect( () => {

    if (sessionStorage.getItem("Successmsg") != '') {
      toast.success(sessionStorage.getItem("Successmsg"));
      sessionStorage.removeItem("Successmsg");
    }

    if (sessionStorage.getItem("Failederror") != '') {
      toast.success(sessionStorage.getItem("Failederror"));
      sessionStorage.removeItem("Failederror");
    }

    //creating function to load ip address from the API
     Axios.get('https://geolocation-db.com/json/').then((res)=>{
      // console.log(res.data);
      setIP(res.data.IPv4)
      setcountry(res.data.country_name)
      
     })
  });

  const submit = (e) => {
    const userId = localStorage.getItem("userId");
    const browser = `${browserName} ${browserVersion}`;
    const currentDateTime = Date().toLocaleString()
    const sendData = {
      user_id: userId,
      user_agent: browser,
      location: country,
      ipaddress: ip,
      created_date:currentDateTime
    };
    // console.log("sendData", sendData)
    Axios.post(`${baseURL}/api/auth/createauthorizedbrowsers`, sendData)
      .then(res => {
        if (res.data.status == 200) {
         //toast.success("Password Changed Successfully!");
          sessionStorage.setItem("Successmsg", "Authorized Browsers Data Stored Successfully!");
          window.location.reload();
        }else{
          sessionStorage.setItem("Failederror", res.data.errorMessage);
          window.location.reload();
        }
         //console.log("res.data.......", res.data);
          }).catch(error => {
          sessionStorage.setItem("Failederror", "Authorized Browsers Data Stored Failed");
        //window.location.reload();
        //window.location.reload();
      })
  }

  function handle(e) {
    // console.log('e.target.id:', e.target.id);
    let newdata = data;
    newdata[e.target.id] = e.target.value
    setData(newdata)
    // console.log("newdata......", newdata);
  }

  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="login-guard-container">
        <div className="row flex-column-reverse flex-lg-row reveal">
          <div class="col-md-12">
            <h4>{t("Loginguard.Login guard")}</h4>
            <br />
            <p>
              <a href="/settings/" style={{ color:"blue"}}><i class="fa fa-arrow-left" />{t("Twofactor.Back to Account security")} </a>
            </p>
           </div>

          <div class="col-md-12">
             <Form
                  name="loginguard"
                  id="loginguard-changed-form"
                  initialValues={{
                    remember: true,
                  }}
                  ref={formRef}
                  onFinish={(e) => {
                    submit(e);
                  }}
                // onFinish={onFinish}
                >
              <input type="hidden" name="csrfmiddlewaretoken" value="wVCyB7b34p2gFBfh0Px14QSnqgEjwZNMRXbwVssQIKe3q8VAoafeSEAPSej9qOWZ" />
              <fieldset> <legend style={{ fontSize: '20px' }}>{t("Loginguard.Security options to prevent unauthorized logins")}</legend>  </fieldset>
                   <Form.Item >
                    <Input type="checkbox" name="new_browser" />&nbsp; {t("Loginguard.Confirm logins from unknown web browsers")}
                  </Form.Item>
                  {/* <h5>{errorMsg}</h5> */}
                  <Form.Item>
                    <Button type="primary" htmlType="submit" id="continue">{t("Loginguard.Change settings")}</Button>
                  </Form.Item>

              </Form>
            <p>{t("Loginguard.Any change to these settings requires a confirmation by email")}</p>
            <p class="pb-1 my-0 ml-2">
              <a href="/authorized_browsers/"><small>{t("Loginguard.Authorized browsers")}</small></a>
            </p>
          </div>


        </div>
      </div>
      <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
    </section>
  );
};

export default Account;
