import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
//import { ThunderboltOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import {
  getTier0,
  getTier1,
  getTier2,
  kycData,
} from "../../../views/Verification/Helper_veri";
import "./OfferWoWCard.css";
import { useTranslation, withTranslation } from "react-i18next";
import axios from "axios";
import { Auth, authData } from "../../../auth/Auth";
const OfferWoWCard = (props) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const orderDetails = useSelector((state) => state.orders.order);

  const [tierzero, setTierzero] = useState("");
  const [getLiveprice, setLiveprice] = useState("");
  const [Userprofile, setUserprofile] = useState("");

  const [tierone, setTierone] = useState("");
  const [tiertwo, setTiertwo] = useState("");
  // console.log('props.offer:::::::::::',props.offer);

  const {
    id,
    username,
    payment_method,
    headline,
    country,
    city,
    maker_id,
    limit_min,
    limit_max,
    trade_count,
    currency_type,
    margin,
    margin_above,
    currency_symbol,
    buyBCH,
  } = props.offer;
  //console.log("props.offer.......",props.offer)
  const userId = localStorage.getItem("userId");
  const coinStatsAPI = "https://openapiv1.coinstats.app/coins/usd?currency=";
  // let fastBadge = false;
  // if (badges.includes("fast-response")) {
  //   fastBadge = true;
  // }

  const [islogin, setIslogin] = useState(true);

  const checkAuth = () => {
    authData.subscribe((res) => {
      if (res == null) {
        Auth.isAuthenticated();
      } else {
        setIslogin(res);
      }
    });
  };
  useEffect(() => {
    getData();
    LivePrice();
    userData();
    checkAuth();
  }, []);

  const LivePrice = async () => {
    try {
      const options = {
        method: "GET",
        headers: {
          accept: "application/json",
          "X-API-KEY": "6UEisKAzxdGYrzCYCGJTFIBPQMY8XGGtFDHkEPU4Fgc=",
        },
      };
      const result = await axios.get(`${coinStatsAPI}${currency_type}`, options);
      let price = result.data.price * 100;
      if(currency_type === 'USD'){
        const resp = await axios.get(`${baseURL}/api/wowprice/`)
        price = Number(resp.data.price);
      }
      let adjPrice =
        price +
        (margin_above ? price * (margin / 100) : -price * (margin / 100));
      setLiveprice(adjPrice.toFixed(2));
    } catch {
      // console.log("error in tier 0");
    }
  };

  const userData = async () => {
    const res = await axios.get(
      `${baseURL}/api/trades/get_userdetail_forall_user/${maker_id}`
    );
    setUserprofile(res.data);
  };

  const getData = async () => {
    try {
      kycData.subscribe((res) => {
        if (!res || !res.tier_z) {
          getTier0();
        } else {
          // console.log('kycData res:',res);
          setTierzero(res.tier_z);
        }
      });
    } catch {
      // console.log("error in tier 0");
    }

    try {
      kycData.subscribe((res) => {
        if (!res || !res.dataa) {
          getTier1();
        } else {
          // console.log("kycData tier1.....", res)
          setTierone(res.dataa);
        }
      });
    } catch {
      // console.log("error in tier 1");
    }

    try {
      kycData.subscribe((res) => {
        if (!res || !res.data) {
          getTier2();
        } else {
          // console.log("kycData tier2.....", res)
          setTiertwo(res.data);
        }
      });
    } catch {
      // console.log("error in tier 2");
    }
  };

  let tradeHistory = (Math.random() * 100 + 5).toFixed(0);
  let limitText = "no limit";
  if (limit_min && limit_max) {
    limitText = `${limit_min} to ${limit_max}`;
  } else if (limit_min && !limit_max) {
    limitText = `from ${limit_max}`;
  } else if (!limit_min && limit_max) {
    limitText = `up to ${limit_max}`;
  }
  // need to refactor badges to map over array and
  // paint the right icon/tooltip pair
  // it appears local.bitcoin has atm 3 badges only

  const [t, i18n] = useTranslation("common");
  return (
    <>
      {" "}
      <div className="buy-coin-sell-row">
        <div className="section-trade" style={{ width: "72px" }}>
          <p className="buy-username">{username}</p>
          <p className="buy-trades">
            {Userprofile.number_of_confirmed_trade} Trades
          </p>
        </div>
        <p style={{ minWidth: "44px" }} className="price-of-coin">
          {buyBCH == 0 ? (
            <>{t("newoffers.Seller")}</>
          ) : (
            <>{t("newoffers.Buyer")}</>
          )}
        </p>
        <p style={{ width: "120px" }} className="price-of-coin">
          {t("newoffers." + payment_method)}
        </p>
        <p style={{ width: "59px" }} className="price-of-coin">
          {city}
        </p>
        <p style={{ width: "64px" }} className="price-of-coin">
          {currency_type}
        </p>
        <p style={{ width: "104px" }} className="price-of-wow-coin">
          {currency_symbol} {getLiveprice}
        </p>
        <p style={{ width: "150px" }} className="price-of-coin">
          {limitText}
        </p>
        <div>
          {tierone.identy_verification == 1 ? (
            <>
              {maker_id == userId ? (
                <button
                  style={{
                    minWidth: "90px",
                    minHeight: "50px",
                    background: "none",
                  }}
                  type="default"
                  disabled
                  title={t("Explore.self trade")}
                >
                  <Link to={`/offer-details/${id}`}>
                    <span>
                      {buyBCH ? (
                        <p className="coin-buy">{t("Explore.Sell")}</p>
                      ) : (
                        <p className="coin-buy">{t("Explore.Buy")}</p>
                      )}
                    </span>
                  </Link>
                </button>
              ) : (
                <button
                  style={{
                    minWidth: "90px",
                    minHeight: "50px",
                    background: "none",
                  }}
                  type="default"
                >
                  <Link to={`/offer-details/${id}`}>
                    <span>
                      {buyBCH ? (
                        <p className="coin-buy">{t("Explore.Sell")}</p>
                      ) : (
                        <p className="coin-buy">{t("Explore.Buy")}</p>
                      )}
                    </span>
                  </Link>
                </button>
              )}
            </>
          ) : (
            <>
              {" "}
              {!islogin ? (
                <button
                  style={{
                    marginLeft: "30px",
                    minWidth: "90px",
                    minHeight: "50px",
                    background: "none",
                  }}
                  type="default"
                  disabled
                  title={t("Explore.self trade")}
                >
                  <Link to={`/login`}>
                    <span>
                      {buyBCH ? (
                        <p className="coin-buy">{t("Explore.Sell")}</p>
                      ) : (
                        <p className="coin-buy">{t("Explore.Buy")}</p>
                      )}
                    </span>
                  </Link>
                </button>
              ) : (
                <button
                  style={{
                    marginLeft: "30px",
                    minWidth: "90px",
                    minHeight: "50px",
                    background: "none",
                  }}
                  type="default"
                  disabled
                  title={t("Explore.self trade")}
                >
                  <Link to={`/verification`}>
                    <span>
                      {buyBCH ? (
                        <p className="coin-buy">{t("Explore.Sell")}</p>
                      ) : (
                        <p className="coin-buy">{t("Explore.Buy")}</p>
                      )}
                    </span>
                  </Link>
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <div className="buy-coin-sell-row-mobile-2">
        <div className="buy-coin-mobile-screen">
          {" "}
          <div className="buy-coin-line">
            <div className="buy-coin-wrappers">
              <p className="buy-coins-titles">{t("offerTrade.Username")}</p>
              <div
                className="section-trade"
                style={{
                  marginLeft: "0px",
                  width: "52px",
                  minWidth: "52px",
                  maxWidth: "52px",
                }}
              >
                <p className="buy-username">{username}</p>
                <p className="buy-trades">
                  {" "}
                  {Userprofile.number_of_confirmed_trade} Trades
                </p>
              </div>
            </div>

            <div className="buy-coin-wrappers">
              <p className="buy-coins-titles">{t("offerTrade.Type")}</p>
              <p
                style={{
                  marginLeft: "0px",
                  width: "46px",
                  minWidth: "46px",
                  maxWidth: "46px",
                }}
                className="price-of-coin"
              >
                {buyBCH == 0 ? (
                  <>{t("newoffers.Seller")}</>
                ) : (
                  <>{t("newoffers.Buyer")}</>
                )}
              </p>
            </div>
            <div className="buy-coin-wrappers">
              <p className="buy-coins-titles">
                {t("offerTrade.Payment Method")}
              </p>
              <p
                style={{
                  marginLeft: "0px",
                  width: "80px",
                  minWidth: "80px",
                  maxWidth: "60px",
                }}
                className="price-of-coin"
              >
                {t("newoffers." + payment_method)}
              </p>
            </div>
          </div>
          <div className="buy-coin-line">
            <div className="buy-coin-wrappers">
              <p className="buy-coins-titles">{t("offerTrade.Currency")}</p>
              <p
                style={{
                  marginLeft: "0px",
                  width: "46px",
                  minWidth: "46px",
                  maxWidth: "46px",
                }}
                className="price-of-coin"
              >
                {currency_type}
              </p>
            </div>
            <div
              className="buy-coin-wrappers"
              style={{
                marginLeft: "10px",
              }}
            >
              <p className="buy-coins-titles">{t("offerTrade.Location")}</p>
              <p
                style={{
                  width: "46px",
                  maxWidth: "60px",
                }}
                className="price-of-coin"
              >
                {city}
              </p>
            </div>
            <div className="buy-coin-wrappers">
              <p className="buy-coins-titles">
                {t("offerTrade.Price/Wowcoin")}
              </p>

              <p
                style={{ marginLeft: "0px", width: "104px" }}
                className="price-of-wow-coin "
              >
                {currency_symbol} {getLiveprice}
              </p>
            </div>
          </div>
          <div className="buy-coin-line">
            <div className="buy-coin-wrappers">
              <p className="buy-coins-titles">{t("offerTrade.Limit")}</p>
              <p
                style={{
                  marginLeft: "0px",
                  width: "123px",
                  maxWidth: "123px",
                }}
                className="price-of-coin"
              >
                {limitText}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="buy-coin-btn buy-coin-sell-row-mobile-2">
        {tierone.identy_verification == 1 ? (
          <>
            {maker_id == userId ? (
              <button
                style={{ background: "none" }}
                type="default"
                disabled
                title={t("Explore.self trade")}
              >
                <Link to={`/offer-details/${id}`}>
                  <span>
                    {buyBCH ? (
                      <p
                        style={{ minWidth: "90px", minHeight: "50px" }}
                        className="coin-buy"
                      >
                        {t("Explore.Sell")}
                      </p>
                    ) : (
                      <p
                        style={{ minWidth: "90px", minHeight: "50px" }}
                        className="coin-buy"
                      >
                        {t("Explore.Buy")}
                      </p>
                    )}
                  </span>
                </Link>
              </button>
            ) : (
              <button style={{ background: "none" }} type="default">
                <Link to={`/offer-details/${id}`}>
                  <span style={{ minWidth: "90px", minHeight: "50px" }}>
                    {buyBCH ? (
                      <p className="coin-buy">{t("Explore.Sell")}</p>
                    ) : (
                      <p className="coin-buy">{t("Explore.Buy")}</p>
                    )}
                  </span>
                </Link>
              </button>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default OfferWoWCard;
