import React, { useEffect, Component, useState } from 'react';
import Authcheck from "../../components/Authcheck/Authcheck";
import Axios from 'axios';
import "./Kycimage.scss";
import { ToastContainer, toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
const baseURL = process.env.REACT_APP_BASE_URL;



const Kycid = () => {
    const [t, i18n] = useTranslation('common');
    const [allFile, setAllfile] = useState({});
    const history = useHistory();
    const token = localStorage.getItem("token");
    // console.log("allFile", allFile);

    useEffect(() => {
        if (sessionStorage.getItem("kyc_error")) {
          toast.error(sessionStorage.getItem("kyc_error"));
          sessionStorage.removeItem("kyc_error");
        }
    
      }, [])

    const onFormSubmit = (e) => {
        const button = document.getElementById('continue');
        button.disabled = true
        e.preventDefault();
        // console.log('Object.keys(allFile).length:',Object.keys(allFile).length);
        if (Object.keys(allFile).length == 0) {
            toast.error(" Select atlist one document");
        } else {

            const userId = localStorage.getItem("userId");

            var formData = new FormData();
            formData.append('passport', allFile.filepassport);
            formData.append('license', allFile.filelicense);
            formData.append('residencepermit', allFile.file);
            formData.append('identitycard', allFile.fileid);
            formData.append('userid', userId);


             console.log("fileData......",formData);
            Axios.post(`${baseURL}/api/verification/uploadofficialphotoid`, formData, {
                headers: {
                    // "Accept": "*/*",
                    "Authorization" : `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                }
            }).then((response) => {
                     console.log("Filedata", response);
                     if(response.data.errmessage){
                        sessionStorage.setItem("kyc_error", response.data.errmessage);
                        //toast.error(`${response.data.errmessage}`);
                        window.location.reload();
                      }else{
                        sessionStorage.setItem("kyc_docs", t('Identity.Document submitted successfully'));
                        history.push("/verification");
                      }
                    // toast.success("Document Updated successfully");
                 }).catch((error) => {
                    // console.log("errorsdsds", error);
                    //toast.error("Document Update Failed");
                    sessionStorage.setItem("kyc_error","Document Update Failed");
                        //toast.error(`${response.data.errmessage}`);
                     window.location.reload();
                })
        }


    }

    const onChange = (e, name) => {
        setAllfile({ ...allFile, [name]: e.target.files[0] });
        // console.log("{ [name]: e.target.files[0] }", { [name]: e.target.files[0] });
    }
    return (
        <>

            <section className=" hero--full hero--v10 d-flex align-items-center hidden z-index2 " style={{ marginTop: "100px" }} >
                <div className="container sidebox">
            <Authcheck />
                    <div className="row flex-column-reverse flex-lg-row reveal">
                        <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 ">
                            <div className="col-12 col-md-10 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 " style={{ marginTop: '25px' }}>
                                <div className="ico-counter">
                                    <div className="counter-down">
                                        <div className="content">
                                            <div className="conuter-header">
                                                <h4 align="left">{t('Identity.Wowcoins Identity Verification')}</h4>
                                                <span className="onfido-sdk-ui-PageTitle-titleSpan" tabIndex={-1} style={{ fontSize: '20px', lineHeight: '1.34', fontWeight: '900' }}>{t('Identity.Verify your identity')}</span>
                                                <div className="onfido-sdk-ui-PageTitle-subTitle" role="heading" aria-level={2} style={{ fontSize: '16px', fontWeight: '900' }}>{t('Identity.It should take')}</div>
                                                <p style={{ color: "red" }}>*{t('Identity.Atlist one of the document among four is necessary')}</p>
                                            </div>
                                            <div className="signup-container">
                                                <form onSubmit={onFormSubmit}>


                                                    <label className="formkyc kycbot">{t('Identity.Residence')}</label><br />
                                                    <input type="file" onChange={($event) => onChange($event, 'file')} className="kycbot" /><br />
                                                    <label className="kycbot formkyc">{t('Identity.Passport')} </label><br />
                                                    <input type="file" onChange={($event) => onChange($event, 'filepassport')} className="kycbot" /><br />

                                                    <label className="kycbot formkyc">{t('Identity.License')} </label><br />
                                                    <input type="file" className="kycbot" onChange={($event) => onChange($event, 'filelicense')} /><br />
                                                    <label className="kycbot formkyc">{t('Identity.Identity Card')} </label><br />
                                                    <input type="file" className="kycbot" onChange={($event) => onChange($event, 'fileid')} /><br />
                                                    <button type="submit" className="buttona" id="continue">{t('Identity.Upload')}</button>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>
                </div>
                <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
            </section>

        </>
    )
}

export default Kycid
