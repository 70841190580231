import React, { createContext, useState } from 'react';

export const TimeContext = createContext({});

export const TimeProvider = ({ children }) => {
    const [time, setTime] = useState(0);

    const timeData = { 
        time : time,
        setTime : setTime,
    };

    return (
        <TimeContext.Provider
            value={timeData}
        >
             {children}
        </TimeContext.Provider>
    )
}