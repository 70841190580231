import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Route, Link, NavLink } from "react-router-dom";
import { Form, Input, Button } from "antd";
import { updateAction } from "../../helpers/updateActions";
import { ToastContainer, toast } from "react-toastify";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { doLogout } from "../../store/actions/authActions";
import { fetchAccount } from "../../store/actions/myAccountActions";
import { gettwoFactorenable } from "../../views/Verification/Helper_veri";
import "./Setting.css";
import SectionSix from "../../components/Home/sections/sectionsix";

import Axios from "axios";
import { Breadcrumb } from "antd";
import { useTranslation, withTranslation } from "react-i18next";
export const LOGOUT = "LOGOUT";

const baseURL = process.env.REACT_APP_BASE_URL;

const Account = (props) => {
    const dispatch = useDispatch();
    const [userInfo, setUserInfo] = useState({});
    const [enable, setEnable] = useState(null);
    const [twoFactor, setTwofactor] = useState();
    const logoutHandler = () => {
        dispatch(doLogout());
    };
    // const url = "${baseURL}/api/auth/tfasetup";
    const Countries = [
        { label: "Albania (+355)", value: 355 },
        { label: "Argentina (+54)", value: 54 },
        { label: "Austria (+43)", value: 43 },
        { label: "Cocos Islands (+61)", value: 61 },
        { label: "Kuwait (+965)", value: 965 },
        { label: "Sweden (+46)", value: 46 },
        { label: "Venezuela (+58)", value: 58 },
    ];
    const UserId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("username");
    const senduserDetail = {
        username: user,
        id: UserId,
    };
    const userData = () => {
        tfagetup();
    };

    const DeleteuserDetail = {
        delete_status: 1,
        id: UserId,
    };

    useEffect(() => {
        (async () => {
            try {
                const enableTwofactor = await gettwoFactorenable();
                // console.log("twofactor........................", enableTwofactor)
                setTwofactor(enableTwofactor.data.on);
            } catch {
                // // console.log("error in tier 0");
            }
        })();
    }, []);

    const handleDeleteAccount = () => {
        Axios.post(`${baseURL}/api/auth/deleteuser`, DeleteuserDetail, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => {
                sessionStorage.setItem(
                    "DeleteAccount",
                    t("Profile.Account Deleted Successfully")
                );
                localStorage.clear();
                dispatch(updateAction(LOGOUT));
                window.location.reload();
            })
            .catch((error) => {
                // console.log("error:", error);
            });
    };

    const tfagetup = () => {
        Axios.get(`${baseURL}/api/auth/tfagetup/${UserId}`).then(
            async (response) => {
                // console.log('QRresponse:', response.data);
                if (response.data && response.data.dataURL) {
                    // console.log("this is not null ");
                    props.history.push("/two_factor/");
                } else {
                    // console.log("this is null ");
                    Axios.post(`${baseURL}/api/auth/tfasetup`, senduserDetail)
                        .then((res) => {
                            props.history.push("/two_factor/");
                        })
                        .catch((error) => {
                            // console.log("error:", error);
                        });
                }
            },
            (error) => {
                // console.log(error);
            }
        );
    };

    // console.log("senduserDetail", senduserDetail);

    useEffect(() => { });
    const [t, i18n] = useTranslation("common");
    return (
        <>
            {" "}
            <div className="settings-page">
                <div className="settings-upper"></div>
                <Authcheck />

                <div className="settings-container">
                    <div className="boxSections-sub-wrap">
                        <div className="non-active-section">
                            <NavLink
                                style={{ color: "black" }}
                                to="/my-account"
                                activeClassName="active-section"
                            >
                                {t("Profile.Profile")}
                            </NavLink>
                        </div>
                        <div className="non-active-section">
                            <NavLink to="/settings" activeClassName="active-section">
                                {t("Profile.Security")}
                            </NavLink>
                        </div>
                        <div className="non-active-section">
                            <NavLink
                                style={{ color: "black" }}
                                to="/verification"
                                activeClassName="active-section"
                            >
                                {t("Profile.Verification")}
                            </NavLink>
                        </div>
                    </div>

                    <div class="col-12 col-lg-12 pr-lg-0">
                        <p className="profile-heading"> Security </p>
                        <div className="security-tagline">
                            <Breadcrumb separator=">">
                                <Breadcrumb.Item>
                                    <p className="security-inner-tagline">
                                        {t(
                                            "Security.Weak account security increases the risk of theft"
                                        )}
                                        .{" "}
                                    </p>
                                </Breadcrumb.Item>
                            </Breadcrumb>
                        </div>
                    </div>
                    <br />

                    <div class="row">
                        <div class="col-12 ">
                            <div className="settings-flex">
                                <div>
                                    <p class="settings-heading">{t("Security.Password")}</p>
                                    <p class="settings-tags">
                                        {t("Security.Protect your account with a strong password")}.
                                    </p>
                                </div>
                                <div>
                                    <Link to="/password_change/" activeClassName="is-active">
                                        <button className="right-side-btn">
                                            {t("Security.Change")}
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="settings-flex">
                                <div>
                                    <p class="settings-heading">
                                        {t("Security.Two-Factor Authentication")}
                                    </p>
                                    <p class="settings-tags">
                                        {t(
                                            "Security.Protect your WOW with an extra layer of security"
                                        )}
                                    </p>
                                </div>
                                <div>
                                    {twoFactor == 1 ? (
                                        <button onClick={userData} className="right-side-btn">
                                            {t("Security.Disable")}
                                        </button>
                                    ) : (
                                        <button onClick={userData} className="right-side-btn">
                                            {t("Security.Enable")}
                                        </button>
                                    )}
                                </div>
                            </div>
                            <div className="settings-flex">
                                <div>
                                    <p class="settings-heading">{t("Security.Login History")}</p>
                                    <p class="settings-tags">
                                        {t("Security.A list of previous logins with IP addresses")}.
                                    </p>
                                </div>
                                <div>
                                    <Link to="/login_history/" activeClassName="is-active">
                                        <button className="right-side-btn">
                                            {t("Security.View")}
                                        </button>
                                    </Link>
                                </div>
                            </div>
                            <div className="settings-flex">
                                <div>
                                    <p class="settings-heading">
                                        {t("Security.Account Deletion")}
                                    </p>
                                    <p class="settings-tags">
                                        {t("Security.Take the steps to delete your account")}.
                                    </p>
                                </div>

                                <div>
                                    <Link to="/accountdelete" activeClassName="is-active">
                                        <button className="right-side-btn">
                                            {t("Security.Delete")}
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ToastContainer
                    style={{ marginTop: "60px" }}
                    position="top-right"
                    autoClose={10000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </div>
            <SectionSix />
        </>
    );
};

export default Account;