import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Form, Input, Button } from "antd";
import { ToastContainer, toast } from 'react-toastify';
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Axios from 'axios';
import { useTranslation, withTranslation } from "react-i18next";

const baseURL = process.env.REACT_APP_BASE_URL;
export const LOGIN = "LOGIN";
export const LOADING_USER = "LOADING_USER";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT = "LOGOUT";
export const GET_USER = "GET_USER";
export const REGISTER_ERROR = "REGISTER_ERROR";
export const USER_ERROR = "USER_ERROR";

// const url = "${baseURL}/api/auth/forgotpassword";
const Forgetpass = () => {

  const [data, setData] = useState({});
  let history = useHistory();
  const submit = (e) => {

    // const button = document.getElementById('reset');
    // button.disabled = true
    // console.log("data", data)
    const sendData = {
      email: data.normal_login_email,
    };
    // console.log("sendData", sendData);
    Axios.post(`${baseURL}/api/auth/forgotpassword`, sendData)
      .then(res => {
       // console.log("reset password", res);
        localStorage.setItem("email", sendData.email);
        history.push('/Resetpassword')
        // window.location.reload();
      }).catch(error => {
        // console.log("error", error);
        toast.error(t("ForgetPassword.Please enter your valid email id"));
        // window.location.reload();
      })
  }



  function handle(e) {
    // console.log('e.target.id:', e.target.id);
    let newdata = data;
    newdata[e.target.id] = e.target.value
    setData({ ...data, [e.target.id]: e.target.value })
    // console.log("newdata......", newdata);
  }

  const [t, i18n] = useTranslation('common');

  return (
    <div>
      <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
         <div className="container-fluid">
      <Authcheck />
          <div className="row flex-column-reverse flex-lg-row reveal">
            <div className="col-11 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">
              <div className="col-lg-6">
                <div className="login-container">
                  <div className="panel left">
                    <h3>{t('ForgetPassword.Forget Password')}</h3>
                    <p>{t('ForgetPassword.Provide your email address to get password')}</p>
                    <Form
                      name="normal_login"
                      className="login-form"
                      initialValues={{
                        remember: true,
                      }}
                      onFinish={(e) => {
                        submit(e);
                      }}
                    >
                      <Form.Item
                        label={t('ForgetPassword.Email')}
                        name="email"
                        onChange={(e) => handle(e)}
                        rules={[
                          {
                            required: true,
                            message: t('ForgetPassword.Please input your email'),
                          },
                        ]}
                      >
                        <Input placeholder={t('ForgetPassword.Email')} />
                      </Form.Item>

                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="login-form-button"
                          id="reset"
                        >
                          {t('ForgetPassword.Reset Password')}
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>

                </div>
              </div>
              <div className="col-lg-6">

              </div>
            </div>
          </div>
        </div>
        <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
      </section>
    </div>
  )

}

export default Forgetpass;