import React, { useEffect, useState } from "react";
import Authcheck from "../../components/Authcheck/Authcheck";
import { Form, Input, Button, Select } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
// import Axios from 'axios';
import { Link } from "react-router-dom";
import "./CoinPurchase.scss";



const CoinPurchase = (props) => {

  return (
    <section className="hero hero--full hero--v10 d-flex align-items-center hidden" style={{ paddingTop: '95px' }}>
      <div className="container">
                <Authcheck />
        <div className="row flex-column-reverse flex-lg-row reveal">

          <div className="col-12 col-md-12 col-lg-12 mx-auto mx-lg-0 text-center text-lg-left z-index2 sidebox">

            <div className="my-offers-main">
              <h4 style={{ paddingBottom: "30px" }}>Buy Wowcoins using IMPS Bank Transfer India with Indian Rupee (INR)</h4>
              <p><b>Payment method:</b> <span> Bank Transfer</span></p>
              <p><b>User: </b>Trupti<span></span></p>
              <p><b>Location:</b> {props?.city}<span></span></p>
              <p><b>Payment window:</b> 1hr, 30min<span></span></p>
              <p className="orang" style={{ paddingTop: "30px" }}>You need to update your verification status to continue using LocalBitcoins.<Link to={`/verification`}>

                <span style={{ color: "#2585ce" }}> Proceed to verify</span>
              </Link></p>
              {/* <Link to="/new-offer">
        <Button>CREATE A NEW OFFER</Button>
      </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoinPurchase;
