import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import testimonialPic1 from "../../../assets/icons/testimonial-pic-1.svg";
import { ReactComponent as StarGreen } from "./img/Star-2.svg";
import NextArrow from "./img/next-arrow.svg";
import { useTranslation, withTranslation } from "react-i18next";
import InfiniteCarousel from "react-leaf-carousel";
import "./sections.css";
import Axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;
const SectionFour = ({ title, content }) => {

  const [testimonials, setTestimonials] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getTestimonials()
  }, [])

  const getTestimonials = async () => {
    try {
      fetch(`${baseURL}/api/testimonials/`).then(res => res.json()).then(resJson => {
        console.log(resJson)
        setTestimonials(resJson)
      })
      setIsLoading(false);
    } catch (err) {
      console.log(err)
      setIsLoading(false);
    }
  }

  const [t, i18n] = useTranslation("common");

  if (testimonials.length > 0) {
    return (
      <div className="section4">
        <p className="section4-heading">{t("Home.Testimonials")}</p>
        <div className="slider">
          {" "}
          <InfiniteCarousel
            breakpoints={[
              {
                breakpoint: 780,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                },
              },
              {
                breakpoint: 1500,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 2,
                },
              },
            ]}
            dots={true}
            showSides={true}
            sidesOpacity={0.5}
            sideSize={0.1}
            slidesToScroll={3}
            slidesToShow={3}
            scrollOnDevice={true}
          >
            {
              testimonials.map(item => {
                return (
                  <div className="testimonial">
                    {console.log('ddddd', item.name)}
                    <img className="testimonial-pic" alt="img" src={item.image} />
                    <div className="sub-testimonial">
                      <p className="section4-name">{item.name}</p>
                      <p className="section4-title">{item.designation}</p>
                      <p className="section4-description">
                        {item.description}
                      </p>
                      <div className="star-wrap">
                        {Array(Number(item.rating))
                          .fill(Number(item.rating))
                          .map((_) => (
                            <StarGreen />
                          ))}
                      </div>
                    </div>
                  </div>
                )
              })
            }

          </InfiniteCarousel>
        </div>
      </div>
    );
  } else {
    return null
  }

};

export default SectionFour;
