import React, { useState, useEffect } from "react";
import Authcheck from "../../../components/Authcheck/Authcheck";
import axios from "axios";
import ReactPaginate from "react-paginate";
// import "./.scss";
import Kyccheck from "../../../components/Kyccheck/Kyccheck";
import { useTranslation, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./Notiall.css";
import SectionSix from "../../../components/Home/sections/sectionsix";
import { ToastContainer, toast } from "react-toastify";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem("userId");
const token = localStorage.getItem("token");

function Notiall() {
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [t, i18n] = useTranslation("common");
  const [data, setData] = useState([]);
  const getPostData = (data) => {
    return (
      <>
        {" "}
        <Authcheck />
        <Kyccheck />
        <div className="notification-table">
          <p class="profile-heading">{t("noti.Notification")}</p>
          <div className="notification-table">
            <div className="notification-table-header">
              <div className="notification-table-header-Id">{t("noti.Id")}</div>
              <div className="notification-table-header-message">
                {" "}
                {t("noti.Message")}
              </div>
              <div className="notification-table-header-status">
                {t("noti.Status")}
              </div>
            </div>

            {data ? (
              <div className="notification-table-body">
                {data.map((item) => {
                  if (item?.type == "Complate_order") {
                    const Complateordermsg = item?.msg;
                    var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
                    if (splitted.length == 4) {
                      var msg = "Paid successfully by";
                      var name = splitted[3];
                    }
                  }

                  if (item?.type == "Dispute") {
                    const Complateordermsg = item?.msg;
                    var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
                    var msg =
                      "A dispute request has been sent against you from";
                    var name = splitted[10];
                  }

                  if (item?.type == "Conversation") {
                    const Complateordermsg = item?.msg;
                    var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
                    var msg = "Trade# new message from";
                    var id = splitted[1];
                    var name = splitted[5];
                  }

                  if (item?.type == "Dispute_Conversation") {
                    const Complateordermsg = item?.msg;
                    var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
                    var msg = "Trade# new message from Dispute";
                    var id = splitted[1];
                    var name = splitted[6];
                  }

                  if (item?.type == "Dispute Solve Admin") {
                    const Complateordermsg = item?.msg;
                    var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
                    var msg = "Admin resolved the dispute in favor of";
                    if (splitted[7]) {
                      var name = splitted[7];
                    } else {
                      var name = splitted[8];
                    }
                  }

                  if (item?.type == "Approve") {
                    const Complateordermsg = item?.msg;
                    var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
                    var msg = "Trade# released escrow from";
                    var id = splitted[1];
                    var name = splitted[5];
                  }

                  if (item?.type == "MaticTransaction") {
                    const Complateordermsg = item?.msg;
                    var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
                    var msg = "Matic received from";
                    var name = splitted[3];
                  }

                  if (item?.type == "Trade") {
                    const Complateordermsg = item?.msg;
                    var splitted = Complateordermsg.split(" ");
                    let result1 = Complateordermsg.substring(6, 0);
                    if (splitted[8] == "Sell") {
                      var result = Complateordermsg.substring(59, 11);
                    } else {
                      var result = Complateordermsg.substring(58, 11);
                    }
                    //this will output ["1234", "56789"]
                    var msg = result1 + " " + result;
                    var id = splitted[1];
                    var name = splitted[12];
                  }

                  if (item?.type == "Cancel") {
                    const Complateordermsg = item?.msg;
                    var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
                    var msg =
                      splitted[0] + " " + splitted[2] + " " + splitted[3];
                    var id = splitted[1];
                    if (splitted[4]) {
                      var name = splitted[4];
                    } else {
                      var name = splitted[5];
                    }
                  }

                  if (item?.type == "Transaction") {
                    const Complateordermsg = item?.msg;
                    var splitted = Complateordermsg.split(" "); //this will output ["1234", "56789"]
                    var msg = "Wowcoin received from";
                    var name = splitted[3];
                  }

                  if (item?.type == "Trade Cancelled In 90 Minutes") {
                    const Complateordermsg = item?.msg;
                    var msg = Complateordermsg.substring(62, 0);
                  }

                  if (item?.type == "Kyc_approve_byadmin") {
                    const Complateordermsg = item?.msg;
                    if (Complateordermsg.length) {
                      var msg = item?.msg;
                    } else {
                      var msg = Complateordermsg.substring(38, 0);
                    }
                  }

                  return (
                    <div className="notification-table-row" key={item.id}>
                      <p
                        key={item.id}
                        data-toggle="tooltip"
                        data-placement="top"
                        title={item?.hash}
                        className="notification-Id "
                      >
                        {item?.id}
                      </p>
                      {item?.type == "Complate_order" ? (
                        <div className="notification-message-cover">
                          {" "}
                          <p className="notification-message" key={item.id}>
                            {t("notification." + msg, { name: name })}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}

                      {item?.type == "Conversation" ? (
                        <div className="notification-message-cover">
                          {" "}
                          <p className="notification-message" key={item.id}>
                            {t("notification." + msg, {
                              name: name,
                              id: id,
                            })}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}

                      {item?.type == "Dispute_Conversation" ? (
                        <div className="notification-message-cover">
                          {" "}
                          <p className="notification-message" key={item.id}>
                            {t("notification." + msg, {
                              name: name,
                              id: id,
                            })}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}

                      {item?.type == "Dispute Solve Admin" ? (
                        <div className="notification-message-cover">
                          {" "}
                          <p className="notification-message" key={item.id}>
                            {t("notification." + msg, { name: name })}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}

                      {item?.type == "Approve" ? (
                        <div className="notification-message-cover">
                          {" "}
                          <p className="notification-message" key={item.id}>
                            {t("notification." + msg, {
                              name: name,
                              id: id,
                            })}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}

                      {item?.type == "Trade" ? (
                        <div className="notification-message-cover">
                          {" "}
                          <p className="notification-message" key={item.id}>
                            {t("notification." + msg, {
                              name: name,
                              id: id,
                            })}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item?.type == "Cancel" ? (
                        <div className="notification-message-cover">
                          {" "}
                          <p className="notification-message" key={item.id}>
                            {t("notification." + msg, {
                              name: name,
                              id: id,
                            })}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}

                      {item?.type == "Dispute" ? (
                        <div className="notification-message-cover">
                          {" "}
                          <p className="notification-message" key={item.id}>
                            {t("notification." + msg, { name: name })}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item?.type == "Trade Cancelled In 90 Minutes" ? (
                        <div className="notification-message-cover">
                          {" "}
                          <p className="notification-message" key={item.id}>
                            {t("notification." + msg)}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item?.type == "Kyc_approve_byadmin" ? (
                        <div className="notification-message-cover">
                          {" "}
                          <p className="notification-message" key={item.id}>
                            {t("notification." + msg)}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item?.type == "MaticTransaction" ? (
                        <div className="notification-message-cover">
                          {" "}
                          <p className="notification-message" key={item.id}>
                            {t("notification." + msg, { name: name })}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item?.type == "Transaction" ? (
                        <div className="notification-message-cover">
                          <p className="notification-message" key={item.id}>
                            {t("notification." + msg, { name: name })}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                      {item?.type == "Kyc_Declined_byadmin" ||
                      item?.type == "withdrawal" ? (
                        <div className="notification-message-cover">
                          <p className="notification-message" key={item.id}>
                            {t("notification." + item.msg)}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}

                      {item?.status == 1 ? (
                        <div className="notification-status-viewed">
                          {" "}
                          <Link
                            to={`/noti_detail/${item?.main_type}/${item?.type}/${item?.id}/${item?.offer_id}`}
                          >
                            <p
                              key={item.id}
                              className="notification-status-viewed"
                            >
                              {t("notification.Viewed")}
                            </p>
                          </Link>
                        </div>
                      ) : (
                        <div className="notification-view">
                          <Link
                            to={`/noti_detail/${item?.main_type}/${item?.type}/${item?.id}/${item?.offer_id}`}
                          >
                            <p className="notification-view">
                              {t("Trades.View")}
                            </p>
                          </Link>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="notification-table-body">
                {t("Transactions.No data Found")}
              </div>
            )}
          </div>
          <div className="breaker"></div>
        </div>
      </>
    );
  };

  const getAllPosts = async () => {
    const UserId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    if (UserId && token) {
      try {
        const res = await axios.get(
          `${baseURL}/api/notifications/getall_commannotification/${UserId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = res.data.data;
        setData(res.data.data);
        // console.log("allnoti", res);
        const slice = data.slice(offset - 1, offset - 1 + postsPerPage);

        // For displaying Data
        const postData = getPostData(slice);

        // Using Hooks to set value
        setAllPosts(postData);
        setPageCount(Math.ceil(data.length / postsPerPage));
      } catch (err) {
        toast.error(err.response.data.msg)
      }
    }
  };

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    setOffset(selectedPage + 1);

    let start = selectedPage * postsPerPage;
    let end = start + postsPerPage;
    const slice = data.slice(start, end);
    // console.log('slice:',slice);
    const postData = getPostData(slice);
    setAllPosts(postData);
    setPageCount(Math.ceil(data.length / postsPerPage));
  };

  useEffect(() => {
    getAllPosts();
  }, []);

  return (
    <>
      <div className="notiall-page">
        <div className="notiall-upper"></div>
        <div className="notiall-container">
          {posts}
          <div className="pagination-row">
            <ReactPaginate
              previousLabel={t("Withdraw.Previous")}
              nextLabel={t("Withdraw.Next")}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={pageCount}
              onPageChange={handlePageClick}
              containerClassName={"pagination-container"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </div>
      </div>
      <ToastContainer
      style={{marginTop: "60px"}}
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <SectionSix />
    </>
  );
}

export default Notiall;
