import React, { useState, useEffect } from 'react'
import Authcheck from "../../components/Authcheck/Authcheck";
import axios from 'axios'
import ReactPaginate from 'react-paginate';
import "./Escrowview.scss";
import { ToastContainer, toast } from 'react-toastify';
import { useTranslation, withTranslation } from "react-i18next";
import { getWowBalance } from "../../helpers/CallAPi";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

const baseURL = process.env.REACT_APP_BASE_URL;
const UserId = localStorage.getItem('userId');
    const  token = localStorage.getItem("token");

function Escrowview() {
  const [postsPerPage] = useState(10);
  const [offset, setOffset] = useState(1);
  const [posts, setAllPosts] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  let history = useHistory();

  // const [notiDetail, setNotidetail] = useState({});
  // const [notiDetail_, setNotidetail_] = useState('');
  const [t, i18n] = useTranslation('common');
  const [data, setData] = useState([]);
  console.log("chtenadata", data);
 
  const tradeDetail=(offer_id, usermaker, maker_id, uniqueid)=>{
    // history.push(`/${usermaker}/${maker_id}/noti_/offer-details/${offer_id}/${uniqueid}`);
    history.push(`/noti_/Trade/actives/${offer_id}/${usermaker}/${maker_id}/${uniqueid}`);
  }
  const getPostData = (data) => {


    return (

      <section className=" hero--full hero--v10 d-flex align-items-center hidden" style={{ minHeight: '300px', paddingBottom: '0px', paddingTop: "100px" }}>
         <div class="container">
        <Authcheck />
          <div className="conuter-header">
            <h4><b>{t('Escrow.Escrow Trades:')}</b></h4><br />
          </div>
          <div className="counterdown-content">
            {/* Countdown  */}
            <div class="row">
              <div class="col-lg-12">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className="padabc">{t("Transactions.No")}</th>
                      <th scope="col" className="padabc">Date</th>
                      <th scope="col" className="padabc">Trading Partner</th>
                      <th scope="col" className="padabc">{t('newoffers.Trade Id')}</th>
                      <th scope="col" className="padabc">{t('newoffers.Escrow Amount')}</th>
                      <th scope="col" className="padabc">{t('newoffers.Action')}</th>
                    </tr>
                  </thead>
                  {data ?
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={item.id}>
                          <td>{(index + 1) + (offset - 1) * postsPerPage}</td>
                          <td key={item.id} className="padabc">{item?.updated_at}</td>
                          <td key={item.id} className="padabc">{item?.usermaker}</td>
                          <th scope="row" key={item.id} className="padabc">
                            <button onClick={()=> tradeDetail(item?.offer_id,item?.usermaker,item?.maker_id,item?.uniqueid)}>{item?.id}</button>
                          </th>
                          <td key={item.id} className="padabc">{item?.amount}</td>
                          <td key={item.id} className="padabc "><button onClick={() => escrowApprove(item?.id, item?.userid, item?.to_address, item?.amount)} id="approve" style={{ background: "#00cc90", color: "#fff" }} className="ant-btn">{t('newoffers.APPROVE')}</button>


                          </td>

                        </tr>
                      ))}
                    </tbody>
                    :
                    <tbody>
                      <tr >
                        <h6 style={{ color: "grey" }}>{t('Trades.No trade in Escrow')}</h6>
                      </tr>
                    </tbody>}
                </table>
              </div>
            </div>
          </div>

        </div>
        <ToastContainer autoClose={10000} style={{marginTop: "60px"}}/>
      </section>

    )

  }

  const getAllPosts = async () => {
    const res = await axios.get(`${baseURL}/api/wallet/p1_list_escrocoin/${UserId}`);
    // console.log("transaction data", res);
    if (res.data.data) {
      const data = res.data.data;
      setData(res.data.data);
      const slice = data.slice(offset - 1, offset - 1 + postsPerPage)

      // For displaying Data

      // Using Hooks to set value
      setAllPosts(slice)
      setPageCount(Math.ceil(data.length / postsPerPage))
    } else {
      setAllPosts(null)
    }
  }

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    // console.log('selectedPage:',selectedPage);
    setOffset(selectedPage + 1);

    let start = selectedPage * postsPerPage;
    let end = start + postsPerPage;
    const slice = data.slice(start, end);
    // console.log('slice:',slice);
    const postData = getPostData(slice);
    setAllPosts(postData)
    setPageCount(Math.ceil(data.length / postsPerPage))
  };

  const escrowApprove = (id, userid, to_address, amount) => {
    // console.log('escrowApprove id:',id)
    const button = document.getElementById('approve');
    button.disabled = true
    try {
      const data = {
        userid: userid,
        to_address: to_address,
        amount: amount,
      }
      // console.log("sdata", data);
      axios.post(`${baseURL}/api/wallet/p1_permission_p2/${id}`, data, {
        headers: {
           "Authorization" : `Bearer ${token}`
        }
         })
        .then((response) => {
          getWowBalance(UserId);
          // console.log("escrowtransactiondone", response);
          toast.success("Approved Successfully");
          getAllPosts();
        });
    } catch (error) {
      // console.log(error);
    }
  }

  // const escrowTradeCancel = (id) => {
  //   const cancel = document.getElementById("cancel");
  //   cancel.disabled = true;
  //   axios.get(`${baseURL}/api/wallet/delete_transaction_from_p1/${id}`)
  //     .then(res => {
  //       toast.success("Cancel Trade Successfully");
  //       getWowBalance(UserId);
  //       // console.log("Canceltrade.......", res.data);
  //       getAllPosts();
  //     }).catch(error => {
  //       // console.log("error:", error);
  //       // toast.error("Trade Cancel Failed");
  //       window.location.reload();
  //     })
  // }

  useEffect(() => {
    getAllPosts()

  }, [])

  return (
    <div className="main-app">

      {/* Display all the posts */}
      {getPostData(posts)}

      {/* Using React Paginate */}
      <ReactPaginate
        previousLabel={t('Withdraw.Previous')}
        nextLabel={t('Withdraw.Next')}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"} />
    </div>
  );
}

export default Escrowview;

// import React, { useState, useEffect } from 'react'
// import axios from 'axios'
// import ReactPaginate from 'react-paginate';
// import { useTranslation, withTranslation } from "react-i18next";
// import Escrow from './Escrow';

// const baseURL = process.env.REACT_APP_BASE_URL;
// const UserId = localStorage.getItem('userId');

// function Escrowview() {
//   const [postsPerPage] = useState(10);
//   const [offset, setOffset] = useState(1);
//   const [posts, setAllPosts] = useState([]);
//   const [pageCount, setPageCount] = useState(0)
//   const [t, i18n] = useTranslation('common');
//   const [data, setData] =  useState([]);
//   const [allescrow, setAllEscrow] = useState([]);
//   const getPostData = (data) => {


//     return (
//       <section className=" hero--full hero--v10 d-flex align-items-center hidden" style={{ minHeight: '300px', paddingBottom: '0px',paddingTop: "150px" }}>
//          <div class="container">
//           <br/><br/><br/>

//           <Escrow/>
//           <div class="table">
//           <table class="table">
//                       <thead>
//                         <tr>
//                           <th scope="col" className="padabc">{t('newoffers.Trade Id')}</th>
//                           <th scope="col" className="padabc">{t('newoffers.Escrow Amount')}</th>
//                           <th scope="col" className="padabc">{t('newoffers.Action')}</th>
//                         </tr>
//                       </thead>
//                       {allescrow ?
//                         <tbody>
//                           {allescrow.map(item => (
//                             <tr key={item.id}>
//                               <th scope="row" key={item.id} className="padabc">{item?.id}</th>
//                               <td key={item.id} className="padabc">{item?.amount}</td>
//                               <td key={item.id} className="padabc"><button onClick={() => escrowApprove(item?.id, item?.userid, item?.to_address, item?.amount)} id="approve" style={{ background: "#00cc90", color: "#fff" }}>{t('newoffers.APPROVE')}</button><button onClick={() => escrowTradeCancel(item?.id)} style={{ background: "#f28d8a", color: "#fff" }} id="cancel">{t('newoffers.Cancel Trade')}</button></td>

//                             </tr>
//                           ))}
//                         </tbody>
//                         :
//                         <tbody>
//                           <tr >
//                             <h6 style={{ color: "grey" }}>No trade in Escrow</h6>
//                           </tr>
//                         </tbody>}
//                     </table>
//           </div>
//         </div>

//       </section>

//     )

//   }

//   const getAllPosts = async () => {
//     const res = await axios.get(`${baseURL}/api/wallet/p1_list_escrocoin/${UserId}`);
//     // console.log("transaction data", res);
//     const data = res.data.data;
//     setData(res.data.data);
//     const slice = data.slice(offset - 1, offset - 1 + postsPerPage)
//     // console.log('slice:',slice);


//     // For displaying Data
//     const postData = getPostData(slice)

//     // Using Hooks to set value
//     setAllPosts(postData)
//     setPageCount(Math.ceil(data.length / postsPerPage))

//   }

//   const handlePageClick = (event) => {
//     const selectedPage = event.selected;
//     setOffset(selectedPage + 1)

//     let start = selectedPage * postsPerPage;
//     let end  = start+ postsPerPage;
//     const slice = data.slice(start, end);
//     // console.log('slice:',slice);
//     const postData = getPostData(slice);
//     setAllPosts(postData)
//     setPageCount(Math.ceil(data.length / postsPerPage))
//   };

//   const escrowApprove = (id, userid, to_address, amount) => {
//     // console.log('escrowApprove id:',id)
//     const button = document.getElementById('approve');
//     button.disabled = true
//     try {
//       const data = {
//         userid: userid,
//         to_address: to_address,
//         amount: amount,
//       }
//       // console.log("sdata", data);
//       axios.post(`${baseURL}/api/wallet/p1_permission_p2/${id}`, data)
//         .then((response) => {
//           // console.log("escrowtransactiondone", response);
//           window.location.reload();
//           localStorage.setItem("Approve", "Approved Successfully!")
//           // console.log("Hello");
//         });
//     } catch (error) {
//       // console.log(error);
//     }
//   }

//   const escrowTradeCancel = (id) => {
//     const cancel = document.getElementById("cancel");
//     cancel.disabled = true;
//     axios.get(`${baseURL}/api/wallet/delete_transaction_from_p1/${id}`)
//       .then(res => {
//         // toast.success("Cancel Trade Successfully");
//         // console.log("Canceltrade.......", res.data);
//         window.location.reload();
//       }).catch(error => {
//         // console.log("error:", error);
//         // toast.error("Trade Cancel Failed");
//         window.location.reload();
//       })
//   }

//   useEffect(() => {
//     getAllPosts()
//   }, [])

//   return (
//     <div className="main-app">

//       {/* Display all the posts */}
//       {posts}

//       {/* Using React Paginate */}
//       <ReactPaginate
//         previousLabel={t('Withdraw.Previous')}
//         nextLabel={t('Withdraw.Next')}
//         breakLabel={"..."}
//         breakClassName={"break-me"}
//         pageCount={pageCount}
//         onPageChange={handlePageClick}
//         containerClassName={"pagination"}
//         subContainerClassName={"pages pagination"}
//         activeClassName={"active"} />
//     </div>
//   );
// }

// export default Escrowview;